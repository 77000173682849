import React, { useState, useEffect, createRef } from 'react';
import {
  Icon,
  Image,
  Header,
  Popup,
  Menu,
  Divider,
  Loader,
  Dimmer,
  Segment,
  Radio,
  Form,
  Input,
  Checkbox,
  Modal,
  Button,
  Tab,
} from 'semantic-ui-react';
import {
  humanize,
  getUserProfileByID,
  IMAGES_URL,
  deleteProcedure,
} from '../../../config/functions';

import md5 from 'md5';
import Moment from 'react-moment';

import { useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from '../../../config/i18n/slice';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useHistory } from 'react-router-dom';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import { LetteredAvatarImageUrl } from '../../../components/common/userProfilePlaceholder';
import { toast } from 'react-toastify';
import {
  CheckboxIcon,
  TextIcon,
  NumberIcon,
  AmountIcon,
  MultipleChoiceIcon,
  ChecklistIcon,
  InspectionIcon,
  SignatureIcon,
  DateIcon,
  FileIcon,
  PhotoIcon,
  HeadingIcon,
  CloseIcon,
  DeleteIcon,
  EditTextIcon,
  ShareIcon,
  FileDownloadIcon,
  DownloadIconBucket,
  Attachment,
  Success,
  Close,
} from '../../../../Assets/Icons/svg';

import { useDeletePermissionModal } from '../../../../hooks/Messages/useDeletePermissionModal';
import { useDeleteResultModal } from '../../../../hooks/Messages/useDeleteResultModal';
import moment from 'moment';
import DownloadExcelFileWithData from '../../../../services/DownloadExcelFileWithData';
import DownloadPdfFileWithData from '../../../../services/DownloadPdfFileWithData';
import Tooltip from 'react-power-tooltip';
import { fetchProcedureInitData } from '../../../../redux/reducers/procedureReducer';
import Toggle from 'react-toggle';
import Logs from './log';
import Details from './details';
import { Resizable } from 're-resizable';

function Preview({
  toggled,
  untoggle,
  targetedData,
  edit,
  refresh,
  setRefresh,
  setModalWidth,
  modalWidth,
  setSearchTitle,
  setFilterOptions
}) {
  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const { setDeleteResultModal, DeleteResultModal } = useDeleteResultModal();
  const t = useSelector(selectTranslations);
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const panes = [
    {
      menuItem: t.common.details,
      pane: {
        key: 'details',
        content: (
          <div className="pane-body">
            <Details untoggle={untoggle} targetedData={targetedData} />
          </div>
        ),
      },
    },
    // {
    //   menuItem: t.workOrders.details_view.log,
    //   pane: {
    //     key: 'log',
    //     content: (
    //       <div className="pane-body">
    //         <Logs targetedData={targetedData} />
    //       </div>
    //     ),
    //   },
    // },
  ];

  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);
  const [procedureFields, setProcedureFields] = useState([]);
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);

  const [showExclTooltip, setShowExclTooltip] = useState(false);
  const [showPdfTooltip, setShowPdfTooltip] = useState(false);
  const [showShareTooltip, setShareTooltip] = useState(false);
  const [showEditTooltip, setEditTooltip] = useState(false);
  const [showDeleteTooltip, setDeleteTooltip] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [showAttachmentTooltip, setAttachmentTooltip] = useState(false);
  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    if (data) {
      untoggle();
      setModalWidth(Math.max(window.innerWidth / 2, 514));
    }
  };
  const deleteHandler = async () => {
    const response = await deleteProcedure(targetedData.id);

    // console.log(response);

    if (response.status == 200) {
      setDeleteErrorMessage(false);
      toast.success(t.procedures.form.delete_success_statement);
      setFilterOptions({
        category: [],
        createdByUser: [],
      });
      setSearchTitle('');
      toggleDeleteSuccessModal('close');

    } else if (response.status == 400) {
      setDeleteErrorMessage(true);
      toast.error(t.procedures.form.delete_connected_error_statement);
      toggleDeleteSuccessModal();
    } else {
      toast.error(t.common.something_wrong);
    }
  };
  const deleteSuccessHandler = (data) => {
    if (data) {
      dispatch(fetchProcedureInitData(organisationId));
      untoggle();
    }
  };

  const fieldTypes = [
    {
      key: 'checkbox',
      value: 'checkbox',
      text: t.procedures.form.type_options[0],
      icon: <CheckboxIcon width="24px" height="24px" />,
    },
    {
      key: 'text',
      value: 'text',
      text: t.procedures.form.type_options[1],
      icon: <TextIcon width="24px" height="24px" />,
    },
    {
      key: 'number',
      value: 'number',
      text: t.procedures.form.type_options[2],
      icon: <NumberIcon width="24px" height="24px" />,
    },
    {
      key: 'amount',
      value: 'amount',
      text: t.procedures.form.type_options[3],
      icon: <AmountIcon width="24px" height="24px" />,
    },
    {
      key: 'multipleChoice',
      value: 'multipleChoice',
      text: t.procedures.form.type_options[4],
      icon: <MultipleChoiceIcon width="24px" height="24px" />,
    },
    {
      key: 'checklist',
      value: 'checklist',
      text: t.procedures.form.type_options[5],
      icon: <ChecklistIcon width="24px" height="24px" />,
    },
    {
      key: 'inspectionCheck',
      value: 'inspectionCheck',
      text: t.procedures.form.type_options[6],
      icon: <InspectionIcon width="24px" height="24px" />,
    },
    {
      key: 'signature',
      value: 'signature',
      text: t.procedures.form.type_options[7],
      icon: <SignatureIcon width="24px" height="24px" />,
    },
    {
      key: 'date',
      value: 'date',
      text: t.procedures.form.type_options[8],
      icon: <DateIcon width="24px" height="24px" />,
    },
    {
      key: 'file',
      value: 'file',
      text: t.procedures.form.type_options[9],
      icon: <FileIcon width="24px" height="24px" />,
    },
    {
      key: 'photo',
      value: 'photo',
      text: t.procedures.form.type_options[10],
      icon: <PhotoIcon width="24px" height="24px" />,
    },
    {
      key: 'heading',
      value: 'heading',
      text: t.procedures.form.heading,
      icon: <HeadingIcon width="24px" height="24px" />,
    },
  ];

  const inspectionTypes = [
    {
      type: 'yesNoNA',
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
        { label: 'N/A', value: 'N/A' },
      ],
    },
    {
      type: 'goodFairPoorNA',
      options: [
        { label: 'Good', value: 'good' },
        { label: 'Fair', value: 'fair' },
        { label: 'Poor', value: 'poor' },
        { label: 'N/A', value: 'N/A' },
      ],
    },
    {
      type: 'safeRiskNA',
      options: [
        { label: 'Safe', value: 'safe' },
        { label: 'Risk', value: 'risk' },
        { label: 'N/A', value: 'N/A' },
      ],
    },
    {
      type: 'passFailNA',
      options: [
        { label: 'Pass', value: 'pass' },
        { label: 'Fail', value: 'fail' },
        { label: 'N/A', value: 'N/A' },
      ],
    },
    {
      type: 'compliantNonCompliantNA',
      options: [
        { label: 'Compliant', value: 'compliant' },
        { label: 'Non-Compliant', value: 'non-compliant' },
        { label: 'N/A', value: 'N/A' },
      ],
    },
    {
      type: 'scaleFive',
      options: [
        { label: '0', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
      ],
    },
  ];
  const categoryOptions = [
    {
      value: 'damage',
      label: t.workOrders.form.category_options[0],
    },
    {
      value: 'corrective action Downtime',
      label: t.workOrders.form.category_options[1],
    },
    {
      value: 'planned Downtime',
      label: t.workOrders.form.category_options[2],
    },
    {
      value: 'unplanned Downtime',
      label: t.workOrders.form.category_options[3],
    },
    {
      value: 'inspection',
      label: t.workOrders.form.category_options[4],
    },
    {
      value: 'electrical',
      label: t.workOrders.form.category_options[5],
    },
    {
      value: 'mechanical',
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: 'HVAC',
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: 'preventive maintenance',
      label: t.workOrders.form.category_options[8],
    },
    {
      value: 'project',
      label: t.workOrders.form.category_options[9],
    },
    {
      value: 'safety',
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: 'sop',
      label: t.workOrders.form.category_options[11],
    },
  ];
  const headers = [
    { label: t.procedures.table.ID, key: 'id' },
    { label: t.procedures.table.title, key: 'title' },
    { label: t.procedures.form.description, key: 'description' },
    { label: t.procedures.details_view.category, key: 'category' },
    { label: t.procedures.form.procedure_items, key: 'fields' },
    { label: t.procedures.details_view.assets, key: 'assets' },
    { label: t.procedures.details_view.locations, key: 'locations' },
    { label: t.procedures.details_view.parts, key: 'parts' },
    { label: t.procedures.table.createdByUser, key: 'createdByUser' },
    { label: t.procedures.table.createdAt, key: 'createdAt' },
    { label: t.procedures.table.updatedAt, key: 'updatedAt' },
  ];

  const categoryStatus = categoryOptions.find(
    (status) => status.value == targetedData?.attributes?.category
  );

  const downloadXLS = () => {
    let csvData = [];

    let procedure_data = [];

    procedure_data.id = targetedData.id;
    procedure_data.title = targetedData.attributes.title || t.common.na;
    procedure_data.description =
      targetedData.attributes.description || t.common.na;

    procedure_data.category = categoryStatus?.label || t.common.na;
    procedure_data.fields = targetedData.attributes.fields.length || 0;

    let assetsData = targetedData.attributes?.assets?.data;
    let assetsString = '';

    if (assetsData?.length > 0) {
      assetsString = assetsData
        .map((people) => people.attributes?.name)
        .join(', ');
    } else {
      assetsString = t.common.na;
    }

    procedure_data.assets = assetsString;

    let locationData = targetedData.attributes?.locations?.data;
    let locationString = '';

    if (locationData?.length > 0) {
      locationString = locationData
        .map((each) => each.attributes?.name)
        .join(', ');
    } else {
      locationString = t.common.na;
    }

    procedure_data.locations = locationString;

    let partsData = targetedData.attributes?.parts?.data;
    let partsString = '';

    if (partsData?.length > 0) {
      partsString = partsData.map((each) => each.attributes?.name).join(', ');
    } else {
      partsString = t.common.na;
    }

    procedure_data.parts = partsString;

    procedure_data.createdByUser =
      targetedData?.attributes?.createdByUser?.data?.attributes?.firstName ||
      t.common.na;
    procedure_data.createdAt =
      moment(targetedData.attributes.createdAt).format('DD/MM/YYYY LT') ||
      t.common.na;
    procedure_data.updatedAt =
      moment(targetedData.attributes.updatedAt).format('DD/MM/YYYY LT') ||
      t.common.na;

    csvData.push([
      procedure_data.id,
      procedure_data.title,
      procedure_data.description,
      procedure_data.category,
      procedure_data.fields,
      procedure_data.assets,
      procedure_data.locations,
      procedure_data.parts,
      procedure_data.createdByUser,
      procedure_data.createdAt,
      procedure_data.updatedAt,
    ]);
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 20 }, // Description column
      { wch: 25 }, // Category column
      { wch: 15 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
      { wch: 15 }, // Created By column
      { wch: 20 }, // Created At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      targetedData?.attributes?.title || 'Single-Procedure'
    );
  };

  const generatePDF = () => {
    let csvData = [];

    let procedure_data = [];

    procedure_data.id = targetedData.id;
    procedure_data.title = targetedData.attributes.title || t.common.na;
    procedure_data.description =
      targetedData.attributes.description || t.common.na;

    procedure_data.category = categoryStatus?.label || t.common.na;
    procedure_data.fields = targetedData.attributes.fields.length || 0;

    let assetsData = targetedData.attributes?.assets?.data;
    let assetsString = '';

    if (assetsData?.length > 0) {
      assetsString = assetsData
        .map((people) => people.attributes?.name)
        .join(', ');
    } else {
      assetsString = t.common.na;
    }

    procedure_data.assets = assetsString;

    let locationData = targetedData.attributes?.locations?.data;
    let locationString = '';

    if (locationData?.length > 0) {
      locationString = locationData
        .map((each) => each.attributes?.name)
        .join(', ');
    } else {
      locationString = t.common.na;
    }

    procedure_data.locations = locationString;

    let partsData = targetedData.attributes?.parts?.data;
    let partsString = '';

    if (partsData?.length > 0) {
      partsString = partsData.map((each) => each.attributes?.name).join(', ');
    } else {
      partsString = t.common.na;
    }

    procedure_data.parts = partsString;

    procedure_data.createdByUser =
      targetedData?.attributes?.createdByUser?.data?.attributes?.firstName ||
      t.common.na;
    procedure_data.createdAt =
      moment(targetedData.attributes.createdAt).format('DD/MM/YYYY LT') ||
      t.common.na;
    procedure_data.updatedAt =
      moment(targetedData.attributes.updatedAt).format('DD/MM/YYYY LT') ||
      t.common.na;

    csvData.push([
      procedure_data.id,
      procedure_data.title,
      procedure_data.description,
      procedure_data.category,
      procedure_data.fields,
      procedure_data.assets,
      procedure_data.locations,
      procedure_data.parts,
      procedure_data.createdByUser,
      procedure_data.createdAt,
      procedure_data.updatedAt,
    ]);

    const columnWidths = [, , , , , , , , , 22, 22];

    DownloadPdfFileWithData(
      headers,
      csvData,
      targetedData?.attributes?.title || t.procedures.procedure,
      columnWidths,
      'A3'
    );
  };

  useEffect(() => {
    if (targetedData?.attributes.fields.length > 0) {
      let newFields = targetedData.attributes.fields.map((field) => ({
        ...field,
        isExpanded: false,
      }));
      setProcedureFields(newFields);
    }
  }, [targetedData?.attributes.fields]);

  const expandedArray = [
    'checkbox',
    'multipleChoise',
    'checklist',
    'inspectionCheck',
  ];

  const generateSubFields = (field) => {
    const generatedOptions = (options) => {
      return options?.map((option, index) => {
        return (
          <div className="procedure-item-option">
            <p>{option.item}</p>
            <input type="checkbox" checked={false} />
          </div>
        );
      });
    };

    const generatedMultiOptions = (options) => {
      return options?.map((option, index) => {
        return (
          <div className="procedure-item-option">
            <p>{option.item}</p>
            <input type="checkbox" checked={false} />
          </div>
        );
      });
    };

    const generatedInspectionOptions = (field) => {
      let filteredArray = inspectionTypes.filter(
        (insp) => insp.type == field.inspectionType
      );
      filteredArray = filteredArray[0];

      let filteredFailed = filteredArray?.options?.filter(
        (option) => option?.value == field?.failedValue
      );
      filteredFailed = filteredFailed ? filteredFailed[0] : null;

      let logicAnswer = filteredArray?.options?.filter(
        (option) => option.value == field?.logicAnswer
      );
      logicAnswer = logicAnswer ? logicAnswer[0] : null;

      // console.log(logicAnswer);

      return filteredArray?.options?.map((option) => (
        <div
          style={
            filteredFailed?.value == option?.value
              ? { backgroundColor: '#fff6f6' }
              : {}
          }
          className="procedure-item-option"
        >
          <p>
            {option.label
              ? t.procedures.inspectionTypes[option.label.toLowerCase()] ||
                option.label
              : ''}
          </p>

          {/* <input
            type="checkbox"
            className="procedure-item-option-radio"
            checked={filteredFailed?.value == option?.value ? true : false}
          /> */}
        </div>
      ));
    };

    let returnFields = null;
    switch (field.fieldType) {
      case 'text':
        returnFields = (
          <Segment secondary>{t.procedures.form.text_placeholder}</Segment>
        );
        break;
      case 'number':
        returnFields = (
          <Input
            disabled
            placeholder={t.procedures.form.number_placeholder}
            fluid
          />
        );
        break;
      case 'amount':
        returnFields = (
          <Input
            disabled
            placeholder={t.procedures.form.amount_placeholder}
            fluid
          />
        );
        break;
      case 'signature':
        returnFields = (
          <Segment secondary>{t.procedures.form.signature_placeholder}</Segment>
        );
        break;
      case 'date':
        returnFields = (
          <Segment secondary>{t.procedures.form.date_placeholder} </Segment>
        );
        break;
      case 'file':
        // text,number,amount, signature, date, file fields
        //isrequired
        returnFields = (
          <Segment secondary>{t.procedures.form.file_placeholder}</Segment>
        );
        break;

      case 'photo':
        // text,number,amount, signature, date, file fields
        //isrequired
        returnFields = (
          <Segment secondary>{t.procedures.form.photo_placeholder}</Segment>
        );
        break;
      case 'multipleChoice':
        returnFields = generatedMultiOptions(field.options);
        break;
      case 'checklist':
        // multipleChoice fields
        //isRequired & options
        returnFields = generatedOptions(field.options);
        break;
      case 'inspectionCheck':
        // inspectionCheck fields
        //isRequired & inspectionTypes & logic
        returnFields = generatedInspectionOptions(field);

        break;
      default:
        returnFields = null;
    }

    return (
      <>
        {returnFields}
        <Divider />
        <div className="d-flex align-items-center">
          <Toggle
            // type="checkbox"
            className="toggle-data mt-2"
            checked={field.isRequired}
            disabled
          />
          {/* <input type="checkbox" checked={field.isRequired} className="" /> */}
          <span className="required-text ml-2 mt-2 mr-2">
            {t.procedures.form.is_required}
          </span>

          <div className="d-flex flex-wrap mt-2" style={{ maxWidth: '250px' }}>
            {field?.attachments?.data?.length > 0 && (
              <>
                {field?.attachments?.data?.map((prev) => {
                  return (
                    <>
                      <div
                        className="c-pointer"
                        onClick={() => {
                          window.open(
                            IMAGES_URL + prev.attributes.url,
                            '_blank'
                          );
                        }}
                        style={{ position: 'relative' }}
                        onMouseOver={() => setAttachmentTooltip(true)}
                        onMouseLeave={() => setAttachmentTooltip(false)}
                      >
                        <Attachment />
                        <Tooltip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position="bottom center"
                          show={showAttachmentTooltip}
                          textBoxWidth="66px"
                          arrowAlign="center"
                          fontSize="9px"
                          fontWeight="400"
                          fontFamily="Roboto"
                          padding="5px 5px 5px 7px"
                          borderRadius="5px"
                          moveUp="5px"
                          static
                        >
                          <span className="work-order-preview-tooltip">
                            {t.people.personal_info_form.attachments}
                          </span>
                        </Tooltip>
                      </div>
                      <span className="ml-2 mr-2 c-pointer">
                        {prev?.attributes?.name}
                      </span>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const generateFields = (fields) => {
    return procedureFields.map((field, index) =>
      field?.fieldType == 'heading' ? (
        <ProcedureItemContainerCard field={field} fieldTypes={fieldTypes}>
          {field?.label}
        </ProcedureItemContainerCard>
      ) : (
        <ProcedureItemContainerCard field={field} fieldTypes={fieldTypes}>
          <div className="item-value">{generateSubFields(field)}</div>
        </ProcedureItemContainerCard>
      )
    );
  };
  return (
    <>
      <div
        style={{ maxWidth: `${modalWidth}px` }}
        clearing
        className={
          toggled
            ? 'aside-modal preview active procedure'
            : 'aside-modal preview procedure'
        }
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header procedure-detail-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {t.procedures.procedure} #{targetedData?.attributes?.countId }
              </div>
              <div className="flexbox align-center procedure-detail-header-buttons">
                <span
                  className="procedure-header-button c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setShowPdfTooltip(true)}
                  onMouseLeave={() => setShowPdfTooltip(false)}
                  onClick={() => generatePDF()}
                >
                  <DownloadIconBucket />
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="bottom center"
                    arrowAlign="center"
                    show={showPdfTooltip}
                    textBoxWidth="60px"
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.export_pdf}
                    </span>
                  </Tooltip>
                </span>
                {/* <span
                className="procedure-header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShowExclTooltip(true)}
                onMouseLeave={() => setShowExclTooltip(false)}
                onClick={() => downloadXLS()}
              >
                <FileDownloadIcon />
                <Tooltip
                  color="#fff"
                  backgroundColor="#172B4D"
                 
                  show={showExclTooltip}
                  textBoxWidth="67px"
                  position="bottom center"
                  arrowAlign="center"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.export_excel}
                  </span>
                </Tooltip>
              </span> */}
                {/* <span
                className="procedure-header-button  c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShareTooltip(true)}
                onMouseLeave={() => setShareTooltip(false)}
              >
                <ShareIcon fill={'#788CA5'} />
                <Tooltip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="bottom center"
                  arrowAlign="center"
                  show={showShareTooltip}
                  textBoxWidth="40px"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.share}
                  </span>
                </Tooltip>
              </span> */}
                {userPermissions?.procedure?.edit ||
                checkForPermission(
                  targetedData?.attributes?.createdByUser?.data?.id
                ) ? (
                  <span
                    className="procedure-header-button c-pointer mt-1"
                    style={{ position: 'relative' }}
                    onMouseOver={() => setEditTooltip(true)}
                    onMouseLeave={() => setEditTooltip(false)}
                    onClick={() => {
                      edit();
                      setPopupIsOpen(false);
                    }}
                  >
                    <EditTextIcon />
                    <Tooltip
                      color="#fff"
                      backgroundColor="#172B4D"
                      show={showEditTooltip}
                      textBoxWidth="33px"
                      position="bottom center"
                      arrowAlign="center"
                      padding="5px 5px 5px 8px"
                      borderRadius="5px"
                      static
                    >
                      <span className="work-order-preview-tooltip">
                        {t.common.edit}
                      </span>
                    </Tooltip>
                  </span>
                ) : null}
                {userPermissions?.procedure?.delete ? (
                <span
                  className="procedure-header-button c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setDeleteTooltip(true)}
                  onMouseLeave={() => setDeleteTooltip(false)}
                  onClick={() => {
                    setDeletePermissionModal(true);
                    setPopupIsOpen(false);
                  }}
                >
                  <DeleteIcon />
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                   position="bottom center"
                  arrowAlign="center"
                    show={showDeleteTooltip}
                    textBoxWidth="41px"
                    
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.delete}
                    </span>
                  </Tooltip>
                </span>
              ) : null}
                <span
                  className={`procedure-header-button c-pointer ${
                    selectedLanguage == 'en' ? 'ml-1' : 'mr-3'
                  }`}
                  style={{ position: 'relative' }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() => {
                    untoggle();
                  }}
                >
                  <CloseIcon />
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == 'en'
                        ? 'bottom center'
                        : 'bottom center'
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
          <div className="body">
            {targetedData ? (
              <Tab panes={panes} renderActiveOnly={false} />
            ) : (
              <Dimmer active inverted>
                <Loader inverted>{t.common.loading}</Loader>
              </Dimmer>
            )}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => {
          untoggle();
        }}
      ></div>

      {/* Delete Permission Modal */}
      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={t.procedures.procedure}
      >
        <span>{t.procedures.form.delete_procedure_statement}</span>
      </DeletePermissionModal>

      {/* Delete Result Modal */}
      <DeleteResultModal
        closeCallback={() => {
          deleteSuccessHandler(deleteErrorMessage ? '' : 'close');
        }}
        deleteErrorMessage={deleteErrorMessage}
      >
        <div className="mb-2">
          {deleteErrorMessage ? <Close /> : <Success />}
        </div>
        <p className="status-modal-popup-message">
          {deleteErrorMessage
            ? t.procedures.form.delete_connected_error_statement
            : t.procedures.form.delete_success_statement}
        </p>
      </DeleteResultModal>
    </>
  );
}

const ProcedureItemContainerCard = ({ fieldTypes, field, children }) => {
  const generatedFieldType = (type) => {
    let filteredFields = fieldTypes.filter((field) => field.value == type);
    filteredFields = filteredFields[0];

    return (
      <>
        {filteredFields.icon}
        <div className="photo">{filteredFields.text}</div>
      </>
    );
  };
  return (
    <div>
      <div className="procedure-item-parent">
        <div className="title">
          {field?.fieldType == 'checkbox' ? (
            <input type="checkbox" checked className="l-2 mr-2" />
          ) : null}
          {field.label}
        </div>
        <div className="type-parent">
          {generatedFieldType(field?.fieldType)}
        </div>
      </div>
      {field?.fieldType && !['heading'].includes(field?.fieldType) ? (
        <div className="procedure-sub-item-parent">{children}</div>
      ) : (
        <div className="mb-5"></div>
      )}
    </div>
  );
};

export default Preview;
