import React from 'react';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../app/config/i18n/slice';
import {
  AddIcon,
  AddIconWithCircle,
  AmountIcon,
  CategoryInspection,
  CategoryMechanical,
  CheckboxIcon,
  ChecklistIcon,
  CloseIcon,
  CopyIcon,
  Damage,
  DateIcon,
  DeleteIcon,
  Electrical,
  FileIcon,
  FilePresentIcon,
  HeadingIcon,
  InspectionIcon,
  LogicIcon,
  MultipleChoiceIcon,
  NumberIcon,
  OperationProcedure,
  OptionIcon,
  OrderIcon,
  PhotoIcon,
  PictureIcon,
  Preventive,
  Project,
  Refrigeration,
  Safety,
  ShareIcon,
  SignatureIcon,
  Success,
  TextIcon,
  WhiteDownChevron,
} from '../../Assets/Icons/svg';
import { cloneDeep } from 'lodash';
import {
  Message,
  Header,
  Dropdown,
  Grid,
  Checkbox,
  Radio,
  Modal,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Segment,
  TextArea,
  List,
  DropdownMenu,
  DropdownItem,
} from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Asterisk from '../../app/components/common/Asterisk';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import Toggle from 'react-toggle';

function useProcedureFields() {
  const t = useSelector(selectTranslations);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const fieldTypes = [
    {
      key: 'checkbox',
      value: 'checkbox',
      text: (
        <span>
          <span className="ml-2 mr-2">
            <CheckboxIcon />
          </span>
          {t.procedures.form.type_options[0]}
        </span>
      ),
    },
    {
      key: 'text',
      value: 'text',
      text: (
        <span>
          <span className="ml-2 mr-2">
            <TextIcon />
          </span>
          {t.procedures.form.type_options[1]}
        </span>
      ),
    },
    {
      key: 'number',
      value: 'number',
      text: (
        <span>
          <span className="ml-2 mr-2">
            <NumberIcon />
          </span>
          {t.procedures.form.type_options[2]}
        </span>
      ),
    },
    {
      key: 'amount',
      value: 'amount',
      text: (
        <span>
          <span className="ml-2 mr-2">
            <AmountIcon />
          </span>
          {t.procedures.form.type_options[3]}
        </span>
      ),
    },
    {
      key: 'multipleChoice',
      value: 'multipleChoice',
      text: (
        <span>
          <span className="ml-2 mr-2">
            <MultipleChoiceIcon />
          </span>
          {t.procedures.form.type_options[4]}
        </span>
      ),
    },
    {
      key: 'checklist',
      value: 'checklist',
      text: (
        <span>
          <span className="ml-2 mr-2">
            <ChecklistIcon />
          </span>
          {t.procedures.form.type_options[5]}
        </span>
      ),
    },
    {
      key: 'inspectionCheck',
      value: 'inspectionCheck',
      text: (
        <span>
          <span className="ml-2 mr-2">
            <InspectionIcon />
          </span>
          {t.procedures.form.type_options[6]}
        </span>
      ),
    },
    {
      key: 'signature',
      value: 'signature',
      text: (
        <span>
          <span className="ml-2 mr-2">
            <SignatureIcon />
          </span>
          {t.procedures.form.type_options[7]}
        </span>
      ),
    },
    {
      key: 'date',
      value: 'date',
      text: (
        <span>
          <span className="ml-2 mr-2">
            <DateIcon />
          </span>
          {t.procedures.form.type_options[8]}
        </span>
      ),
    },
    {
      key: 'file',
      value: 'file',
      text: (
        <span>
          <span className="ml-2 mr-2">
            <FileIcon />
          </span>
          {t.procedures.form.type_options[9]}
        </span>
      ),
    },
    {
      key: 'photo',
      value: 'photo',
      text: (
        <span>
          <span className="ml-2 mr-2">
            <PhotoIcon />
          </span>
          {t.procedures.form.type_options[10]}
        </span>
      ),
    },
  ];

  const inspectionTypes = [
    {
      key: 'yesNoNA',
      value: 'yesNoNA',
      text: t.procedures.form.inspection_types[0].join(' - '),
      logicAnswers: [
        {
          key: 0,
          value: 'yes',
          text: t.procedures.form.inspection_types[0][0],
        },
        {
          key: 1,
          value: 'no',
          text: t.procedures.form.inspection_types[0][1],
        },
        {
          key: 2,
          value: 'na',
          text: t.procedures.form.inspection_types[0][2],
        },
      ],
    },
    {
      key: 'goodFairPoorNA',
      value: 'goodFairPoorNA',
      text: t.procedures.form.inspection_types[1].join(' - '),
      logicAnswers: [
        {
          key: 0,
          value: 'good',
          text: t.procedures.form.inspection_types[1][0],
        },
        {
          key: 1,
          value: 'fair',
          text: t.procedures.form.inspection_types[1][1],
        },
        {
          key: 2,
          value: 'poor',
          text: t.procedures.form.inspection_types[1][2],
        },
        {
          key: 3,
          value: 'na',
          text: t.procedures.form.inspection_types[1][3],
        },
      ],
    },
    {
      key: 'passFailNA',
      value: 'passFailNA',
      text:t.procedures.form.inspection_types[2].join(' - '), 
      logicAnswers: [
        {
          key: 0,
          value: 'pass',
          text: t.procedures.form.inspection_types[2][0],
        },
        {
          key: 1,
          value: 'fail',
          text: t.procedures.form.inspection_types[2][1],
        },
        {
          key: 2,
          value: 'na',
          text: t.procedures.form.inspection_types[2][2],
        },
      ],
    },
    {
      key: 'safeRiskNA',
      value: 'safeRiskNA',
      text: t.procedures.form.inspection_types[3].join(' - '),
      logicAnswers: [
        {
          key: 0,
          value: 'safe',
          text: t.procedures.form.inspection_types[3][0],
        },
        {
          key: 1,
          value: 'risk',
          text: t.procedures.form.inspection_types[3][1],
        },
        {
          key: 2,
          value: 'na',
          text: t.procedures.form.inspection_types[3][2],
        },
      ],
    },
   
    {
      key: 'compliantNonCompliantNA',
      value: 'compliantNonCompliantNA',
      text: t.procedures.form.inspection_types[4].join(' - '),
      logicAnswers: [
        {
          key: 0,
          value: 'compliant',
          text: t.procedures.form.inspection_types[4][0],
        },
        {
          key: 1,
          value: 'non-compliant',
          text: t.procedures.form.inspection_types[4][1],
        },
        {
          key: 2,
          value: 'na',
          text: t.procedures.form.inspection_types[4][2],
        },
      ],
    },
    {
      key: 'scaleFive',
      value: 'scaleFive',
      text: t.procedures.form.inspection_types[5][0],
      logicAnswers: [
        {
          key: 0,
          value: '0',
          text: '0',
        },
        {
          key: 1,
          value: '1',
          text: '1',
        },
        {
          key: 2,
          value: '2',
          text: '2',
        },
        {
          key: 3,
          value: '3',
          text: '3',
        },
        {
          key: 4,
          value: '4',
          text: '4',
        },
        {
          key: 5,
          value: '5',
          text: '5',
        },
      ],
    },
  ];

  const logicTriggers = [
    {
      key: 0,
      value: 'action',
      icon: 'bullhorn',
      text: t.procedures.form.then_options[0],
    },
    {
      key: 1,
      value: 'evidence',
      icon: 'images',
      text: t.procedures.form.then_options[1],
    },
  ];

  const generatedFields = (
    fields,
    setFields,
    validationProcedureItems,
    setValidationProcedureItems,
    attachmentsRef,
    setActiveIndex
  ) => {
    const handleFieldChange = (id, type) => (event, value) => {

      // 1. Make a shallow copy of the items
      let currentFieldValue = '';
      let newfields = cloneDeep(fields);
      // 2. Make a shallow copy of the item you want to mutate
      let item = cloneDeep(fields[id]);
      // 3. Replace the property you're intested in

      if (type == 'label') {
        item.label = event.target.value;
        currentFieldValue = event.target.value;
      }
      if (type == 'fieldType') {
        // setDefaultField(value.value);
        item.fieldType = value.value;
        currentFieldValue = value.value;
        if (item?.fieldType == 'inspectionCheck' && !item?.inspectionType) {
          item.inspectionType = inspectionTypes[0].value;
        }
        if (item.inspectionType) {
          let values = inspectionTypes.find(
            (one) => one.value === item.inspectionType
          );
          if (values) {
            item.failedValue = values.logicAnswers[0].value;
          }
        }
      }
      if (type == 'isRequired') item.isRequired = value.checked;

      if (type == 'inspectionType') {
        item.inspectionType = value.value;
        item.logicTrigger = null;
        item.logicAnswer = '';
        item.actionDetails = '';
        item.evidenceDetails = '';
        if (item.inspectionType) {
          let values = inspectionTypes.find(
            (one) => one.value === item.inspectionType
          );
          if (values) {
            item.failedValue = values.logicAnswers[0].value;
          }
        }
      }

      if (type == 'failedValue') item.failedValue = String(value.value);

      if (type == 'logic') {
        event.stopPropagation();
        item.logic = item.logic ? false : true;
        if (!item.logic) {
          item.logicTrigger = null;
          item.logicAnswer = '';
          item.actionDetails = '';
          item.evidenceDetails = '';
        }
      }
      if (type == 'logicAnswer') item.logicAnswer = String(value.value);
      if (type == 'logicTrigger') item.logicTrigger = value.value;
      if (type == 'actionDetails') {
        item.actionDetails = event.target.value;
      }

      if (type == 'evidenceDetails') {
        item.evidenceDetails = event.target.value;
      }

      if (type == 'active') {
        newfields.forEach((el) => (el.active = false));
        item.active = true;
        setActiveIndex(id);
      }
      // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
      newfields[id] = item;

      /////     Validate the item data and set validation array for items

      let validationProcedureItemsFields = [...validationProcedureItems];
      let ItemFieldValidation = { ...validationProcedureItemsFields[id] };
      if (ItemFieldValidation && ItemFieldValidation[type]) {
        let currentValidation = {
          ...ItemFieldValidation,
          [type]: {
            ...ItemFieldValidation[type],
            error: ItemFieldValidation[type].pattern(currentFieldValue)
              ? false
              : true,
            skipped: true,
          },
        };
        //console.log('current', currentValidation);
        validationProcedureItemsFields[id] = currentValidation;
        setValidationProcedureItems(validationProcedureItemsFields);
      }

      ////////////////////////////////////////////////////////////////////////////////////////////
      // //console.log(newfields[id]);
      // 5. Set the state to our new copy
      setFields(newfields);
    };
    const removeThisField = (index) => (e) => {
      e.stopPropagation();
      let newfields = [...fields];
      newfields.splice(index, 1);
      let newItemValidationFields = [...validationProcedureItems];
      newItemValidationFields.splice(index, 1);
      setFields(newfields);
      setValidationProcedureItems(newItemValidationFields);
    };

    const handleDeleteAttachment = (attachmentId) => {
      setFields((prevFields) => {
        return prevFields.map((prevField) => {
          const newPrevAttachments = prevField.prevAttachments.filter(
            (prev) => prev.id !== attachmentId
          );
          const newAttachments = prevField.attachments.filter(
            (id) => id !== attachmentId
          );
          return {
            ...prevField,
            prevAttachments: newPrevAttachments,
            attachments: newAttachments,
          };
        });
      });
    };

    const updateValidationData = (startIndex, endIndex) => {
      const newValidationData = [...validationProcedureItems];
      const movedItem = newValidationData.splice(startIndex, 1)[0];
      newValidationData.splice(endIndex, 0, movedItem);

      // Update validationProcedureItems state
      setValidationProcedureItems(newValidationData);
    };

    const handleDragEnd = (result) => {
      if (!result.destination) return;

      const reorderedFields = [...fields];
      const [removed] = reorderedFields.splice(result.source.index, 1);
      reorderedFields.splice(result.destination.index, 0, removed);

      setFields(reorderedFields);

      updateValidationData(result.source.index, result.destination.index);
    };

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="fields">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {fields.map((field, index) => (
                <Draggable
                  key={field?.key}
                  draggableId={field?.key?.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        // Ensure the dragged item remains visible
                        left: '0px',
                        userSelect: 'none',
                        position: 'static',
                        background:"white"
                      }}
                    >
                      <div className="mb-5" tabIndex="1">
                        {/* <div className="procedure-form-item-header"> */}
                        {field.fieldType == 'heading' ? (
                          <div className="main-div header m-1 mt-5">
                         <div  style={{ width: '-webkit-fill-available' }}>
                              <div className="header-title-change-procedure blue">
                                <div className="title-div d-flex">
                                  <div
                                    {...provided.dragHandleProps}
                                    className="mr-1 mt-2"
                                  >
                                    <OrderIcon />{' '}
                                  </div>
                                  <>
                                    <Input
                                      autoComplete="new-password"
                                   style={{ width: '-webkit-fill-available' }}
                                      placeholder={
                                        t.procedures.form.title_placeholder
                                      }
                                      value={field?.label}
                                      onChange={handleFieldChange(
                                        index,
                                        'label'
                                      )}
                                    />
                                  </>
                                  {fields && fields.length > 1 ? (
                                    <div
                                      className="mt-2"
                                      onClick={removeThisField(index)}
                                    >
                                      <DeleteIcon />
                                    </div>
                                  ) : null}
                                  <div className="ml-1 mt-2">
                                    <OptionIcon />
                                  </div>
                                </div>
                                {validationProcedureItems[index]?.label
                                  ?.error &&
                                validationProcedureItems[index]?.label
                                  ?.skipped ? (
                                  <div className="label-error ml-8 mr-8">
                                    {
                                      validationProcedureItems[index].label
                                        .message
                                    }
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        ) :(
                          <div className="main-div m-3 mt-5 ">
                            <div className="">
                              
                                <>
                                  <div className="header-title-change-procedure">
                                    <div className="title-div d-flex">
                                      <div
                                        {...provided.dragHandleProps}
                                        className="mr-1 mt-2"
                                      >
                                        <OrderIcon />{' '}
                                      </div>
                                      <>
                                        <Input
                                          autoComplete="new-password"
                                       style={{ width: '-webkit-fill-available' }}
                                          placeholder={
                                            t.procedures.form.title_placeholder
                                          }
                                          value={field?.label}
                                          onChange={handleFieldChange(
                                            index,
                                            'label'
                                          )}
                                        />
                                      </>

                                      {/* {field.fieldType == 'checkbox' ? (  <div>
                                    <div className="flexbox align-center">
                                      <Checkbox disabled />
                                      <Input
                                        autoComplete="new-password"
                                        fluid
                                        placeholder={
                                          t.procedures.form.checkbox_label
                                        }
                                        style={{
                                          width: '100%',
                                          marginLeft: '5px',
                                        }}
                                        value={field.label}
                                        onChange={handleFieldChange(
                                          index,
                                          'label'
                                        )}
                                      />
                                    </div>

                                    {validationProcedureItems[index]?.label
                                      ?.error &&
                                    validationProcedureItems[index]?.label
                                      ?.skipped ? (
                                      <div className="label-error">
                                        {
                                          validationProcedureItems[index].label
                                            .message
                                        }
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>) : (
                                    <Input
                                      autoComplete="new-password"
                                      defaultValue={"njdjss"}
                                      style={{width:"379px"}}
                                      placeholder={
                                        t.procedures.form.title_placeholder
                                      }
                                      value={field?.label}
                                      onChange={handleFieldChange(
                                        index,
                                        'label'
                                      )}
                                    />
                                    {validationProcedureItems[index]?.label
                                      ?.error &&
                                    validationProcedureItems[index]?.label
                                      ?.skipped ? (
                                      <div className="label-error">
                                        {
                                          validationProcedureItems[index].label
                                            .message
                                        }
                                      </div>
                                    ) : (
                                      ''
                                    )} )} */}
                                      <div className="ml-1 mt-2">
                                        <OptionIcon />
                                      </div>
                                    </div>
                                    {validationProcedureItems[index]?.label
                                      ?.error &&
                                    validationProcedureItems[index]?.label
                                      ?.skipped ? (
                                      <div className="label-error ml-8 mr-8">
                                        {
                                          validationProcedureItems[index].label
                                            .message
                                        }
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>

                                  {/* <label className="label-item">
                                  {field.fieldType == 'checkbox'
                                    ? t.procedures.form.checkbox_label
                                    : t.procedures.form.title}
                                
                                  <Asterisk color="#172B4D" />
                                </label> */}

                                  {/* {field.fieldType == 'checkbox' ? (
                                  <div>
                                    <div className="flexbox align-center">
                                      <Checkbox disabled />
                                      <Input
                                        autoComplete="new-password"
                                        fluid
                                        placeholder={
                                          t.procedures.form.checkbox_label
                                        }
                                        style={{
                                          width: '100%',
                                          marginLeft: '5px',
                                        }}
                                        value={field.label}
                                        onChange={handleFieldChange(
                                          index,
                                          'label'
                                        )}
                                      />
                                    </div>

                                    {validationProcedureItems[index]?.label
                                      ?.error &&
                                    validationProcedureItems[index]?.label
                                      ?.skipped ? (
                                      <div className="label-error">
                                        {
                                          validationProcedureItems[index].label
                                            .message
                                        }
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    <Input
                                      autoComplete="new-password"
                                      fluid
                                      placeholder={
                                        t.procedures.form.title_placeholder
                                      }
                                      value={field.label}
                                      onChange={handleFieldChange(
                                        index,
                                        'label'
                                      )}
                                    />
                                    {validationProcedureItems[index]?.label
                                      ?.error &&
                                    validationProcedureItems[index]?.label
                                      ?.skipped ? (
                                      <div className="label-error">
                                        {
                                          validationProcedureItems[index].label
                                            .message
                                        }
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                )} */}

                                  <Form.Field style={{ minHeight: '60px' }}>
                                    <label className="label-item mt-9">
                                      {t.procedures.form.type}
                                      {/* <Icon name="asterisk" color="red" size="small" /> */}
                                      <Asterisk color="#172B4D" />
                                    </label>
                                    <Dropdown
                                      placeholder={t.procedures.form.type}
                                      fluid
                                      noResultsMessage={
                                        t.common.no_results_found
                                      }
                                      selection
                                      options={fieldTypes}
                                      value={field.fieldType}
                                      onChange={handleFieldChange(
                                        index,
                                        'fieldType'
                                      )}
                                    />
                                  </Form.Field>
                                </>
                          
                            </div>

                            <div className="hide-on-non-focused mt-8">
                              {/* Additional fields go here */}
                              {generatedSubFields(
                                field.fieldType,
                                field,
                                index,
                                fields,
                                setFields,
                                validationProcedureItems,
                                setValidationProcedureItems,
                                handleFieldChange
                              )}
                              {field?.prevAttachments?.length > 0
                                ? field.prevAttachments?.map((prev, index) => {
                                    return (
                                      <span
                                        onClick={() =>
                                          attachmentsRef.current.click()
                                        }
                                        className="file-name-show-procedure"
                                      >
                                        {/* <FilePresentIcon /> */}
                                        <label className="m-0">
                                          <span className="ml-2 mr-2">
                                            <PictureIcon />
                                          </span>{' '}
                                          {prev.name}
                                        </label>

                                        <span
                                          className="ml-3 mr-3 c-pointer"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteAttachment(prev.id);
                                          }}
                                        >
                                          <CloseIcon />
                                        </span>
                                      </span>
                                    );
                                  })
                                : null}
                              {/* <Divider /> */}
                              <div className="d-flex justify-content-between mt-5">
                                <div
                                  style={
                                    field.fieldType != 'heading'
                                      ? {}
                                      : {
                                          marginTop: '20px',
                                          justifyContent: 'flex-end',
                                        }
                                  }
                                  className={`flexbox align-center procedure-actions ${
                                    field.fieldType === 'signature' ||
                                    field.fieldType === 'file' ||
                                    // field.fieldType === 'checklist' ||
                                    // field.fieldType === 'text' ||
                                    // field.fieldType === 'amount' ||
                                    // field.fieldType === 'number' ||
                                    field.fieldType === 'photo'
                                      ? 'justify-start'
                                      : 'justify-end'
                                  } `}
                                  basic
                                >
                                  {field.fieldType != 'heading' && (
                                    <div
                                      className="flexbox align-center change-svg-procedure flex-wrap"
                                      style={{ maxWidth: '178px' }}
                                    >
                                      {/* {field.prevAttachments?.length > 0 ? (
                                      field.prevAttachments?.map(
                                        (prev, index) => {
                                          return (
                                            <span
                                              onClick={() =>
                                                attachmentsRef.current.click()
                                              }
                                            >
                                              <FilePresentIcon />
                                              <label className="m-0">
                                                {prev.name}
                                              </label>

                                              <span
                                                className="ml-3 mr-3 c-pointer"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleDeleteAttachment(
                                                    prev.id
                                                  );
                                                }}
                                              >
                                                <DeleteIcon />
                                              </span>
                                            </span>
                                          );
                                        }
                                      )
                                    ) : ( */}
                                      <span
                                        onClick={() =>
                                          {setActiveIndex(index);attachmentsRef.current.click()}
                                        }
                                        // onClick={() => {
                                        //   handleFieldChange(index, 'active')(
                                        //     {},
                                        //     {}
                                        //   );
                                        //   attachmentsRef.current.click();
                                        // }}
                                      >
                                        <FilePresentIcon />
                                        <label className="m-0"></label>
                                      </span>
                                      {/* )} */}
                                    </div>
                                  )}
                                  {fields && fields.length > 1 ? (
                                    <div
                                      className="buttun-type-link remove"
                                      basic
                                      onClick={removeThisField(index)}
                                    >
                                      <DeleteIcon
                                        width="20"
                                        height="20"
                                        fill="red"
                                      />
                                      {t.procedures.form.remove_button}
                                    </div>
                                  ) : null}
                                </div>

                                <div
                                  className={`flexbox align-center ${
                                    selectedLanguage == 'en'
                                      ? ''
                                      : 'rtl-container'
                                  }`}
                                >
                                  {field.fieldType != 'heading' && (
                                    <>
                                      <span className="required-text ml-2 mr-2">
                                        {t.procedures.form.is_required}
                                      </span>

                                      <Toggle
                                        // type="checkbox"
                                        className="toggle-data"
                                        checked={field.isRequired}
                                        onChange={(e) =>
                                          handleFieldChange(
                                            index,
                                            'isRequired'
                                          )({}, { checked: e.target.checked })
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )  }
                        {/* <p>
                            {t.procedures.form.add_procedure_item}{' '}
                            {` ${index + 1} `}
                          </p> */}
                        {/* </div> */}
                  
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );

    // return fields.map((field, index) => (
    //   <>
    //     <div
    //       tabindex="1"
    //       className="mb-5"
    //       //   onClick={handleFieldChange(index, 'active')}
    //       // className={field.active ? 'active-field' : ''}
    //     >

    //       <div className="procedure-form-item-header">
    //         <p>
    //           {t.procedures.form.add_procedure_item}
    //           {` ${index + 1} `}
    //         </p>
    //       </div>
    //       <div className="mt-5">
    //         {field.fieldType == 'heading' ? (
    //           <Form>
    //             <Form.Field>
    //               <label className="label-item">
    //                 {t.procedures.form.add_heading}
    //                 {/* <Icon name="asterisk" color="red" size="small" /> */}
    //                 <Asterisk color="#172B4D" />
    //               </label>
    //               <Input
    //                 autoComplete="new-password"
    //                 fluid
    //                 placeholder={t.procedures.form.add_heading}
    //                 value={field.label}
    //                 onChange={handleFieldChange(index, 'label')}
    //               />
    //               {validationProcedureItems[index]?.label?.error &&
    //               validationProcedureItems[index]?.label?.skipped ? (
    //                 <div className="label-error">
    //                   {validationProcedureItems[index].label.message}
    //                 </div>
    //               ) : (
    //                 ''
    //               )}
    //             </Form.Field>
    //           </Form>
    //         ) : (
    //           <Form>
    //             <Form.Field className="mb-0">
    //               <label className="label-item">
    //                 {field.fieldType == 'checkbox'
    //                   ? t.procedures.form.checkbox_label
    //                   : t.procedures.form.title}
    //                 {/* <Icon name="asterisk" color="red" size="small" /> */}
    //                 <Asterisk color="#172B4D" />
    //               </label>

    //               {field.fieldType == 'checkbox' ? (
    //                 <div>
    //                   <div className="flexbox align-center">
    //                     <Checkbox disabled />
    //                     <Input
    //                       autoComplete="new-password"
    //                       fluid
    //                       placeholder={t.procedures.form.checkbox_label}
    //                       style={{ width: '100%', marginLeft: '5px' }}
    //                       value={field.label}
    //                       onChange={handleFieldChange(index, 'label')}
    //                     />
    //                   </div>

    //                   {validationProcedureItems[index]?.label?.error &&
    //                   validationProcedureItems[index]?.label?.skipped ? (
    //                     <div className="label-error">
    //                       {validationProcedureItems[index].label.message}
    //                     </div>
    //                   ) : (
    //                     ''
    //                   )}
    //                 </div>
    //               ) : (
    //                 <div>
    //                   <Input
    //                     autoComplete="new-password"
    //                     fluid
    //                     placeholder={t.procedures.form.title_placeholder}
    //                     value={field.label}
    //                     onChange={handleFieldChange(index, 'label')}
    //                   />
    //                   {validationProcedureItems[index]?.label?.error &&
    //                   validationProcedureItems[index]?.label?.skipped ? (
    //                     <div className="label-error">
    //                       {validationProcedureItems[index].label.message}
    //                     </div>
    //                   ) : (
    //                     ''
    //                   )}
    //                 </div>
    //               )}
    //             </Form.Field>

    //             <Form.Field>
    //               <label className="label-item">
    //                 {t.procedures.form.type}
    //                 {/* <Icon name="asterisk" color="red" size="small" /> */}
    //                 <Asterisk color="#172B4D" />
    //               </label>
    //               <Dropdown
    //                 placeholder={t.procedures.form.type}
    //                 fluid
    //                 selection
    //                 options={fieldTypes}
    //                 value={field.fieldType}
    //                 onChange={handleFieldChange(index, 'fieldType')}
    //               />
    //             </Form.Field>
    //           </Form>
    //         )}
    //       </div>
    //       <div className="hide-on-non-focused">
    //         {generatedSubFields(
    //           field.fieldType,
    //           field,
    //           index,
    //           fields,
    //           setFields,
    //           validationProcedureItems,
    //           setValidationProcedureItems,
    //           handleFieldChange
    //         )}

    //         <Divider />
    //         <div
    //           style={
    //             field.fieldType != 'heading'
    //               ? { marginTop: '10px' }
    //               : { marginTop: '20px', justifyContent: 'flex-start' }
    //           }
    //           className={`flexbox align-center procedure-actions ${
    //             field.fieldType === 'signature' ||
    //             field.fieldType === 'file' ||
    //             field.fieldType === 'photo'
    //               ? 'justify-start'
    //               : 'justify-end'
    //           } `}
    //           basic
    //         >
    //           <div className={`flexbox align-center ${selectedLanguage == 'en' ? '' : 'rtl-container'}`}>
    //             {field.fieldType != 'heading' && (
    //               <>
    //                 <span className="required-text ml-2 mr-2">
    //                   {t.procedures.form.is_required}
    //                 </span>

    //                 <Toggle
    //                   // type="checkbox"
    //                   className="toggle-data"
    //                   checked={field.isRequired}
    //                   onChange={(e) =>
    //                     handleFieldChange(index, 'isRequired')(
    //                       {},
    //                       { checked: e.target.checked }
    //                     )
    //                   }
    //                 />
    //               </>
    //             )}
    //           </div>
    //           {field.fieldType != 'heading' && (
    //             <div
    //               className="flexbox align-center flex-wrap"
    //               style={{ maxWidth: '178px' }}
    //             >
    //               {field.prevAttachments?.length > 0 ? (
    //                 field.prevAttachments?.map((prev, index) => {
    //                   return (
    //                     <span onClick={() => attachmentsRef.current.click()}>
    //                       <FilePresentIcon />
    //                       <label className="m-0">{prev.name}</label>

    //                       <span
    //                         className="ml-3 mr-3 c-pointer"
    //                         onClick={(e) => {
    //                           e.stopPropagation();
    //                           handleDeleteAttachment(prev.id);
    //                         }}
    //                       >
    //                         <DeleteIcon />
    //                       </span>
    //                     </span>
    //                   );
    //                 })
    //               ) : (
    //                 <span
    //                   onClick={() => {
    //                     handleFieldChange(index, 'active')({}, {});
    //                     attachmentsRef.current.click();
    //                   }}
    //                 >
    //                   <FilePresentIcon />
    //                   <label className="m-0"></label>
    //                 </span>
    //               )}
    //             </div>
    //           )}
    //           {fields && fields.length > 1 ? (
    //             <div
    //               className="buttun-type-link remove ml-4 mr-4"
    //               basic
    //               onClick={removeThisField(index)}
    //             >
    //               <DeleteIcon width="20" height="20" fill="red" />
    //               {t.procedures.form.remove_button}
    //             </div>
    //           ) : null}
    //         </div>
    //       </div>
    //     </div>
    //   </>
    // ));
  };

  const generatedSubFields = (
    type,
    field,
    index,
    fields,
    setFields,
    validationProcedureItems,
    setValidationProcedureItems,
    handleFieldChange
  ) => {
    let logicAnswersOptions = inspectionTypes.filter(
      (el) => el.value == field.inspectionType
    );
    if (logicAnswersOptions.length > 0)
      logicAnswersOptions = logicAnswersOptions[0].logicAnswers;

    const addOption = (parentIndex) => {
      let newFields = [...fields];

      let modifiedField = { ...newFields[parentIndex] };
      // 1. Make a shallow copy of the items
      let newOptions = [...field.options];
      // 2. Make a shallow copy of the item you want to mutate
      newOptions.push({ item: '' });

      modifiedField.options = newOptions;
      // 5. Set the state to our new copy
      newFields[parentIndex] = modifiedField;

      setFields(newFields);

      let validations = cloneDeep(validationProcedureItems);
      validations[parentIndex].options.push({
        error: false,
        pattern: (value, fieldType) =>
          ['multipleChoice', 'checklist'].includes(fieldType)
            ? value != ''
            : true,
        skipped: false,
        message: t.common.required_field,
      });
      setValidationProcedureItems(validations);
    };

    const removeOption = (parentIndex, id) => (e) => {
      e.stopPropagation();

      let newFields = [...fields];

      let modifiedField = { ...newFields[parentIndex] };
      // 1. Make a shallow copy of the items
      let newOptions = [...field.options];
      // 2. Make a shallow copy of the item you want to mutate
      newOptions.splice(id, 1);

      modifiedField.options = newOptions;
      // 5. Set the state to our new copy
      newFields[parentIndex] = modifiedField;

      setFields(newFields);
      let validations = cloneDeep(validationProcedureItems);
      validations[parentIndex].options.splice(id, 1);
      setValidationProcedureItems(validations);
    };

    const handleOptionChange = (parentIndex, id) => (event) => {
      let newFields = [...fields];

      let modifiedField = { ...newFields[parentIndex] };
      // 1. Make a shallow copy of the items
      let newOptions = [...field.options];
      // 2. Make a shallow copy of the item you want to mutate
      let item = { ...field.options[id] };
      // 3. Replace the property you're intested in

      item = event.target.value;
      // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
      newOptions[id].item = item;

      modifiedField.options = newOptions;
      // 5. Set the state to our new copy
      newFields[parentIndex] = modifiedField;

      setFields(newFields);
      let validations = cloneDeep(validationProcedureItems);
      validations[parentIndex].options[id] = {
        ...validations[parentIndex].options[id],
        error: validations[parentIndex].options[id].pattern(
          newFields[parentIndex].options[id].item,
          newFields[parentIndex].fieldType
        )
          ? false
          : true,
        skipped: true,
      };
      setValidationProcedureItems(validations);
    };

    const generatedOptions = (parentIndex) => {
      let checkStyle = <Checkbox disabled />;
      if (field.fieldType == 'multipleChoice') {
        checkStyle = <Radio disabled />;
      }
      if (field.fieldType == 'checkbox') {
        return (
          <div className="flexbox align-center">
            <Form.Field
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 0,
                width: '100%',
              }}
            >
              {checkStyle}
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.procedures.form.option}
                value={field?.options[0].item}
                style={{ flex: 1, marginLeft: '10px' }}
                onChange={handleOptionChange(parentIndex, 0)}
              />
            </Form.Field>
          </div>
        );
      } else {
        const getItemStyle = (isDragging, draggableStyle) => ({
          ...draggableStyle,
          userSelect: 'none',
          position: 'static',
          padding: '8px 0',
          margin: `15px 0 14px 0`,
          borderRadius: '3px',
          background: isDragging ? '#e2f5fc' : '',
          height: '28px',
        });

        return field?.options.map((item, index) => (
          <Draggable
            key={index}
            draggableId={'draggable-' + index}
            index={index}
          >
            {(provided, snapshot) => {
              return (
                <div className="procedure-form-item-container">
                  <div
                    className="flexbox align-center "
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <Form.Field
                      style={{ minHeight: '65px' }}
                      className="d-flex align-items-center mb-7 w-100 "
                    >
                      <span
                        style={{ minWidth: '25px' }}
                        {...provided.dragHandleProps}
                      >
                        <OrderIcon />
                      </span>
                      {/* {checkStyle} */}

                      <div className="item-option-container">
                        <Input
                          id={'option-' + index}
                          autoComplete="new-password"
                          fluid
                          placeholder={t.procedures.form.option}
                          value={item.item}
                          onChange={handleOptionChange(parentIndex, index)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              if (item.item.length > 0) {
                                addOption(parentIndex);
                              }
                            }
                          }}
                        />
                        {/* <input type="checkbox" /> */}
                      </div>

                      <span
                        className="ml-1 c-pointer"
                        onClick={removeOption(parentIndex, index)}
                      >
                        <DeleteIcon />
                      </span>
                    </Form.Field>
                  </div>
                  <div>
                    {validationProcedureItems[parentIndex]?.options[index] &&
                    validationProcedureItems[parentIndex]?.options[index]
                      ?.skipped &&
                    validationProcedureItems[parentIndex]?.options[index]
                      ?.error ? (
                      <div
                        className="label-error ml-7 mr-7"
                        style={{ marginTop: '-15px' }}
                      >
                        {
                          validationProcedureItems[parentIndex]?.options[index]
                            ?.message
                        }
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              );
            }}
          </Draggable>
        ));
      }
    };

    let returnFields = null;
    switch (type) {
      case 'text':
        returnFields = (
          <Segment secondary>{t.procedures.form.text_placeholder}</Segment>
        );
        break;
      case 'number':
        returnFields = (
          <Segment secondary>{t.procedures.form.number_placeholder}</Segment>
        );
        break;
      case 'amount':
        returnFields = (
          <Segment secondary>{t.procedures.form.amount_placeholder}</Segment>
        );
        break;
      case 'signature':
        returnFields = (
          <Segment secondary>{t.procedures.form.signature_placeholder}</Segment>
        );
        break;
      case 'date':
        returnFields = <Segment secondary>{t.procedures.form.date_placeholder}</Segment>;
        break;
      case 'file':
        // text,number,amount, signature, date, file fields
        //isrequired
        returnFields = (
          <Segment secondary>{t.procedures.form.file_placeholder}</Segment>
        );
        break;

      case 'photo':
        // text,number,amount, signature, date, file fields
        //isrequired
        returnFields = (
          <Segment secondary>{t.procedures.form.photo_placeholder}</Segment>
        );
        break;
      case 'multipleChoice':
      case 'checklist':
        // multipleChoice fields
        //isRequired & options
        returnFields = (
          <>
            {/* <label className="label-item">{t.procedures.form.options}</label> */}
            <DragDropContext
              onDragEnd={(result) => {
                if (!result.destination) {
                  return;
                }
                const [removed] = field.options.splice(result.source.index, 1);
                field.options.splice(result.destination.index, 0, removed);
              }}
            >
              <Droppable droppableId="droppable-1">
                {(provided, _) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {generatedOptions(index)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div style={{ marginTop: '-6px' }} className="flexbox">
              <div
                className="item-button-group"
                onClick={() => addOption(index)}
              >
                <span className="item-button-icon-container">
                  <AddIconWithCircle />
                </span>
                <div className="item-add-option-text">
                  {t.procedures.form.add_option}
                </div>
              </div>
              {/* <div
                  className={'buttun-type-link'}
                  basic
                  onClick={() => addOption(index)}
                >
                  <Icon name="add" />
                  {t.procedures.form.add_option}
                </div> */}
            </div>
          </>
        );
        break;
      case 'inspectionCheck':
        // inspectionCheck fields
        //isRequired & inspectionTypes & logic
        returnFields = (
          <>
            <Form>
              <Form.Field className="mb-0">
                <label className="label-item">
                  {t.procedures.form.inspection_type}
                  <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.procedures.form.inspection_type}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  options={inspectionTypes}
                  value={field.inspectionType}
                  onChange={handleFieldChange(index, 'inspectionType')}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  {t.procedures.form.failed_value}
                </label>
                <Dropdown
                  placeholder={t.procedures.form.failed_value_placeholder}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  disabled={logicAnswersOptions.length < 1}
                  options={logicAnswersOptions}
                  value={field.failedValue}
                  onChange={handleFieldChange(index, 'failedValue')}
                />
              </Form.Field>
            </Form>
            {field.logic && (
              <div className="d-flex align-items-center add-procedure-dropdown-n justify-content-between">
                <Message className="warning-inspection-option-container option-2-forlogic-change w-100">
                  <div className="flexbox align-center justify-between">
                    <div className="flexbox align-center">
                      {/* <Icon name="code branch" style={{ marginRight: '5px' }} /> */}
                      <p className="m-1">{t.procedures.form.if_answer_is}</p>
                      <div className="custom-menu-dropdown">
                        <UncontrolledDropdown className="me-2" direction="down">
                          <DropdownToggle className="option-toggle inspection-option-dropdown">
                            {field.logicAnswer
                              ? logicAnswersOptions.find(
                                  (one) => one.value === field.logicAnswer
                                )?.text
                              : t.procedures.form.if_answer_is_placeholder}{' '}
                            <WhiteDownChevron fill="#0C66E4" />{' '}
                          </DropdownToggle>
                          <DropdownMenu>
                            {logicAnswersOptions.map((one) => {
                              return (
                                <div
                                  onClick={() => {
                                    handleFieldChange(index, 'logicAnswer')(
                                      {},
                                      { value: one.value }
                                    );
                                  }}
                                >
                                  <DropdownItem>{one.text}</DropdownItem>{' '}
                                  <DropdownItem className="divider" />
                                </div>
                              );
                            })}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      {/* <Dropdown
                        style={{ margin: '0 5px' }}
                        inline
                        disabled={logicAnswersOptions.length < 1}
                        options={logicAnswersOptions}
                        value={field.logicAnswer}
                        placeholder={t.procedures.form.if_answer_is_placeholder}
                        onChange={handleFieldChange(index, 'logicAnswer')}
                      /> */}
                      <p className="m-1">{t.procedures.form.then}</p>

                      {/* <Dropdown
                          style={{ margin: '0 5px' }}
                          inline
                          multiple
                          options={logicTriggers}
                          disabled={logicAnswersOptions.length < 1}
                          value={field.logicTrigger}
                          placeholder={t.procedures.form.then_placeholder}
                          onChange={handleFieldChange(index, 'logicTrigger')}
                        /> */}
                      <div className="custom-menu-dropdown">
                        <UncontrolledDropdown className="me-2" direction="down">
                          <DropdownToggle className="option-toggle inspection-option-dropdown d-flex justify-content-between align-items-center">
                            <div>
                              {field.logicTrigger && field.logicTrigger.length
                                ? field.logicTrigger.map((tr) => {
                                    let triggerLabel = logicTriggers.find(
                                      (each) => each.value == tr
                                    );
                                    return (
                                      <p className="m-0">
                                        {triggerLabel?.text}
                                      </p>
                                    );
                                  })
                                : t.procedures.form.then_placeholder}{' '}
                            </div>
                            <WhiteDownChevron fill="#0C66E4" />{' '}
                          </DropdownToggle>
                          <DropdownMenu>
                            {logicTriggers.map((one) => {
                              return (
                                <div>
                                  <div className="d-flex ">
                                    <DropdownItem>
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={
                                            field.logicTrigger &&
                                            field.logicTrigger.includes(
                                              one.value
                                            )
                                          }
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              let filteredTrigger = field.logicTrigger
                                                ? field.logicTrigger
                                                : [];
                                              handleFieldChange(
                                                index,
                                                'logicTrigger'
                                              )(
                                                {},
                                                {
                                                  value: [
                                                    ...filteredTrigger,
                                                    one.value,
                                                  ],
                                                }
                                              );
                                            } else {
                                              let filteredTrigger = [];
                                              filteredTrigger = field.logicTrigger
                                                ? field.logicTrigger.filter(
                                                    (tr) => tr !== one.value
                                                  )
                                                : [];
                                              handleFieldChange(
                                                index,
                                                'logicTrigger'
                                              )(
                                                {},
                                                { value: [...filteredTrigger] }
                                              );
                                            }
                                          }}
                                        />
                                        <span className="ml-2 mr-2">
                                          {one.text}
                                        </span>
                                      </label>
                                    </DropdownItem>{' '}
                                  </div>
                                  <DropdownItem className="divider" />
                                </div>
                              );
                            })}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                    {/* <div
                        className={'buttun-type-link remove'}
                        basic
                        onClick={handleFieldChange(index, 'logic')}
                      >
                        {t.procedures.form.cancel_button}
                      </div> */}
                  </div>
                  {field?.logicTrigger?.includes('action') ? (
                    <span className="new-added-add-logic-txt">
                      {logicTriggers[0]?.text}
                    </span>
                  ) : null}
                  {field?.logicTrigger?.includes('evidence') ? (
                    <span className="new-added-add-logic-txt">
                      {' '}
                      {logicTriggers[1]?.text}
                    </span>
                  ) : null}
                </Message>
                <div onClick={handleFieldChange(index, 'logic')}>
                  <DeleteIcon />
                </div>
              </div>
            )}
            {field.logicTrigger && field.logicTrigger.length ? (
              <div>
                <Form className="mt-5 mb-5">
                  {field.logicTrigger.includes('action') ? (
                    <Form.Field>
                      <label className="label-item">
                        {t.procedures.form.required_action}
                      </label>
                      <TextArea
                        autoComplete="new-password"
                        value={field.actionDetails}
                        onChange={handleFieldChange(index, 'actionDetails')}
                        rows={2}
                        fluid
                        placeholder={t.procedures.form.required_action}
                      />
                    </Form.Field>
                  ) : null}
                  {field.logicTrigger.includes('evidence') ? (
                    <Form.Field>
                      <label className="label-item">
                        {t.procedures.form.required_evidence}
                      </label>
                      <TextArea
                        autoComplete="new-password"
                        value={field.evidenceDetails}
                        onChange={handleFieldChange(index, 'evidenceDetails')}
                        rows={2}
                        fluid
                        placeholder={t.procedures.form.required_evidence}
                      />
                    </Form.Field>
                  ) : null}
                </Form>
              </div>
            ) : null}
            <div style={{ marginTop: '0px' }}>
              {!field.logic && (
                <div
                  className="item-button-group"
                  onClick={handleFieldChange(index, 'logic')}
                >
                  <span className="item-button-icon-container">
                    <LogicIcon />
                  </span>
                  <div className="item-add-option-text">
                    {t.procedures.form.add_logic}
                  </div>
                </div>
              )}
            </div>
          </>
        );
        break;
      default:
        returnFields = null;
    }

    return returnFields;
  };
  return { generatedProcedureFields: generatedFields };
}

export default useProcedureFields;
