import React from "react";
import { CloseIcon } from "../../../../../Assets/Icons/svg";
import { Modal, TextArea } from "semantic-ui-react";

const StatusChangeModal = ({
  selectedLanguage,
  loading,
  onHoldFlag,
  reOpenFlag,
  setMessage,
  message,
  handleSave,
  t,
  openModal,
  openFlag,
  closeFlag,
  inCancleFlag,
  setModal,
  approveModal,
  inProgressFlag,
}) => {
  return (
    <Modal
      className="assets-modal"
      size="tiny"
      open={openModal}
      onClose={() => {
        setModal(false);
      }}
    >
      <div
        className={
          approveModal || inProgressFlag
            ? "header-container green"
            : reOpenFlag ?  "header-container reOpen":inCancleFlag
            ? "header-container grey"
            : closeFlag
            ? "header-container close-data"
            : openFlag
            ? "header-container open"
            : "header-container red"
        }
      >
        <span className="assets-header">
          {" "}
          {`${
            inCancleFlag
              ? t.common.cancel
              : inProgressFlag
              ? t.workOrders.work_order_status.inProgress
              : reOpenFlag ? t.workOrders.work_order_status.reOpen : approveModal
              ? t.workOrders.complete
              : closeFlag
              ? t.workOrders.work_order_status.close
              : openFlag
              ? t.workOrders.work_order_status.open_status_action
              : t.workOrders.work_order_status.hold
          } `}
          {t.workOrders.details_view.work_order}
        </span>
        <div>
          <span
            className="c-pointer text-right"
            onClick={() => setModal(false)}
          >
            <CloseIcon />
          </span>
        </div>
      </div>

      <Modal.Content>
        <div className="comment-container" style={selectedLanguage == "en" ? { marginLeft: "3px" } : { marginRight: "3px"}}>
          <label className="comment-label">
            {" "}
            {inCancleFlag
              ? t.workOrders.cancel_WO
              : inProgressFlag
              ? t.workOrders.progress_WO
              : reOpenFlag ? t.workOrders.reOpen_WO : approveModal
              ? t.workOrders.approve_WO
              : closeFlag
              ? t.workOrders.decline_WO
              : openFlag
              ? t.workOrders.open_WO
              : t.workOrders.hold_WO}
          </label>
          <div className="add-comment">
            <TextArea
              className="comment-textarea"
              rows={5}
              placeholder={`${
                !inCancleFlag && !openFlag && !reOpenFlag
                  ? t.workOrders.add_reason
                  : `${t.workOrders.required}`
              }`}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div
          className={`modal-button-container ${
            selectedLanguage == "en" ? "mr-6" : "ml-10"
          }`}
        >
          <button
            className="modal-form-action-button"
            onClick={() => setModal(false)}
            disabled={loading}
            style={{ color: "#172B4D", fontWeight: "500" }}
          >
            {t.common.back}
          </button>

          <button
            onClick={() => {
              handleSave();
            }}
            style={{ fontWeight: "500" }}
            className={`modal-form-action-button ${
              inProgressFlag
                ? "blue"
                : inCancleFlag
                ? "grey"
                : onHoldFlag
                ? "red"
                : reOpenFlag ? "purple" : approveModal
                ? "green"
                : closeFlag
                ? "dark-blue"
                : openFlag
                ? "blue"
                : "blue"
            }`}
          >
            {inCancleFlag
              ? t.assets.form.cancel
              : inProgressFlag
              ? t.workOrders.work_order_status.inProgress
              : approveModal
              ? t.workOrders.complete
              : reOpenFlag ? t.workOrders.work_order_status.reOpen : closeFlag
              ? t.workOrders.work_order_status.close
              : openFlag
              ? t.workOrders.work_order_status.open_status_action
              : t.workOrders.work_order_status.hold}
          </button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default StatusChangeModal;
