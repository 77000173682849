import React, { useEffect, useState } from "react";
import {
  BlackClockIcon,
  BlackCostIcon,
  CustomerPlusIcon,
  OptionIcon,
} from "../../../../../Assets/Icons/svg";
import { useDeletePermissionModal } from "../../../../../hooks/Messages/useDeletePermissionModal";

import { useSelector } from "react-redux";
import { selectTranslations } from "../../../../config/i18n/slice";
import { DropdownItem, DropdownMenu, Image, Loader } from "semantic-ui-react";
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from "../../../../components/common/userProfilePlaceholder";
import useDisplayFormattedText from "../../../../../hooks/useDisplayFormattedText";
import {
  addLaborTime,
  deleteLaborTabData,
  editLaborTime,
  getActiveUsersByRole,
  getLaborTabData,
  humanize,
  IMAGES_URL,
} from "../../../../config/functions";

import { toast } from "react-toastify";
import { scrollToError } from "../../../../../utilFunctions/scrollToError";

import { format } from "date-fns";
import moment from "moment";
import LaborModal from "./laborModal";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";

const Labor = ({ data }) => {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const organisationTimezone = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.timezone;
    } else {
      return null;
    }
  });

  /**
   * The function `getLocalDateTime` calculates the local date and time based on a specified timezone
   * offset.
   */
  const getLocalDateTime = (offset) => {
    const now = new Date(); // Get the current time in local timezone
    const utcMilliseconds = now.getTime() + now.getTimezoneOffset() * 60000; // Convert local time to UTC
    const localMilliseconds = utcMilliseconds + offset * 3600000; // Add timezone offset in milliseconds

    const localDate = new Date(localMilliseconds);

    return format(localDate, "yyyy-MM-dd hh:mm a");
  };

  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });
  const { DisplayFormattedText } = useDisplayFormattedText();
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [addLaborModal, setaddLaborModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [validation, setValidation] = useState({
    people: {
      error: false,
      pattern: (code) => code != "",
      skipped: false,
      message: t.common.required_field,
    },
    duration: {
      error: false,
      pattern: (code) => code !== "" && code !== "00:00" && code !== null, // Return false if code is "" or "00:00"
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [loading, setLoading] = useState(true);
  const [logData, setLogData] = useState();

  const [options, setOptions] = useState({
    people: [],
    category: [
      {
        key: 0,
        value: "Drive Time",
        text: t.workOrders.labor.drive_time,
      },

      {
        key: 1,
        value: "Preparation Time",
        text: t.workOrders.labor.preparation_time,
      },
      {
        key: 2,
        value: "Inspection Time",
        text: t.workOrders.labor.inspection_time,
      },
      {
        key: 3,
        value: "Testing and Verification Time",
        text: t.workOrders.labor.testing_time,
      },
      {
        key: 4,
        value: "Downtime/Waiting Time",
        text: t.workOrders.labor.downtime_time,
      },
      {
        key: 6,
        value: "Wrench Time",
        text: t.workOrders.labor.wrench_time,
      },
      {
        key: 7,
        value: "Other Time",
        text: t.workOrders.labor.other_time,
      },
    ],
  });

  const [laborData, setLaborData] = useState({
    people: "",
    startDate: getLocalDateTime(organisationTimezone?.split("+")[1]),
    duration: "",
    hourRate: "",
    category: "Other Time",
  });

  /**
   * The fetchData function sets loading state to true, fetches data using getLaborTabData, updates log
   * data if data is fetched, and sets loading state to false.
   */
  const fetchData = async () => {
    setLoading(true);
    const fetchData = await getLaborTabData(data?.id);
    if (fetchData) {
      setLogData(fetchData);
      setLoading(true);
    }
    setLoading(false);
  };

  /**
   * The `deleteHandler` function deletes labor tab data and displays a success message if the deletion
   * is successful, otherwise it shows an error message.
   */
  const deleteHandler = async () => {
    const response = await deleteLaborTabData(laborData?.id, laborData?.logId);

    if (response?.status == 200) {
      window.dispatchEvent(new Event("triggerNotificationRefresh"));
      toast.success(t.workOrders.labor.delete);
      fetchData();
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  /**
   * The `onValuesChange` function handles changes in input values, updating the state accordingly and
   * performing validation checks.
   */
  const onValuesChange = (name, actualValue) => (event, value) => {
    let currentFieldValue = "";
    const dropdown_fields = ["people", "category"];

    if (dropdown_fields.includes(name)) {
      currentFieldValue = actualValue || value.value;

      let resulteNewData = {};
      if (name === "people") {
        let hrRate = options?.people?.find((el) => el?.value === value?.value);

        resulteNewData = {
          ...laborData,
          [name]: actualValue || value.value,
          hourRate: hrRate?.hrRate,
        };
      } else {
        resulteNewData = {
          ...laborData,
          [name]: actualValue || value.value,
        };
      }
      setLaborData(resulteNewData);
    } else {
      currentFieldValue = actualValue || event.target.value;
      setLaborData({
        ...laborData,
        [name]: currentFieldValue,
      });
    }
    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
  };

  /**
   * The `fetchPeopleOptions` function fetches active users by role and prepares options for displaying
   * user data with profile images and hourly rates.
   */
  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "limited_admin", "admin"],
      organisationId,
      data?.attributes?.location?.data?.id
    );
    // console.log("THE FETCH USERS", fetchData)
    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // description: `${teams_string_array.join(" , ")}`,
          hrRate: el?.userProfile?.hourRate ? el?.userProfile?.hourRate : 0,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };
  /**
   * The handleEdit function sets the labor data and toggles edit mode and the add labor modal.
   */

  const handleEdit = (labor) => {
    setLaborData({
      people: labor?.userId,
      startDate: moment.utc(labor?.startTime).format("yyyy-MM-DD hh:mm a"),
      duration:
        labor?.duration !== "0"
          ? `${Math.floor(labor?.duration / 3600000)
              .toString()
              .padStart(2, "0")}:${Math.floor(
              (labor?.duration % 3600000) / 60000
            )
              .toString()
              .padStart(2, "0")}`
          : "",
      hourRate: labor?.hourRate ? labor?.hourRate : 0,
      category: labor?.category ? labor?.category : "Other Time",
      id: labor?.id,
      logId: labor?.logId,
    });

    fetchPeopleOptions();
    setIsEdit(true);
    setaddLaborModal(true);
  };

  /**
   * The function `checkPermission` determines whether a user has permission based on various conditions
   * such as user role and status.
   */
  const checkPermission = (labor) => {
    if (!labor?.addedByUser) {
      return false;
    } else if (["cancelled", "completed"].includes(data?.attributes?.status)) {
      return false;
    } else if (["super_admin", "admin", "limited_admin"].includes(roleData)) {
      return true;
    } else if (labor?.addedByUser?.id === currentUserId) {
      return true;
    }
    return false;
  };

  /**
   * The `handleAssign` function performs validation on labor data, makes an API call to either edit or
   * add labor time, and displays appropriate toast messages based on the response status.
   */
  const handleAssign = async () => {
    let currentValidation = {
      ...validation,
      people: {
        ...validation.people,
        error: validation.people.pattern(laborData.people) ? false : true,
        skipped: true,
      },
      duration: {
        ...validation.duration,
        error: validation.duration.pattern(laborData.duration)
          ? // &&
            // !laborData.duration?.split(":")[0] > 24
            false
          : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);

    if (!errorsInData) {
      let response;
      if (isEdit) {
        response = await editLaborTime(
          organisationId,
          laborData?.people,
          laborData?.hourRate ? Number(laborData?.hourRate) : 0,
          laborData?.startDate,
          laborData?.duration,
          laborData?.category,
          laborData?.id,
          laborData?.logId
        );
      } else {
        response = await addLaborTime(
          data?.id,
          organisationId,
          laborData?.people,
          laborData?.hourRate ? Number(laborData?.hourRate) : 0,
          laborData?.startDate,
          laborData?.duration,
          laborData?.category
        );
      }

      if (response && response.status == 200) {
        toast.success(
          isEdit ? t.workOrders.labor.edit : t.workOrders.labor.success
        );

        window.dispatchEvent(new Event("triggerNotificationRefresh"));

        setLaborData({
          people: "",
          startDate: getLocalDateTime(organisationTimezone?.split("+")[1]),
          duration: "",
          hourRate: "",
          category: "Other Time",
        });

        setIsEdit(false);

        setaddLaborModal(false);

        fetchData();
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(response.error.message);
        } else {
          toast.error(t.common.something_wrong);
        }
      } else {
        toast.error(t.common.something_wrong);
      }
    } else {
      scrollToError();
    }
  };

  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          {t.common.loading}
        </Loader>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div
                className={`labor-grey-btn ${
                  selectedLanguage == "ar" ? "ml-5" : "mr-5"
                }`}
              >
                <span
                  className={`${selectedLanguage == "ar" ? "ml-3" : "mr-3"}`}
                >
                  {" "}
                  <BlackClockIcon />
                </span>
                {t.workOrders.labor.total_time}

                <span
                  className={`${selectedLanguage == "en" ? "ml-3" : "mr-3"}`}
                >
                  {`${Math.floor(logData?.totalTime / 3600000)
                    .toString()
                    .padStart(2, "0")}:${Math.floor(
                    (logData?.totalTime % 3600000) / 60000
                  )
                    .toString()
                    .padStart(2, "0")}:${Math.floor(
                    (logData?.totalTime % 60000) / 1000
                  )
                    .toString()
                    .padStart(2, "0")} Hrs`}
                </span>
              </div>

              <div
                className={`labor-grey-btn ${
                  selectedLanguage == "ar" ? "ml-5" : "mr-5"
                }`}
              >
                <span
                  className={`${selectedLanguage == "ar" ? "ml-3" : "mr-3"}`}
                >
                  {" "}
                  <BlackCostIcon />
                </span>
                {t.workOrders.labor.total_cost}
                <span
                  className={`${selectedLanguage == "en" ? "ml-3" : "mr-3"}`}
                >
                  {" "}
                  {logData?.totalCost ? logData?.totalCost : 0}{" "}
                  {organisationCurrency}
                </span>
              </div>
            </div>
            <div>
              {!["completed", "cancelled"].includes(data?.attributes?.status) &&
              ["user", "admin", "super_admin", "limited_admin"].includes(
                roleData
              ) ? (
                <button
                  className={`buttun-type-link mt-1`}
                  basic
                  type="button"
                  style={{ background: "transparent" }}
                  onClick={() => setaddLaborModal(true)}
                >
                  <span className="customer-add-btn">
                    {" "}
                    <CustomerPlusIcon width="20px" height="20px" />{" "}
                  </span>

                  <p className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                    {t.workOrders.labor.add_time}
                  </p>
                </button>
              ) : null}
            </div>
          </div>
          {logData?.data?.length > 0 ? (
            <div className="scroll-data" style={{ overflow: "auto" }}>
              <div className={"mt-6"}>
                <div className={`kanbar-header`}>
                  <div class="grid-container-element set labor-form">
                    <div
                      style={{
                        minWidth: "140px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.details_view.name}
                    </div>
                    <div
                      style={{
                        minWidth: "161px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.labor.statDate}
                    </div>
                    <div
                      style={{
                        minWidth: "100px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.labor.duration}
                    </div>
                    <div
                      style={{
                        minWidth: "100px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.people.table.hourly_rate}
                    </div>
                    <div
                      style={{
                        minWidth: "100px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.dashboard.cards.cost_currency}
                    </div>
                    <div
                      style={{
                        minWidth: "100px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.table.category}
                    </div>
                    <div
                      style={{
                        minWidth: "40px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    ></div>
                  </div>
                </div>
              </div>

              {logData?.data?.map((part, index) => (
                <div className="grid-container-element set labor-form form">
                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "144px",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="text-center">
                        {part?.profileImage ? (
                          <Image
                            avatar
                            title={part?.name}
                            src={`${part?.profileImage}`}
                            style={{
                              width: "32px",
                              height: "32px",
                              right: selectedLanguage === "en" ? "6px" : "-6px",
                            }}
                          />
                        ) : (
                          <UserProfilePlaceholder
                            name={part?.name}
                            width={"32px"}
                            height={"32px"}
                            right={selectedLanguage === "en" ? "6px" : "-6px"}
                          />
                        )}
                      </div>
                      <div className="username-container ml-1">
                        <p className="user-text">
                          {DisplayFormattedText(humanize(part?.name), 7)}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "161px",
                    }}
                  >
                    <p>
                      {moment.utc(part?.startTime).format("DD/MM/YYYY hh:mm A")}
                    </p>
                  </div>
                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "100px",
                    }}
                  >
                    {part?.duration !== "0"
                      ? `${Math.floor(part?.duration / 3600000)
                          .toString()
                          .padStart(2, "0")}:${Math.floor(
                          (part?.duration % 3600000) / 60000
                        )
                          .toString()
                          .padStart(2, "0")}:${Math.floor(
                          (part?.duration % 60000) / 1000
                        )
                          .toString()
                          .padStart(2, "0")}`
                      : ""}
                  </div>
                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "100px",
                    }}
                  >
                    {part?.hourRate !== null
                      ? `${part?.hourRate} ${organisationCurrency}`
                      : ""}
                  </div>
                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "100px",
                    }}
                  >
                    {part?.cost !== null && part.duration !== "0"
                      ? `${part?.cost} ${organisationCurrency}`
                      : ""}
                  </div>

                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "100px",
                    }}
                  >
                    {DisplayFormattedText(
                      options?.category?.find(
                        (item) => item?.value === part?.category
                      )?.text
                        ? options?.category?.find(
                            (item) => item?.value === part?.category
                          )?.text
                        : part?.category
                        ? t.workOrders.form.labor_title
                        : "",
                      8
                    )}
                  </div>
                  {checkPermission(part) ? (
                    <div
                      className="grid-child-element wo-data-form"
                      style={{
                        padding: "10px 9px",
                        minWidth: "20px",
                      }}
                    >
                      <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 rtl-custom-menu ml-1 mt-2 align-self-start">
                        <UncontrolledDropdown className="mr-2" direction="down">
                          <DropdownToggle
                            color="primary"
                            className="procedure-toggle"
                          >
                            <OptionIcon />
                          </DropdownToggle>
                          <DropdownMenu
                            className={
                              selectedLanguage == "ar"
                                ? "procedure labor labor-rtl"
                                : "procedure labor"
                            }
                          >
                            <DropdownItem className="divider" />
                            <DropdownItem onClick={() => handleEdit(part)}>
                              {t.common.edit}
                            </DropdownItem>
                            <DropdownItem className="divider" />
                            <DropdownItem
                              onClick={() => {
                                setDeletePermissionModal(true);
                                setLaborData({
                                  ...laborData,
                                  id: part.id,
                                  logId: part.logId,
                                });
                              }}
                            >
                              {t.common.delete}
                            </DropdownItem>{" "}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center mt-5">
              <p className="required-text">{t.common.na}</p>
            </div>
          )}
        </>
      )}

      <LaborModal
        addLaborModal={addLaborModal}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        setValidation={setValidation}
        setLaborData={setLaborData}
        setaddLaborModal={setaddLaborModal}
        handleAssign={handleAssign}
        laborData={laborData}
        options={options}
        validation={validation}
        fetchPeopleOptions={fetchPeopleOptions}
        onValuesChange={onValuesChange}
        getLocalDateTime={getLocalDateTime}
      />
      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={t.workOrders.labor.delete_time}
      >
        <span>{t.workOrders.labor.delete_labor}</span>
      </DeletePermissionModal>
    </>
  );
};

export default Labor;
