import axios from "axios";
import { toast } from "react-toastify";
import store, { persistor } from "../../redux/store";
import { Country, State } from "country-state-city";
import { assign, set } from "lodash";
import { Category } from "@material-ui/icons"; // Adjust the path as necessary// Adjust the path as necessary
import * as auth from "../../app/modules/Auth/_redux/authRedux";

const qs = require("qs");

export const handleLogout = async () => {
  const {
    auth: { user },
  } = store.getState();

  if (user?.id) {
    await updateUserNotificationToken(user.id, "", "", true);
  }

  // Dispatch the logout action
  store.dispatch(auth.actions.logout());

  // login(user.email, user.password)
  //         .then(async ({ data: { jwt, user } }) => {
  //           disableLoading();
  //           if (
  //             user.role.type === 'admin' ||
  //             user.role.type === 'super_admin' ||
  //             user.role.type === 'user' ||
  //             user.role.type === 'technician' ||
  //             user.role.type === 'limited_admin' ||
  //             user.role.type === 'requester' ||
  //             user.role.type === 'view_only'
  //           ) {
  //             store.dispatch.login(jwt);
  //             let deviceToken = await getToken();
  //             await updateUserNotificationToken(user?.id,  );
  //           } else {
  //             setStatus(
  //               intl.formatMessage({
  //                 id: 'AUTH.VALIDATION.INVALID_LOGIN',
  //               })
  //             );
  //           }
  //         })
  //         .catch((err) => {
  //           if (err.status === 403) {
  //             setStatus(t.auth.login_message.organisation_disabled);
  //           } else {
  //             setStatus(t.auth.login_message.login_fail);
  //           }
  //         })
  //         .finally(() => {
  //           disableLoading();
  //           setSubmitting(false);
  //         });
};

// new axios obj.
const uninterceptedAxiosInstance = axios.create();
uninterceptedAxiosInstance.interceptors.request.use(
  (config) => {
    const {
      auth: { authToken },
    } = store.getState();

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  (err) => Promise.reject(err)
);
export const IMAGES_URL = '';
const backend_url = 'https://api.quiqflow.com/api/';
const reg_url = 'https://api.quiqflow.com/api/';

// export const IMAGES_URL = 'https://api.quiqflow.com';

// const backend_url = 'https://api.quiqflow.com/api/';
// const reg_url = 'https://api.quiqflow.com/';

const throwError = function({ message = "", status = 400 }) {
  let errorObject = new Error(message);
  errorObject.status = status;
  return errorObject;
};

export const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

// generic functions
const generateRandomPassword = (length) => {
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};
export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const humanize = (str) => {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

export const to_snake_case = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join("_");

export const removeAtIndex = (arr, index) => {
  const copy = [...arr];
  copy.splice(index, 1);
  return copy;
};

export const toggle = (arr, items, getValue = (item) => item) => {
  if (Array.isArray(items)) {
    let updatedArray = [...arr];
    items.forEach((item) => {
      const index = updatedArray.findIndex(
        (i) => getValue(i) === getValue(item)
      );
      if (index === -1) {
        updatedArray.push(item); // Add item if not found
      } else {
        updatedArray = removeAtIndex(updatedArray, index); // Remove item if found
      }
    });
    return updatedArray;
  } else {
    const index = arr.findIndex((i) => getValue(i) === getValue(items));
    if (index === -1) return [...arr, items];
    return removeAtIndex(arr, index);
  }
};

export const uploadFile = async (files) => {
  const formData = new FormData();
  // //console.log(files);
  // formData.append("files", file);
  files.forEach((file) => formData.append("files", file, file.name));
  // files.forEach((file) => //console.log(file));

  try {
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "upload",
      formData
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    return toast.error(err.response?.data?.error?.message);
  }
};

export const removeFile = async (ID) => {
  try {
    const response = uninterceptedAxiosInstance.delete(
      backend_url + "upload/files/" + ID
    );

    let the_response = response;
    //////// //console.log("response", response.data);
    return the_response;
  } catch (err) {
    // Handle Error Here
    //// //console.log(err);
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const urlToFile = async (rel_url, name) => {
  const url = backend_url + rel_url.substring(1);
  const response = await fetch(rel_url);

  // here image is url/location of image
  const blob = await response.blob();
  const file = new File([blob], name, { type: blob.type });
  return file;
};
export const imageUrlToFile = async (rel_url, name) => {
  const url = IMAGES_URL + "/" + rel_url.substring(1);
  const response = await fetch(rel_url);
  // here image is url/location of image
  const blob = await response.blob();
  const file = new File([blob], name, { type: blob.type });
  return file;
};

export const nationalities = [
  {
    key: "JOR",
    value: "JOR",
    text: "Jordan",
    flag: "jo",
  },
  {
    key: "AFG",
    value: "AFG",
    text: "Afghanistan",
    flag: "af",
  },
  {
    key: "ALA",
    value: "ALA",
    text: "Aland Islands",
    flag: "ax",
  },
  {
    key: "ALB",
    value: "ALB",
    text: "Albania",
    flag: "al",
  },
  {
    key: "DZA",
    value: "DZA",
    text: "Algeria",
    flag: "dz",
  },
  {
    key: "ASM",
    value: "ASM",
    text: "American Samoa",
    flag: "as",
  },
  {
    key: "AND",
    value: "AND",
    text: "Andorra",
    flag: "ad",
  },
  {
    key: "AGO",
    value: "AGO",
    text: "Angola",
    flag: "ao",
  },
  {
    key: "AIA",
    value: "AIA",
    text: "Anguilla",
    flag: "ai",
  },
  {
    key: "ARG",
    value: "ARG",
    text: "Argentina",
    flag: "ar",
  },
  {
    key: "ARM",
    value: "ARM",
    text: "Armenia",
    flag: "am",
  },
  {
    key: "ABW",
    value: "ABW",
    text: "Aruba",
    flag: "aw",
  },
  {
    key: "AUS",
    value: "AUS",
    text: "Australia",
    flag: "au",
  },
  {
    key: "AUT",
    value: "AUT",
    text: "Austria",
    flag: "at",
  },
  {
    key: "AZE",
    value: "AZE",
    text: "Azerbaijan",
    flag: "az",
  },
  {
    key: "BHS",
    value: "BHS",
    text: "Bahamas",
    flag: "bs",
  },
  {
    key: "BHR",
    value: "BHR",
    text: "Bahrain",
    flag: "bh",
  },
  {
    key: "BGD",
    value: "BGD",
    text: "Bangladesh",
    flag: "bd",
  },
  {
    key: "BRB",
    value: "BRB",
    text: "Barbados",
    flag: "bb",
  },
  {
    key: "BLR",
    value: "BLR",
    text: "Belarus",
    flag: "by",
  },
  {
    key: "BEL",
    value: "BEL",
    text: "Belgium",
    flag: "be",
  },
  {
    key: "BLZ",
    value: "BLZ",
    text: "Belize",
    flag: "bz",
  },
  {
    key: "BEN",
    value: "BEN",
    text: "Benin",
    flag: "bj",
  },
  {
    key: "BMU",
    value: "BMU",
    text: "Bermuda",
    flag: "bm",
  },
  {
    key: "BTN",
    value: "BTN",
    text: "Bhutan",
    flag: "bt",
  },
  {
    key: "BOL",
    value: "BOL",
    text: "Bolivia",
    flag: "bo",
  },
  {
    key: "BIH",
    value: "BIH",
    text: "Bosnia",
    flag: "ba",
  },
  {
    key: "BWA",
    value: "BWA",
    text: "Botswana",
    flag: "bw",
  },
  {
    key: "BVT",
    value: "BVT",
    text: "Bouvet Island",
    flag: "bv",
  },
  {
    key: "BRA",
    value: "BRA",
    text: "Brazil",
    flag: "br",
  },
  {
    key: "BGR",
    value: "BGR",
    text: "Bulgaria",
    flag: "bg",
  },
  {
    key: "BFA",
    value: "BFA",
    text: "Burkina Faso",
    flag: "bf",
  },
  {
    key: "BDI",
    value: "BDI",
    text: "Burundi",
    flag: "bi",
  },
  {
    key: "KHM",
    value: "KHM",
    text: "Cambodia",
    flag: "kh",
  },
  {
    key: "CMR",
    value: "CMR",
    text: "Cameroon",
    flag: "cm",
  },
  {
    key: "CAN",
    value: "CAN",
    text: "Canada",
    flag: "ca",
  },
  {
    key: "CPV",
    value: "CPV",
    text: "Cape Verde",
    flag: "cv",
  },
  {
    key: "CYM",
    value: "CYM",
    text: "Cayman Islands",
    flag: "ky",
  },
  {
    key: "CAF",
    value: "CAF",
    text: "Central African Republic",
    flag: "cf",
  },
  {
    key: "TCD",
    value: "TCD",
    text: "Chad",
    flag: "td",
  },
  {
    key: "CHL",
    value: "CHL",
    text: "Chile",
    flag: "cl",
  },
  {
    key: "CHN",
    value: "CHN",
    text: "China",
    flag: "cn",
  },
  {
    key: "CXR",
    value: "CXR",
    text: "Christmas Island",
    flag: "cx",
  },
  {
    key: "CCK",
    value: "CCK",
    text: "Cocos (Keeling) Islands",
  },
  {
    key: "COL",
    value: "COL",
    text: "Colombia",
    flag: "co",
  },
  {
    key: "COM",
    value: "COM",
    text: "Comoros",
    flag: "km",
  },
  {
    key: "COG",
    value: "COG",
    text: "Congo",
    flag: "cd",
  },
  {
    key: "COK",
    value: "COK",
    text: "Cook Islands",
    flag: "ck",
  },
  {
    key: "CRI",
    value: "CRI",
    text: "Costa Rica",
    flag: "cr",
  },
  {
    key: "CIV",
    value: "CIV",
    text: "Cote D'Ivoire",
    flag: "ci",
  },
  {
    key: "HRV",
    value: "HRV",
    text: "Croatia",
    flag: "hr",
  },
  {
    key: "CUB",
    value: "CUB",
    text: "Cuba",
    flag: "cu",
  },
  {
    key: "CYP",
    value: "CYP",
    text: "Cyprus",
    flag: "cy",
  },
  {
    key: "CZE",
    value: "CZE",
    text: "Czech Republic",
    flag: "cz",
  },
  {
    key: "DNK",
    value: "DNK",
    text: "Denmark",
    flag: "dk",
  },
  {
    key: "DJI",
    value: "DJI",
    text: "Djibouti",
    flag: "dj",
  },
  {
    key: "DMA",
    value: "DMA",
    text: "Dominica",
    flag: "dm",
  },
  {
    key: "DOM",
    value: "DOM",
    text: "Dominican Republic",
    flag: "do",
  },
  {
    key: "ECU",
    value: "ECU",
    text: "Ecuador",
    flag: "ec",
  },
  {
    key: "EGY",
    value: "EGY",
    text: "Egypt",
    flag: "eg",
  },
  {
    key: "SLV",
    value: "SLV",
    text: "El Salvador",
    flag: "sv",
  },
  {
    key: "GNQ",
    value: "GNQ",
    text: "Equatorial Guinea",
    flag: "gq",
  },
  {
    key: "ERI",
    value: "ERI",
    text: "Eritrea",
    flag: "er",
  },
  {
    key: "EST",
    value: "EST",
    text: "Estonia",
    flag: "ee",
  },
  {
    key: "ETH",
    value: "ETH",
    text: "Ethiopia",
    flag: "et",
  },
  {
    key: "FRO",
    value: "FRO",
    text: "Faroe Islands",
    flag: "fo",
  },
  {
    key: "FJI",
    value: "FJI",
    text: "Fiji",
    flag: "fj",
  },
  {
    key: "FIN",
    value: "FIN",
    text: "Finland",
    flag: "fi",
  },
  {
    key: "FRA",
    value: "FRA",
    text: "France",
    flag: "fr",
  },
  {
    key: "GUF",
    value: "GUF",
    text: "French Guiana",
    flag: "gf",
  },
  {
    key: "PYF",
    value: "PYF",
    text: "French Polynesia",
    flag: "pf",
  },
  {
    key: "GAB",
    value: "GAB",
    text: "Gabon",
    flag: "ga",
  },
  {
    key: "GMB",
    value: "GMB",
    text: "Gambia",
    flag: "gm",
  },
  {
    key: "GEO",
    value: "GEO",
    text: "Georgia",
    flag: "ge",
  },
  {
    key: "DEU",
    value: "DEU",
    text: "Germany",
    flag: "de",
  },
  {
    key: "GHA",
    value: "GHA",
    text: "Ghana",
    flag: "gh",
  },
  {
    key: "GIB",
    value: "GIB",
    text: "Gibraltar",
    flag: "gi",
  },
  {
    key: "GRC",
    value: "GRC",
    text: "Greece",
    flag: "gr",
  },
  {
    key: "GRL",
    value: "GRL",
    text: "Greenland",
    flag: "gl",
  },
  {
    key: "GRD",
    value: "GRD",
    text: "Grenada",
    flag: "gd",
  },
  {
    key: "GLP",
    value: "GLP",
    text: "Guadeloupe",
    flag: "gp",
  },
  {
    key: "GUM",
    value: "GUM",
    text: "Guam",
    flag: "gu",
  },
  {
    key: "GTM",
    value: "GTM",
    text: "Guatemala",
    flag: "gt",
  },
  {
    key: "GIN",
    value: "GIN",
    text: "Guinea",
    flag: "gn",
  },
  {
    key: "GNB",
    value: "GNB",
    text: "Guinea-Bissau",
    flag: "gw",
  },
  {
    key: "GUY",
    value: "GUY",
    text: "Guyana",
    flag: "gy",
  },
  {
    key: "HTI",
    value: "HTI",
    text: "Haiti",
    flag: "ht",
  },
  {
    key: "HND",
    value: "HND",
    text: "Honduras",
    flag: "hn",
  },
  {
    key: "HKG",
    value: "HKG",
    text: "Hong Kong",
    flag: "hk",
  },
  {
    key: "HUN",
    value: "HUN",
    text: "Hungary",
    flag: "hu",
  },
  {
    key: "ISL",
    value: "ISL",
    text: "Iceland",
    flag: "is",
  },
  {
    key: "IND",
    value: "IND",
    text: "India",
    flag: "in",
  },
  {
    key: "IDN",
    value: "IDN",
    text: "Indonesia",
    flag: "id",
  },
  {
    key: "IRN",
    value: "IRN",
    text: "Iran",
    flag: "ir",
  },
  {
    key: "IRQ",
    value: "IRQ",
    text: "Iraq",
    flag: "iq",
  },
  {
    key: "IRL",
    value: "IRL",
    text: "Ireland",
    flag: "ie",
  },
  {
    key: "ITA",
    value: "ITA",
    text: "Italy",
    flag: "it",
  },
  {
    key: "JAM",
    value: "JAM",
    text: "Jamaica",
    flag: "jm",
  },
  {
    key: "JPN",
    value: "JPN",
    text: "Japan",
    flag: "jp",
  },
  {
    key: "KAZ",
    value: "KAZ",
    text: "Kazakhstan",
    flag: "kz",
  },
  {
    key: "KEN",
    value: "KEN",
    text: "Kenya",
    flag: "ke",
  },
  {
    key: "KIR",
    value: "KIR",
    text: "Kiribati",
    flag: "ki",
  },
  {
    key: "KWT",
    value: "KWT",
    text: "Kuwait",
    flag: "kw",
  },
  {
    key: "KGZ",
    value: "KGZ",
    text: "Kyrgyzstan",
    flag: "kg",
  },
  {
    key: "LVA",
    value: "LVA",
    text: "Latvia",
    flag: "lv",
  },
  {
    key: "LBN",
    value: "LBN",
    text: "Lebanon",
    flag: "lb",
  },
  {
    key: "LSO",
    value: "LSO",
    text: "Lesotho",
    flag: "ls",
  },
  {
    key: "LBR",
    value: "LBR",
    text: "Liberia",
    flag: "lr",
  },
  {
    key: "LBY",
    value: "LBY",
    text: "Libya",
    flag: "ly",
  },
  {
    key: "LIE",
    value: "LIE",
    text: "Liechtenstein",
    flag: "li",
  },
  {
    key: "LTU",
    value: "LTU",
    text: "Lithuania",
    flag: "lt",
  },
  {
    key: "LUX",
    value: "LUX",
    text: "Luxembourg",
    flag: "lu",
  },
  {
    key: "MKD",
    value: "MKD",
    text: "Macedonia",
    flag: "mk",
  },
  {
    key: "MDG",
    value: "MDG",
    text: "Madagascar",
    flag: "mg",
  },
  {
    key: "MWI",
    value: "MWI",
    text: "Malawi",
    flag: "mw",
  },
  {
    key: "MYS",
    value: "MYS",
    text: "Malaysia",
    flag: "my",
  },
  {
    key: "MDV",
    value: "MDV",
    text: "Maldives",
    flag: "mv",
  },
  {
    key: "MLI",
    value: "MLI",
    text: "Mali",
    flag: "ml",
  },
  {
    key: "MLT",
    value: "MLT",
    text: "Malta",
    flag: "mt",
  },
  {
    key: "MHL",
    value: "MHL",
    text: "Marshall Islands",
    flag: "mh",
  },
  {
    key: "MTQ",
    value: "MTQ",
    text: "Martinique",
    flag: "mq",
  },
  {
    key: "MRT",
    value: "MRT",
    text: "Mauritania",
    flag: "mr",
  },
  {
    key: "MUS",
    value: "MUS",
    text: "Mauritius",
    flag: "mu",
  },
  {
    key: "MYT",
    value: "MYT",
    text: "Mayotte",
    flag: "yt",
  },
  {
    key: "MEX",
    value: "MEX",
    text: "Mexico",
    flag: "mx",
  },
  {
    key: "MCO",
    value: "MCO",
    text: "Monaco",
    flag: "mc",
  },
  {
    key: "MNG",
    value: "MNG",
    text: "Mongolia",
    flag: "mn",
  },
  {
    key: "MNE",
    value: "MNE",
    text: "Montenegro",
    flag: "me",
  },
  {
    key: "MSR",
    value: "MSR",
    text: "Montserrat",
    flag: "ms",
  },
  {
    key: "MAR",
    value: "MAR",
    text: "Morocco",
    flag: "ma",
  },
  {
    key: "MOZ",
    value: "MOZ",
    text: "Mozambique",
    flag: "mz",
  },
  {
    key: "NAM",
    value: "NAM",
    text: "Namibia",
    flag: "na",
  },
  {
    key: "NRU",
    value: "NRU",
    text: "Nauru",
    flag: "nr",
  },
  {
    key: "NPL",
    value: "NPL",
    text: "Nepal",
    flag: "np",
  },
  {
    key: "NLD",
    value: "NLD",
    text: "Netherlands",
    flag: "nl",
  },
  {
    key: "NCL",
    value: "NCL",
    text: "New Caledonia",
    flag: "nc",
  },
  {
    key: "NZL",
    value: "NZL",
    text: "New Zealand",
    flag: "nz",
  },
  {
    key: "NIC",
    value: "NIC",
    text: "Nicaragua",
    flag: "ni",
  },
  {
    key: "NER",
    value: "NER",
    text: "Niger",
    flag: "ne",
  },
  {
    key: "NGA",
    value: "NGA",
    text: "Nigeria",
    flag: "ng",
  },
  {
    key: "NIU",
    value: "NIU",
    text: "Niue",
    flag: "nu",
  },
  {
    key: "NFK",
    value: "NFK",
    text: "Norfolk Island",
    flag: "nf",
  },
  {
    key: "PRK",
    value: "PRK",
    text: "North Korea",
    flag: "kp",
  },
  {
    key: "MNP",
    value: "MNP",
    text: "Northern Mariana Islands",
    flag: "mp",
  },
  {
    key: "NOR",
    value: "NOR",
    text: "Norway",
    flag: "no",
  },
  {
    key: "OMN",
    value: "OMN",
    text: "Oman",
    flag: "om",
  },
  {
    key: "PAK",
    value: "PAK",
    text: "Pakistan",
    flag: "pk",
  },
  {
    key: "PLW",
    value: "PLW",
    text: "Palau",
    flag: "pw",
  },
  {
    key: "PSE",
    value: "PSE",
    text: "Palestine",
    flag: "ps",
  },
  {
    key: "PAN",
    value: "PAN",
    text: "Panama",
    flag: "pa",
  },
  {
    key: "PRY",
    value: "PRY",
    text: "Paraguay",
    flag: "py",
  },
  {
    key: "PER",
    value: "PER",
    text: "Peru",
    flag: "pe",
  },
  {
    key: "PHL",
    value: "PHL",
    text: "Philippines",
    flag: "ph",
  },
  {
    key: "POL",
    value: "POL",
    text: "Poland",
    flag: "pl",
  },
  {
    key: "PRT",
    value: "PRT",
    text: "Portugal",
    flag: "pt",
  },
  {
    key: "PRI",
    value: "PRI",
    text: "Puerto Rico",
    flag: "pr",
  },
  {
    key: "QAT",
    value: "QAT",
    text: "Qatar",
    flag: "qa",
  },
  {
    key: "REU",
    value: "REU",
    text: "Reunion",
    flag: "re",
  },
  {
    key: "ROM",
    value: "ROM",
    text: "Romania",
    flag: "ro",
  },
  {
    key: "RUS",
    value: "RUS",
    text: "Russia",
    flag: "ru",
  },
  {
    key: "RWA",
    value: "RWA",
    text: "Rwanda",
    flag: "rw",
  },
  {
    key: "SHN",
    value: "SHN",
    text: "Saint Helena",
    flag: "sh",
  },
  {
    key: "KNA",
    value: "KNA",
    text: "Saint Kitts and Nevis",
    flag: "kn",
  },
  {
    key: "LCA",
    value: "LCA",
    text: "Saint Lucia",
    flag: "lc",
  },
  {
    key: "WSM",
    value: "WSM",
    text: "Samoa",
    flag: "ws",
  },
  {
    key: "SMR",
    value: "SMR",
    text: "San Marino",
    flag: "sm",
  },
  {
    key: "SAU",
    value: "SAU",
    text: "Saudi Arabia",
    flag: "sa",
  },
  {
    key: "SEN",
    value: "SEN",
    text: "Senegal",
    flag: "sn",
  },
  {
    key: "SRB",
    value: "SRB",
    text: "Serbia",
    flag: "cs",
  },
  {
    key: "SYC",
    value: "SYC",
    text: "Seychelles",
    flag: "sc",
  },
  {
    key: "SLE",
    value: "SLE",
    text: "Sierra Leone",
    flag: "sl",
  },
  {
    key: "SGP",
    value: "SGP",
    text: "Singapore",
    flag: "sg",
  },
  {
    key: "SVK",
    value: "SVK",
    text: "Slovakia",
    flag: "sk",
  },
  {
    key: "SVN",
    value: "SVN",
    text: "Slovenia",
    flag: "si",
  },
  {
    key: "SLB",
    value: "SLB",
    text: "Solomon Islands",
    flag: "sb",
  },
  {
    key: "SOM",
    value: "SOM",
    text: "Somalia",
    flag: "so",
  },
  {
    key: "ZAF",
    value: "ZAF",
    text: "South Africa",
    flag: "za",
  },
  {
    key: "KOR",
    value: "KOR",
    text: "South Korea",
    flag: "kr",
  },
  {
    key: "SSD",
    value: "SSD",
    text: "South Sudan",
  },
  {
    key: "ESP",
    value: "ESP",
    text: "Spain",
    flag: "es",
  },
  {
    key: "LKA",
    value: "LKA",
    text: "Sri Lanka",
    flag: "lk",
  },
  {
    key: "SDN",
    value: "SDN",
    text: "Sudan",
    flag: "sd",
  },
  {
    key: "SUR",
    value: "SUR",
    text: "Suriname",
    flag: "sr",
  },
  {
    key: "SWZ",
    value: "SWZ",
    text: "Swaziland",
    flag: "sz",
  },
  {
    key: "SWE",
    value: "SWE",
    text: "Sweden",
    flag: "se",
  },
  {
    key: "CHE",
    value: "CHE",
    text: "Switzerland",
    flag: "ch",
  },
  {
    key: "SYR",
    value: "SYR",
    text: "Syria",
    flag: "sy",
  },
  {
    key: "TWN",
    value: "TWN",
    text: "Taiwan",
    flag: "tw",
  },
  {
    key: "TJK",
    value: "TJK",
    text: "Tajikistan",
    flag: "tj",
  },
  {
    key: "TZA",
    value: "TZA",
    text: "Tanzania",
    flag: "tz",
  },
  {
    key: "THA",
    value: "THA",
    text: "Thailand",
    flag: "th",
  },
  {
    key: "TGO",
    value: "TGO",
    text: "Togo",
    flag: "tg",
  },
  {
    key: "TKL",
    value: "TKL",
    text: "Tokelau",
    flag: "tk",
  },
  {
    key: "TON",
    value: "TON",
    text: "Tonga",
    flag: "to",
  },
  {
    key: "TUN",
    value: "TUN",
    text: "Tunisia",
    flag: "tn",
  },
  {
    key: "TUR",
    value: "TUR",
    text: "Turkey",
    flag: "tr",
  },
  {
    key: "TKM",
    value: "TKM",
    text: "Turkmenistan",
    flag: "tm",
  },
  {
    key: "TUV",
    value: "TUV",
    text: "Tuvalu",
    flag: "tv",
  },
  {
    key: "UGA",
    value: "UGA",
    text: "Uganda",
    flag: "ug",
  },
  {
    key: "UKR",
    value: "UKR",
    text: "Ukraine",
    flag: "ua",
  },
  {
    key: "ARE",
    value: "ARE",
    text: "United Arab Emirates",
    flag: "ae",
  },
  {
    key: "GBR",
    value: "GBR",
    text: "United Kingdom",
    flag: "gb",
  },
  {
    key: "USA",
    value: "USA",
    text: "United States",
    flag: "us",
  },
  {
    key: "URY",
    value: "URY",
    text: "Uruguay",
    flag: "uy",
  },
  {
    key: "UZB",
    value: "UZB",
    text: "Uzbekistan",
    flag: "uz",
  },
  {
    key: "VUT",
    value: "VUT",
    text: "Vanuatu",
    flag: "vu",
  },
  {
    key: "VEN",
    value: "VEN",
    text: "Venezuela",
    flag: "ve",
  },
  {
    key: "VNM",
    value: "VNM",
    text: "Vietnam",
    flag: "vn",
  },
  {
    key: "VGB",
    value: "VGB",
    text: "Virgin Islands, British",
  },
  {
    key: "WLF",
    value: "WLF",
    text: "Wallis and Futuna",
    flag: "wf",
  },
  {
    key: "ESH",
    value: "ESH",
    text: "Western Sahara",
    flag: "eh",
  },
  {
    key: "YEM",
    value: "YEM",
    text: "Yemen",
    flag: "ye",
  },
  {
    key: "ZMB",
    value: "ZMB",
    text: "Zambia",
    flag: "zm",
  },
  {
    key: "ZWE",
    value: "ZWE",
    text: "Zimbabwe",
    flag: "zw",
  },
];

export const country_codes = [
  {
    key: "962",
    value: "962",
    text: "+962",
    flag: "jo",
  },
  {
    key: "964",
    value: "964",
    text: "+964",
    flag: "iq",
  },
];

export const currencies = [
  {
    key: "JOD",
    value: "JOD",
    text: "JOD",
  },
  {
    key: "AUD",
    value: "AUD",
    text: "AUD",
  },
  {
    key: "BWP",
    value: "BWP",
    text: "BWP",
  },
  {
    key: "GBP",
    value: "GBP",
    text: "GBP",
  },
  {
    key: "CAD",
    value: "CAD",
    text: "CAD",
  },
  {
    key: "CNY",
    value: "CNY",
    text: "CNY",
  },
  {
    key: "DKK",
    value: "DKK",
    text: "DKK",
  },
  {
    key: "EUR",
    value: "EUR",
    text: "EUR",
  },
  {
    key: "HUF",
    value: "HUF",
    text: "HUF",
  },
  {
    key: "INR",
    value: "INR",
    text: "INR",
  },
  {
    key: "IDR",
    value: "IDR",
    text: "IDR",
  },
  {
    key: "JPY",
    value: "JPY",
    text: "JPY",
  },
  {
    key: "KWD",
    value: "KWD",
    text: "KWD",
  },
  {
    key: "MYR",
    value: "MYR",
    text: "MYR",
  },
  {
    key: "NZD",
    value: "NZD",
    text: "NZD",
  },
  {
    key: "NGN",
    value: "NGN",
    text: "NGN",
  },
  {
    key: "NOK",
    value: "NOK",
    text: "NOK",
  },
  {
    key: "PKR",
    value: "PKR",
    text: "PKR",
  },
  {
    key: "PYG",
    value: "PYG",
    text: "PYG",
  },
  {
    key: "PHP",
    value: "PHP",
    text: "PHP",
  },
  {
    key: "QAR",
    value: "QAR",
    text: "QAR",
  },
  {
    key: "RWF",
    value: "RWF",
    text: "RWF",
  },
  {
    key: "SAR",
    value: "SAR",
    text: "SAR",
  },
  {
    key: "SGD",
    value: "SGD",
    text: "SGD",
  },
  {
    key: "ZAR",
    value: "ZAR",
    text: "ZAR",
  },
  {
    key: "KRW",
    value: "KRW",
    text: "KRW",
  },
  {
    key: "TZS",
    value: "TZS",
    text: "TZS",
  },
  {
    key: "THB",
    value: "THB",
    text: "THB",
  },
  {
    key: "UGX",
    value: "UGX",
    text: "UGX",
  },
  {
    key: "AED",
    value: "AED",
    text: "AED",
  },
  {
    key: "USD",
    value: "USD",
    text: "USD",
  },
];

export const isoToDate = (isoformat, full = false) => {
  let options = {};
  if (full) {
    options = {
      weekday: "long",
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
  } else {
    options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
  }
  const the_date = new Date(isoformat);

  return the_date.toLocaleString("en-US", options);
};

var headers = new Headers();
headers.append("X-CSCAPI-KEY", "API_KEY");

var requestOptions = {
  method: "GET",
  headers: headers,
  redirect: "follow",
};

export const GMAPAPIKEY = "AIzaSyApFL9HUeVFwZdGwN6JW-gO7v4VNdenN3M";

// POST functions
export const createNewUser = async (
  username,
  role,
  phone,
  email,
  teams,
  firstName,
  lastName,
  supervisor,
  locations,
  organisation = "",
  createdByUser = null,
  profileImage
) => {
  try {
    const password = generateRandomPassword(6);

    // let emailMod = email.toLowerCase()

    const params = {
      username,
      role,
      phone,
      email,
      teams,
      firstName,
      lastName,
      supervisor,
      locations,
      password,
      organisation,
      createdByUser,
      profileImage,
    };

    // //console.log("params role", params.role);
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "auth/local/register",
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    // //console.log('response', response);
    return response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    throw throwError({
      message: err.response?.data?.error?.message,
    });
  }
};

export const createNewUserProfile = async (
  user,
  phone,
  address,
  emergencyName,
  emergencyPhone,
  educationLevel,
  fieldOfStudy,
  martialStatus,
  nationality,
  passportNo,
  identificationNo,
  dob,
  cob,
  gender,
  childrenNo,
  visaNo,
  visaExpireDate,
  jobTitle,
  department,
  hourRate,
  whpd,
  whpw,
  contractStart,
  contractEnd,
  registrationNo,
  badgeId,
  shifts,
  workPermitNo,
  days,
  contractCopy,
  idCopyFront,
  personalPicture,
  passportCopy,
  visaCopy,
  covidCopy
) => {
  try {
    let params = {
      user,
      phone,
      address,
      emergencyName,
      emergencyPhone,
      educationLevel,
      fieldOfStudy,
      martialStatus,
      nationality,
      passportNo,
      identificationNo,
      dob,
      cob,
      gender,
      childrenNo,
      visaNo,
      visaExpireDate,
      job: jobTitle,
      department,
      hourRate,
      whpd,
      whpw,
      contractStart,
      contractEnd,
      registrationNo,
      badgeId,
      shifts,
      workPermitNo,
      days,
    };

    if (contractCopy)
      params = {
        ...params,
        contractCopy,
      };
    if (idCopyFront)
      params = {
        ...params,
        idCopyFront,
      };

    if (personalPicture)
      params = {
        ...params,
        personalPicture,
      };

    if (passportCopy)
      params = {
        ...params,
        passportCopy,
      };

    if (visaCopy)
      params = {
        ...params,
        visaCopy,
      };

    if (covidCopy)
      params = {
        ...params,
        covidCopy,
      };

    // //console.log('params', params);
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "user-profiles",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    return response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const createNewAttendanceRecord = async (
  user,
  location,
  isAddedManually,
  reasonToAddManually,
  punchIn,
  punchOut
) => {
  try {
    let params = {
      user,
      location,
      isAddedManually,
      reasonToAddManually,
      punchInOut: {
        punchIn,
        punchOut,
      },
    };

    // //console.log('params', params);
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "attendance-records",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    return response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const createNewTeam = async (
  name,
  description,
  users,
  superior,
  locations,
  organisation = "",
  createdByUser = null,
  customer = null,
  images
) => {
  try {
    const password = generateRandomPassword(6);

    let params = {
      name,
      description,
      users,
      superior,
      locations,
      organisation,
      createdByUser,
      customers: customer,
      images,
    };

    // //console.log('params', params);
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "teams",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const createNewLocation = async (
  name,
  address,
  isParent,
  parentLocation,
  country,
  city,
  customer,
  vendors,
  teams,
  users,
  mainLocation,
  geofencingCoords,
  customFields,
  organisation = "",
  procedure = null,
  createdByUser = null,
  propertyType,
  area,
  image
) => {
  try {
    const password = generateRandomPassword(6);
    let params = {
      name,
      address,
      isParent,
      parentLocation,
      country,
      city,
      customers: customer,
      vendors,
      teams,
      users,
      mainLocation,
      geofencingCoords,
      customFields,
      organisation,
      procedure,
      createdByUser,
      propertyType,
      area,
      image,
    };

    if (params.country) {
      params.countryName = Country.getCountryByCode(params.country).name;
    }
    if (params.city) {
      const citiesOfCountry = State.getStatesOfCountry(params.country);
      const the_city = params.city
        ? citiesOfCountry.filter((city) => {
            return city.isoCode == params.city;
          })[0].name
        : "";
      params.cityName = the_city;
    }
    if (customFields && customFields.length) {
      let newFieldsArray = customFields.map((one) => {
        delete one.id;
        return one;
      });
      params.customFields = newFieldsArray;
    }

    if (area && area?.length) {
      let filteredArea = area.filter((one) => one.area && one.area.trim());
      params.area = filteredArea;
    }

    // //console.log('params', params);
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "locations",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const createNewCustomer = async (
  name,
  phone,
  website,
  email,
  serviceType,
  description,
  // rate,
  billingInformation,
  logo,
  organisation = "",
  createdByUser = null,
  locations = null,
  Industry = null,
  contactInformation
) => {
  try {
    let params = {
      name: name,
      phone: phone,
      website: website,
      email: email,
      serviceType: serviceType,
      description: description,
      // rate,
      billingInformation: billingInformation,
      organisation,
      createdByUser,
      locations,
      Industry,
      contactInformation,
      logo,
    };

    if (params?.billingInformation?.country) {
      params.billingInformation.countryName = Country.getCountryByCode(
        params?.billingInformation?.country
      )?.name;
    }
    if (params?.billingInformation?.city) {
      const citiesOfCountry = State.getStatesOfCountry(
        params.billingInformation.country
      );
      const the_city = params.billingInformation.city
        ? citiesOfCountry.filter((city) => {
            return city.isoCode == params.billingInformation.city;
          })[0].name
        : "";
      params.billingInformation.cityName = the_city;
    }

    // if (!email)
    //   params = {
    //     ...params,
    //     email: 'empty-customer@quiqflow.com',
    //   };

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "customers",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const createNewVendor = async (
  name,
  address,
  phone,
  website,
  email,
  serviceType,
  description,
  // rate,
  billingInformation,
  logo,
  organisation = "",
  createdByUser = null,
  Industry,
  contactInformation,
  creditDays,
  startDate,
  endDate,
  location,
  preferences
) => {
  try {
    let params = {
      name,
      address,
      phone,
      website,
      email,
      serviceType,
      description,
      logo,
      // rate,
      billingInformation,
      organisation,
      createdByUser,
      Industry,
      contactInformation,
      creditDays,
      startDate,
      endDate,
      location,
      preferences,
    };

    if (params?.billingInformation?.country) {
      params.billingInformation.countryName = Country.getCountryByCode(
        params?.billingInformation?.country
      )?.name;
    }
    if (params?.billingInformation?.city) {
      const citiesOfCountry = State.getStatesOfCountry(
        params.billingInformation.country
      );
      const the_city = params.billingInformation.city
        ? citiesOfCountry.filter((city) => {
            return city.isoCode == params.billingInformation.city;
          })[0].name
        : "";
      params.billingInformation.cityName = the_city;
    }

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "vendors",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const createNewSet = async (
  name,
  parts,
  sets,
  organisation,
  createdByUser,
  partsOfSet,
  location
) => {
  try {
    let params = {
      name,
      parts,
      sets,
      createdByUser,
      organisation,
      partsOfSet,
      location,
    };

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "sets",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const createNewPurchaseOrder = async (
  details,
  orderItems,
  shippingAddress,
  billingAddress,
  organisation,
  createdByUser,
  attachments
) => {
  try {
    let params = {
      details,
      orderItems,
      shippingAddress,
      billingAddress,
      createdByUser,
      organisation,
      attachments,
    };

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "purchase-orders",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updatePurchaseOrder = async (
  details,
  orderItems,
  shippingAddress,
  billingAddress,
  organisation,
  id,
  attachments
) => {
  try {
    let params = {
      details,
      orderItems,
      shippingAddress,
      billingAddress,
      organisation,
      attachments,
    };

    const response = await uninterceptedAxiosInstance.put(
      backend_url + "purchase-orders/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};
export const updateSet = async (
  id,
  name,
  parts,
  sets,
  organisation,
  partsOfSet,
  location
) => {
  try {
    let params = {
      name,
      parts,
      sets,
      organisation,
      partsOfSet,
      location,
    };

    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "sets/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    //console.log('Error config.data', err.config.data);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const createCategory = async (
  name,
  organisationId = null,
  createdByUser = null
) => {
  try {
    let params = {
      name,
      createdByUser,
      organisation: organisationId,
    };

    // //console.log('params', params);

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "categories",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
    };
  }
};

export const createJobTitle = async (
  title,
  organisationId = null,
  createdByUser = null
) => {
  try {
    let params = {
      title,
      createdByUser,
      organisation: organisationId,
    };

    // //console.log('params', params);

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "jobs",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
    };
  }
};

export const updateCategory = async (id, name) => {
  try {
    let params = {
      name,
    };

    // //console.log('params', params);

    const response = await uninterceptedAxiosInstance.put(
      backend_url + `categories/${id}`,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    // //console.log('response', response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
    };
  }
};
export const updateJobTitle = async (id, title) => {
  try {
    let params = {
      title,
    };

    // //console.log('params', params);

    const response = await uninterceptedAxiosInstance.put(
      backend_url + `jobs/${id}`,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    // //console.log('response', response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
    };
  }
};

export const deleteCategory = async (id) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + `categories/${id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    return {
      status: err?.response?.status,
    };
    //console.log('Error config', err.config);
  }
};

export const deleteJobTitle = async (id) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + `jobs/${id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    return {
      status: err?.response?.status,
    };
    //console.log('Error config', err.config);
  }
};

export const createNewAsset = async (
  name,
  description,
  model,
  category,
  area,
  serialNumber,
  barcode,
  vendor,
  customer,
  placedDate,
  warrantyExpirationDate,
  additionalInformation,
  image,
  warrantyFile,
  qrcode,
  organisation = "",
  procedure = null,
  createdByUser = null,
  location = null,
  subLocations = null,
  purchasePrice,
  manufacturer,
  descriptionRate,
  estimatedUsefulLife,
  peoples,
  teams,
  isChild,
  parentAsset
) => {
  try {
    let params = {
      name,
      description,
      model,
      categories: category,
      area,
      serialNumber,
      barcode,
      vendor,
      customer,
      placedDate,
      warrantyExpirationDate,
      additionalInformation,
      qrcode,
      organisation,
      procedure,
      createdByUser,
      location,
      subLocations,
      purchasePrice,
      manufacturer,
      descriptionRate,
      estimatedUsefulLife,
      peoples,
      teams,
      barcode,
      parentAsset,
      warrantyFile,
      isChild,
    };

    if (image)
      params = {
        ...params,
        image: image,
      };

    // //console.log('params', params);

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "assets",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const createNewInventory = async (
  name,
  description,
  category,
  // unitCost,
  measuringUnit,
  type,
  isNonStock,
  qrcode,
  barcode,
  serialNumber,
  vendors,
  assets,
  people,
  teams,
  logo,
  files,
  organisation = "",
  createdByUser = null,
  inventory
  // minimumQuantity,
  // maximumQuantity
) => {
  try {
    let params = {
      name,
      description,
      category,
      // unitCost,
      measuringUnit,
      type,
      isNonStock,
      qrcode,
      barcode,
      serialNumber,
      vendors,
      assets,
      people,
      teams,
      logo,
      files,
      organisation,
      createdByUser,
      inventory,
      // minimumQuantity,
      // maximumQuantity,
    };
    // if (customFields && customFields.length) {
    //   let newFieldsArray = customFields.map((one) => {
    //     delete one.id;
    //     return one;
    //   });
    //   params.customFields = newFieldsArray;
    // }
    // if (image)
    //   params = {
    //     ...params,
    //     image: image,
    //   };

    // if (files.length > 0)
    //   params = {
    //     ...params,
    //     files,
    //   };

    // //console.log('params', params);

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "parts",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const restockQuantity = async (
  location,
  unitCost,
  totalCost,
  // area,
  quantity,
  attachments,
  createdByUser,
  organisation,
  part
) => {
  try {
    let params = {
      location,
      unitCost,
      totalCost,
      // area,
      quantity,
      attachments,
      createdByUser,
      organisation,
      part,
    };

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "restock-parts",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const createNewProcedure = async (
  title,
  description,
  category,
  fields,
  headers,
  organisation = "",
  createdByUser = null,
  isGlobalTemplate,
  assets
) => {
  try {
    let params = {
      title,
      description,
      category,
      fields,
      headers,
      organisation,
      createdByUser,
      isGlobalTemplate,
      assets,
    };

    // //console.log('params', params);
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "procedures",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const createNewWorkOrder = async (
  title,
  description,
  customer,
  location,
  orderType,
  dueDate,
  dueTime,
  repeatEvery,
  daysRepeat,
  repeatEveryWeek,
  repeatEveryMonth,
  repeatMonthDay,
  repeatEveryYear,
  endRepeat,
  estimationTime,
  laborCost,
  priority,
  category,
  people,
  teams,
  assets,
  part,
  procedure,

  image,
  attachments,
  organisation = "",
  createdByUser = null,
  qrcode,
  // code,
  work_request,
  set,
  area,
  startDate,
  startTime,
  primaryAssignee
) => {
  try {
    let params = {
      title,
      description,
      customer,
      location,
      orderType,
      dueDate,
      dueTime,
      repeatEvery,
      startDate,
      startTime,
      daysRepeat,
      repeatEveryWeek,
      repeatEveryMonth,
      repeatMonthDay,
      repeatEveryYear,
      endRepeat,
      estimationTime,
      laborCost,
      priority,
      category,
      people,
      teams,
      assets,
      part,
      procedure,
      image,
      status: "open",
      organisation,
      createdByUser,
      qrcode,
      attachments,
      // code,
      work_request,
      set,
      area,
      primaryAssignee
    };

    // if ((people && people.length > 0) || (teams && teams.length > 0)) {
    //   params.status = 'inProgress';
    // }

    if (image)
      params = {
        ...params,
        image: image,
      };
    // if (file)
    //   params = {
    //     ...params,
    //     file: file,
    //   };

    //console.log(params);

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "work-orders",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const createNewSchedule = async (
  title,
  description,
  customer,
  location,
  orderType,
  dueDate,
  dueTime,
  repeatEvery,
  daysRepeat,
  repeatEveryWeek,
  repeatEveryMonth,
  repeatMonthDay,
  repeatEveryYear,
  endRepeat,
  estimationTime,
  laborCost,
  priority,
  category,
  people,
  teams,
  assets,
  part,
  procedure,
  image,
  attachments,
  organisation = "",
  createdByUser = null,
  endDate = "",
  endType = "",
  qrcode,
  // code,
  work_request,
  set,
  area,
  startDate,
  startTime,
  primaryAssignee
) => {
  try {
    let params = {
      title,
      description,
      customer,
      location,
      orderType,
      dueDate,
      dueTime,
      repeatEvery,
      daysRepeat,
      repeatEveryWeek,
      repeatEveryMonth,
      repeatMonthDay,
      repeatEveryYear,
      endRepeat,
      estimationTime,
      laborCost,
      priority,
      category,
      people,
      teams,
      assets,
      part,
      startDate,
      startTime,
      procedure,
      status: "open",
      organisation,
      createdByUser,
      attachments,
      endDate,
      endType,
      qrcode,
      // code,
      work_request,
      set,
      area,
      primaryAssignee
    };

    if (image)
      params = {
        ...params,
        image: image,
      };
    //console.log('params', params);

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "schedule-work-order",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const createNewWorkRequest = async (
  title,
  description,
  priority,
  location,
  assets,
  people,
  teams,
  logo,
  attachments,
  organisation = "",
  submittedBy = null
) => {
  try {
    let params = {
      title,
      description,
      priority,
      location,
      assets,
      people,
      teams,
      logo,
      attachments,
      organisation,
      submittedBy,
    };

    //console.log('params', params);
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "work-requests",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

// PUT functions (update functions)
export const updateUser = async (
  id,
  role,
  username,
  email,
  teams,
  firstName,
  lastName,
  supervisor,
  locations,
  phone,
  profileImage,
  organisation,
  userProfile
) => {
  try {
    let params = {
      role,
      email,
      username,
      teams,
      firstName,
      lastName,
      supervisor,
      locations,
      phone,
      profileImage,
      organisation,
      userProfile,
    };

    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "users/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    //console.log('Error config.data', err.config.data);
    throw throwError({
      message: err.response?.data?.error?.message,
    });
  }
};

export const updateUserProfile = async (
  id,
  phone,
  address,
  emergencyName,
  emergencyPhone,
  educationLevel,
  fieldOfStudy,
  martialStatus,
  nationality,
  passportNo,
  identificationNo,
  dob,
  cob,
  gender,
  childrenNo,
  visaNo,
  visaExpireDate,
  jobTitle,
  department,
  hourRate,
  whpd,
  whpw,
  contractStart,
  contractEnd,
  registrationNo,
  badgeId,
  shifts,
  workPermitNo,
  days,
  contractCopy,
  idCopyFront,
  personalPicture,
  passportCopy,
  visaCopy,
  covidCopy
) => {
  //console.log('Personal', personalPicture);
  try {
    let params = {
      phone,
      address,
      emergencyName,
      emergencyPhone,
      educationLevel,
      fieldOfStudy,
      martialStatus,
      nationality,
      passportNo,
      identificationNo,
      dob,
      cob,
      gender,
      childrenNo,
      visaNo,
      visaExpireDate,
      job: jobTitle ? jobTitle : null,
      department,
      hourRate,
      whpd,
      whpw,
      contractStart,
      contractEnd,
      registrationNo,
      badgeId,
      shifts,
      workPermitNo,
      days,
      passportCopy,
      idCopyFront,
      personalPicture,
      visaCopy,
      covidCopy,
      contractCopy,
    };

    // if (personalPicture)
    //   params = {
    //     ...params,
    //     personalPicture,
    //   };

    // if (passportCopy)
    //   params = {
    //     ...params,
    //     passportCopy,
    //   };

    // if (idCopyFront)
    //   params = {
    //     ...params,
    //     idCopyFront,
    //   };

    // if (visaCopy)
    //   params = {
    //     ...params,
    //     visaCopy,
    //   };

    // if (covidCopy)
    //   params = {
    //     ...params,
    //     covidCopy,
    //   };

    const response = await uninterceptedAxiosInstance.put(
      backend_url + "user-profiles/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    return response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const updateTeam = async (
  id,
  name,
  description,
  users,
  superior,
  locations,
  organisation,
  customers,
  images
) => {
  try {
    let params = {
      name,
      description,
      users,
      superior,
      locations,
      organisation,
      customers,
      images,
    };
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "teams/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updateMainLocation = async (id, mainLocation, organisation) => {
  try {
    let params = {
      mainLocation,
      organisation,
    };

    const response = await uninterceptedAxiosInstance.put(
      backend_url + "locations/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updateGeofencingCoords = async (id, geofencingCoords) => {
  try {
    let params = {
      geofencingCoords,
    };

    const response = await uninterceptedAxiosInstance.put(
      backend_url + "locations/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const assignTeamsToUser = async (teams, id, organisation) => {
  try {
    let params = {
      teams,
      organisation,
    };
    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "users/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const updateUserRole = async (role, id, organisation) => {
  try {
    let params = {
      role,
      organisation,
    };
    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "users/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const toggleBlockedUser = async (blocked, id, organisation) => {
  try {
    let params = {
      blocked,
      organisation,
    };
    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "users/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const changePassword = async (password, id, organisation) => {
  try {
    let params = {
      password,
      organisation,
    };
    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "users/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const updateWorkOrder = async (
  id,
  title,
  description,
  customer,
  location,
  orderType,
  dueDate,
  dueTime,
  repeatEvery,
  daysRepeat,
  repeatEveryWeek,
  repeatEveryMonth,
  repeatMonthDay,
  repeatEveryYear,
  endRepeat,
  estimationTime,
  laborCost,
  priority,
  category,
  people,
  teams,
  assets,
  part,
  procedure,
  image,
  attachments,
  status = "",
  endDate = null,
  endType = "",
  qrcode,
  isSingle,
  organisation,
  set,
  area,
  startDate,
  startTime,
  closeOutNotes,
  primaryAssignee 
) => {
  try {
    let params = {
      title,
      description,
      customer,
      location,
      orderType,
      dueDate,
      dueTime,
      repeatEvery,
      daysRepeat,
      repeatEveryWeek,
      repeatEveryMonth,
      repeatMonthDay,
      repeatEveryYear,
      endRepeat,
      estimationTime,
      laborCost,
      priority,
      category,
      people,
      teams,
      assets,
      part,
      procedure,
      startDate,
      startTime,
      image,
      attachments,
      endDate,
      endType,
      qrcode,
      organisation,
      set,
      area,
      closeOutNotes,
      primaryAssignee
    };

    // if (
    //   status &&
    //   status === 'open' &&
    //   ((people && people.length) || (teams && teams.length))
    // ) {
    //   params.status = 'inProgress';
    // }

    if (orderType == "scheduled") {
      params.isSingle = !isSingle;
    }

    //console.log(params);

    const response = await uninterceptedAxiosInstance.put(
      backend_url + "work-orders/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updatePartWorkOrder = async (
  part,
  set,
  workOrder,
  organisation
) => {
  try {
    let params = {
      part,
      set,
      workOrder,
      organisation,
    };

    // if (
    //   status &&
    //   status === 'open' &&
    //   ((people && people.length) || (teams && teams.length))
    // ) {
    //   params.status = 'inProgress';
    // }
    //console.log(params);

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "work-order/parts",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updateAssigneeOrder = async (
  id,
 people = [],
 primaryAssignee='',
 peopleUpdate = true,

) => {
  try {
    let params = {
     
    };

    if(peopleUpdate){
      params.people = people;
    }else{
      params.primaryAssignee = primaryAssignee;
    }

    // if (
    //   status &&
    //   status === 'open' &&
    //   ((people && people.length) || (teams && teams.length))
    // ) {
    //   params.status = 'inProgress';
    // }
    //console.log(params);

    const response = await uninterceptedAxiosInstance.put(
      backend_url + "work-orders/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updateWorkOrderAssign = async (id, teams, people, orderType) => {
  try {
    let params = {
      id,
      teams,
      people,
      orderType,
    };

    //console.log(params);

    const response = await uninterceptedAxiosInstance.put(
      backend_url + "work-orders/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updateWorkOrderPrimaryAssign = async (id,primaryAssignee, teams, people, orderType,isPrimary) => {
  try {
    let params = {
      orderType
    };

    if(teams !== null){
      params.teams = teams;
    }
     if(people !== null){
      params.people = people;
    } 

    if(isPrimary){
      params.people = people;
      params.primaryAssignee = primaryAssignee;
    }

    //console.log(params);

    const response = await uninterceptedAxiosInstance.put(
      backend_url + "work-orders/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const addLaborTime = async (
  workOrder,
  organisation,
  assignee,
  hourlyRate,
  startTime,
  duration,
  category
) => {
  try {
    let params = {
      workOrder,
      organisation,
      assignee,
      hourlyRate,
      startTime,
      duration,
      category,
    };

    //console.log(params);

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "work-order-log/add-time",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};
export const editLaborTime = async (
  organisation,
  assignee,
  hourlyRate,
  startTime,
  duration,
  category,
  id,
  logId
) => {
  try {
    let params = {
      organisation,
      assignee,
      hourlyRate,
      startTime,
      duration,
      category,
    };

    //console.log(params);

    const response = await uninterceptedAxiosInstance.put(
      backend_url + `work-order-log/update-time/${id}?logId=${logId}`,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const addCostData = async (
  workOrder,
  organisation,
  createdByUser,
  assignee,
  cost,
  dateTime,
  description,
  category
) => {
  try {
    let params = {
      workOrder,
      organisation,
      createdByUser,
      assignee,
      cost,
      dateTime,
      description,
      category,
    };

    //console.log(params);

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "work-order-costs",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};
export const editCostData = async (
  workOrder,
  organisation,
  updatedByUser,
  assignee,
  cost,
  dateTime,
  description,
  category,
  id
) => {
  try {
    let params = {
      workOrder,
      organisation,
      updatedByUser,
      assignee,
      cost,
      dateTime,
      description,
      category,
    };

    //console.log(params);

    const response = await uninterceptedAxiosInstance.put(
      backend_url + `work-order-costs/${id}`,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const editWoPartData = async (
  part,
  location,
  unitCost,
  quantity,
  totalCost,
  workOrderId,
  type = "Part",
  id
) => {
  try {
    let params = {
      location,
      unitCost,
      quantity,
      totalCost,
      workOrderId,
    };

    if (type == "Part") {
      params.part = part;
    } else {
      params.set = part;
    }

    const response = await uninterceptedAxiosInstance.put(
      backend_url + `work-order/parts/${id}`,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updateProcedure = async (
  id,
  title,
  description,
  category,
  fields,
  headers,
  assets
) => {
  try {
    let params = {
      title,
      description,
      category,
      fields,
      headers,
      assets,
    };

    //console.log('params', params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "procedures/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updateLocation = async (
  id,
  name,
  address,
  isParent,
  parentLocation,
  country,
  city,
  customer = [],
  vendors,
  teams,
  users,
  mainLocation,
  geofencingCoords,
  customFields,
  procedure = null,
  propertyType,
  area,
  image
  // removedArea
) => {
  try {
    let params = {
      name,
      address,
      isParent,
      parentLocation,
      country,
      city,
      customers: customer,
      vendors,
      teams,
      users,
      mainLocation,
      geofencingCoords,
      customFields,
      procedure,
      propertyType,
      area,
      image,
      // removedArea,
    };

    if (customFields && customFields.length) {
      let newFieldsArray = customFields.map((one) => {
        if (one.new) {
          delete one.id;
        }
        return one;
      });
      params.customFields = newFieldsArray;
    }
    if (params.country) {
      params.countryName = Country.getCountryByCode(params.country).name;
    }
    if (params.city) {
      const citiesOfCountry = State.getStatesOfCountry(params.country);
      const the_city = params.city
        ? citiesOfCountry.filter((city) => {
            return city.isoCode == params.city;
          })[0].name
        : "";
      params.cityName = the_city;
    }

    if (area && area?.length) {
      let filteredArea = area.filter(
        (one) => one.attributes.name && one.attributes.name.trim()
      );
      params.area = filteredArea;
    }

    //console.log('params', params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "locations/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updateUserStatusToPending = async (id) => {
  try {
    let params = {
      confirmed: false,
      declined: false,
    };
    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "users/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};
export const updateUserStatusToActive = async (id) => {
  try {
    let params = {
      confirmed: true,
    };
    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "users/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const declineUser = async (id, declinedReason) => {
  try {
    let params = {
      confirmed: false,
      declined: true,
      declinedReason,
    };
    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "users/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const approveUser = async (id) => {
  try {
    let params = {
      confirmed: true,
    };
    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "users/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const updateWorkRequestStatus = async (ID, status, declinedReason) => {
  try {
    let params = {
      status,
      declinedReason,
    };
    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "work-requests/" + ID,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const updatePurchaseOrderStatus = async (
  ID,
  status,
  declinedReason,
  sendVendor
) => {
  try {
    let params = {
      status,
    };

    if (declinedReason) {
      params.declinedReason = declinedReason;
    }

    if (sendVendor) {
      params.sendToVendor = "yes";
    }
    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "status/" + ID,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        responseType: "arraybuffer",
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    return err?.response;
    //console.log('Error config', err.config);
  }
};

export const fulFillPurchaseOrder = async (
  orderItems,
  isFulfill = "yes",
  organisation,
  ID,
  status
) => {
  console.log("orderItems: ", orderItems);
  try {
    let params = {
      orderItems: orderItems?.orderItems,
      isFulfill,
      organisation,
      status,
    };
    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "status/" + ID,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    return err?.response?.status;
    //console.log('Error config', err.config);
  }
};

export const updateAsset = async (
  id,
  name,
  description,
  model,
  category,
  area,
  serialNumber,
  barcode,
  vendor,
  customer,
  placedDate,
  warrantyExpirationDate,
  additionalInformation,
  image,
  warrantyFile,
  qrcode,
  procedure = null,
  location = null,
  subLocations = null,
  purchasePrice,
  manufacturer,
  descriptionRate,
  estimatedUsefulLife,
  peoples,
  teams,
  organisation,
  isChild,
  parentAsset
) => {
  try {
    let params = {
      name,
      description,
      model,
      categories: category,
      area,
      serialNumber,
      barcode,
      vendor,
      customer,
      placedDate,
      warrantyExpirationDate,
      additionalInformation,
      image,
      warrantyFile,
      qrcode,
      procedure,
      location,
      subLocations,
      purchasePrice,
      manufacturer,
      descriptionRate,
      estimatedUsefulLife,
      peoples,
      teams,
      organisation,
      isChild,
      parentAsset,
    };

    //console.log('params', params);

    const response = await uninterceptedAxiosInstance.put(
      backend_url + "assets/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updateWorkRequest = async (
  id,
  title,
  description,
  priority,
  location,
  assets,
  people,
  teams,
  logo,
  attachments,
  organisation = "",
  submittedBy = null
) => {
  try {
    let params = {
      title,
      description,
      priority,
      location,
      assets,
      people,
      teams,
      logo,
      attachments,
      organisation,
      submittedBy,
    };

    const response = await uninterceptedAxiosInstance.put(
      backend_url + "work-requests/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updateInventory = async (
  id,
  name,
  description,
  category,
  // unitCost,
  measuringUnit,
  type,
  isNonStock,
  qrcode,
  barcode,
  serialNumber,
  vendors,
  assets,
  people,
  teams,
  logo,
  files,
  organisation = "",
  createdByUser = null,
  inventory
  // minimumQuantity,
  // maximumQuantity
) => {
  try {
    let params = {
      name,
      description,
      category,
      // unitCost,
      measuringUnit,
      type,
      isNonStock,
      qrcode,
      barcode,
      serialNumber,
      vendors,
      assets,
      people,
      teams,
      logo,
      files,
      organisation,
      createdByUser,
      inventory,
      // minimumQuantity,
      // maximumQuantity,
    };

    const response = await uninterceptedAxiosInstance.put(
      backend_url + "parts/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updateCustomer = async (
  id,
  name,
  phone,
  website,
  email,
  serviceType,
  description,
  billingInformation,
  logo,
  locations = null,
  Industry = null,
  contactInformation,
  organisation
) => {
  try {
    let params = {
      name,
      phone,
      website,
      email,
      serviceType,
      description,
      billingInformation,
      locations,
      Industry,
      logo,
      contactInformation,
      organisation,
    };

    if (params?.billingInformation?.country) {
      params.billingInformation.countryName = Country.getCountryByCode(
        params?.billingInformation?.country
      )?.name;
    }
    if (params?.billingInformation?.city) {
      const citiesOfCountry = State.getStatesOfCountry(
        params.billingInformation.country
      );
      const the_city = params.billingInformation.city
        ? citiesOfCountry.filter((city) => {
            return city.isoCode == params.billingInformation.city;
          })[0].name
        : "";
      params.billingInformation.cityName = the_city;
    }

    //console.log('params', params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "customers/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const updateVendor = async (
  id,
  name,
  address,
  phone,
  website,
  email,
  serviceType,
  description,
  // rate,
  billingInformation,
  logo,
  location,
  Industry,
  contactInformation,
  startDate,
  endDate,
  creditDays,
  organisation,
  preferences
) => {
  try {
    let params = {
      name,
      address,
      phone,
      website,
      email,
      logo,
      serviceType,
      description,
      location,
      Industry,
      contactInformation,
      // rate,
      billingInformation,
      startDate,
      endDate,
      creditDays,
      organisation,
      preferences,
    };

    if (params?.billingInformation?.country) {
      params.billingInformation.countryName = Country.getCountryByCode(
        params?.billingInformation?.country
      )?.name;
    }
    if (params?.billingInformation?.city) {
      const citiesOfCountry = State.getStatesOfCountry(
        params.billingInformation.country
      );
      const the_city = params.billingInformation.city
        ? citiesOfCountry.filter((city) => {
            return city.isoCode == params.billingInformation.city;
          })[0].name
        : "";
      params.billingInformation.cityName = the_city;
    }

    //console.log('params', params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "vendors/" + id,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

// GET functions
export const getAllCustomers = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 99999,
  location = [],
  country = [],
  city = [],
  rate = {},
  sort = [],
  role = "",
  allIds = []
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    // location: {
    //   id: {
    //     $in: location,
    //   },
    // },
    billingInformation: {
      country: {
        $in: country[0],
      },
      city: {
        $in: city[0],
      },
    },
    // billingInformation: {
    //   city: {
    //     $in: city[0],
    //   },
    // },
  };

  // if(country.length > 0 && city.length == 0){
  //   assetFilters.billingInformation.country = {
  //     $in: country[0],
  //   };
  // }  else if(city.length > 0 && country.length > 0){
  //   console.log("inside sdsd")
  //   assetFilters.billingInformation = {
  //     city: { $in: city[0] },
  //     country: { $in: country[0] },
  //   };
  // }

  if (rate.from && !rate.to) {
    assetFilters.billingInformation.rate = {
      $gte: rate.from,
    };
  } else if (!rate.from && rate.to) {
    assetFilters.billingInformation.rate = {
      $lte: rate.to,
    };
  } else if (rate.to && rate.from) {
    assetFilters.billingInformation.rate = {
      $gte: rate.from,
      $lte: rate.to,
    };
  }

  if (location?.length == 0 && role !== "super_admin") {
    assetFilters.locations = {
      id: {
        $in: allIds,
      },
    };
  } else {
    assetFilters.locations = {
      id: {
        $in: location,
      },
    };
  }

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    _q: searchName,
    populate: [
      "user",
      "billingInformation",
      "contactInformation",
      "logo",
      "parts",
      "assets",
      "createdByUser",
      "updatedByUser",
      "locations",
      "locations.parentLocation",
      "location",
      "createdByUser.profileImage",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  // const query = qs.stringify(
  //   {
  //     sort: ['countNumId:desc'],
  //     filters: {
  //       organisation: {
  //         id: {
  //           $eq: organisationID,
  //         },
  //       },
  //     },
  //     populate: [
  //       'user',
  //       'billingInformation',
  //       'contactInformation',
  //       'logo',
  //       'parts',
  //       'assets',
  //       'createdByUser',
  //       'locations',
  //       'location',
  //       'createdByUser.profileImage',
  //     ],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `customers?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getCustomerByID = async (ID) => {
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: {
        id: {
          $eq: ID,
        },
      },
      populate: [
        "user",
        "billingInformation",
        "contactInformation",
        "logo",
        "parts",
        "assets",
        "createdByUser",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `customers?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllVendros = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 99999,
  location = [],
  startDate = [],
  endDate = [],
  sort = [],
  role,
  allIds
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    // location: {
    //   id: {
    //     $in: location,
    //   },
    // },
  };

  if (startDate.length === 1) {
    assetFilters.startDate = {
      $gte: startDate[0],
    };
  } else if (startDate.length === 2) {
    assetFilters.startDate = {
      $gte: startDate[0],
      $lte: startDate[1],
    };
  }

  if (endDate.length === 1) {
    assetFilters.endDate = {
      $gte: endDate[0],
    };
  } else if (endDate.length === 2) {
    assetFilters.endDate = {
      $gte: endDate[0],
      $lte: endDate[1],
    };
  }

  if (location?.length == 0 && role !== "super_admin") {
    assetFilters.location = {
      id: {
        $in: allIds,
      },
    };
  } else {
    assetFilters.location = {
      id: {
        $in: location,
      },
    };
  }

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    _q: searchName,
    populate: [
      "customFields",
      "billingInformation",
      "billingInformation.paymentDetails",
      "contactInformation",
      "logo",
      "parts",
      "assets",
      "createdByUser",
      "updatedByUser",
      "location",
      "createdByUser.profileImage",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  // const query = qs.stringify(
  //   {
  //     sort: ['countNumId:desc'],
  //     filters: {
  //       organisation: {
  //         id: {
  //           $eq: organisationID,
  //         },
  //       },
  //     },
  //     populate: [
  //       'customFields',
  //       'billingInformation',
  //       'billingInformation.paymentDetails',
  //       'contactInformation',
  //       'logo',
  //       'parts',
  //       'assets',
  //       'createdByUser',
  //       'location',
  //       'createdByUser.profileImage',
  //     ],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `vendors?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;

    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };

    return dataObj;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllWorkRequest = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 99999,
  role,
  currentUserId,
  userId = "",
  people = [],
  status = [],
  priority = [],
  location = [],
  assets = [],
  submissionDate = [],
  sort = []
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    assets: {
      id: {
        $in: assets,
      },
    },
    // submittedBy: {
    //   id: {
    //     $in: people,
    //   },
    // },
  };

  // if (location?.length == 0 && role !== "super_admin" && role !== "admin") {
  //   assetFilters.location = {
  //     id: {
  //       $in: currentUserId,
  //     },
  //   };
  // } else {
  //   assetFilters.location = {
  //     id: {
  //       $in: location,
  //     },
  //   };
  // }

  if (role !== "super_admin") {
    assetFilters = {
      organisation: {
        id: {
          $eq: organisationID,
        },
      },
      assets: {
        id: {
          $in: assets,
        },
      },
      $or:
        people.length > 0
          ? [
              // Require both conditions for 'AND' logic
              {
                $and: [
                  {
                    location: {
                      id: {
                        $in: location.length > 0 ? location : currentUserId,
                      },
                    },
                  },
                  {
                    submittedBy: {
                      id: {
                        $in: people,
                      },
                    },
                  },
                ],
              },
            ]
          : location?.length > 0
          ? [
              // Require both conditions for 'AND' logic
              {
                $and: [
                  {
                    location: {
                      id: {
                        $in: location.length > 0 ? location : currentUserId,
                      },
                    },
                  },
                ],
              },
            ]
          : [
              {
                location: {
                  id: {
                    $in: location.length > 0 ? location : currentUserId,
                  },
                },
              },
              {
                submittedBy: {
                  id: {
                    $in: [userId],
                  },
                },
              },
            ],
    };
  } else {
    // If role is super_admin or admin, use provided filters for submittedBy and location
    if (people.length > 0) {
      assetFilters.submittedBy = {
        id: {
          $in: people,
        },
      };
    }
    if (location.length > 0) {
      assetFilters.location = {
        id: {
          $in: location,
        },
      };
    }
  }

  // if (people?.length == 0 && role !== "super_admin" && role !== "admin") {
  //   assetFilters.location = {
  //     id: {
  //       $in: currentUserId,
  //     },
  //   };
  // } else if (people?.length > 0 && role !== "super_admin" && role !== "admin") {
  //   assetFilters.location = {
  //     id: {
  //       $in: currentUserId,
  //     },
  //   };
  //   assetFilters.submittedBy = {
  //     id: {
  //       $in: people,
  //     },
  //   };
  // } else {
  //   assetFilters.submittedBy = {
  //     id: {
  //       $in: people,
  //     },
  //   };
  // }
  // if (location?.length == 0 && role !== 'super_admin') {
  //   assetFilters.location = {
  //     id: {
  //       $in: currentUserId,
  //     },
  //   };
  // } else {
  //   assetFilters.location = {
  //     id: {
  //       $in: location,
  //     },
  //   };
  // }

  // if (role == 'requester' || role == 'user' || role == 'view_only') {
  //   assetFilters.submittedBy = {
  //     id: {
  //       $in: [currentUserId],
  //     },
  //   };
  // }

  if (submissionDate.length === 1) {
    assetFilters.createdAt = {
      $gte: submissionDate[0],
    };
  } else if (submissionDate.length === 2) {
    assetFilters.createdAt = {
      $gte: submissionDate[0],
      $lte: submissionDate[1],
    };
  }
  if (status) {
    assetFilters.status = {
      $eq: status[0],
    };
  }

  if (priority) {
    assetFilters.priority = {
      $eq: priority[0],
    };
  }

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    _q: searchName,
    populate: [
      "teams",
      "submittedBy",
      "submittedBy.profileImage",
      "people",
      "people.role",
      "people.userProfile",
      "people.profileImage",
      "location",
      "assets",
      "reason",
      "logs",
      "attachments",
      "createdByUser",
      "updatedByUser",
      "workOrder",
      "logo",
      "log",
      "log.user",
      "log.user.userProfile",
      "log.user.profileImage",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  // const query = qs.stringify(
  //   {
  //     sort: ['countNumId:desc'],
  //     filters: {
  //       organisation: {
  //         id: {
  //           $eq: organisationID,
  //         },
  //       },
  //     },
  //     populate: [
  //       'customFields',
  //       'billingInformation',
  //       'billingInformation.paymentDetails',
  //       'contactInformation',
  //       'logo',
  //       'parts',
  //       'assets',
  //       'createdByUser',
  //       'location',
  //       'createdByUser.profileImage',
  //     ],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-requests?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;

    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };

    return dataObj;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllLogs = async (type = "work_request", ID = "") => {
  let assetFilters = {
    type: {
      $eq: type,
    },
  };

  if (type == "work_request") {
    assetFilters.workRequest = {
      id: {
        $eq: ID,
      },
    };
  } else if (type == "purchase_order") {
    assetFilters.purchaseOrder = {
      id: {
        $eq: ID,
      },
    };
  } else if (type == "location") {
    assetFilters.location = {
      id: {
        $eq: ID,
      },
    };
  } else if (type == "user") {
    assetFilters.logOfUser = {
      id: {
        $eq: ID,
      },
    };
  } else if (type == "asset") {
    assetFilters.asset = {
      id: {
        $eq: ID,
      },
    };
  } else if (type == "team") {
    assetFilters.team = {
      id: {
        $eq: ID,
      },
    };
  } else if (type == "procedure") {
    assetFilters.procedure = {
      id: {
        $eq: ID,
      },
    };
  } else if (type == "customer") {
    assetFilters.customer = {
      id: {
        $eq: ID,
      },
    };
  } else if (type == "vendor") {
    assetFilters.vendor = {
      id: {
        $eq: ID,
      },
    };
  } else if (type == "work_order") {
    assetFilters.workOrder = {
      id: {
        $eq: ID,
      },
    };
  } else if (type == "set") {
    assetFilters.set = {
      id: {
        $eq: ID,
      },
    };
  } else {
    assetFilters.part = {
      id: {
        $eq: ID,
      },
    };
  }

  const queryParams = {
    sort: ["id:desc"],
    filters: assetFilters,
    populate: [
      "",
      "user",
      "logOfUser",
      "customer",
      "user.userProfile",
      "user.profileImage",
      "part",
      "workRequest",
      "workOrder",
      "procedure",
      "team",
      "vendor",
      "set",
      "location",

      "purchaseOrder",
    ],
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  // const query = qs.stringify(
  //   {
  //     sort: ['countNumId:desc'],
  //     filters: {
  //       organisation: {
  //         id: {
  //           $eq: organisationID,
  //         },
  //       },
  //     },
  //     populate: [
  //       'customFields',
  //       'billingInformation',
  //       'billingInformation.paymentDetails',
  //       'contactInformation',
  //       'logo',
  //       'parts',
  //       'assets',
  //       'createdByUser',
  //       'location',
  //       'createdByUser.profileImage',
  //     ],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `logs?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;

    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getLaborTabData = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-order-log/getUsersLogs/${ID}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data;

    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getCostTabData = async (organisationID, ID) => {
  let costFilter = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    workOrder: {
      id: {
        $eq: ID,
      },
    },
  };

  const queryParams = {
    sort: ["id:desc"],
    filters: costFilter,
    populate: "*",
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-order-costs/?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = {
      data: response.data.data,
      totalCost: response.data.totalCost,
    };

    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const deleteLaborTabData = async (ID, logId) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + `work-order-log/delete-time/${ID}?logId=${logId}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;

    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const deleteCostTabData = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + `work-order-costs/${ID}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;

    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllAssets = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 9999,
  location = [],
  category = [],
  status = "",
  serialNumber = "",
  placedDate = [],
  sort = [],
  role,
  allIds,
  fromAsset = false
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    $and: [],
    // isChild: {
    //   $eq: false,
    // },
    // categories: {
    //   id: {
    //     $in: category,
    //   },
    // },
  };

  if (searchName == "all_asset") {
    assetFilters.status = {
      $ne: "dispose",
    };
  }

  const statusConditions = [];
  if (status) {
    statusConditions.push(
      { status: { $eq: status } },
      { childAssets: { status: { $eq: status } } },
      { grandChildAssets: { status: { $eq: status } } }
    );
  }

  if (searchName == "all_asset") {
  } else if (searchName) {
    assetFilters.$or = [
      {
        name: {
          $containsi: searchName,
        },
      },
      // {
      //   childAssets: {
      //     name: {
      //       $containsi: searchName,
      //     },
      //   },
      // },
      // {
      //   childAssets: {
      //     countId: {
      //       $containsi: searchName,
      //     },
      //   },
      // },
      // {
      //   grandChildAssets: {
      //     name: {
      //       $containsi: searchName,
      //     },
      //   },
      // },
      // {
      //   grandChildAssets: {
      //     countId: {
      //       $containsi: searchName,
      //     },
      //   },
      // },
      {
        countId: {
          $containsi: searchName,
        },
      },
    ];
    // assetFilters.name = {
    //   $containsi: searchName,
    // };

    // assetFilters.countId = {
    //   $containsi: searchName,
    // };
  } else {
    assetFilters.isChild = {
      $eq: false,
    };
  }
  const categoryConditions = [];
  if (category?.length > 0) {
    categoryConditions.push(
      { categories: { id: { $in: category } } },
      { childAssets: { categories: { id: { $in: category } } } },
      { grandChildAssets: { categories: { id: { $in: category } } } }
    );
  }

  // if (placedDate.length === 1) {
  //   assetFilters.placedDate = {
  //     $gte: placedDate[0],
  //   };
  // } else if (placedDate.length === 2) {
  //   assetFilters.placedDate = {
  //     $gte: placedDate[0],
  //     $lte: placedDate[1],
  //   };
  // }

  const placedDateConditions = [];
  if (placedDate.length === 1) {
    placedDateConditions.push(
      { placedDate: { $gte: placedDate[0] } },
      { childAssets: { placedDate: { $gte: placedDate[0] } } },
      { grandChildAssets: { placedDate: { $gte: placedDate[0] } } }
    );
  } else if (placedDate.length === 2) {
    placedDateConditions.push(
      { placedDate: { $gte: placedDate[0], $lte: placedDate[1] } },
      {
        childAssets: {
          placedDate: { $gte: placedDate[0], $lte: placedDate[1] },
        },
      },
      {
        grandChildAssets: {
          placedDate: { $gte: placedDate[0], $lte: placedDate[1] },
        },
      }
    );
  }

  const serialNumberConditions = [];
  if (serialNumber) {
    serialNumberConditions.push(
      { serialNumber: { $eq: serialNumber } },
      { childAssets: { serialNumber: { $eq: serialNumber } } },
      { grandChildAssets: { serialNumber: { $eq: serialNumber } } }
    );
  }
  // if (serialNumber) {
  //   assetFilters.serialNumber = {
  //     $eq: serialNumber,
  //   };
  // }

  // const locationConditions = [];
  // if (fromAsset) {
  //   locationConditions.push(
  //     { location: { id: { $in: allIds } } },
  //     { childAssets: { location: { id: { $in: allIds } } } }
  //   );
  // } else if (
  //   location?.length === 0 &&
  //   !(role === "super_admin" || role === "admin")
  // ) {
  //   locationConditions.push(
  //     { location: { id: { $in: allIds } } },
  //     { childAssets: { location: { id: { $in: allIds } } } },
  //     { grandChildAssets: { location: { id: { $in: allIds } } } }
  //   );
  // } else if (location?.length > 0) {
  //   locationConditions.push(
  //     { location: { id: { $in: location } } },
  //     { childAssets: { location: { id: { $in: location } } } },
  //     { grandChildAssets: { location: { id: { $in: location } } } }
  //   );
  // }

  // if (statusConditions.length > 0) {
  //   assetFilters.$and.push({ $or: statusConditions });
  // }
  if (serialNumberConditions.length > 0) {
    assetFilters.$and.push({ $or: serialNumberConditions });
  }
  // if (categoryConditions.length > 0) {
  //   assetFilters.$and.push({ $or: categoryConditions });
  // }
  // if (placedDateConditions.length > 0) {
  //   assetFilters.$and.push({ $or: placedDateConditions });
  // }
  // if (locationConditions.length > 0) {
  //   assetFilters.$and.push({ $or: locationConditions });
  // }

  // Finalize filters to include all conditions
  if (assetFilters.$and.length > 0) {
    assetFilters = { $and: [assetFilters] };
  } else {
    assetFilters = { $and: [assetFilters] };
  }

  // if (fromAsset) {
  //   assetFilters.$or = [
  //     {
  //       location: {
  //         id: {
  //           $in: allIds,
  //         },
  //       },
  //     },
  //     {
  //       childAssets: {
  //         location: {
  //           id: {
  //             $in: allIds,
  //           },
  //         },
  //       },
  //     },
  //   ];
  // } else if (
  //   location?.length == 0 &&
  //   !(role === "super_admin" || role === "admin")
  // ) {
  //   assetFilters.$or = [
  //     {
  //       location: {
  //         id: {
  //           $in: allIds,
  //         },
  //       },
  //     },
  //     {
  //       childAssets: {
  //         location: {
  //           id: {
  //             $in: allIds,
  //           },
  //         },
  //       },
  //     },
  //     {
  //       grandChildAssets: {
  //         location: {
  //           id: {
  //             $in: allIds,
  //           },
  //         },
  //       },
  //     },
  //   ];

  // } else {
  //   assetFilters.$or = [
  //     {
  //       location: {
  //         id: {
  //           $in: location,
  //         },
  //       },
  //     },
  //     {
  //       childAssets: {
  //         location: {
  //           id: {
  //             $in: location,
  //           },
  //         },
  //       },
  //     },
  //     {
  //       grandChildAssets: {
  //         location: {
  //           id: {
  //             $in: location,
  //           },
  //         },
  //       },
  //     },
  //   ];
  // }

  let startPlacedDate;
  let endPlacedDate;
  if (placedDate?.length === 1) {
    startPlacedDate = placedDate[0];
  } else if (placedDate?.length === 2) {
    startPlacedDate = placedDate[0];
    endPlacedDate = placedDate[1];
  }
  // if (fromAsset) {
  //   assetFilters.$or = [
  //     {
  //       location: {
  //         id: {
  //           $in: allIds,
  //         },
  //       },
  //     },
  //     {
  //       childAssets: {
  //         location: {
  //           id: {
  //             $in: allIds,
  //           },
  //         },
  //       },
  //     },
  //   ];
  // } else if (
  //   location?.length == 0 &&
  //   !(role === "super_admin" || role === "admin")
  // ) {
  //   assetFilters.$or = [
  //     {
  //       location: {
  //         id: {
  //           $in: allIds,
  //         },
  //       },
  //     },
  //     {
  //       childAssets: {
  //         location: {
  //           id: {
  //             $in: allIds,
  //           },
  //         },
  //       },
  //     },
  //     {
  //       grandChildAssets: {
  //         location: {
  //           id: {
  //             $in: allIds,
  //           },
  //         },
  //       },
  //     },
  //   ];

  // } else {
  //   assetFilters.$or = [
  //     {
  //       location: {
  //         id: {
  //           $in: location,
  //         },
  //       },
  //     },
  //     {
  //       childAssets: {
  //         location: {
  //           id: {
  //             $in: location,
  //           },
  //         },
  //       },
  //     },
  //     {
  //       grandChildAssets: {
  //         location: {
  //           id: {
  //             $in: location,
  //           },
  //         },
  //       },
  //     },
  //   ];
  // }

  // const queryParams = {
  //   sort: sort.length > 0 ? sort : ["countNumId:desc"],
  //   filters: assetFilters,

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    status,
    categories: category,
    locations: location,
    startPlacedDate,
    endPlacedDate,
    // _q: searchName,
    populate: [
      "image",
      "storedAssets.childAsset.image",
      "childAssets.image",
      "grandChildAssets.image",
      "childAssets.peoples",
      "grandChildAssets.peoples",
      // 'childAssets.peoples.role',
      // 'grandChildAssets.peoples.role',
      "childAssets.peoples.dispose",
      "grandChildAssets.peoples.dispose",
      "childAssets.peoples.profileImage",
      "childAssets.teams",
      "grandChildAssets.peoples.profileImage",
      "childAssets.warrantyFile",
      "grandChildAssets.teams",
      "storedAssets.grandChildAsset.image",
      "peoples",
      "storedAssets.childAsset.peoples",
      "storedAssets.grandChildAsset.peoples",
      // 'peoples.role',
      // 'storedAssets.childAsset.peoples.role',
      // 'storedAssets.grandChildAsset.peoples.role',
      "dispose",
      "storedAssets.childAsset.dispose",
      "storedAssets.grandChildAsset.dispose",
      "peoples.profileImage",
      "storedAssets.childAsset.peoples.profileImage",
      "storedAssets.grandChildAsset.peoples.profileImage",
      "teams",
      "storedAssets.childAsset.teams",
      "storedAssets.grandChildAsset.teams",
      "warrantyFile",
      "storedAssets.childAsset.warrantyFile",
      "storedAssets.grandChildAsset.warrantyFile",
      "grandChildAssets.warrantyFile",
      "createdByUser",
      "storedAssets.childAsset.createdByUser",
      "childAssets.createdByUser",
      "storedAssets.childAsset.parentAsset",
      "childAssets.parentAsset",
      "storedAssets.grandChildAsset.createdByUser",
      "grandChildAssets.createdByUser",
      // 'storedAssets.grandChildAsset.parentAsset',

      "grandChildAssets.parentAsset",
      "updatedByUser",
      "storedAssets.childAsset.updatedByUser",
      "childAssets.updatedByUser",
      "storedAssets.grandChildAsset.updatedByUser",
      "grandChildAssets.updatedByUser",
      "vendor.logo",
      "storedAssets.childAsset.vendor.logo",
      "childAssets.vendor.logo",
      "storedAssets.grandChildAsset.vendor.logo",
      "grandChildAssets.vendor.logo",
      "customer.logo",
      "storedAssets.childAsset.customer.logo",
      "childAssets.customer.logo",
      "storedAssets.grandChildAsset.customer.logo",
      "grandChildAssets.customer.logo",
      "vendor",
      "storedAssets.childAsset.vendor",
      "childAssets.vendor",
      "storedAssets.grandChildAsset.vendor",
      "grandChildAssets.vendor",
      "parts",
      "storedAssets.childAsset.parts",
      "storedAssets.grandChildAsset.parts",
      "customer",
      "storedAssets.childAsset.customer",
      "childAssets.customer",
      "storedAssets.grandChildAsset.customer",
      "grandChildAssets.customer",
      "procedure",
      "storedAssets.childAsset.procedure",
      "childAssets.procedure",
      "storedAssets.grandChildAsset.procedure",
      "grandChildAssets.procedure",
      // 'procedure.fields',
      // 'storedAssets.childAsset.procedure.fields',
      // 'childAssets.procedure.fields',
      // 'storedAssets.grandChildAsset.procedure.fields',
      // 'grandChildAssets.procedure.fields',
      // 'procedure.fields.options',
      // 'storedAssets.childAsset.procedure.fields.options',
      // 'storedAssets.grandChildAsset.procedure.fields.options',
      // 'procedure.fields.attachments',
      "categories",
      "storedAssets.childAsset.categories",
      "childAssets.categories",
      "storedAssets.grandChildAsset.categories",
      // 'createdByUser.profileImage',
      // 'storedAssets.childAsset.createdByUser.profileImage',
      // 'storedAssets.grandChildAsset.createdByUser.profileImage',
      // 'childAssets.createdByUser.profileImage',
      "grandChildAssets.categories",
      "grandChildAssets.createdByUser.profileImage",
      // 'parts',
      "location",
      "storedAssets.childAsset.location",
      "childAssets.location",
      "storedAssets.childAsset.grandChildAssets",
      "storedAssets.childAsset.grandChildAssets.location",
      "childAssets.grandChildAssets",
      "storedAssets.grandChildAsset.location",
      "storedAssets.grandChildAsset.childAssets",
      // "subLocations",
      // "storedAssets.childAsset.subLocations",
      // "storedAssets.grandChildAsset.subLocations",
      "area",
      "storedAssets.childAsset.area",
      "storedAssets.grandChildAsset.area",
      "grandChildAssets.location",
      "grandChildAssets.childAssets",
      "childAssets.parts",
      "grandChildAssets.parts",
      "childAssets.area",
      "grandChildAssets.area",
      "storedAssets",
      "childAssets",
      "parentAsset",
      "grandChildAssets",
      "grandChildAssets.childAssets",
      "storedAssets.childAsset",

      "storedAssets.grandChildAsset",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }
  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `assets?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;

    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllAssetsOption = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 9999,
  location = [],
  category = [],
  status = "",
  serialNumber = "",
  placedDate = [],
  sort = [],
  role,
  allIds,
  fromAsset = false
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    $and: [],
  };

  // if (searchName == "all_asset") {
  //   assetFilters.status = {
  //     $ne: "dispose",
  //   };
  // }

  const statusConditions = [];

  if (status) {
    statusConditions.push(
      { status: { $eq: status } },
      { childAssets: { status: { $eq: status } } },
      { grandChildAssets: { status: { $eq: status } } }
    );
  }

  if (searchName == "all_asset") {
    assetFilters.isChild = {
      $eq: false,
    };
  } else if (searchName) {
    assetFilters.$or = [
      {
        name: {
          $containsi: searchName,
        },
      },
      // {
      //   childAssets: {
      //     name: {
      //       $containsi: searchName,
      //     },
      //   },
      // },
      // {
      //   childAssets: {
      //     countId: {
      //       $containsi: searchName,
      //     },
      //   },
      // },
      // {
      //   grandChildAssets: {
      //     name: {
      //       $containsi: searchName,
      //     },
      //   },
      // },
      // {
      //   grandChildAssets: {
      //     countId: {
      //       $containsi: searchName,
      //     },
      //   },
      // },
      {
        countId: {
          $containsi: searchName,
        },
      },
    ];
    // assetFilters.name = {
    //   $containsi: searchName,
    // };

    // assetFilters.countId = {
    //   $containsi: searchName,
    // };
  } else {
    assetFilters.isChild = {
      $eq: false,
    };
  }

  // if (serialNumber) {
  //   assetFilters.serialNumber = {
  //     $eq: serialNumber,
  //   };
  // }

  // const locationConditions = [];
  // if (fromAsset) {
  //   locationConditions.push(
  //     { location: { id: { $in: allIds } } },
  //     { childAssets: { location: { id: { $in: allIds } } } }
  //   );
  // } else if (
  //   location?.length === 0 &&
  //   !(role === "super_admin" || role === "admin")
  // ) {
  //   locationConditions.push(
  //     { location: { id: { $in: allIds } } },
  //     { childAssets: { location: { id: { $in: allIds } } } },
  //     { grandChildAssets: { location: { id: { $in: allIds } } } }
  //   );
  // } else if (location?.length > 0) {
  //   locationConditions.push(
  //     { location: { id: { $in: location } } },
  //     { childAssets: { location: { id: { $in: location } } } },
  //     { grandChildAssets: { location: { id: { $in: location } } } }
  //   );
  // }

  if (statusConditions.length > 0) {
    assetFilters.$and.push({ $or: statusConditions });
  }

  // if (locationConditions.length > 0) {
  //   assetFilters.$and.push({ $or: locationConditions });
  // }

  // Finalize filters to include all conditions
  if (assetFilters.$and.length > 0) {
    assetFilters = { $and: [assetFilters] };
  } else {
    assetFilters = { $and: [assetFilters] };
  }

  // if (fromAsset) {
  //   assetFilters.$or = [
  //     {
  //       location: {
  //         id: {
  //           $in: allIds,
  //         },
  //       },
  //     },
  //     {
  //       childAssets: {
  //         location: {
  //           id: {
  //             $in: allIds,
  //           },
  //         },
  //       },
  //     },
  //   ];
  // } else if (
  //   location?.length == 0 &&
  //   !(role === "super_admin" || role === "admin")
  // ) {
  //   assetFilters.$or = [
  //     {
  //       location: {
  //         id: {
  //           $in: allIds,
  //         },
  //       },
  //     },
  //     {
  //       childAssets: {
  //         location: {
  //           id: {
  //             $in: allIds,
  //           },
  //         },
  //       },
  //     },
  //     {
  //       grandChildAssets: {
  //         location: {
  //           id: {
  //             $in: allIds,
  //           },
  //         },
  //       },
  //     },
  //   ];

  // } else {
  //   assetFilters.$or = [
  //     {
  //       location: {
  //         id: {
  //           $in: location,
  //         },
  //       },
  //     },
  //     {
  //       childAssets: {
  //         location: {
  //           id: {
  //             $in: location,
  //           },
  //         },
  //       },
  //     },
  //     {
  //       grandChildAssets: {
  //         location: {
  //           id: {
  //             $in: location,
  //           },
  //         },
  //       },
  //     },
  //   ];
  // }

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    locations: location,
    status,
    // _q: searchName,
    populate: [
      // 'peoples.role',
      // 'storedAssets.childAsset.peoples.role',
      // 'storedAssets.grandChildAsset.peoples.role',

      "childAssets.parentAsset",
      "storedAssets.grandChildAsset.createdByUser",
      // 'storedAssets.grandChildAsset.parentAsset',

      "grandChildAssets.parentAsset",

      // 'procedure.fields',
      // 'storedAssets.childAsset.procedure.fields',
      // 'childAssets.procedure.fields',
      // 'storedAssets.grandChildAsset.procedure.fields',
      // 'grandChildAssets.procedure.fields',
      // 'procedure.fields.options',
      // 'storedAssets.childAsset.procedure.fields.options',
      // 'storedAssets.grandChildAsset.procedure.fields.options',
      // 'procedure.fields.attachments',

      // 'createdByUser.profileImage',
      // 'storedAssets.childAsset.createdByUser.profileImage',
      // 'storedAssets.grandChildAsset.createdByUser.profileImage',
      // 'childAssets.createdByUser.profileImage',

      // 'parts',
      "location",
      "storedAssets.childAsset.location",
      "childAssets.location",
      "storedAssets.childAsset.grandChildAssets",
      "storedAssets.childAsset.grandChildAssets.location",
      "childAssets.grandChildAssets",
      "storedAssets.grandChildAsset.location",
      "storedAssets.grandChildAsset.childAssets",
      // "subLocations",
      // "storedAssets.childAsset.subLocations",
      // "storedAssets.grandChildAsset.subLocations",
      "grandChildAssets.location",
      "grandChildAssets.childAssets",
      "storedAssets",
      "childAssets",
      "parentAsset",
      "grandChildAssets",
      "grandChildAssets.childAssets",
      "storedAssets.childAsset",

      "storedAssets.grandChildAsset",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }
  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `assets?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;

    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllChildAssets = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 9999,
  location = [],
  category = [],
  status = "",
  serialNumber = "",
  placedDate = [],
  sort = []
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    location: {
      id: {
        $in: location,
      },
    },
    categories: {
      id: {
        $in: category,
      },
    },
  };

  if (status) {
    assetFilters.status = {
      $eq: status,
    };
  }
  if (serialNumber) {
    assetFilters.serialNumber = {
      $eq: serialNumber,
    };
  }

  if (placedDate.length === 1) {
    assetFilters.placedDate = {
      $gte: placedDate[0],
    };
  } else if (placedDate.length === 2) {
    assetFilters.placedDate = {
      $gte: placedDate[0],
      $lte: placedDate[1],
    };
  }
  if (serialNumber) {
    assetFilters.serialNumber = {
      $eq: serialNumber,
    };
  }

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    _q: searchName,
    populate: [
      "image",
      "peoples",
      "peoples.role",
      "dispose",
      "peoples.profileImage",
      "teams",
      "warrantyFile",
      "createdByUser",
      "updatedByUser",
      "vendor.logo",
      "customer.logo",
      "vendor",
      "parts",
      "customer",
      "procedure",
      "procedure.fields",
      "procedure.fields.options",
      "procedure.fields.attachments",
      "categories",
      "createdByUser.profileImage",
      "parts",
      "location",
      "subLocations",
      "area",
      "storedAssets",
      "childAssets",
      "parentAsset",
      "grandChildAssets",
      "storedAssets.childAsset",
      "storedAssets.grandChildAsset",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }
  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `assets?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;

    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllTimesheet = async (organisationID = "", data) => {
  const query = qs.stringify(
    {
      sort: ["id:desc"],
      startDate: data?.startDate || null,
      endDate: data?.endDate || null,
      team: data?.team || false,
      people: data?.people || false,
      isWeek: data?.isWeek || false,
      isMonth: data?.isMonth || false,
      organisation: organisationID,
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `timesheets?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    console.warn(response, "hidad");

    let the_response = response.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }

    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
    //console.log('Error config', err.config);
  }
};

export const getAllCategories = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 9999,
  sort = []
) => {
  let categoryFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
  };

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: categoryFilters,
    _q: searchName,
    populate: [
      "createdByUser",
      "createdByUser.profileImage",
      "assets",
      "parts",
      "organisation",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `categories?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;

    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      return err.response.data;
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllWOParts = async (woId = "") => {
  // let categoryFilters = {
  //   organisation: {
  //     id: {
  //       $eq: organisationID,
  //     },
  //   },
  // };

  const queryParams = {
    workOrderId: woId,
    // sort: sort.length > 0 ? sort : ["countNumId:desc"],
    // filters: categoryFilters,
    // _q: searchName,
    populate: [
      // "createdByUser",
      // "createdByUser.profileImage",
      // "assets",
      // "parts",
      // "organisation",
    ],
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-order/parts?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data;

    // let dataObj = {
    //   response: the_response,
    //   status: response.status,
    //   pagination: response.data.meta.pagination,
    // };
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      return err.response.data;
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};
export const getAllSets = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 9999,
  sort = [],
  locationId = "",
  areaId = "",
  role,
  allIds
) => {
  let setFilter = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
  };

  if (locationId && areaId) {
    setFilter.$or = [
      {
        parts: {
          part: {
            inventory: {
              $and: [
                {
                  location: {
                    id: {
                      $eq: locationId,
                    },
                  },
                },
                {
                  area: {
                    id: {
                      $eq: areaId,
                    },
                  },
                },
              ],
            },
          },
        },
      },
      {
        sets: {
          set: {
            parts: {
              part: {
                inventory: {
                  $and: [
                    {
                      location: {
                        id: {
                          $eq: locationId,
                        },
                      },
                    },
                    {
                      area: {
                        id: {
                          $eq: areaId,
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
      },
    ];
  }

  if (!(role === "super_admin")) {
    setFilter.location = {
      id: {
        $in: allIds,
      },
    };
  }

  // if (role !== "super_admin" && allIds?.length > 0) {
  //   setFilter.$or = [
  //     {
  //       parts: {
  //         part: {
  //           inventory: {
  //             $or: [
  //               {
  //                 location: {
  //                   id: {
  //                     $in: allIds,
  //                   },
  //                 },
  //               },
  //             ],
  //           },
  //         },
  //       },
  //     },
  //     {
  //       sets: {
  //         set: {
  //           parts: {
  //             part: {
  //               inventory: {
  //                 $or: [
  //                   {
  //                     location: {
  //                       id: {
  //                         $in: allIds,
  //                       },
  //                     },
  //                   },

  //                 ],
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //   ];
  // }

  // if (locationId && areaId) {
  //   setFilter.parts = {
  //     // $and: [

  //     part: {
  //       inventory: {
  //         $and: [
  //           {
  //             location: {
  //               id: {
  //                 $eq: locationId,
  //               },
  //             },
  //           },
  //           {
  //             area: {
  //               id: {
  //                 $eq: areaId,
  //               },
  //             },
  //           },
  //         ],
  //       },
  //     },

  //     // {
  //     //   part: {
  //     //     inventory: {
  //     //       area: {
  //     //         id: {
  //     //           $eq: areaId,
  //     //         },
  //     //       },
  //     //     },
  //     //   },
  //     // },
  //     // ],
  //     // location: {
  //     //   id: {
  //     //     $in: locations,
  //     //   },
  //     // },
  //     // $eq: partType[0],
  //   };

  //   setFilter.sets = {
  //     set: {
  //       parts: {
  //         part: {
  //           inventory: {
  //             $and: [
  //               {
  //                 location: {
  //                   id: {
  //                     $eq: locationId,
  //                   },
  //                 },
  //               },
  //               {
  //                 area: {
  //                   id: {
  //                     $eq: areaId,
  //                   },
  //                 },
  //               },
  //             ],
  //           },
  //         },
  //       },
  //     },
  //   };
  // }

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: setFilter,
    _q: searchName,
    populate: [
      "createdByUser",
      "createdByUser.profileImage",
      "updatedByUser",
      "updatedByUser.profileImage",
      "sets",
      "parts",
      "location",
      "parts.part",
      "parts.part.inventory.location",
      "parts.part.inventory.area",
      "sets.set",
      "sets.set.parts",
      "sets.set.parts.part",
      "sets.set.parts.part.inventory",
      "sets.set.parts.part.inventory.location",
      "sets.set.parts.part.inventory.area",
      "organisation",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `sets?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;

    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      return err.response.data;
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllActiveSets = async (
  organisationID = "",
  locations,
  searchName = "",
  page = 1,
  pageSize = 9999
) => {
  let setFilter = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
  };

  // if (locationId && areaId) {
  //   setFilter.parts = {
  //     // $and: [

  //     part: {
  //       inventory: {
  //         $and: [
  //           {
  //             location: {
  //               id: {
  //                 $eq: locationId,
  //               },
  //             },
  //           },
  //           {
  //             area: {
  //               id: {
  //                 $eq: areaId,
  //               },
  //             },
  //           },
  //         ],
  //       },
  //     },

  //     // {
  //     //   part: {
  //     //     inventory: {
  //     //       area: {
  //     //         id: {
  //     //           $eq: areaId,
  //     //         },
  //     //       },
  //     //     },
  //     //   },
  //     // },
  //     // ],
  //     // location: {
  //     //   id: {
  //     //     $in: locations,
  //     //   },
  //     // },
  //     // $eq: partType[0],
  //   };

  //   setFilter.sets = {
  //     set: {
  //       parts: {
  //         part: {
  //           inventory: {
  //             $and: [
  //               {
  //                 location: {
  //                   id: {
  //                     $eq: locationId,
  //                   },
  //                 },
  //               },
  //               {
  //                 area: {
  //                   id: {
  //                     $eq: areaId,
  //                   },
  //                 },
  //               },
  //             ],
  //           },
  //         },
  //       },
  //     },
  //   };
  // }

  const queryParams = {};
  if (organisationID) {
    queryParams["organisation"] = Number(organisationID);
  }

  if (locations) {
    queryParams["location"] = Number(locations); // Add location query here
  }

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `sets/getset?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;

    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      return err.response.data;
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllJobTitles = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 9999,
  sort = []
) => {
  let jobTitleFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
  };

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: jobTitleFilters,
    _q: searchName,
    populate: [
      "createdByUser",
      "createdByUser.profileImage",
      "assets",
      "parts",
      "organisation",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `jobs?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      return err.response.data;
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getWOByDetails = async (organisationID = "", id) => {
  let jobTitleFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },

    id: {
      $eq: id,
    },
  };

  const queryParams = {
    filters: jobTitleFilters,
    populate: ["part", "set","people.profileImage", "people.userProfile", "people.role","teams","primaryAssignee.profileImage", "primaryAssignee.userProfile", "primaryAssignee.role"],
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-orders?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response?.data?.data;
    // //console.log("response", response);

    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      return err.response.data;
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllAssetsByCustomer = async (
  customerID,
  organisationID = ""
) => {
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: {
        $and: [
          {
            organisation: {
              id: {
                $eq: organisationID,
              },
            },
          },
          {
            customer: {
              id: {
                $eq: customerID,
              },
            },
          },
        ],
      },
      populate: [
        "image",
        "warrantyFile",
        "dispose",
        "createdByUser",
        "vendor.logo",
        "customer.logo",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `assets?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllPeopleByLocation = async (
  customerID,
  organisationID = "",
  page = 1,
  pageSize = 99999
) => {
  const baseFilter = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    // role: {
    //   type: {
    //     $in: ["limited_admin",'super_admin'],
    //   },
    // },
  };

  let filters;
  if (customerID?.length === 1) {
    filters = {
      $and: [
        baseFilter,
        {
          $or: [
            // Condition for super_admin role
            {
              role: {
                type: {
                  $eq: "super_admin",
                },
              },
            },
            // Condition for other roles with location filtering
            {
              $and: [
                {
                  role: {
                    type: {
                      $in: "limited_admin",
                    },
                  },
                },
                {
                  locations: {
                    id: {
                      $in: [customerID],
                    },
                  },
                },
              ],
            },
          ],
        },
        // {
        //   locations: {
        //     id: {
        //       $in: [customerID],
        //     },
        //   },
        // },
      ],
    };
  } else {
    filters = {
      $and: [
        baseFilter,
        {
          $or: [
            // Condition for super_admin role
            {
              role: {
                type: {
                  $eq: "super_admin",
                },
              },
            },
            // Condition for other roles with location filtering
            {
              $and: [
                {
                  role: {
                    type: {
                      $in: "limited_admin",
                    },
                  },
                },
                {
                  locations: {
                    id: {
                      $in: customerID,
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  }
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters,
      "pagination[page]": String(page),
      "pagination[pageSize]": String(pageSize),
      populate: [
        "userProfile",
        "role",
        "locations",
        "teams",
        "supervisor",
        "supervisor.profileImage",
        "createdByUser",
        "createdByUser.profileImage",
        "profileImage",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `users?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllPeopleByActiveLocation = async (
  customerID,
  organisationID = "",
  page = 1,
  pageSize = 99999
) => {
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: {
        $and: [
          {
            organisation: {
              id: {
                $eq: organisationID,
              },
            },
          },
          {
            role: {
              type: {
                $in: ["limited_admin", "user", "admin", "super_admin"],
              },
            },
          },
          {
            blocked: false,
          },
          {
            confirmed: true,
          },

          {
            locations: {
              id: {
                $in: [customerID],
              },
            },
          },
        ],
      },
      "pagination[page]": String(page),
      "pagination[pageSize]": String(pageSize),
      populate: [
        "userProfile",
        "role",
        "locations",
        "teams",
        "supervisor",
        "supervisor.profileImage",
        "createdByUser",
        "createdByUser.profileImage",
        "profileImage",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `users?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllTeamsByLocation = async (
  customerID,
  organisationID = ""
) => {
  const baseFilter = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
  };

  let filters;
  if (customerID?.length === 1) {
    filters = {
      $and: [
        baseFilter,
        {
          locations: {
            id: {
              $in: [customerID],
            },
          },
        },
      ],
    };
  } else {
    filters = {
      ...baseFilter,
      locations: {
        id: {
          $in: customerID,
        },
      },
    };
  }
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters,
      // : {
      //   $and: [
      //     {
      //       organisation: {
      //         id: {
      //           $eq: organisationID,
      //         },
      //       },
      //     },
      //     {
      //       locations: {
      //         id: {
      //           $in: [customerID],
      //         },
      //       },
      //     },
      //   ],
      // },
      populate: [
        "userProfile",
        "role",
        "locations",
        "teams",
        "supervisor",
        "supervisor.profileImage",
        "createdByUser",
        "createdByUser.profileImage",
        "profileImage",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `teams?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllParts = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 99999,
  partType = [],
  status = [],
  assets = [],
  vendors = [],
  locations = [],
  area = [],
  teams = [],
  category = [],
  serialNumber,
  sort = [],
  role,
  allIds
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },

    inventory: {
      location: {
        id: {
          $in: locations,
        },
      },
    },
    assets: {
      id: {
        $in: assets,
      },
    },
    vendors: {
      id: {
        $in: vendors,
      },
    },
    teams: {
      id: {
        $in: teams,
      },
    },
    category: {
      id: {
        $in: category,
      },
    },
  };

  if (locations?.length == 0 && role !== "super_admin") {
    assetFilters.inventory = {
      location: {
        id: {
          $in: allIds,
        },
      },
    };
  } else {
    assetFilters.inventory = {
      location: {
        id: {
          $in: locations,
        },
      },
    };
  }

  if (status.length > 0 && status[0] !== "") {
    assetFilters.status = {
      $eq: status[0],
    };
  }

  if (partType && partType[0] !== "") {
    assetFilters.type = {
      $eq: partType[0],
    };
  }

  if (serialNumber) {
    assetFilters.serialNumber = {
      $eq: serialNumber,
    };
  }

  if (locations.length > 0 && area.length == 0) {
    assetFilters.inventory = {
      location: {
        id: {
          $in: locations,
        },
      },
      // $eq: partType[0],
    };
  } else if (locations.length > 0 && area.length > 0) {
    assetFilters.inventory = {
      $and: [
        {
          location: {
            id: {
              $in: locations,
            },
          },
        },
        {
          area: {
            id: {
              $in: area,
            },
          },
        },
      ],
      // location: {
      //   id: {
      //     $in: locations,
      //   },
      // },
      // $eq: partType[0],
    };
  }

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    _q: searchName,
    populate: [
      "image",
      "files",
      "assets",
      "location",
      "vendors",
      "customFields",
      "vendors.logo",
      "assets.image",
      "procedure",
      "createdByUser",
      "purchaseOrder",
      "category",
      "createdByUser.profileImage",
      "inventory",
      "inventory.location",
      "inventory.area",
      "people",
      "teams",
      "updatedByUser",
      "updatedByUser.profileImage",
      "logo",
      "sets",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }
  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  // const query = qs.stringify(
  //   {
  //     sort: ['countNumId:desc'],
  //     filters: {
  //       organisation: {
  //         id: {
  //           $eq: organisationID,
  //         },
  //       },
  //     },
  //     populate: [
  //       'image',
  //       'files',
  //       'assets',
  //       'location',
  //       'vendors',
  //       'customFields',
  //       'vendors.logo',
  //       'assets.image',
  //       'procedure',
  //       'createdByUser',
  //       'category',
  //       'createdByUser.profileImage',
  //       'inventory',
  //       'people',
  //       'teams',
  //       'updatedByUser',
  //       'updatedByUser.profileImage',
  //       'logo',
  //     ],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `parts?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let dataObj = {
      response: response.data.data,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    // return the_response;
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllWOPartsData = async (organisationID = "") => {
  // let assetFilters = {
  //   organisation: {
  //     id: {
  //       $eq: organisationID,
  //     },
  //   },
  // };

  const query = qs.stringify(
    {
      // sort: ['countNumId:desc'],
      organisation: organisationID,
      // filters: {
      //   organisation: {
      //     id: {
      //       $eq: organisationID,
      //     },
      //   },
      // },
    },
    {
      encodeValuesOnly: true,
    }
  );

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-order/parts-and-sets?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    // let dataObj = {
    //   response: response.data.data,
    //   status: response.status,
    //   pagination: response.data.meta.pagination,
    // };
    // //console.log("response", response);
    // return the_response;
    return response.data;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllPurchaseOrder = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 99999,
  vendors = [],
  status = [],
  totalCost = {},
  submissionDate = [],
  dueDate = [],
  sort = [],
  role,
  allIds
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    details: {
      vendor: {
        id: {
          $in: vendors,
        },
      },
    },
    orderItems: {},
  };

  if (status) {
    assetFilters.status = {
      $eq: status[0],
    };
  }

  if (submissionDate.length === 1) {
    assetFilters.createdAt = {
      $gte: submissionDate[0],
    };
  } else if (submissionDate.length === 2) {
    assetFilters.createdAt = {
      $gte: submissionDate[0],
      $lte: submissionDate[1],
    };
  }

  if (dueDate.length === 1) {
    assetFilters.details.dueDate = {
      $gte: dueDate[0],
    };
  } else if (dueDate.length === 2) {
    assetFilters.details.dueDate = {
      $gte: dueDate[0],
      $lte: dueDate[1],
    };
  }

  if (totalCost.from && !totalCost.to) {
    assetFilters.orderItems.totalCost = {
      $gte: Number(totalCost.from),
    };
  } else if (!totalCost.from && totalCost.to) {
    assetFilters.orderItems.totalCost = {
      $lte: Number(totalCost.to),
    };
  } else if (totalCost.to && totalCost.from) {
    assetFilters.orderItems.totalCost = {
      $gte: Number(totalCost.from),
      $lte: Number(totalCost.to),
    };
  }

  // shippingAddress companyName

  if (role !== "super_admin") {
    assetFilters.shippingAddress = {
      companyName: {
        id: {
          $in: allIds,
        },
      },
    };
  }

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    _q: searchName,
    populate: [
      "image",
      "attachments",
      "assets",
      "location",
      "vendors",
      "customFields",
      "assets.image",
      "procedure",
      "createdByUser",
      "category",
      "createdByUser.profileImage",
      "inventory",
      "inventory.location",
      "inventory.area",
      "details",
      "details.vendor",
      "details.mainLocation",
      "details.people",
      "orderItems",
      "orderItems.items",
      "orderItems.items.location",
      "orderItems.items.part",
      "shippingAddress",
      "shippingAddress.companyName",
      "shippingAddress.area",
      "billingAddress",
      "updatedByUser",
      "approvedByUser",
      "updatedByUser.profileImage",
      "logo",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }
  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `purchase-orders?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let dataObj = {
      response: response.data.data,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    // return the_response;
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllPOByPart = async (organisationID = "", partID = "") => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    orderItems: {
      items: {
        part: {
          id: {
            $in: partID,
          },
        },
      },
    },
  };

  const queryParams = {
    sort: ["countNumId:desc"],
    filters: assetFilters,
    populate: [
      "customFields",
      "vendors.logo",
      "assets.image",
      "procedure",
      "createdByUser",
      "category",
      "createdByUser.profileImage",
      "inventory",
      "inventory.location",
      "inventory.area",
      "details",
      "details.vendor",
      "orderItems",
      "orderItems.items",
      "orderItems.items.part",
      "billingAddress",
      "updatedByUser",
      "approvedByUser",
      "updatedByUser.profileImage",
    ],
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `purchase-orders?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    // //console.log("response", response);
    return response.data.data;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getDashBoardData = async (
  organisationID = "",
  period = [],
  location = [],
  orderType = [],
  people = [],
  categories = [],
  assets = [],
  teams = [],
  priority = [],
  customer = [],
  createSelectedDates = [],
  customSelectedDates = [],
  area = [],
  completedDate = [],
  progressDate = [],
  updatedDates = [],
  reviewDate = [],
  cancelledDate = []
) => {
  let startCompletedDate;
  let endCompletedDate;
  let startUpdatedAt;
  let endUpdatedAt;
  let startProgressDate;
  let endProgressDate;
  let startReviewDate;
  let endReviewDate;
  let startCancelledDate;
  let endCancelledDate;
  let startcreatedAt;
  let endcreatedAt;
  let startdueDate;
  let enddueDate;

  if (completedDate?.length === 1) {
    startCompletedDate = completedDate[0];
  } else if (completedDate?.length === 2) {
    startCompletedDate = completedDate[0];
    endCompletedDate = completedDate[1];
  }
  if (updatedDates?.length === 1) {
    startUpdatedAt = updatedDates[0];
  } else if (updatedDates?.length === 2) {
    startUpdatedAt = updatedDates[0];
    endUpdatedAt = updatedDates[1];
  }
  if (progressDate?.length === 1) {
    startProgressDate = progressDate[0];
  } else if (progressDate?.length === 2) {
    startProgressDate = progressDate[0];
    endProgressDate = progressDate[1];
  }
  if (reviewDate?.length === 1) {
    startReviewDate = reviewDate[0];
  } else if (reviewDate?.length === 2) {
    startReviewDate = reviewDate[0];
    endReviewDate = reviewDate[1];
  }
  if (cancelledDate?.length === 1) {
    startCancelledDate = cancelledDate[0];
  } else if (cancelledDate?.length === 2) {
    startCancelledDate = cancelledDate[0];
    endCancelledDate = cancelledDate[1];
  }

  if (createSelectedDates?.length === 1) {
    startcreatedAt = createSelectedDates[0];
  } else if (createSelectedDates?.length === 2) {
    startcreatedAt = createSelectedDates[0];
    endcreatedAt = createSelectedDates[1];
  }

  if (customSelectedDates?.length === 1) {
    startdueDate = customSelectedDates[0];
  } else if (customSelectedDates?.length === 2) {
    startdueDate = customSelectedDates[0];
    enddueDate = customSelectedDates[1];
  }

  const queryParams = {
    // sort: ['id:desc']
    organisation: organisationID,
    period: period[0],
    location: location,
    orderType: orderType[0] == "" ? null : orderType[0],
    people: people,
    category: categories,
    assets: assets,
    teams: teams,
    priority: priority,
    customer: customer,
    area: area,
    startcreatedAt: startcreatedAt,
    endcreatedAt: endcreatedAt,
    startdueDate: startdueDate,
    enddueDate: enddueDate,
    startCompletedDate,
    endCompletedDate,
    startUpdatedAt,
    endUpdatedAt,
    startProgressDate,
    endProgressDate,
    startReviewDate,
    endReviewDate,
    startCancelledDate,
    endCancelledDate,
    // populate: ['*']
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-orders-dashboard?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    // //console.log("response", response);
    return response.data;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAssetDashBoardData = async (
  organisationID = "",
  period = [],
  location = [],
  people = [],
  categories = [],
  assets = [],
  teams = [],
  status = [],
  customer = [],
  vendor = [],
  manufacturer = [],
  createSelectedDates = [],
  customSelectedDates = [],
  warrentySelectedDates = [],
  updatedDates = []
) => {
  let startPlacedDate;
  let endPlacedDate;
  let startcreatedAt;
  let endcreatedAt;
  let startExDate;
  let endExDate;
  let startUpdatedAt;
  let endUpdatedAt;
  if (warrentySelectedDates?.length === 1) {
    startExDate = warrentySelectedDates[0];
  } else if (warrentySelectedDates?.length === 2) {
    startExDate = warrentySelectedDates[0];
    endExDate = warrentySelectedDates[1];
  }

  if (updatedDates?.length === 1) {
    startUpdatedAt = updatedDates[0];
  } else if (updatedDates?.length === 2) {
    startUpdatedAt = updatedDates[0];
    endUpdatedAt = updatedDates[1];
  }

  if (customSelectedDates?.length === 1) {
    startcreatedAt = customSelectedDates[0];
  } else if (customSelectedDates?.length === 2) {
    startcreatedAt = customSelectedDates[0];
    endcreatedAt = customSelectedDates[1];
  }

  if (createSelectedDates?.length === 1) {
    startPlacedDate = createSelectedDates[0];
  } else if (createSelectedDates?.length === 2) {
    startPlacedDate = createSelectedDates[0];
    endPlacedDate = createSelectedDates[1];
  }

  const queryParams = {
    // sort: ['id:desc']
    organisation: organisationID,
    period: period[0],
    location: location,
    // orderType: orderType[0] == "" ? null : orderType[0],
    peoples: people,
    category: categories,
    asset: assets,
    teams: teams,
    status: status,
    customer: customer,
    vendor: vendor,
    manufacturer: manufacturer,
    startPlacedDate: startPlacedDate,
    endPlacedDate: endPlacedDate,
    startExDate: startExDate,
    endExDate: endExDate,
    startcreatedAt,
    endcreatedAt,
    startUpdatedAt,
    endUpdatedAt,
    // populate: ['*']
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `assets-dashboard?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    // //console.log("response", response);
    return response.data;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getMaintenanceDashBoardData = async (
  organisationID = ""
  // period = [],
  // location = [],
  // people = [],
  // categories = [],
  // assets = [],
  // teams = [],
  // status = [],
  // customer = [],
  // vendor = [],
  // manufacturer = [],
  // createSelectedDates = [],
  // customSelectedDates = [],
  // warrentySelectedDates = [],
  // updatedDates = []
) => {
  // let startPlacedDate;
  // let endPlacedDate;
  // let startcreatedAt;
  // let endcreatedAt;
  // let startExDate;
  // let endExDate;
  // let startUpdatedAt;
  // let endUpdatedAt;
  // if (warrentySelectedDates?.length === 1) {
  //   startExDate = warrentySelectedDates[0];
  // } else if (warrentySelectedDates?.length === 2) {
  //   startExDate = warrentySelectedDates[0];
  //   endExDate = warrentySelectedDates[1];
  // }

  // if (updatedDates?.length === 1) {
  //   startUpdatedAt = updatedDates[0];
  // } else if (updatedDates?.length === 2) {
  //   startUpdatedAt = updatedDates[0];
  //   endUpdatedAt = updatedDates[1];
  // }

  // if (customSelectedDates?.length === 1) {
  //   startcreatedAt = customSelectedDates[0];
  // } else if (customSelectedDates?.length === 2) {
  //   startcreatedAt = customSelectedDates[0];
  //   endcreatedAt = customSelectedDates[1];
  // }

  // if (createSelectedDates?.length === 1) {
  //   startPlacedDate = createSelectedDates[0];
  // } else if (createSelectedDates?.length === 2) {
  //   startPlacedDate = createSelectedDates[0];
  //   endPlacedDate = createSelectedDates[1];
  // }

  const queryParams = {
    // sort: ['id:desc']
    organisation: organisationID,
    // period: period[0],
    // location: location,
    // // orderType: orderType[0] == "" ? null : orderType[0],
    // peoples: people,
    // category: categories,
    // asset: assets,
    // teams: teams,
    // status: status,
    // customer: customer,
    // vendor: vendor,
    // manufacturer: manufacturer,
    // startPlacedDate: startPlacedDate,
    // endPlacedDate: endPlacedDate,
    // startExDate: startExDate,
    // endExDate: endExDate,
    // startcreatedAt,
    // endcreatedAt,
    // startUpdatedAt,
    // endUpdatedAt,
    // populate: ['*']
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `maintenance-dashboards?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    // //console.log("response", response);
    return response.data;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};
export const getResponseTimeByPriorityData = async (
  organisationID = "",
  interval = "week",
  filterOptions,
  createSelectedDates = [],
  customSelectedDates = [],
  reviewDate,
  cancelledDate,
  completedDate,
  progressDate,
  updatedDates
) => {
  const {
    dueDate,
    location,
    orderType,
    people,
    categories,
    asset,
    teams,
    priority,
    customer,
    area,
  } = filterOptions;

  let startCompletedDate;
  let endCompletedDate;
  let startUpdatedAt;
  let endUpdatedAt;
  let startProgressDate;
  let endProgressDate;
  let startReviewDate;
  let endReviewDate;
  let startCancelledDate;
  let endCancelledDate;
  let startcreatedAt;
  let endcreatedAt;
  let startdueDate;
  let enddueDate;

  if (completedDate?.length === 1) {
    startCompletedDate = completedDate[0];
  } else if (completedDate?.length === 2) {
    startCompletedDate = completedDate[0];
    endCompletedDate = completedDate[1];
  }
  if (updatedDates?.length === 1) {
    startUpdatedAt = updatedDates[0];
  } else if (updatedDates?.length === 2) {
    startUpdatedAt = updatedDates[0];
    endUpdatedAt = updatedDates[1];
  }
  if (progressDate?.length === 1) {
    startProgressDate = progressDate[0];
  } else if (progressDate?.length === 2) {
    startProgressDate = progressDate[0];
    endProgressDate = progressDate[1];
  }
  if (reviewDate?.length === 1) {
    startReviewDate = reviewDate[0];
  } else if (reviewDate?.length === 2) {
    startReviewDate = reviewDate[0];
    endReviewDate = reviewDate[1];
  }
  if (cancelledDate?.length === 1) {
    startCancelledDate = cancelledDate[0];
  } else if (cancelledDate?.length === 2) {
    startCancelledDate = cancelledDate[0];
    endCancelledDate = cancelledDate[1];
  }

  if (createSelectedDates?.length === 1) {
    startcreatedAt = createSelectedDates[0];
  } else if (createSelectedDates?.length === 2) {
    startcreatedAt = createSelectedDates[0];
    endcreatedAt = createSelectedDates[1];
  }

  if (customSelectedDates?.length === 1) {
    startdueDate = customSelectedDates[0];
  } else if (customSelectedDates?.length === 2) {
    startdueDate = customSelectedDates[0];
    enddueDate = customSelectedDates[1];
  }

  const queryParams = {
    // sort: ['id:desc'],
    organisation: organisationID,
    interval: interval,
    period: dueDate[0],
    location: location,
    orderType: orderType[0] == "" ? null : orderType[0],
    people: people,
    category: categories,
    assets: asset,
    teams: teams,
    priority: priority,
    customer: customer,
    area: area,
    startcreatedAt: startcreatedAt,
    endcreatedAt: endcreatedAt,
    startdueDate: startdueDate,
    enddueDate: enddueDate,
    startCompletedDate,
    endCompletedDate,
    startUpdatedAt,
    endUpdatedAt,
    startProgressDate,
    endProgressDate,
    startReviewDate,
    endReviewDate,
    startCancelledDate,
    endCancelledDate,
    // populate: ['*']
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-order-dashboard/responsetime?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    // //console.log("response", response);
    return response.data;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};
export const getResolutionTimeByPriorityData = async (
  organisationID = "",
  interval = "week",
  filterOptions,
  createSelectedDates = [],
  customSelectedDates = [],
  reviewDate,
  cancelledDate,
  completedDate,
  progressDate,
  updatedDates
) => {
  const {
    dueDate,
    location,
    orderType,
    people,
    categories,
    asset,
    teams,
    priority,
    customer,
    area,
  } = filterOptions;

  let startCompletedDate;
  let endCompletedDate;
  let startUpdatedAt;
  let endUpdatedAt;
  let startProgressDate;
  let endProgressDate;
  let startReviewDate;
  let endReviewDate;
  let startCancelledDate;
  let endCancelledDate;
  let startcreatedAt;
  let endcreatedAt;
  let startdueDate;
  let enddueDate;

  if (completedDate?.length === 1) {
    startCompletedDate = completedDate[0];
  } else if (completedDate?.length === 2) {
    startCompletedDate = completedDate[0];
    endCompletedDate = completedDate[1];
  }
  if (updatedDates?.length === 1) {
    startUpdatedAt = updatedDates[0];
  } else if (updatedDates?.length === 2) {
    startUpdatedAt = updatedDates[0];
    endUpdatedAt = updatedDates[1];
  }
  if (progressDate?.length === 1) {
    startProgressDate = progressDate[0];
  } else if (progressDate?.length === 2) {
    startProgressDate = progressDate[0];
    endProgressDate = progressDate[1];
  }
  if (reviewDate?.length === 1) {
    startReviewDate = reviewDate[0];
  } else if (reviewDate?.length === 2) {
    startReviewDate = reviewDate[0];
    endReviewDate = reviewDate[1];
  }
  if (cancelledDate?.length === 1) {
    startCancelledDate = cancelledDate[0];
  } else if (cancelledDate?.length === 2) {
    startCancelledDate = cancelledDate[0];
    endCancelledDate = cancelledDate[1];
  }

  if (createSelectedDates?.length === 1) {
    startcreatedAt = createSelectedDates[0];
  } else if (createSelectedDates?.length === 2) {
    startcreatedAt = createSelectedDates[0];
    endcreatedAt = createSelectedDates[1];
  }

  if (customSelectedDates?.length === 1) {
    startdueDate = customSelectedDates[0];
  } else if (customSelectedDates?.length === 2) {
    startdueDate = customSelectedDates[0];
    enddueDate = customSelectedDates[1];
  }

  const queryParams = {
    // sort: ['id:desc'],

    organisation: organisationID,
    interval: interval,
    period: dueDate[0],
    location: location,
    orderType: orderType[0] == "" ? null : orderType[0],
    people: people,
    category: categories,
    assets: asset,
    teams: teams,
    priority: priority,
    customer: customer,
    area: area,
    startcreatedAt: startcreatedAt,
    endcreatedAt: endcreatedAt,

    startdueDate: startdueDate,
    enddueDate: enddueDate,
    startCompletedDate,
    endCompletedDate,
    startUpdatedAt,
    endUpdatedAt,
    startProgressDate,
    endProgressDate,
    startReviewDate,
    endReviewDate,
    startCancelledDate,
    endCancelledDate,
    // populate: ['*']
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-order-dashboard/resolutiontime?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    // //console.log("response", response);
    return response.data;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getCostEstimateTimeData = async (
  organisationID = "",
  interval = "week",
  filterOptions,
  createSelectedDates = [],
  customSelectedDates = [],
  reviewDate,
  cancelledDate,
  completedDate,
  progressDate,
  updatedDates
) => {
  const {
    dueDate,
    location,
    orderType,
    people,
    categories,
    asset,
    teams,
    priority,
    customer,
    area,
  } = filterOptions;

  let startCompletedDate;
  let endCompletedDate;
  let startUpdatedAt;
  let endUpdatedAt;
  let startProgressDate;
  let endProgressDate;
  let startReviewDate;
  let endReviewDate;
  let startCancelledDate;
  let endCancelledDate;
  let startcreatedAt;
  let endcreatedAt;
  let startdueDate;
  let enddueDate;

  if (completedDate?.length === 1) {
    startCompletedDate = completedDate[0];
  } else if (completedDate?.length === 2) {
    startCompletedDate = completedDate[0];
    endCompletedDate = completedDate[1];
  }
  if (updatedDates?.length === 1) {
    startUpdatedAt = updatedDates[0];
  } else if (updatedDates?.length === 2) {
    startUpdatedAt = updatedDates[0];
    endUpdatedAt = updatedDates[1];
  }
  if (progressDate?.length === 1) {
    startProgressDate = progressDate[0];
  } else if (progressDate?.length === 2) {
    startProgressDate = progressDate[0];
    endProgressDate = progressDate[1];
  }
  if (reviewDate?.length === 1) {
    startReviewDate = reviewDate[0];
  } else if (reviewDate?.length === 2) {
    startReviewDate = reviewDate[0];
    endReviewDate = reviewDate[1];
  }
  if (cancelledDate?.length === 1) {
    startCancelledDate = cancelledDate[0];
  } else if (cancelledDate?.length === 2) {
    startCancelledDate = cancelledDate[0];
    endCancelledDate = cancelledDate[1];
  }
  if (createSelectedDates?.length === 1) {
    startcreatedAt = createSelectedDates[0];
  } else if (createSelectedDates?.length === 2) {
    startcreatedAt = createSelectedDates[0];
    endcreatedAt = createSelectedDates[1];
  }

  if (customSelectedDates?.length === 1) {
    startdueDate = customSelectedDates[0];
  } else if (customSelectedDates?.length === 2) {
    startdueDate = customSelectedDates[0];
    enddueDate = customSelectedDates[1];
  }

  const queryParams = {
    // sort: ['id:desc'],
    organisation: organisationID,
    interval: interval,
    period: dueDate[0],
    location: location,
    orderType: orderType[0] == "" ? null : orderType[0],
    people: people,
    category: categories,
    assets: asset,
    teams: teams,
    priority: priority,
    customer: customer,
    area: area,
    startcreatedAt: startcreatedAt,
    endcreatedAt: endcreatedAt,

    startdueDate: startdueDate,
    enddueDate: enddueDate,
    startCompletedDate,
    endCompletedDate,
    startUpdatedAt,
    endUpdatedAt,
    startProgressDate,
    endProgressDate,
    startReviewDate,
    endReviewDate,
    startCancelledDate,
    endCancelledDate,
    // populate: ['*']
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-order-dashboard/estimatecost?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    // //console.log("response", response);
    return response.data;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getTimeEstimateData = async (
  organisationID = "",
  interval = "week",
  filterOptions,
  createSelectedDates = [],
  customSelectedDates = [],
  reviewDate,
  cancelledDate,
  completedDate,
  progressDate,
  updatedDates
) => {
  const {
    dueDate,
    location,
    orderType,
    people,
    categories,
    asset,
    teams,
    priority,
    customer,
    area,
  } = filterOptions;

  let startCompletedDate;
  let endCompletedDate;
  let startUpdatedAt;
  let endUpdatedAt;
  let startProgressDate;
  let endProgressDate;
  let startReviewDate;
  let endReviewDate;
  let startCancelledDate;
  let endCancelledDate;
  let startcreatedAt;
  let endcreatedAt;
  let startdueDate;
  let enddueDate;

  if (completedDate?.length === 1) {
    startCompletedDate = completedDate[0];
  } else if (completedDate?.length === 2) {
    startCompletedDate = completedDate[0];
    endCompletedDate = completedDate[1];
  }
  if (updatedDates?.length === 1) {
    startUpdatedAt = updatedDates[0];
  } else if (updatedDates?.length === 2) {
    startUpdatedAt = updatedDates[0];
    endUpdatedAt = updatedDates[1];
  }
  if (progressDate?.length === 1) {
    startProgressDate = progressDate[0];
  } else if (progressDate?.length === 2) {
    startProgressDate = progressDate[0];
    endProgressDate = progressDate[1];
  }
  if (reviewDate?.length === 1) {
    startReviewDate = reviewDate[0];
  } else if (reviewDate?.length === 2) {
    startReviewDate = reviewDate[0];
    endReviewDate = reviewDate[1];
  }
  if (cancelledDate?.length === 1) {
    startCancelledDate = cancelledDate[0];
  } else if (cancelledDate?.length === 2) {
    startCancelledDate = cancelledDate[0];
    endCancelledDate = cancelledDate[1];
  }

  if (createSelectedDates?.length === 1) {
    startcreatedAt = createSelectedDates[0];
  } else if (createSelectedDates?.length === 2) {
    startcreatedAt = createSelectedDates[0];
    endcreatedAt = createSelectedDates[1];
  }

  if (customSelectedDates?.length === 1) {
    startdueDate = customSelectedDates[0];
  } else if (customSelectedDates?.length === 2) {
    startdueDate = customSelectedDates[0];
    enddueDate = customSelectedDates[1];
  }

  const queryParams = {
    // sort: ['id:desc'],
    organisation: organisationID,
    interval: interval,
    period: dueDate[0],
    location: location,
    orderType: orderType[0] == "" ? null : orderType[0],
    people: people,
    category: categories,
    assets: asset,
    teams: teams,
    priority: priority,
    customer: customer,
    area: area,
    startcreatedAt: startcreatedAt,
    endcreatedAt: endcreatedAt,

    startdueDate: startdueDate,
    enddueDate: enddueDate,
    startCompletedDate,
    endCompletedDate,
    startUpdatedAt,
    endUpdatedAt,
    startProgressDate,
    endProgressDate,
    startReviewDate,
    endReviewDate,
    startCancelledDate,
    endCancelledDate,
    // populate: ['*']
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-order-dashboard/estimatetime?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    // //console.log("response", response);
    return response.data;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllPartsByAssets = async (assetsID, organisationID = "") => {
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: {
        $and: [
          {
            organisation: {
              id: {
                $eq: organisationID,
              },
            },
          },
          {
            asset: {
              id: {
                $in: assetsID,
              },
            },
          },
        ],
      },
      populate: [
        "image",
        "files",
        "customFields",
        "vendor.logo",
        "asset.image",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `parts?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getLocationByID = async (the_ID, organisationID = "") => {
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: {
        $and: [
          {
            organisation: {
              id: {
                $eq: organisationID,
              },
            },
          },
          {
            id: {
              $eq: the_ID,
            },
          },
        ],
      },
      populate: [
        "geofencingCoords",
        "customFields",
        "areas",
        "mainLocation",
        "users.userProfile.personalPicture",
        "createdByUser",
        "createdByUser.profileImage",
        "parentLocation",
        "teams",
        "customers.logo",
        "vendors",
        "vendors.logo",
        "image",
        "propertyType",

        "users.role",
        "users.profileImage",

        "procedure",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `locations?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;

    //////console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllLocations = async (organisationID = "") => {
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: {
        organisation: {
          id: {
            $eq: organisationID,
          },
        },
      },
      populate: [
        "geofencingCoords",
        "customFields",
        "mainLocation",
        "users.userProfile.personalPicture",
        "createdByUser",
        "parentLocation",
        "subLocations",
        "teams",
        "customer.logo",
        "vendors",
        "users.role",
        "people",
        "updatedByUser",
        "team",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `locations?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllAreaLocations = async (
  organisationID = "",
  people = [],
  fromAdd = false
) => {
  let assetFilters = {
    $and: [
      {
        organisation: {
          id: {
            $eq: organisationID,
          },
        },
      },
      // {
      //   isParent: {
      //     $eq: true,
      //   },
      // },
      {
        areas: {
          isStore: {
            $eq: true,
          },
        },
      },
    ],
  };

  if (people?.length > 0) {
    assetFilters.users = {
      id: {
        $in: people,
      },
    };
  }

  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: assetFilters,
      populate: [
        "geofencingCoords",
        "customFields",
        "mainLocation",
        "users.userProfile.personalPicture",
        "createdByUser",
        "parentLocation",
        "subLocations",
        "teams",
        "areas",
        "customer.logo",
        "vendors",
        "users.role",
        "users",
        "updatedByUser",
        "team",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `locations?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllAreaByLocationId = async (
  organisationID = "",
  id,
  multipleId = []
) => {
  const locationFilter =
    multipleId.length > 0
      ? { id: { $in: multipleId } } // Use $in when multiple IDs are provided
      : { id: { $eq: id } }; // Use $eq when a single ID is provided
  const query = qs.stringify(
    {
      sort: ["id:desc"],
      filters: {
        $and: [
          {
            organisation: {
              id: {
                $eq: organisationID,
              },
            },
          },
          {
            location: locationFilter, // Dynamically use $eq or $in based on the input
          },
        ],
      },
      populate: [
        "locations",
        "location",
        "mainLocation",
        "users.userProfile.personalPicture",
        "createdByUser",
        "parentLocation",
        "subLocations",
        "teams",
        "customer.logo",
        "vendors",
        "users.role",
        "people",
        "updatedByUser",
        "team",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `areas?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const UpdateArea = async (areaId, organisation, isStore) => {
  try {
    let params = {
      areaId,
      organisation,
    };

    if (isStore) {
      params.isStore = false;
    }

    //   {
    //     "data": {
    //         "name": "g1",
    //         "isStore": true,
    //         "location": 58,
    //         "organisation": 103
    //     }
    // }
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "location/checkArea",
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    return {
      status: err?.response?.status,
    };
    //console.log('Error config', err.config);
  }
};

export const getParentBySubLoc = async (organisationID = "") => {
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + "location/find",
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllAreaByLocationIdActive = async (organisationID = "", id) => {
  let locationID;

  if (id[1]) {
    locationID = id;
  } else {
    locationID = id[0];
  }

  let filters = {
    $and: [
      {
        organisation: {
          id: {
            $eq: organisationID,
          },
        },
      },
      {
        location: {
          id: {
            $eq: locationID,
          },
        },
      },
    ],
  };
  const query = qs.stringify(
    {
      sort: ["id:desc"],
      filters: filters,
      populate: [
        "locations",
        "location",
        "mainLocation",
        "users.userProfile.personalPicture",
        "createdByUser",
        "parentLocation",
        "subLocations",
        "teams",
        "customer.logo",
        "vendors",
        "users.role",
        "people",
        "updatedByUser",
        "team",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `areas?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllParentLocations = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 25,
  customer = [],
  country = [],
  city = [],
  sort = [],
  role,
  allIds,
  currentUserId
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    customers: {
      id: {
        $in: customer,
      },
    },

    country: {
      $in: country,
    },
    city: {
      $in: city,
    },
  };

  if (searchName === "") {
    assetFilters.isParent = {
      $eq: true,
    };
  }

  if (searchName) {
    assetFilters.$or = [
      {
        name: {
          $containsi: searchName,
        },
      },
      {
        countId: {
          $containsi: searchName,
        },
      },
      {
        countryName: {
          $containsi: searchName,
        },
      },
      {
        cityName: {
          $containsi: searchName,
        },
      },
    ];
  }

  if (role !== "super_admin") {
    assetFilters.id = {
      $in: allIds,
    };
  }

  // if (role === "limited_admin") {
  //   assetFilters.$or = [
  //     {
  //       createdByUser: {
  //         id: {
  //           $in: currentUserId,
  //         },
  //       },
  //     },
  //   ];
  //   // assetFilters.createdByUser = {
  //   //   id: {
  //   //     $in: currentUserId,
  //   //   },
  //   // };
  //   //   assetFilters.createdByUser = {
  //   //    id:{

  //   //     $in: allIds,

  //   // }
  // }

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    // _q: searchName,
    populate: [
      "geofencingCoords",
      "customFields",
      "mainLocation",
      "users.userProfile.personalPicture",
      "createdByUser",
      "updatedByUser",
      "createdByUser.profileImage",
      "parentLocation",
      "parentLocation.customers",
      "parentLocation.customers.logo",
      "subLocations",
      "teams",
      "customers",
      "customers.logo",
      "vendors",
      "vendors.logo",
      "propertyType",
      "areas",
      "image",

      "users.role",
      "users.profileImage",
      "procedure",
      "subLocations.procedure",
      "subLocations.propertyType",
      "subLocations.customers",
      "subLocations.customers.logo",
      "subLocations.vendors.logo",
      "subLocations.areas",
      "subLocations.image",
      "subLocations.customFields",

      "subLocations.createdByUser",
      "subLocations.createdByUser.profileImage",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  // const query = qs.stringify(
  //   {
  //     sort: ['countNumId:desc'],
  //     filters: {
  //       $and: [
  //         {
  //           organisation: {
  //             id: {
  //               $eq: organisationID,
  //             },
  //           },
  //         },
  //         {
  //           isParent: {
  //             $eq: true,
  //           },
  //         },
  //       ],
  //     },
  //     populate: [
  //       'geofencingCoords',
  //       'customFields',
  //       'mainLocation',
  //       'users.userProfile.personalPicture',
  //       'createdByUser',
  //       'createdByUser.profileImage',
  //       'parentLocation',
  //       'subLocations',
  //       'teams',
  //       'customers',
  //       'customers.logo',
  //       'vendors',
  //       'vendors.logo',
  //       'propertyType',
  //       'areas',
  //       'image',

  //       'users.role',
  //       'users.profileImage',
  //       'procedure',
  //       'subLocations.procedure',
  //       'subLocations.propertyType',
  //       'subLocations.customers',
  //       'subLocations.customers.logo',
  //       'subLocations.vendors.logo',
  //       'subLocations.areas',
  //       'subLocations.image',
  //       'subLocations.customFields',

  //       'subLocations.createdByUser',
  //       'subLocations.createdByUser.profileImage',
  //     ],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `locations?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};
export const fetchInvLocationsOptions = async (organisationID = "") => {
  // let assetFilters = {
  //   organisation: {
  //     id: {
  //       $eq: organisationID,
  //     },
  //   },
  //   customers: {
  //     id: {
  //       $in: customer,
  //     },
  //   },

  //   country: {
  //     $in: country,
  //   },
  //   city: {
  //     $in: city,
  //   },
  // };

  // if (role === "limited_admin") {
  //   assetFilters.$or = [
  //     {
  //       createdByUser: {
  //         id: {
  //           $in: currentUserId,
  //         },
  //       },
  //     },
  //   ];
  //   // assetFilters.createdByUser = {
  //   //   id: {
  //   //     $in: currentUserId,
  //   //   },
  //   // };
  //   //   assetFilters.createdByUser = {
  //   //    id:{

  //   //     $in: allIds,

  //   // }
  // }

  const queryParams = {
    organisation: organisationID,
    // sort: sort.length > 0 ? sort : ["countNumId:desc"],
    // filters: assetFilters,
    // _q: searchName,
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  // const query = qs.stringify(
  //   {
  //     sort: ['countNumId:desc'],
  //     filters: {
  //       $and: [
  //         {
  //           organisation: {
  //             id: {
  //               $eq: organisationID,
  //             },
  //           },
  //         },
  //         {
  //           isParent: {
  //             $eq: true,
  //           },
  //         },
  //       ],
  //     },
  //     populate: [
  //       'geofencingCoords',
  //       'customFields',
  //       'mainLocation',
  //       'users.userProfile.personalPicture',
  //       'createdByUser',
  //       'createdByUser.profileImage',
  //       'parentLocation',
  //       'subLocations',
  //       'teams',
  //       'customers',
  //       'customers.logo',
  //       'vendors',
  //       'vendors.logo',
  //       'propertyType',
  //       'areas',
  //       'image',

  //       'users.role',
  //       'users.profileImage',
  //       'procedure',
  //       'subLocations.procedure',
  //       'subLocations.propertyType',
  //       'subLocations.customers',
  //       'subLocations.customers.logo',
  //       'subLocations.vendors.logo',
  //       'subLocations.areas',
  //       'subLocations.image',
  //       'subLocations.customFields',

  //       'subLocations.createdByUser',
  //       'subLocations.createdByUser.profileImage',
  //     ],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-order/inventory-locations?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    return response?.data;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllParentLocations2 = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 25,
  customer = [],
  country = [],
  city = [],
  sort = [],
  role,
  allIds,
  currentUserId
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    customers: {
      id: {
        $in: customer,
      },
    },

    country: {
      $in: country,
    },
    city: {
      $in: city,
    },
  };

  if (searchName === "") {
    assetFilters.isParent = {
      $eq: true,
    };
  }

  if (searchName) {
    assetFilters.$or = [
      {
        name: {
          $containsi: searchName,
        },
      },
      {
        countId: {
          $containsi: searchName,
        },
      },
    ];
    // assetFilters.name = {
    //   $containsi: searchName,
    // };

    // assetFilters.countId = {
    //   $containsi: searchName,
    // };
  }

  if (role !== "super_admin" && role !== "limited_admin" && role !== "admin") {
    assetFilters.id = {
      $in: allIds,
    };
  }

  if (role === "limited_admin" || role === "admin") {
    if (searchName) {
      assetFilters.$or = [
        // First part of $or: createdByUser matches currentUserId and name/countId conditions match searchName
        {
          $and: [
            {
              createdByUser: {
                id: {
                  $eq: currentUserId,
                },
              },
            },
            {
              $or: [
                {
                  name: {
                    $containsi: searchName,
                  },
                },
                {
                  countId: {
                    $containsi: searchName,
                  },
                },
              ],
            },
          ],
        },
        // Second part of $or: id is in allIds and name/countId conditions match searchName
        {
          $and: [
            {
              id: {
                $in: allIds,
              },
            },
            {
              $or: [
                {
                  name: {
                    $containsi: searchName,
                  },
                },
                {
                  countId: {
                    $containsi: searchName,
                  },
                },
              ],
            },
          ],
        },
      ];
      // assetFilters.createdByUser = {
      //   id: {
      //     $eq: currentUserId,
      //   },
      // };
      // assetFilters.id = {
      //   $in: allIds,
      // };

      // assetFilters.$or = [
      //   {
      //     name: {
      //       $containsi: searchName,
      //     },
      //   },
      //   {
      //     countId: {
      //       $containsi: searchName,
      //     },
      //   },
      // ];
      // assetFilters.$and= [
      //         {
      //           name: {
      //             $containsi: searchName,
      //           },
      //         },
      //         {
      //           countId: {
      //             $containsi: searchName,
      //           },
      //         },
      //       ],

      // assetFilters = [
      //   // {
      //   //   createdByUser: {
      //   //     id: {
      //   //       $eq: currentUserId,
      //   //     },
      //   //   },
      //   // },
      //   // {
      //   //   id: {
      //   //     $in: allIds,
      //   //   },
      //   // },
      //   {
      //     // Adding the $and block to combine the 'name' and 'countId' conditions
      //     $and: [
      //       {
      //         name: {
      //           $containsi: searchName,
      //         },
      //       },
      //       {
      //         countId: {
      //           $containsi: searchName,
      //         },
      //       },
      //     ],
      //   },
      // ];
    } else {
      assetFilters.$or = [
        {
          createdByUser: {
            id: {
              $eq: currentUserId,
            },
          },
        },
        {
          id: {
            $in: allIds,
          },
        },
      ];
    }
  }

  // if (role === "limited_admin") {
  //   assetFilters.$or = [
  //     {
  //       createdByUser: {
  //         id: {
  //           $in: currentUserId,
  //         },
  //       },
  //     },
  //   ];
  //   // assetFilters.createdByUser = {
  //   //   id: {
  //   //     $in: currentUserId,
  //   //   },
  //   // };
  //   //   assetFilters.createdByUser = {
  //   //    id:{

  //   //     $in: allIds,

  //   // }
  // }

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    // _q: searchName,
    populate: [
      "geofencingCoords",
      "customFields",
      "mainLocation",
      "users.userProfile.personalPicture",
      "createdByUser",
      "updatedByUser",
      "createdByUser.profileImage",
      "parentLocation",
      "subLocations",
      "teams",
      "customers",
      "customers.logo",
      "vendors",
      "parentLocation.customers",
      "parentLocation.customers.logo",
      "vendors.logo",
      "propertyType",
      "areas",
      "image",

      "users.role",
      "users.profileImage",
      "procedure",
      "subLocations.procedure",
      "subLocations.propertyType",
      "subLocations.customers",
      "subLocations.customers.logo",
      "subLocations.vendors.logo",
      "subLocations.areas",
      "subLocations.image",
      "subLocations.customFields",

      "subLocations.createdByUser",
      "subLocations.createdByUser.profileImage",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  // const query = qs.stringify(
  //   {
  //     sort: ['countNumId:desc'],
  //     filters: {
  //       $and: [
  //         {
  //           organisation: {
  //             id: {
  //               $eq: organisationID,
  //             },
  //           },
  //         },
  //         {
  //           isParent: {
  //             $eq: true,
  //           },
  //         },
  //       ],
  //     },
  //     populate: [
  //       'geofencingCoords',
  //       'customFields',
  //       'mainLocation',
  //       'users.userProfile.personalPicture',
  //       'createdByUser',
  //       'createdByUser.profileImage',
  //       'parentLocation',
  //       'subLocations',
  //       'teams',
  //       'customers',
  //       'customers.logo',
  //       'vendors',
  //       'vendors.logo',
  //       'propertyType',
  //       'areas',
  //       'image',

  //       'users.role',
  //       'users.profileImage',
  //       'procedure',
  //       'subLocations.procedure',
  //       'subLocations.propertyType',
  //       'subLocations.customers',
  //       'subLocations.customers.logo',
  //       'subLocations.vendors.logo',
  //       'subLocations.areas',
  //       'subLocations.image',
  //       'subLocations.customFields',

  //       'subLocations.createdByUser',
  //       'subLocations.createdByUser.profileImage',
  //     ],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `locations?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllLocationOfCustomer = async (
  customerID,
  organisationID = ""
) => {
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: {
        $and: [
          {
            organisation: {
              id: {
                $eq: organisationID,
              },
            },
          },
          {
            customer: {
              id: {
                $eq: customerID,
              },
            },
          },
        ],
      },
      populate: ["customer.logo"],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `locations?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllWorkOrders = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 99999,
  assignee = [],
  status = [],
  priority = [],
  category = [],
  customers = [],
  locations = [],
  dueDate = [],
  serialNumber = "",
  teams = [],
  sort = [],
  role = "",
  allIds = [],
  currentUserId = "",
  orderType=[],
  fromCal = false
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },

    $or: [
      {
        people: {
          id: {
            $in: assignee,
          },
        },
      },
      {
        primaryAssignee: {
          id: {
            $in: assignee,
          },
        },
      },
    ],

    // people: {
    //   id: {
    //     $in: assignee,
    //   },
    // },
    location: {
      id: {
        $in: locations,
      },
    },
    teams: {
      id: {
        $in: teams,
      },
    },
    customer: {
      id: {
        $in: customers,
      },
    },
    category: {
      $in: category,
    },
  };

  if (
    locations?.length == 0 &&
    // role !== "admin" &&
    role !== "super_admin" &&
    role !== "user"
  ) {
    assetFilters.location = {
      id: {
        $in: allIds,
      },
    };
  } else if (role === "user") {
    
    if (locations?.length == 0) {
      assetFilters.$or = [
        {
          location: {
            id: {
              $in: allIds,
            },

            // Include if location is in allIds
          },
        },
        {
          createdByUser: {
            id: {
              $in: currentUserId,
            },
          }, // Include if created by the user
        },
        {
          teams: {
            users: {
              id: {
                $in: [currentUserId],
              },
            },
          }, // Include if created by the user
        },
      ];
      // assetFilters.location = {
      //   id: {
      //     $in: allIds,
      //   },
      // };    assetFilters.location = {
      //   id: {
      //     $in: allIds,
      //   },
      // };
    } else {
      assetFilters.$or = [
        {
          location: {
            id: {
              $in: locations,
            },

            // Include if location is in allIds
          },
        },
        {
          createdByUser: {
            id: {
              $in: [currentUserId],
            },
          }, // Include if created by the user
        },
        {
          teams: {
            users: {
              id: {
                $in: [currentUserId],
              },
            },
          }, // Include if created by the user
        },
      ];
      // assetFilters.location = {
      //   id: {
      //     $in: locations,
      //   },
      // };
    }

    if (assignee?.length > 0) {
      assetFilters.$or = [
        {
          location: {
            id: {
              $in: locations,
            },

            // Include if location is in allIds
          },
        },
        {
          createdByUser: {
            id: {
              $in: [currentUserId],
            },
          }, // Include if created by the user
        },
        {
          people: {
            id: {
              $in: assignee,
            },
          }, // Include if created by the user
        },
        {
          teams: {
            users: {
              id: {
                $in: [currentUserId],
              },
            },
          }, // Include if created by the user
        },
        
      ];
      // assetFilters.people = {
      //   id: {
      //     $in: assignee,
      //   },
      // };
    } else {
      assetFilters.$or = [
        {
          location: {
            id: {
              $in: locations,
            },

            // Include if location is in allIds
          },
        },
        {
          createdByUser: {
            id: {
              $in: [currentUserId],
            },
          }, 
        },
        {
          people: {
            id: {
              $in: [currentUserId],
            },
          }, 
        },
        {
          primaryAssignee: {
            id: {
              $in: [currentUserId],
            },
          }, 
        },
        {
          teams: {
            users: {
              id: {
                $in: [currentUserId],
              },
            },
          }, // Include if created by the user
        },
      ];
     
    }

    if (teams?.length > 0) {
      assetFilters.$or = [
        {
          location: {
            id: {
              $in: locations,
            },

            // Include if location is in allIds
          },
        },
        {
          createdByUser: {
            id: {
              $in: [currentUserId],
            },
          }, // Include if created by the user
        },
        {
          people: {
            id: {
              $in: [currentUserId],
            },
          }, // Include if created by the user
        },
        {
          primaryAssignee: {
            id: {
              $in: [currentUserId],
            },
          }, // Include if created by the user
        },
        {
          teams: {
            users: {
              id: {
                $in: teams,
              },
            },
          }, // Include if created by the user
        },
        
      ];
      // assetFilters.people = {
      //   id: {
      //     $in: assignee,
      //   },
      // };
    } else {
      assetFilters.$or = [
        {
          location: {
            id: {
              $in: locations,
            },

            // Include if location is in allIds
          },
        },
        {
          createdByUser: {
            id: {
              $in: [currentUserId],
            },
          }, // Include if created by the user
        },
        {
          people: {
            id: {
              $in: [currentUserId],
            },
          }, // Include if created by the user
        },
        {
          primaryAssignee: {
            id: {
              $in: [currentUserId],
            },
          }, // Include if created by the user
        },
        {
          teams: {
            users: {
              id: {
                $in: [currentUserId],
              },
            },
          }, // Include if created by the user
        },
      ];
      
    }
  } else {
    assetFilters.location = {
      id: {
        $in: locations,
      },
    };
  }

  if (dueDate.length === 1) {
    assetFilters.dueDate = {
      $gte: dueDate[0],
    };
  } else if (dueDate.length === 2) {
    assetFilters.dueDate = {
      $gte: dueDate[0],
      $lte: dueDate[1],
    };
  }

  if (serialNumber) {
    assetFilters.id = {
      $in: serialNumber,
    };
  }

  if (status) {
    assetFilters.status = {
      $eq: status[0],
    };
  }  if (orderType) {
    assetFilters.orderType = {
      $eq: orderType[0],
    };
  }

  if (priority) {
    assetFilters.priority = {
      $eq: priority[0],
    };
  }

  const queryParams = {
    sort: fromCal
      ? ["countNumId:desc"] // When `fromCal` is true, use `countNumId:asc` regardless of `sort.length`
      : sort.length > 0
      ? sort // When `fromCal` is false and `sort.length > 0`
      : ["countNumId:desc"], // When `fromCal` is false and `sort.length === 0`
    filters: assetFilters,
    _q: searchName,
    populate: [
      "image",
      "file",
      "attachments",
      "daysRepeat",
      "createdByUser",
      "updatedByUser",
      "createdByUser.profileImage",
      "people.profileImage",
      "primaryAssignee.profileImage",

      "location",
      "area",
      "parts",
      "part",
      "part.part",
      "part.location",
      "sets",
      "set",
      "set.set",
      "set.location",
      "people.role",
      "primaryAssignee.role",
      "people.userProfile",
      "primaryAssignee.userProfile",
      "teams",
      "teams.users",
      "work_request",
      "customer",
      "work-order-log",
      "assets",
      "assets.image",
      "parts.image",
      "procedure.fields",



      "procedure.fields.logics",
      "procedure.fields.options",
      "procedure.createdByUser",
      "procedure.fields.attachments",
      "procedure.headers",
      "procedure.headers.fields",
      "procedure.headers.fields.logics",
      "procedure.headers.fields.options",
      "procedure.createdByUser",
      "procedure.fields.attachments",
      "procedure.headers.fields.attachments",
      "procedure.assets",
      "procedure.assets.location",
      "procedure.parts",
      "procedure.locations",

      "report.user",
      "report.part.part",
      "report.part.amount",
      "report.timeTracking.user",
      "report.reportItems",
      "report.headerReportItems",
      "report.headerReportItems.reportItems",
      "report.headerReportItems.reportItems.file",
      "report.reportItems.file",
      "report.headerReportItems.file",
      "report.reportItems.options",
      "report.headerReportItems.options",
      "report.headerReportItems.reportItems.options",
      "report.reportItems.action.people.role",
      "report.headerReportItems.reportItems.action.people.role",
      "report.headerReportItems.action.people.role",
      "report.reportItems.action.teams",
      "report.headerReportItems.action.teams",
      "report.headerReportItems.reportItems.action.teams",
      "report.reportItems.actions",
      "report.headerReportItems.actions",
      "report.headerReportItems.actions.workOrder",
      "report.headerReportItems.reportItems.actions",
      "report.headerReportItems.reportItems.actions.workOrder",
      "report.reportItems.actions.evidences",
      "report.reportItems.actions.workOrder",
      "report.headerReportItems.actions.evidences",
      "report.headerReportItems.actions.workOrder",
      "report.headerReportItems.reportItems.actions.evidences",
      "report.headerReportItems.reportItems.actions.workOrder",
      "report.parts.part.image",
      "report.parts.location",
      "report.sets.location",
      "report.sets.set",
      "report.timeTracking.user",
      "report.otherCosts",
      "reason",
      "reason.user",
      "reason.image",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }
  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-orders?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;

    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };

    return dataObj;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllWorkOrdersBySetId = async (
  organisationID = "",
  setId = ""
) => {
  let assetFilters = {
    $and: [
      {
        organisation: {
          id: {
            $eq: organisationID,
          },
        },
        sets: {
          id: {
            $in: setId,
          },
        },
        status: {
          $in: ["inProgress", "onHold"],
        },
      },
    ],
  };

  const queryParams = {
    sort: ["countNumId:desc"],
    filters: assetFilters,
    populate: [
      "image",
      "file",
      "daysRepeat",
      "createdByUser",
      "updatedByUser",
      "createdByUser.profileImage",
      "people.profileImage",

      "location",
      "area",
      "parts",
      "sets",
      "people.role",
      "people.userProfile",
      "teams",
      "work_request",
      "customer",
      "assets",
      "assets.image",
      "parts.image",
      "procedure.fields",
      "report.user",
      "report.part.part",
      "report.part.amount",
      "report.timeTracking.user",
      "report.reportItems",
      "report.reportItems.file",
      "report.reportItems.options",
      "report.reportItems.action.people.role",
      "report.reportItems.action.teams",
      "report.reportItems.actions",
      "report.reportItems.actions.evidences",
      "report.parts.part.image",
      "report.timeTracking.user",
      "report.otherCosts",
      "reason",
      "reason.user",
      "reason.image",
    ],
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  // const query = qs.stringify(
  //   {
  //     sort: ['countNumId:desc'],
  //     filters: {
  //       organisation: {
  //         id: {
  //           $eq: organisationID,
  //         },
  //       },
  //     },
  //     populate: [
  //       'image',
  //       'file',
  //       'daysRepeat',
  //       'createdByUser',
  //       'createdByUser.profileImage',
  //       'people.profileImage',

  //       'location',
  //       'people.role',
  //       'people.userProfile',
  //       'teams',
  //       'customer',
  //       'assets',
  //       'assets.image',
  //       'parts.image',
  //       'procedure.fields',
  //       'report.user',
  //       'report.part.part',
  //       'report.part.amount',
  //       'report.timeTracking.user',
  //       'report.reportItems',
  //       'report.reportItems.file',
  //       'report.reportItems.options',
  //       'report.reportItems.action.people.role',
  //       'report.reportItems.action.teams',
  //       'report.reportItems.actions',
  //       'report.reportItems.actions.evidences',
  //       'report.parts.part.image',
  //       'report.timeTracking.user',
  //       'report.otherCosts',
  //       'reason',
  //       'reason.user',
  //       'reason.image',
  //     ],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-orders?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;

    return the_response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllWorkOrdersByLocationID = async (
  location,
  organisationID = ""
) => {
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: {
        $and: [
          {
            organisation: {
              id: {
                $eq: organisationID,
              },
            },
          },
          {
            location: {
              id: {
                $eq: location,
              },
            },
          },
        ],
      },
      populate: ["procedure"],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-orders?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getPreventiveOrdersOfAsset = async (
  aid,
  organisationId = "",
  allIds = []
) => {
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: {
        assets: {
          organisation: {
            id: {
              $eq: organisationId,
            },
          },
          id: {
            $in: [aid],
          },
        },
        location: {
          id: {
            $in: allIds,
          },
        },
      },
      populate: ["procedure", "location"],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-orders?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllWorkOrderByID = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + "work-orders/" + ID + "?sort[0]=id",
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllWorkRequests = async (organisationID = "") => {
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: {
        organisation: {
          id: {
            $eq: organisationID,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-requests?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getNewWorkRequests = async () => {
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + "work-requests?populate=*&sort[0]=id&status=open",
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllWorkRequestByID = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + "work-requests/" + ID + "?sort[0]=id",
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getUsersByRole = async (
  role,
  organisationID,
  searchName = "",
  page = 1,
  pageSize = 9999,
  role_type = [],
  supervisor = [],
  team = [],
  status = [],
  job = [],
  rate = {},
  sort = [],
  location = [],
  roleData,
  allIds
) => {
  const superAdminRole = "super_admin";
  const otherRoles = role.filter((r) => r !== superAdminRole);
  let assetFilters = {
    $and: [
      {
        organisation: {
          id: {
            $eq: organisationID,
          },
        },
      },
      {
        $or: [
          // Condition for super_admin role
          {
            role: {
              type: {
                $eq: superAdminRole,
              },
            },
          },
          // Condition for other roles with location filtering
          {
            $and: [
              {
                role: {
                  type: {
                    $in: otherRoles,
                  },
                },
                // Only add the locations filter based on the condition
                ...(location?.length === 0 && roleData !== "super_admin"
                  ? {
                      locations: {
                        id: {
                          $in: allIds,
                        },
                      },
                    }
                  : {}),
              },
            ],
            // $and: [
            //   {
            //     role: {
            //       type: {
            //         $in: otherRoles,
            //       },
            //     },
            //   },
            //   {
            //     locations: {
            //       id: {
            //         $in: allIds,
            //       },
            //     },
            //   },
            // ],
          },
        ],
      },
    ],
    role: {
      id: {
        $eq: role_type,
      },
    },
    supervisor: {
      id: {
        $in: supervisor,
      },
    },

    teams: {
      id: {
        $in: team,
      },
    },
    userProfile: {
      job: {
        id: {
          $in: job,
        },
      },
    },
  };

  if (status[0] === "true") {
    assetFilters.$and.push({
      confirmed: {
        $eq: true,
      },
      blocked: {
        $eq: false,
      },
    });
  } else if (status[0] === "false") {
    assetFilters.$and.push({
      confirmed: {
        $eq: false,
      },
      blocked: {
        $eq: false,
      },
    });
  } else if (status[0] === "declined") {
    assetFilters.$and.push({
      blocked: {
        $eq: true,
      },
    });
  }

  if (rate.from && !rate.to) {
    assetFilters.userProfile.hourRate = {
      $gte: rate.from,
    };
  } else if (!rate.from && rate.to) {
    assetFilters.userProfile.hourRate = {
      $lte: rate.to,
    };
  } else if (rate.to && rate.from) {
    assetFilters.userProfile.hourRate = {
      $gte: rate.from,
      $lte: rate.to,
    };
  }

  if (location?.length == 0 && roleData !== "super_admin") {
    // assetFilters.locations = {
    //   id: {
    //     $in: allIds,
    //   },
    // };
  } else {
    assetFilters.locations = {
      id: {
        $in: location,
      },
    };
  }

  // locations: {
  //   id: {
  //     $in: location,
  //   },
  // },

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    _q: searchName,
    populate: [
      "userProfile",
      "role",
      "locations",
      "teams",
      "supervisor",
      "supervisor.profileImage",
      "createdByUser",
      "updatedByUser",
      "createdByUser.profileImage",
      "profileImage",
      "userProfile.job",
      "userProfile.personalPicture",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  // const query = qs.stringify(
  //   {
  //     sort: ['countNumId:desc'],
  //     filters: {
  //       $and: [
  //         {
  //           organisation: {
  //             id: {
  //               $eq: organisationID,
  //             },
  //           },
  //         },
  //         {
  //           role: {
  //             type: {
  //               $in: role,
  //             },
  //           },
  //         },
  //       ],
  //     },
  //     populate: [
  //       'userProfile',
  //       'role',
  //       'locations',
  //       'teams',
  //       'supervisor',
  //       'supervisor.profileImage',
  //       'createdByUser',
  //       'createdByUser.profileImage',
  //       'profileImage',
  //       'userProfile.job',
  //       'userProfile.personalPicture',
  //     ],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );
  try {
    // let roleParams = role.map((el) => "role.type=" + el + "&").join("");
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `users?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;

    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getUsersByID = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + "users/" + ID + "?populate=*&sort[0]=id",
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getDashboardUsersByID = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + "users/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response.data;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getUserProfileByID = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + "user-profiles/" + ID + "?populate=*",
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAttendanceRecordsByUserID = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + "attendance-records?sort[0]=id&user.id=" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllTeams = async (
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 99999,
  customer = [],
  superior = [],
  team = [],
  location = [],
  sort = [],
  userRole,
  currentUserId
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    customers: {
      id: {
        $in: customer,
      },
    },
    locations: {
      id: {
        $in: location,
      },
    },

    superior: {
      id: {
        $in: superior,
      },
    },
    id: {
      $in: team,
    },
  };

  if (location?.length == 0 && userRole !== "super_admin") {
    assetFilters.locations = {
      id: {
        $in: currentUserId,
      },
    };
  } else {
    assetFilters.locations = {
      id: {
        $in: location,
      },
    };
  }

  // locations: {
  //   id: {
  //     $in: location,
  //   },
  // },

  // if (userRole == 'requester') {
  //   assetFilters.users = {
  //     id: {
  //       $in: [currentUserId],
  //     },
  //   };
  // }

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    _q: searchName,
    populate: [
      "superior",
      "images",
      "superior.profileImage",
      "users",
      "users.profileImage",
      "createdByUser",
      "updatedByUser",
      "createdByUser.profileImage",
      "customers",
      "customers.logo",

      "locations",
      "locations.customer.logo",
    ],
  };

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });

  // const query = qs.stringify(
  //   {
  //     sort: ['countNumId:desc'],
  //     filters: {
  //       organisation: {
  //         id: {
  //           $eq: organisationID,
  //         },
  //       },
  //     },
  //     populate: [
  //       'superior',
  //       'superior.profileImage',
  //       'users',
  //       'users.profileImage',
  //       'createdByUser',
  //       'createdByUser.profileImage',
  //       'customers',
  //       'customers.logo',

  //       'locations',
  //       'locations.customer.logo',
  //     ],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `teams?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getTeamByID = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + "teams/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const getAllProcedures = async (
  isGlobalTemplate = false,
  organisationID = "",
  searchName = "",
  page = 1,
  pageSize = 9999,
  category,
  createdby = [],
  createdat = [],
  sort = [],
  assets = []
) => {
  let assetFilters = {
    organisation: {
      id: {
        $eq: organisationID,
      },
    },
    isGlobalTemplate: {
      $eq: isGlobalTemplate,
    },
    category: {
      $in: category,
    },
    createdByUser: {
      id: {
        $in: createdby,
      },
    },
    assets: {
      id: {
        $in: assets,
      },
    },
  };

  if (createdat.length === 1) {
    assetFilters.createdAt = {
      $gte: createdat[0],
    };
  } else if (createdat.length === 2) {
    assetFilters.createdAt = {
      $gte: createdat[0],
      $lte: createdat[1],
    };
  }

  const queryParams = {
    sort: sort.length > 0 ? sort : ["countNumId:desc"],
    filters: assetFilters,
    _q: searchName,
    populate: [
      "fields",
      "fields.logics",
      "fields.options",
      "createdByUser",
      "fields.attachments",
      "headers",
      "headers.fields",
      "headers.fields.logics",
      "headers.fields.options",
      "createdByUser",
      "fields.attachments",
      "headers.fields.attachments",
      "assets",
      "assets.location",
      "parts",
      "locations",
      "createdByUser.profileImage",
      "updatedByUser",
    ],
  };

  // const query = qs.stringify(
  //   {
  //     sort: ['countNumId:desc'],
  //     filters: assetFilters,
  //     populate: [
  //       'fields',
  //       'fields.logics',
  //       'fields.options',
  //       'createdByUser',
  //       'fields.attachments',
  //       'assets',
  //       'parts',
  //       'locations',
  //       'createdByUser.profileImage',
  //     ],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );

  if (page && pageSize) {
    queryParams["pagination[page]"] = String(page);
    queryParams["pagination[pageSize]"] = String(pageSize);
  }
  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `procedures?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    let dataObj = {
      response: the_response,
      status: response.status,
      pagination: response.data.meta.pagination,
    };
    // //console.log("response", response);
    return dataObj;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

//delete func
export const deleteUser = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + "users/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const deletePartFromWO = async (ID, WOId, isPart = true) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url +
        `work-order/parts/${ID}?workOrderId=${WOId}&isPart=${isPart}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const deleteAsset = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + "assets/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const deleteCustomer = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + "customers/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const deletePart = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + "parts/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    return {
      status: err?.response?.status,
    };
    //console.log('Error config', err.config);
  }
};
export const deletePurchaseOrder = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + "purchase-orders/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    return {
      status: err?.response?.status,
    };
    //console.log('Error config', err.config);
  }
};

export const deleteLocation = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + "locations/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const deleteWorkOrder = async (ID, deleteSeries) => {
  try {
    const handleDeleteSeries = deleteSeries ? "+series" : "";
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + "work-orders/" + ID + handleDeleteSeries,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const deleteTeam = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + "teams/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const deleteWorkRequest = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + "work-requests/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const deleteSet = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + "sets/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    return {
      status: err?.response?.status,
    };
    //console.log('Error config', err.config);
  }
};

export const deleteVendor = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + "vendors/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
    //console.log('Error config', err.config);
  }
};

export const deleteProcedure = async (ID) => {
  try {
    const response = await uninterceptedAxiosInstance.delete(
      backend_url + "procedures/" + ID,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

export const login = async (email, password) => {
  try {
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "auth/local/",
      {
        password: password,
        identifier: email,
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    throw throwError({
      message: err.message,
      status: err.response?.status,
    });
  }
};
export const requestPassword = async (email) => {
  try {
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "auth/forgot-password",
      {
        email,
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    throw new Error(err);
  }
};

export const getCurrentUserData = async (token) => {
  try {
    const query = qs.stringify(
      {
        populate: ["role"],
      },
      {
        encodeValuesOnly: true,
      }
    );
    const response = await uninterceptedAxiosInstance.get(
      backend_url + "users/me",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
  }
};

export const resetPassword = async (code, password, passwordConfirmation) => {
  try {
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "auth/reset-password",
      {
        code,
        password,
        passwordConfirmation,
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    throw new Error(err);
  }
};

export const updateWorkOrderStatus = async (id, status, message, orderType) => {
  try {
    const response = await uninterceptedAxiosInstance.put(
      backend_url + `work-orders/${id}`,
      {
        data: {
          status,
          orderType,
          reason: [
            {
              comment: message ? message : null,
              status: status,
            },
          ],
        },
      }
    );


    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
     
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
  }
};

// GET functions
export const getOrganisationData = async (organisationID = "") => {
  const query = qs.stringify(
    {
      populate: ["profileImage", "allowed_modules"],
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `organisations/${organisationID}?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return null;
  }
};

export const getUserData = async (userId = "") => {
  const query = new URLSearchParams({
    populate: [
      "userProfile",
      "role",
      "locations",
      "locations.subLocations",
      "teams",
      "supervisor",
      "supervisor.profileImage",
      "createdByUser",
      "updatedByUser",
      "createdByUser.profileImage",
      "profileImage",
      "organisation",
      "userProfile.job",
      "userProfile.personalPicture",
    ],
  }).toString();

  const subLocationQuery = new URLSearchParams({
    populate: ["location.subLocations"].join(","),
  }).toString();
  // const query = qs.stringify(
  //   {
  //     populate: ['createdByUser'],
  //   },
  //   {
  //     encodeValuesOnly: true,
  //   }
  // );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `users/${userId}?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data;
    // //console.log('response', response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return null;
  }
};

export const getUserDataDashBoard = async (
  id,
  dashboardFilter,
  assetData = false
) => {
  try {
    let params = {};

    if (assetData == false) {
      params.woDashboardSavedFilter = dashboardFilter;
    } else {
      params.assetDashboardSavedFilter = dashboardFilter;
    }

    //console.log('params', params);

    const response = await uninterceptedAxiosInstance.put(
      backend_url + `users/${id}`,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    // //console.log('response', response);
    return the_response?.data;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
    };
  }
};

export const updateCompanyProfile = async (
  id,
  currency,
  country,
  timezone,
  profileImage
) => {
  try {
    let params = {
      currency,
      country,
      timezone,
      profileImage,
    };
    if (!currency) {
      delete params.currency;
    }
    if (!country) {
      delete params.country;
    }
    if (!timezone) {
      delete params.timezone;
    }

    //console.log('params', params);

    const response = await uninterceptedAxiosInstance.put(
      backend_url + `organisations/${id}`,
      { data: params },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    // //console.log('response', response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
    };
  }
};
export const updateUserProfileImage = async (id, profileImage) => {
  try {
    let params = {
      profileImage,
    };

    //console.log('params', params);

    const response = await uninterceptedAxiosInstance.put(
      backend_url + `users/${id}`,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    // //console.log('response', response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
    };
  }
};

export const changeLoggedInUserPassword = async (
  oldPassword,
  newPassword,
  confirmPassword
) => {
  try {
    let params = {
      oldPassword,
      newPassword,
      confirmPassword,
    };

    //console.log('params', params);

    const response = await uninterceptedAxiosInstance.post(
      backend_url + `users/changePassword`,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    // //console.log('response', response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
      error: err?.response?.data?.error,
    };
  }
};

export const verifyPersonEmailAndPhone = async (
  id,
  email,
  phone,
  organisation,
  role
) => {
  try {
    let params = {
      role: role,
      username: "",
      email: email,
      teams: [],
      firstName: "",
      lastName: "",
      supervisor: null,
      locations: [],
      userId: id,
      phone: phone,
      password: "",
      organisation: organisation,
      createdByUser: null,
    };
    const response = await uninterceptedAxiosInstance.post(
      backend_url + `/auth/verify/phone-email`,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    //console.log(response);
    let the_response = response;
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
      error: err?.response?.data?.error?.message,
    };
  }
};

// PUT functions (update functions)
export const updateUserNotificationToken = async (
  id,
  token,
  language = "English",
  mainToken = false
) => {
  try {
    let params = {
      webFcmToken: token,
      language,
    };
    // if (mainToken) {
    //   params.token = null;
    // }

    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "users/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    //console.log('Error config.data', err.config.data);
    return {
      status: err?.response?.status,
      error: err?.response?.data?.error?.message,
    };
  }
};

export const getAssigneWorkOrderForPeople = async (
  organisationID = "",
  peopleId = ""
) => {
  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: {
        organisation: {
          id: {
            $eq: organisationID,
          },
        },
        people: {
          id: {
            $eq: peopleId,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `work-orders?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
      error: err?.response?.data?.error?.message,
    };
  }
};

export const getActiveUsersByRole = async (
  role,
  organisationID,
  locations = [],
  fromPeople = false,
  page = 1,
  pageSize = 99999
) => {
  const superAdminRole = "super_admin";
  const otherRoles = role.filter((r) => r !== superAdminRole);

  const queryFilters = {
    $and: [
      {
        organisation: {
          id: {
            $eq: organisationID,
          },
        },
      },
      {
        blocked: false,
      },
      // Handle fromPeople condition
      {
        $or: fromPeople
          ? [
              {
                confirmed: true, // Active users
              },
              {
                confirmed: false, // Pending users
              },
            ]
          : [
              {
                confirmed: true, // Active users only when not fromPeople
              },
            ],
      },
      {
        $or: [
          // Condition for super_admin role
          {
            role: {
              type: {
                $eq: superAdminRole,
              },
            },
          },
          // Condition for other roles with location filtering
          {
            $and: [
              {
                role: {
                  type: {
                    $in: otherRoles,
                  },
                },
              },
              {
                locations: {
                  id: {
                    $in: locations,
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  };

  // Your database querying logic here

  const query = qs.stringify(
    {
      sort: ["countNumId:desc"],
      filters: queryFilters,
      "pagination[page]": String(page),
      "pagination[pageSize]": String(pageSize),
      populate: [
        "userProfile",
        "role",
        "locations",
        "teams",
        "supervisor",
        "supervisor.profileImage",
        "createdByUser",
        "createdByUser.profileImage",
        "profileImage",
      ],
    },
    // {
    //   sort: ["countNumId:desc"],
    //   filters: {
    //     $and: [
    //       {
    //         organisation: {
    //           id: {
    //             $eq: organisationID,
    //           },
    //         },
    //       },

    //       {
    //         role: {
    //           type: {
    //             $in: rolesWithSuperAdmin,
    //           },
    //         },
    //       },
    //       {
    //         blocked: false,
    //       },

    //       {
    //         confirmed: true,
    //       },
    //     ],
    //     locations: {
    //       id: {
    //         $in: locations,
    //       },
    //     },
    //   },
    //   populate: [
    //     "userProfile",
    //     "role",
    //     "locations",
    //     "teams",
    //     "supervisor",
    //     "supervisor.profileImage",
    //     "createdByUser",
    //     "createdByUser.profileImage",
    //     "profileImage",
    //   ],
    // },
    {
      encodeValuesOnly: true,
    }
  );
  try {
    // let roleParams = role.map((el) => "role.type=" + el + "&").join("");
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `users?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response.data;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
      error: err?.response?.data?.error?.message,
    };
  }
};

export const DeactivatePeople = async (
  status = "",
  userId = null,
  reassignedUserId = null
) => {
  try {
    const params = {
      status,
      userId,
      reassignedUserId,
    };

    // //console.log("params role", params.role);
    const response = await uninterceptedAxiosInstance.post(
      backend_url + "users/update/status",
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    //console.log('response', response);
    return response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
      error: err?.response?.data?.error?.message,
    };
  }
};

export const getNotifications = async (id) => {
  const query = qs.stringify(
    {
      sort: ["createdAt:desc"],
      filters: {
        $and: [
          {
            user: {
              id: {
                $eq: id,
              },
            },
          },
        ],
      },
      populate: [
        "asset",
        "organisation",
        "part",
        "user",
        "user.sendByUser",
        "sendByUser",
        "workOrder",
        "purchaseOrder",
        "purchaseOrder.approvedByUser",
        "purchaseOrder",
        "purchaseOrder.details",
        "purchaseOrder.details.vendor",
        "sets",
        "workRequest",
        "workRequest.submittedBy",
        "workRequest.updatedByUser",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );

  try {
    const response = await uninterceptedAxiosInstance.get(
      backend_url + `notifications?${query}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
      error: err?.response?.data?.error?.message,
    };
  }
};
export const markNotificationAsRead = async (id) => {
  try {
    const response = await uninterceptedAxiosInstance.put(
      backend_url + `notifications/${id}`,
      {
        data: {
          isRead: true,
        },
      },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
      error: err?.response?.data?.error?.message,
    };
  }
};

export const markAllNotificationAsRead = async () => {
  try {
    const response = await uninterceptedAxiosInstance.post(
      backend_url + `notification/mark-all-as-read`,
      {
        data: {
          isRead: true,
        },
      },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    // //console.log("response", response);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
      error: err?.response?.data?.error?.message,
    };
  }
};

export const createBulkCategory = async (data = [], organisationId = null) => {
  try {
    let params = {
      data,
      organisation: organisationId,
    };

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "categories/import",
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
    };
  }
};

export const createBulkJobtitle = async (data = [], organisationId = null) => {
  try {
    let params = {
      data,
      organisation: organisationId,
    };

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "jobs/import",
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
    };
  }
};
export const createBulkAsset = async (data = [], organisationId = null) => {
  try {
    let params = {
      data,
      organisation: organisationId,
    };

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "assets/import",
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
    };
  }
};

export const createBulkLocation = async (data = [], organisationId = null) => {
  try {
    let params = {
      data,
      organisation: organisationId,
    };

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "location/import",
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
    };
  }
};
export const createBulkPart = async (data = [], organisationId = null) => {
  try {
    let params = {
      data,
      organisation: organisationId,
    };

    const response = await uninterceptedAxiosInstance.post(
      backend_url + "parts/import",
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    let the_response = response;
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    return {
      status: err?.response?.status,
    };
  }
};

// PUT functions (update functions)
export const updateUserLanguage = async (id, language = "English") => {
  try {
    let params = {
      language,
    };

    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "users/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    //console.log('Error config', err.config);
    //console.log('Error config.data', err.config.data);
    return {
      status: err?.response?.status,
      error: err?.response?.data?.error?.message,
    };
  }
};

export const updateAssetsStatus = async (
  id,
  assetsStatus,
  dispose,
  organisation,
  clickButtonFlag = ""
) => {
  try {
    let params = {
      data: {
        status: assetsStatus,
        organisation,
      },
    };
    if (assetsStatus) {
      params.data.clickButton = clickButtonFlag;
    }

    if (dispose) {
      params.data.dispose = {
        reason: dispose.reason,
        costs: Number(dispose.costs),
      };
    }
    //////console.log("params", params);
    const response = await uninterceptedAxiosInstance.put(
      backend_url + "assets/update-status/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    console.log(response, "hisksksksksksks");

    let the_response = response;
    //////console.log("response", response.data.data);
    return the_response;
  } catch (err) {
    // Error 😨
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      //console.log('Error response.data.data', err.response.data);
      //console.log('Error response.status', err.response.status);
      //console.log('Error response.headers', err.response.headers);
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      //console.log('Error request', err.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      //console.log('Error message', err.message);
    }
    return {
      status: err.response.status,
      error: err.response?.data?.error,
    };
    //console.log('Error config', err.config);
  }
};

export const handleKeyDown = (e) => {
  if (
    e.keyCode === 38 ||
    e.keyCode === 40 ||
    ["e", "E", "+", "-"].includes(e.key)
  ) {
    e.preventDefault();
  }
};

export const currencyDecimalPlaces = {
  USD: 2,
  EUR: 2,
  GBP: 2,
  CAD: 2,
  AUD: 2,
  CHF: 2,
  CNY: 2,
  INR: 2,
  RUB: 2,
  SAR: 2,
  JPY: 0,
  KRW: 0,
  IDR: 0,
  BHD: 3,
  KWD: 3,
  JOD: 3,
  OMR: 3,
  LYD: 3,
  TND: 3,
};

export const isTimeValid = (val) => {
  var _slicedToArray = (function() {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;
      try {
        for (
          var _i = arr[Symbol.iterator](), _s;
          !(_n = (_s = _i.next()).done);
          _n = true
        ) {
          _arr.push(_s.value);
          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }
      return _arr;
    }
    return function(arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError(
          "Invalid attempt to destructure non-iterable instance"
        );
      }
    };
  })();

  var letterArr = val
      .split(":")
      .join("")
      .split(""),
    regexp = /^\d{0,2}?\:?\d{0,2}$/,
    valArr = [];

  var _val$split = val.split(":");

  var _val$split2 = _slicedToArray(_val$split, 2);

  var hoursStr = _val$split2[0];
  var minutesStr = _val$split2[1];

  if (!regexp.test(val)) {
    return false;
  }

  var hours = Number(hoursStr);
  var minutes = Number(minutesStr);

  var isValidHour = function isValidHour(hour) {
    return Number.isInteger(hours) && hours >= 0 && hours < 100;
  };
  var isValidMinutes = function isValidMinutes(minutes) {
    return (
      (Number.isInteger(minutes) && hours >= 0 && hours < 100) ||
      Number.isNaN(minutes)
    );
  };
  if (!isValidHour(hours) || !isValidMinutes(minutes)) {
    return false;
  }

  if (minutes < 10 && Number(minutesStr[0]) > 5) {
    return false;
  }

  if (valArr.indexOf(":")) {
    valArr = val.split(":");
  } else {
    valArr.push(val);
  }

  // check mm and HH
  if (
    valArr[0] &&
    valArr[0].length &&
    (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 99)
  ) {
    return false;
  }

  if (
    valArr[1] &&
    valArr[1].length &&
    (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)
  ) {
    return false;
  }

  return true;
};
