import React, { useEffect, useState } from "react";
import {
  BlackCostIcon,
  CustomerPlusIcon,
  OptionIcon,
} from "../../../../../Assets/Icons/svg";
import { useDeletePermissionModal } from "../../../../../hooks/Messages/useDeletePermissionModal";

import { useSelector } from "react-redux";
import { selectTranslations } from "../../../../config/i18n/slice";
import { DropdownItem, DropdownMenu, Loader } from "semantic-ui-react";
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from "../../../../components/common/userProfilePlaceholder";
import useDisplayFormattedText from "../../../../../hooks/useDisplayFormattedText";
import {
  addCostData,
  deleteCostTabData,
  editCostData,
  getActiveUsersByRole,
  getCostTabData,
  IMAGES_URL,
} from "../../../../config/functions";
import Asterisk from "../../../../components/common/Asterisk";

import { toast } from "react-toastify";
import { scrollToError } from "../../../../../utilFunctions/scrollToError";

import { format } from "date-fns";
import moment, { duration } from "moment";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CostModal from "./costModal";

const Cost = ({ data }) => {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const organisationTimezone = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.timezone;
    } else {
      return null;
    }
  });

  const getLocalDateTime = (offset) => {
    const now = new Date(); // Get the current time in local timezone
    const utcMilliseconds = now.getTime() + now.getTimezoneOffset() * 60000; // Convert local time to UTC
    const localMilliseconds = utcMilliseconds + offset * 3600000; // Add timezone offset in milliseconds

    const localDate = new Date(localMilliseconds);

    return format(localDate, "yyyy-MM-dd hh:mm a");
  };

  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });
  const { DisplayFormattedText } = useDisplayFormattedText();
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [addLaborModal, setaddLaborModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [validation, setValidation] = useState({
    people: {
      error: false,
      pattern: (code) => code != "",
      skipped: false,
      message: t.common.required_field,
    },
    cost: {
      error: false,
      pattern: (code) => code != "",
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [loading, setLoading] = useState(true);
  const [logData, setLogData] = useState();

  const [options, setOptions] = useState({
    people: [],
    category: [
      {
        key: 0,
        value: "Labor Cost",
        text: t.workOrders.cost.labor_cost,
      },

      {
        key: 1,
        value: "Part Cost",
        text: t.workOrders.cost.part_cost,
      },
      {
        key: 2,
        value: "Travel Cost",
        text: t.workOrders.cost.travel_cost,
      },
      {
        key: 3,
        value: "Tax",
        text: t.workOrders.cost.tax,
      },
      {
        key: 4,
        value: "Other Cost",
        text: t.workOrders.cost.other_cost,
      },
    ],
  });

  const [costData, setCostData] = useState({
    people: "",
    startDate: getLocalDateTime(organisationTimezone?.split("+")[1]),
    cost: "",
    description: "",
    category: "Other Cost",
  });

  const fetchData = async () => {
    setLoading(true);
    const fetchData = await getCostTabData(organisationId, data?.id);
    if (fetchData) {
      setLogData(fetchData);
      setLoading(true);
    }
    setLoading(false);
  };

  const deleteHandler = async () => {
    const response = await deleteCostTabData(costData?.id);

    if (response?.status == 200) {
      window.dispatchEvent(new Event("triggerNotificationRefresh"));
      toast.success(t.workOrders.cost.delete);
      fetchData();
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  useEffect(() => {
    fetchData();
  }, [data]);
  const onValuesChange = (name, actualValue) => (event, value) => {
    let currentFieldValue = "";
    const dropdown_fields = ["people", "category"];
    if (dropdown_fields.includes(name)) {
      currentFieldValue = actualValue || value.value;
      let resulteNewData = {};
      if (name === "people") {
        let hrRate = options?.people?.find((el) => el?.value === value?.value);

        resulteNewData = {
          ...costData,
          [name]: actualValue || value.value,
          hourRate: hrRate?.hrRate,
        };
      } else {
        resulteNewData = {
          ...costData,
          [name]: actualValue || value.value,
        };
      }
      setCostData(resulteNewData);
    } else {
      currentFieldValue = actualValue || event.target.value;
      setCostData({
        ...costData,
        [name]: currentFieldValue,
      });
    }
    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
  };

  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "limited_admin", "admin"],
      organisationId,
      data?.attributes?.location?.data?.id
    );
    // console.log("THE FETCH USERS", fetchData)
    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // description: `${teams_string_array.join(" , ")}`,
          hrRate: el?.userProfile?.hourRate ? el?.userProfile?.hourRate : 0,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

  const handleEdit = (part) => {
    setCostData({
      people: part?.attributes?.assignee?.data?.id,
      startDate: part?.attributes?.dateTime,
      cost: part?.attributes?.cost ? part?.attributes?.cost : "",
      description: part?.attributes?.description
        ? part?.attributes?.description
        : "",
      category: part?.attributes?.category
        ? part?.attributes?.category
        : "Other Cost",
      id: part?.id,
    });

    fetchPeopleOptions();
    setIsEdit(true);
    setaddLaborModal(true);
  };

  const checkPermission = (labor) => {
    if (["cancelled", "completed"].includes(data?.attributes?.status)) {
      return false;
    } else if (["super_admin", "admin", "limited_admin"].includes(roleData)) {
      return true;
    } else if (labor?.attributes?.createdByUser?.data?.id === currentUserId) {
      return true;
    }
    return false;
  };

  const handleAssign = async () => {
    let currentValidation = {
      ...validation,
      people: {
        ...validation.people,
        error: validation.people.pattern(costData.people) ? false : true,
        skipped: true,
      },
      cost: {
        ...validation.cost,
        error: validation.cost.pattern(costData.cost) ? false : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);

    if (!errorsInData) {
      let response;
      if (isEdit) {
        response = await editCostData(
          data?.id,
          organisationId,
          currentUserId,
          costData?.people,
          costData?.cost ? Number(costData?.cost) : 0,
          moment(costData?.startDate, "YYYY-MM-DD hh:mm A").toISOString(),
          costData?.description ? costData?.description : null,
          costData?.category,
          costData?.id
        );
      } else {
        response = await addCostData(
          data?.id,
          organisationId,
          currentUserId,
          costData?.people,
          costData?.cost ? Number(costData?.cost) : 0,
          moment(costData?.startDate, "YYYY-MM-DD hh:mm A").toISOString(),
          costData?.description ? costData?.description : null,
          costData?.category
        );
      }

      if (response && response.status == 200) {
        toast.success(
          isEdit ? t.workOrders.cost.edit : t.workOrders.cost.success
        );
        window.dispatchEvent(new Event("triggerNotificationRefresh"));

        setCostData({
          people: "",
          startDate: getLocalDateTime(organisationTimezone?.split("+")[1]),
          cost: "",
          description: "",
          category: "Other Cost",
        });

        setIsEdit(false);

        setaddLaborModal(false);

        fetchData();
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(response.error.message);
        } else {
          toast.error(t.common.something_wrong);
        }
      } else {
        toast.error(t.common.something_wrong);
      }
    } else {
      scrollToError();
    }
  };
  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          {t.common.loading}
        </Loader>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div
                className={`labor-grey-btn ${
                  selectedLanguage == "ar" ? "ml-5" : "mr-5"
                }`}
              >
                <span
                  className={`${selectedLanguage == "ar" ? "ml-3" : "mr-3"}`}
                >
                  {" "}
                  <BlackCostIcon />
                </span>
                {t.workOrders.labor.total_cost}
                <span
                  className={`${selectedLanguage == "en" ? "ml-3" : "mr-3"}`}
                >
                  {" "}
                  {logData?.totalCost ? logData?.totalCost : 0}{" "}
                  {organisationCurrency}
                </span>
              </div>
            </div>
            <div>
              {!["completed", "cancelled"].includes(data?.attributes?.status) &&
              ["user", "admin", "super_admin", "limited_admin"].includes(
                roleData
              ) ? (
                <button
                  className={`buttun-type-link mt-1`}
                  basic
                  type="button"
                  style={{ background: "transparent" }}
                  onClick={() => setaddLaborModal(true)}
                >
                  <span className="customer-add-btn">
                    {" "}
                    <CustomerPlusIcon width="20px" height="20px" />{" "}
                  </span>

                  <p className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                    {t.workOrders.cost.add_cost}
                  </p>
                </button>
              ) : null}
            </div>
          </div>
          {logData?.data?.length > 0 ? (
            <div className="scroll-data" style={{ overflow: "auto" }}>
              <div className={"mt-6"}>
                <div className={`kanbar-header`}>
                  <div class="grid-container-element set cost-form">
                    <div
                      style={{
                        minWidth: "140px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.form.description}
                    </div>
                    <div
                      style={{
                        minWidth: "100px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.cost.cost}
                    </div>

                    <div
                      style={{
                        minWidth: "100px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.table.category}
                    </div>

                    <div
                      style={{
                        minWidth: "130px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.cost.assigned}
                    </div>
                    <div
                      style={{
                        minWidth: "161px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.cost.date_time}
                    </div>

                    <div
                      style={{
                        minWidth: "40px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    ></div>
                  </div>
                </div>
              </div>

              {logData?.data?.map((part, index) => (
                <div className="grid-container-element set cost-form form">
                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "144px",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <p className="user-text">
                        {part?.attributes?.description
                          ? DisplayFormattedText(
                              part?.attributes?.description,
                              17
                            )
                          : t.common.na}
                      </p>
                    </div>
                  </div>

                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "100px",
                    }}
                  >
                    {part?.attributes?.cost
                      ? `${DisplayFormattedText(
                          String(part?.attributes?.cost),
                          7
                        )}  ${organisationCurrency}`
                      : t.common.na}
                  </div>

                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "100px",
                    }}
                  >
                    {DisplayFormattedText(
                      options?.category?.find(
                        (item) => item?.value === part?.attributes?.category
                      )?.text || "",
                      9
                    )}
                  </div>

                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "130px",
                    }}
                  >
                    {part?.attributes?.assignee?.data?.attributes?.firstName
                      ? DisplayFormattedText(
                          part?.attributes?.assignee?.data?.attributes
                            ?.firstName +
                            " " +
                            part?.attributes?.assignee?.data?.attributes
                              ?.lastName,
                          13
                        )
                      : t.common.na}
                  </div>

                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "161px",
                    }}
                  >
                    <p>
                      {part?.attributes?.dateTime
                        ? moment(part?.attributes?.dateTime).format(
                            "DD/MM/YYYY hh:mm A"
                          )
                        : t.common.na}
                    </p>
                  </div>

                  {checkPermission(part) ? (
                    <div
                      className="grid-child-element wo-data-form"
                      style={{
                        padding: "10px 9px",
                        minWidth: "20px",
                      }}
                    >
                      <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 rtl-custom-menu ml-1 mt-2 align-self-start">
                        <UncontrolledDropdown className="mr-2" direction="down">
                          <DropdownToggle
                            color="primary"
                            className="procedure-toggle"
                          >
                            <OptionIcon />
                          </DropdownToggle>
                          <DropdownMenu
                            className={
                              selectedLanguage == "ar"
                                ? "procedure labor labor-rtl"
                                : "procedure labor"
                            }
                          >
                            <DropdownItem className="divider" />
                            <DropdownItem onClick={() => handleEdit(part)}>
                              {t.common.edit}
                            </DropdownItem>
                            <DropdownItem className="divider" />
                            <DropdownItem
                              onClick={() => {
                                setDeletePermissionModal(true);
                                setCostData({
                                  ...costData,
                                  id: part.id,
                                  logId: part.logId,
                                });
                              }}
                            >
                              {t.common.delete}
                            </DropdownItem>{" "}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center mt-5">
              <p className="required-text">{t.common.na}</p>
            </div>
          )}
        </>
      )}

      <CostModal
        addLaborModal={addLaborModal}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        setValidation={setValidation}
        setCostData={setCostData}
        setaddLaborModal={setaddLaborModal}
        handleAssign={handleAssign}
        costData={costData}
        options={options}
        validation={validation}
        fetchPeopleOptions={fetchPeopleOptions}
        onValuesChange={onValuesChange}
      />
      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={t.workOrders.cost.cost}
      >
        <span>{t.workOrders.cost.delete_cost}</span>
      </DeletePermissionModal>
    </>
  );
};

export default Cost;
