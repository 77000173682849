import React, { useState, useEffect, createRef, useRef } from 'react';
import {
  Message,
  Header,
  Dropdown,
  Grid,
  Checkbox,
  Radio,
  Modal,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Segment,
  TextArea,
} from 'semantic-ui-react';

import { createNewProcedure } from '../../../config/functions';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useSelector } from 'react-redux';
import { selectTranslations } from '../../../config/i18n/slice';

function AddProcedureModal({ toggled, untoggle, returnAddProcedure, title }) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const [initChange, setInitChange] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [responseData, setResponseData] = useState({});

  const [data, setData] = useState({
    title: title,
    description: '',
  });

  useEffect(() => {
    setData({ ...data, title });
  }, [title]);

  const [defaultField, setDefaultField] = useState('checklist');

  const [fields, setFields] = useState([
    {
      id: 0,
      label: '',
      fieldType: 'checklist',
      isRequired: false,
      active: true,
      logic: false,
      options: [
        {
          item: '',
        },
      ],
    },
  ]);

  const [validation, setValidation] = useState({
    title: {
      error: false,
      pattern: (title) => title != '',
      skipped: false,
      message: 'Field is required',
    },
  });

  const [validForm, setValidForm] = useState(false);

  const resetForm = () => {
    setData({
      ...data,
      title: '',
      description: '',
    });
    setFields([
      {
        id: 0,
        label: '',
        fieldType: 'checklist',
        isRequired: false,
        active: true,
        logic: false,
        options: [
          {
            item: '',
          },
        ],
      },
    ]);
    setInitChange(false);
  };

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    setData({
      ...data,
      [name]: event.target.value,
    });
  };

  const addItem = (isHeading = false) => {
    setInitChange(true);
    const new_fields = [...fields];
    new_fields.forEach((el) => (el.active = false));

    setFields([
      ...fields,
      {
        id: fields.length,
        label: '',
        fieldType: isHeading ? 'heading' : defaultField,
        isRequired: false,
        active: true,
        logic: false,
        options: [
          {
            item: '',
          },
        ],
      },
    ]);
  };

  const removeThisField = (index) => (e) => {
    e.stopPropagation();
    let newfields = [...fields];
    newfields.splice(index, 1);

    setFields(newfields);
  };

  const handleFieldChange = (id, type) => (event, value) => {
    // 1. Make a shallow copy of the items
    let newfields = [...fields];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...fields[id] };
    // 3. Replace the property you're intested in

    if (type == 'label') item.label = event.target.value;
    if (type == 'fieldType') {
      setDefaultField(value.value);
      item.fieldType = value.value;
    }
    if (type == 'isRequired') item.isRequired = value.checked;

    if (type == 'inspectionType') item.inspectionType = value.value;

    if (type == 'failedValue') item.failedValue = String(value.value);

    if (type == 'logic') {
      event.stopPropagation();
      item.logic = item.logic ? false : true;
    }
    if (type == 'logicAnswer') item.logicAnswer = String(value.value);
    if (type == 'logicTrigger') item.logicTrigger = value.value;

    if (type == 'active') {
      newfields.forEach((el) => (el.active = false));
      item.active = true;
    }
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    newfields[id] = item;

    // console.log(newfields[id]);
    // 5. Set the state to our new copy
    setFields(newfields);
  };
  const fieldTypes = [
    {
      key: 'checkbox',
      value: 'checkbox',
      text: t.procedures.form.type_options[0],
      icon: {
        name: 'check square outline',
        color: 'blue',
        circular: true,
      },
    },
    {
      key: 'text',
      value: 'text',
      text: t.procedures.form.type_options[1],
      icon: {
        name: 'align left',
        color: 'olive',
        circular: true,
      },
    },
    {
      key: 'number',
      value: 'number',
      text: t.procedures.form.type_options[2],
      icon: {
        name: 'hashtag',
        color: 'orange',
        circular: true,
      },
    },
    {
      key: 'amount',
      value: 'amount',
      text: t.procedures.form.type_options[3],
      icon: {
        name: 'dollar sign',
        color: 'brown',
        circular: true,
      },
    },
    {
      key: 'multipleChoice',
      value: 'multipleChoice',
      text: t.procedures.form.type_options[4],
      icon: {
        name: 'list ul',
        color: 'violet',
        circular: true,
      },
    },
    {
      key: 'checklist',
      value: 'checklist',
      text: t.procedures.form.type_options[5],
      icon: {
        name: 'list',
        color: 'red',
        circular: true,
      },
    },
    {
      key: 'inspectionCheck',
      value: 'inspectionCheck',
      text: t.procedures.form.type_options[6],
      icon: {
        name: 'search',
        color: 'teal',
        circular: true,
      },
    },
    {
      key: 'signature',
      value: 'signature',
      text: t.procedures.form.type_options[7],
      icon: {
        name: 'pencil alternate',
        color: 'green',
        circular: true,
      },
    },
    {
      key: 'date',
      value: 'date',
      text: t.procedures.form.type_options[8],
      icon: {
        name: 'calendar alternate outline',
        color: 'yellow',
        circular: true,
      },
    },
    {
      key: 'file',
      value: 'file',
      text: t.procedures.form.type_options[9],
      icon: {
        name: 'upload',
        color: 'purple',
        circular: true,
      },
    },
  ];

  const inspectionTypes = [
    {
      key: 'yesNoNA',
      value: 'yesNoNA',
      text: t.procedures.form.inspection_types[0].join(' - '),
      logicAnswers: [
        {
          key: 0,
          value: 'yes',
          text: t.procedures.form.inspection_types[0][0],
        },
        {
          key: 1,
          value: 'no',
          text: t.procedures.form.inspection_types[0][1],
        },
        {
          key: 2,
          value: 'na',
          text: t.procedures.form.inspection_types[0][2],
        },
      ],
    },
    {
      key: 'goodFairPoorNA',
      value: 'goodFairPoorNA',
      text: t.procedures.form.inspection_types[1].join(' - '),
      logicAnswers: [
        {
          key: 0,
          value: 'good',
          text: t.procedures.form.inspection_types[1][0],
        },
        {
          key: 1,
          value: 'fair',
          text: t.procedures.form.inspection_types[1][1],
        },
        {
          key: 2,
          value: 'poor',
          text: t.procedures.form.inspection_types[1][2],
        },
        {
          key: 3,
          value: 'na',
          text: t.procedures.form.inspection_types[1][3],
        },
      ],
    },
    {
      key: 'safeRiskNA',
      value: 'safeRiskNA',
      text: t.procedures.form.inspection_types[2].join(' - '),
      logicAnswers: [
        {
          key: 0,
          value: 'safe',
          text: t.procedures.form.inspection_types[2][0],
        },
        {
          key: 1,
          value: 'risk',
          text: t.procedures.form.inspection_types[2][1],
        },
        {
          key: 2,
          value: 'na',
          text: t.procedures.form.inspection_types[2][2],
        },
      ],
    },
    {
      key: 'passFailNA',
      value: 'passFailNA',
      text: t.procedures.form.inspection_types[3].join(' - '),
      logicAnswers: [
        {
          key: 0,
          value: 'pass',
          text: t.procedures.form.inspection_types[3][0],
        },
        {
          key: 1,
          value: 'fail',
          text: t.procedures.form.inspection_types[3][1],
        },
        {
          key: 2,
          value: 'na',
          text: t.procedures.form.inspection_types[3][2],
        },
      ],
    },
    {
      key: 'compliantNonCompliantNA',
      value: 'compliantNonCompliantNA',
      text: t.procedures.form.inspection_types[4].join(' - '),
      logicAnswers: [
        {
          key: 0,
          value: 'compliant',
          text: t.procedures.form.inspection_types[4][0],
        },
        {
          key: 1,
          value: 'non-compliant',
          text: t.procedures.form.inspection_types[4][1],
        },
        {
          key: 2,
          value: 'na',
          text: t.procedures.form.inspection_types[4][2],
        },
      ],
    },
    {
      key: 'scaleFive',
      value: 'scaleFive',
      text: t.procedures.form.inspection_types[5][0],
      logicAnswers: [
        {
          key: 0,
          value: '0',
          text: '0',
        },
        {
          key: 1,
          value: '1',
          text: '1',
        },
        {
          key: 2,
          value: '2',
          text: '2',
        },
        {
          key: 3,
          value: '3',
          text: '3',
        },
        {
          key: 4,
          value: '4',
          text: '4',
        },
        {
          key: 5,
          value: '5',
          text: '5',
        },
      ],
    },
  ];

  const logicTriggers = [
    {
      key: 0,
      value: 'action',
      icon: 'bullhorn',
      text: t.procedures.form.then_options[0],
    },
    {
      key: 1,
      value: 'evidence',
      icon: 'images',
      text: t.procedures.form.then_options[1],
    },
  ];

  const generatedFields = () => {
    return fields.map((field, index) => (
      <>
        <Segment
          tabindex="1"
          onClick={handleFieldChange(index, 'active')}
          className={field.active ? 'active-field' : ''}
        >
          {field.fieldType == 'heading' ? (
            <Form.Field>
              <label className="label-item">
                {t.procedures.form.add_heading}
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.procedures.form.add_heading}
                value={field.label}
                onChange={handleFieldChange(index, 'label')}
              />
            </Form.Field>
          ) : (
            <Grid style={{ marginBottom: '10px' }}>
              <Grid.Column width={10}>
                <Form.Field>
                  <label className="label-item">
                    {field.fieldType == 'checkbox'
                      ? t.procedures.form.checkbox_label
                      : t.procedures.form.title}
                  </label>

                  {field.fieldType == 'checkbox' ? (
                    <div className="flexbox align-center">
                      <Checkbox disabled />
                      <Input
                        autoComplete="new-password"
                        fluid
                        placeholder={t.procedures.form.checkbox_label}
                        style={{ width: '100%', marginLeft: '5px' }}
                        value={field.label}
                        onChange={handleFieldChange(index, 'label')}
                      />
                    </div>
                  ) : (
                    <Input
                      autoComplete="new-password"
                      fluid
                      placeholder={t.procedures.form.title_placeholder}
                      value={field.label}
                      onChange={handleFieldChange(index, 'label')}
                    />
                  )}
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Field>
                  <label className="label-item">{t.procedures.form.type}</label>
                  <Dropdown
                    placeholder={t.procedures.form.type}
                    fluid
                    selection
                    options={fieldTypes}
                    value={field.fieldType}
                    onChange={handleFieldChange(index, 'fieldType')}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
          )}
          <div className="hide-on-non-focused">
            {generatedSubFields(field.fieldType, field, index)}

            <Divider />
            <div
              style={
                field.fieldType != 'heading'
                  ? { marginTop: '20px' }
                  : { marginTop: '20px', justifyContent: 'flex-end' }
              }
              className={`flexbox align-center justify-between procedure-actions `}
              basic
            >
              {field.fieldType != 'heading' && (
                <div className="flexbox align-center">
                  <Icon
                    name="camera"
                    size="large"
                    color="blue"
                    // onClick={removeThisField(field.id)}
                  />
                  <Icon
                    name="attach"
                    size="large"
                    color="blue"
                    // onClick={removeThisField(field.id)}
                  />
                  {/* <Icon
                name="code branch"
                size="large"
                color="blue"
                onClick={removeThisField(field.id)}
              /> */}
                </div>
              )}

              <div className="flexbox rtl-container align-center">
                {field.fieldType != 'heading' && (
                  <Checkbox
                    value={field.isRequired}
                    label={t.procedures.form.is_required}
                    onChange={handleFieldChange(index, 'isRequired')}
                    style={{ margin: '0 5px' }}
                  />
                )}

                <div
                  className="buttun-type-link remove"
                  basic
                  onClick={removeThisField(index)}
                >
                  <Icon name="trash alternate outline" />
                  {t.procedures.form.remove_button}
                </div>
              </div>
            </div>
          </div>
        </Segment>
      </>
    ));
  };

  const generatedSubFields = (type, field, index) => {
    let logicAnswersOptions = inspectionTypes.filter(
      (el) => el.value == field.inspectionType
    );
    if (logicAnswersOptions.length > 0)
      logicAnswersOptions = logicAnswersOptions[0].logicAnswers;

    const addOption = (parentIndex) => {
      let newFields = [...fields];

      let modifiedField = newFields[parentIndex];
      // 1. Make a shallow copy of the items
      let newOptions = field.options;
      // 2. Make a shallow copy of the item you want to mutate
      newOptions.push({ item: '' });

      modifiedField.options = newOptions;
      // 5. Set the state to our new copy
      newFields[parentIndex] = modifiedField;

      setFields(newFields);
    };

    const removeOption = (parentIndex, id) => (e) => {
      e.stopPropagation();

      let newFields = [...fields];

      let modifiedField = { ...newFields[parentIndex] };
      // 1. Make a shallow copy of the items
      let newOptions = [...field.options];
      // 2. Make a shallow copy of the item you want to mutate
      newOptions.splice(id, 1);

      modifiedField.options = newOptions;
      // 5. Set the state to our new copy
      newFields[parentIndex] = modifiedField;

      setFields(newFields);
    };

    const handleOptionChange = (parentIndex, id) => (event) => {
      let newFields = [...fields];

      let modifiedField = { ...newFields[parentIndex] };
      // 1. Make a shallow copy of the items
      let newOptions = [...field.options];
      // 2. Make a shallow copy of the item you want to mutate
      let item = { ...field.options[id] };
      // 3. Replace the property you're intested in

      item = event.target.value;
      // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
      newOptions[id].item = item;

      modifiedField.options = newOptions;
      // 5. Set the state to our new copy
      newFields[parentIndex] = modifiedField;

      setFields(newFields);
    };

    const generatedOptions = (parentIndex) => {
      let checkStyle = <Checkbox disabled />;
      if (field.fieldType == 'multipleChoice') {
        checkStyle = <Radio disabled />;
      }
      if (field.fieldType == 'checkbox') {
        return (
          <div className="flexbox align-center">
            <Form.Field
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 0,
                width: '100%',
              }}
            >
              {checkStyle}
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.procedures.form.option}
                value={field?.options[0].item}
                style={{ flex: 1, marginLeft: '10px' }}
                onChange={handleOptionChange(parentIndex, 0)}
              />
            </Form.Field>
          </div>
        );
      } else {
        const getItemStyle = (isDragging, draggableStyle) => ({
          ...draggableStyle,
          userSelect: 'none',
          position: 'static',
          padding: '8px 0',
          margin: `0 0 5px 0`,
          borderRadius: '3px',
          background: isDragging ? '#e2f5fc' : '',
        });

        return field?.options?.map((item, index) => (
          <Draggable
            key={index}
            draggableId={'draggable-' + index}
            index={index}
          >
            {(provided, snapshot) => {
              return (
                <div
                  className="flexbox align-center"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <Form.Field
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 0,
                      width: '100%',
                    }}
                  >
                    <Icon name="sort" {...provided.dragHandleProps} />
                    {checkStyle}
                    <Input
                      id={'option-' + index}
                      autoComplete="new-password"
                      fluid
                      placeholder={t.procedures.form.option}
                      value={item.item}
                      style={{ flex: 1, margin: '0 10px' }}
                      onChange={handleOptionChange(parentIndex, index)}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          if (item.item.length > 0) {
                            addOption(parentIndex);
                          }
                        }
                      }}
                    />
                  </Form.Field>
                  <Icon
                    name="trash alternate outline"
                    color="red"
                    style={{ cursor: 'pointer' }}
                    onClick={removeOption(parentIndex, index)}
                  />
                </div>
              );
            }}
          </Draggable>
        ));
      }
    };

    let returnFields = null;
    switch (type) {
      case 'text':
        returnFields = (
          <Segment secondary>{t.procedures.form.text_placeholder}</Segment>
        );
        break;
      case 'number':
        returnFields = (
          <Segment secondary>{t.procedures.form.number_placeholder}</Segment>
        );
        break;
      case 'amount':
        returnFields = (
          <Segment secondary>{t.procedures.form.amount_placeholder}</Segment>
        );
        break;
      case 'signature':
        returnFields = (
          <Segment secondary>{t.procedures.form.signature_placeholder}</Segment>
        );
        break;
      case 'date':
        returnFields = <Segment secondary>{t.procedures.form.date_placeholder}</Segment>;
        break;
      case 'file':
        // text,number,amount, signature, date, file fields
        //isrequired
        returnFields = (
          <Segment secondary>{t.procedures.form.file_placeholder}</Segment>
        );
        break;
      case 'multipleChoice':
      case 'checklist':
        // multipleChoice fields
        //isRequired & options
        returnFields = (
          <>
            <label className="label-item">{t.procedures.form.options}</label>
            <DragDropContext
              onDragEnd={(result) => {
                if (!result.destination) {
                  return;
                }
                const [removed] = field.options.splice(result.source.index, 1);
                field.options.splice(result.destination.index, 0, removed);
              }}
            >
              <Droppable droppableId="droppable-1">
                {(provided, _) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {generatedOptions(index)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div className="flexbox" style={{ marginTop: '20px' }}>
              <div
                className={'buttun-type-link'}
                basic
                onClick={() => addOption(index)}
              >
                <Icon name="add" />
                {t.procedures.form.add_option}
              </div>
            </div>
          </>
        );
        break;
      case 'inspectionCheck':
        // inspectionCheck fields
        //isRequired & inspectionTypes & logic
        returnFields = (
          <>
            <Grid>
              <Grid.Column width={10}>
                <Form.Field>
                  <label className="label-item">
                    {t.procedures.form.inspection_type}
                  </label>
                  <Dropdown
                    placeholder={t.procedures.form.inspection_type}
                    fluid
                    selection
                    options={inspectionTypes}
                    value={field.inspectionType}
                    onChange={handleFieldChange(index, 'inspectionType')}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Field>
                  <label className="label-item">
                    {t.procedures.form.failed_value}
                  </label>
                  <Dropdown
                    placeholder={t.procedures.form.failed_value_placeholder}
                    fluid
                    selection
                    disabled={logicAnswersOptions.length < 1}
                    options={logicAnswersOptions}
                    value={field.failedValue}
                    onChange={handleFieldChange(index, 'failedValue')}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>

            {field.logic && (
              <Message warning>
                <div className="flexbox align-center justify-between">
                  <div className="flexbox align-center">
                    <Icon name="code branch" style={{ marginRight: '5px' }} />
                    {t.procedures.form.if_answer_is}
                    <Dropdown
                      style={{ margin: '0 5px' }}
                      inline
                      disabled={logicAnswersOptions.length < 1}
                      options={logicAnswersOptions}
                      value={field.logicAnswer}
                      placeholder={t.procedures.form.if_answer_is_placeholder}
                      onChange={handleFieldChange(index, 'logicAnswer')}
                    />
                    {t.procedures.form.then}
                    <Dropdown
                      style={{ margin: '0 5px' }}
                      inline
                      options={logicTriggers}
                      disabled={logicAnswersOptions.length < 1}
                      value={field.logicTrigger}
                      placeholder={t.procedures.form.then_placeholder}
                      onChange={handleFieldChange(index, 'logicTrigger')}
                    />
                  </div>
                  <div
                    className={'buttun-type-link remove'}
                    basic
                    onClick={handleFieldChange(index, 'logic')}
                  >
                    {t.procedures.form.cancel_button}
                  </div>
                </div>
              </Message>
            )}
            <div style={{ marginTop: '20px' }}>
              {!field.logic && (
                <div
                  className={'buttun-type-link'}
                  basic
                  onClick={handleFieldChange(index, 'logic')}
                >
                  <Icon name="code branch" />
                  {t.procedures.form.add_logic}
                </div>
              )}
            </div>
          </>
        );
        break;
      default:
        returnFields = null;
    }

    return returnFields;
  };

  const createNewProcedureHandler = async () => {
    // console.log(data);
    // console.log("----");
    // console.log(fields);
    const new_fields = fields.map(({ id, ...rest }) => {
      return rest;
    });

    const response = await createNewProcedure(
      String(data.title),
      String(data.description),
      new_fields,
      organisationId
    );

    if (response.status == 200) {
      setButtonStatus(0);
      resetForm();
      untoggle();
      returnAddProcedure(response.data.data.id);
    }
  };

  const cancelHandler = () => {
    resetForm();
    untoggle();
    toggleCancelModal(false);
    setInitChange(false);
  };

  return (
    <>
      <Modal
        size="small"
        open={toggled}
        onClose={() => {
          cancelHandler();
        }}
      >
        <Modal.Header>Add Procedure</Modal.Header>
        <Modal.Content scrolling>
          <Form.Field>
            <label className="label-item">
              {t.procedures.form.procedure_title}
            </label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder={t.procedures.form.procedure_title}
              value={data.title}
              onChange={onValuesChange('title')}
            />
          </Form.Field>
          <Form style={{ marginBottom: '20px' }}>
            <Form.Field>
              <label className="label-item">
                {t.procedures.form.description}
              </label>
              <TextArea
                autoComplete="new-password"
                value={data.description}
                onChange={onValuesChange('description')}
                rows={2}
                fluid
                placeholder={t.procedures.form.description}
              />
            </Form.Field>
          </Form>
          <Divider horizontal>
            {t.procedures.form.procedure_items} ({fields.length})
          </Divider>
          {generatedFields()}

          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className="buttun-type-link"
              style={{ marginRight: '10px' }}
              basic
              onClick={() => addItem(false)}
            >
              <Icon name="add" />
              {t.procedures.form.add_procedure_item}
            </div>

            <div
              className="buttun-type-link"
              basic
              onClick={() => addItem(true)}
            >
              <Icon name="add" />
              {t.procedures.form.add_heading}
            </div>
          </div>
          <Divider />
        </Modal.Content>

        <Modal.Actions>
          <Button
            content={t.procedures.form.submit.add}
            primary
            onClick={createNewProcedureHandler}
            loading={buttonStatus == 1}
          />

          <Button
            content={t.procedures.form.cancel_button}
            basic
            onClick={() =>
              initChange ? toggleCancelModal(true) : cancelHandler()
            }
          />
        </Modal.Actions>
      </Modal>

      {/* Cancel modal */}
      <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => toggleCancelModal(false)}
      >
        <Modal.Content>
          <p>{t.procedures.form.cancel.msg}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            {t.procedures.form.cancel.keep}
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            {t.procedures.form.cancel.cancel}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default AddProcedureModal;
