import React, { createRef, useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import Tooltip from "react-tooltip";
import ReactDOM from 'react-dom';

import {
  Divider,
  Grid,
  Label,
  Image,
  Image as SemanticImage,
  Segment,
  Icon,
  Button,
  Modal,
  Header,
  Form,
  Input,
  TextArea,
  Dropdown,
  GridColumn,
} from "semantic-ui-react";

import {
  getActiveUsersByRole,
  getAllTeams,
  getNotifications,
  getWOByDetails,
  humanize,
  IMAGES_URL,
  updateAssigneeOrder,
  updateWorkOrderAssign,
  updateWorkOrderPrimaryAssign,
} from "../../../config/functions";
import { updateWorkOrderStatus } from "../../../config/functions";
import md5 from "md5";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from "../../../components/common/userProfilePlaceholder";
import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
// import heic2any from "heic2any";
import {
  AreaDetailsIcon,
  ArrowDetailsIcon,
  Attachment,
  CategortDetailsIcon,
  Close,
  CloseIcon,
  CloseIconWO,
  CloseNoteIcon,
  DateDetailsIcon,
  DetailArrowSvg,
  DetailsAssetIcon,
  DownArrow,
  DuedateIcon,
  Fail,
  FileDownloadIcon,
  FlagDetailsIcon,
  FlagWhiteIcon,
  LocationDetailsIcon,
  OrderTypeIcon,
  PrimaryIcon,
  Printer,
  SchoolIcon,
  SearchIcon,
  SecondaryIcon,
  Success,
  TeamsIcon,
} from "../../../../Assets/Icons/svg";

import ReactToPrint from "react-to-print";
import QRComponentToPrint from "../../Locations/preview/qrCodeComponent";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
// import Tooltip from "react-power-tooltip";
import Asterisk from "../../../components/common/Asterisk";
import { scrollToError } from "../../../../utilFunctions/scrollToError";
import moment from "moment";
import { format } from "date-fns";
import useDisplayFormattedText from "../../../../hooks/useDisplayFormattedText";
import AssigneeModal from "./detailsPageComponents/assigneeModal";
import StatusChangeModal from "./detailsPageComponents/statusChangeModal";

const Details = ({
  data,
  setData,
  negative,
  untoggle,
  setRefresh,
  refresh,
  setFilterOptions,
  setSelectedDates,
  setSearchTitle,
  setModalWidth,
  setActiveIndex,
  setTotalPartCount,
  totalPartCount,
}) => {
  const t = useSelector(selectTranslations);
  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  let allQtyOptions = [
    {
      key: 0,
      value: "pieces",
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: "items",
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: "units",
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: "liters (L)",
      text: selectedLanguage == "en" ? "l" : "لتر",
    },
    {
      key: 4,
      value: "gallons (gal)",
      text: selectedLanguage == "en" ? "gal" : "جالون",
    },
    {
      key: 5,
      value: "cubic meters (m³)",
      text: selectedLanguage == "en" ? "m³" : "م³",
    },
    {
      key: 6,
      value: "cubic feet (ft³)",
      text: selectedLanguage == "en" ? "ft³" : "ق³",
    },
    {
      key: 7,
      value: "barrels",
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: "kilograms (kg)",
      text: selectedLanguage == "en" ? "kg" : "كجم",
    },
    {
      key: 9,
      value: "pounds (lb)",
      text: selectedLanguage == "en" ? "lb" : "رطل",
    },

    {
      key: 10,
      value: "tons",
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: "metric tonnes",
      text: t.parts.mass_unit.m_tons,
    },
  ];

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const HandleUnitType = (data) => {
    let unitType;
    if (data) {
      unitType = allQtyOptions.find((el) => el.value == data);
    }
    return data ? unitType?.text : null;
  };

  const [options, setOptions] = useState({
    people: {
      initOptions: [],
      options: [],
    },
    teams: {
      initOptions: [],
      options: [],
    },
    primaryAssignee: {
      initOptions: [],
      options: [],
    },
  });

  const handleSearchChange = (e) => {
    setSearchTeam(e.target.value);

    if (e.target.value) {
      const filtered = options?.teams?.initOptions?.filter((option) =>
        option.text.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setOptions({
        ...options,
        teams: {
          ...options.teams,
          options: filtered,
        },
      });
    } else {
      setOptions({
        ...options,
        teams: {
          ...options.teams,
          options: options?.teams?.initOptions,
        },
      });
    }
  };

  const handleSearchChangePeople = (e) => {
    setSearchPeople(e.target.value);

    if (e.target.value) {
      const filtered = options?.people?.initOptions?.filter((option) =>
        option.text.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setOptions({
        ...options,
        people: {
          ...options.people,
          options: filtered,
        },
      });
    } else {
      setOptions({
        ...options,
        people: {
          ...options.people,
          options: options?.people?.initOptions,
        },
      });
    }
  };
  const handleSearchChangePrimary = (e) => {
    setSearchPrimary(e.target.value);

    if (e.target.value) {
      const filtered = options?.primaryAssignee?.initOptions?.filter((option) =>
        option.text.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setOptions({
        ...options,
        primaryAssignee: {
          ...options.primaryAssignee,
          options: filtered,
        },
      });
    } else {
      setOptions({
        ...options,
        primaryAssignee: {
          ...options.primaryAssignee,
          options: options?.primaryAssignee?.initOptions,
        },
      });
    }
  };

  const handleTeamSelect = async (team) => {
    let teams = [...selectedTeams];

    const isSelected = selectedTeams?.some(
      (selected) => selected.key === team.key
    );

    if (isSelected) {
      // Deselect by filtering out the team
      teams = teams.filter((selected) => selected.key !== team.key);
    } else {
      // Select by adding the team
      teams = [...teams, team];
    }

    await handleRemoveTeams(teams);
  };
  const handlePrimarySelect = async (team) => {
    let teams = selectedPrimaryAssignee;

    const isSelected = teams?.key === team.key;

    if (isSelected) {
      // Deselect by filtering out the team
      teams = null;
    } else {
      // Select by adding the team
      teams = team;
    }

    await handleRemovePrimaryAssignee(teams);
  };

  const handlePeopleSelect = async (team) => {
    let teams = [...selectedPeople];

    const isPrimaryAssignee = selectedPrimaryAssignee?.key === team.key;

    if (isPrimaryAssignee) {
      toast.error(`${team?.text} is already added as the Primary Assignee.`);
      return;
    }

    const isSelected = teams?.some((selected) => selected.key === team.key);

    if (isSelected) {
      // Deselect by filtering out the team
      teams = teams.filter((selected) => selected.key !== team.key);
    } else {
      // Select by adding the team
      teams = [...teams, team];
    }

    await handleRemovePeople(teams);
    // setSelectedTeams((prev) => {
    //   const isSelected = prev.some((selected) => selected.key === team.key);

    //   if (isSelected) {
    //     // Deselect by filtering out the team
    //     return prev.filter((selected) => selected.key !== team.key);
    //   } else {
    //     // Select by adding the team
    //     return [...prev, team];
    //   }
    // });
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownTeamsOpen(false);
      setDropdownPeopleOpen(false);
      setDropdownPrimaryOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [dropdownTeamsOpen, setDropdownTeamsOpen] = useState(false);
  const [dropdownPeopleOpen, setDropdownPeopleOpen] = useState(false);
  const [dropdownPrimaryOpen, setDropdownPrimaryOpen] = useState(false);
  const { DisplayFormattedText } = useDisplayFormattedText();
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [searchTeam, setSearchTeam] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredPrimaryIndex, setHoveredPrimaryIndex] = useState(null);
  const [searchPrimary, setSearchPrimary] = useState("");
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [selectedPrimaryAssignee, setSelectedPrimaryAssignee] = useState({});
  const [searchPeople, setSearchPeople] = useState([]);
  const history = useHistory();
  const [openModal, setModal] = useState(false);
  const qrRef = createRef();
  const [approveModal, setApproveModal] = useState(false);
  const [onHoldFlag, setOnHoldFlag] = useState(false);
  const [closeFlag, setCloseFlag] = useState(false);
  const [openFlag, setOpenFlag] = useState(false);
  const [reOpenFlag, setReOpenFlag] = useState(false);
  const [totalCostTooltip, setTotalCostTooltip] = useState(false);
  const [inProgressFlag, setInProgressFlag] = useState(false);
  const [inCancleFlag, setInCancleFlag] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  // const [totalPartCount, setTotalPartCount] = useState({});
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [showPrintTooltip, setPrintTooltip] = useState(false);
  const [showDownloadTooltip, setDownloadTooltip] = useState(false);

  const dropdownRef = useRef(null);
  const [assignData, setAssignData] = useState({
    people: [],
    teams: [],
  });

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    if (
      ["cancelled"].includes(data?.attributes?.status) ||
      !["super_admin", "admin", "limited_admin"].includes(roleData)
    ) {
      if (
        roleData == "user" &&
        ["inProgress", "onHold"].includes(data?.attributes?.status) &&
        checkValidUser()
      ) {
        setDropdownVisible(!isDropdownVisible);
      } else {
        setDropdownVisible(false);
      }
    } else {
      setDropdownVisible(!isDropdownVisible);
    }
  };

  const getTotalParts = async () => {
    let res = await getWOByDetails(organisationId, data?.id);

    if (res?.length > 0) {
      let partCount =
        res[0]?.attributes?.part?.length + res[0]?.attributes?.set?.length;

      setTotalPartCount({
        partCount:
          res[0]?.attributes?.part?.length + res[0]?.attributes?.set?.length,
        additionalCost: res[0]?.attributes?.additionalCost,
        estimationTime: res[0]?.attributes?.estimationTime,
        actualTime: res[0]?.attributes?.actualTime,
        totalCost: res[0]?.attributes?.totalCost,
        laborCost: res[0]?.attributes?.laborCost,
        subTotal: res[0]?.attributes?.subTotal,
        people: res[0]?.attributes?.people,
        teams: res[0]?.attributes?.teams,
        totalLaborCost: res[0]?.attributes?.laborTotalCost,
        primaryAssignee: res[0]?.attributes?.primaryAssignee,
      });

      if (res[0]?.attributes?.teams?.data?.length > 0) {
        const initialSelectedTeams = res[0]?.attributes?.teams?.data?.map(
          (team) => {
            const the_image = LetteredAvatarImageUrl(team?.attributes?.name);

            return {
              key: team.id,
              value: team.id,
              text: team?.attributes?.name,
              image: { avatar: true, src: the_image, size: "tiny" },
            };
          }
        );

        setSelectedTeams(initialSelectedTeams);
      } else {
        setSelectedTeams([]);
      }

      if (res[0]?.attributes?.people?.data?.length > 0) {
        const initialSelectedTeams = res[0]?.attributes?.people?.data?.map(
          (team) => {
            const the_image = team?.attributes?.profileImage?.data?.attributes
              ?.url
              ? IMAGES_URL +
                team?.attributes?.profileImage?.data?.attributes?.url
              : LetteredAvatarImageUrl(
                  team?.attributes?.firstName + " " + team?.attributes?.lastName
                );

            return {
              key: team?.id,
              value: team?.id,
              text:
                team?.attributes?.firstName +
                " " +
                team?.attributes?.lastName +
                " (" +
                t.people.roles[team?.attributes?.role?.data?.attributes?.name] +
                ")",
              image: { avatar: true, src: the_image, size: "tiny" },
            };
          }
        );

        setSelectedPeople(initialSelectedTeams);
      } else {
        setSelectedPeople([]);
      }

      if (res[0]?.attributes?.primaryAssignee?.data) {
        const team = res[0]?.attributes?.primaryAssignee?.data;

        const the_image = team?.attributes?.profileImage?.data?.attributes?.url
          ? IMAGES_URL + team?.attributes?.profileImage?.data?.attributes?.url
          : LetteredAvatarImageUrl(
              team?.attributes?.firstName + " " + team?.attributes?.lastName
            );

        const initialSelectedTeam = {
          key: team?.id,
          value: team?.id,
          text:
            team?.attributes?.firstName +
            " " +
            team?.attributes?.lastName +
            " (" +
            t.people.roles[team?.attributes?.role?.data?.attributes?.name] +
            ")",
          image: { avatar: true, src: the_image, size: "tiny" },
        };

        setSelectedPrimaryAssignee(initialSelectedTeam);
      } else {
        setSelectedPrimaryAssignee({});
      }
    } else {
      toast.error("Failed to get parts");
    }
  };

  useEffect(() => {
    if(!selectedPrimaryAssignee?.key){

      fetchPeopleOptions()
    }
    getTotalParts();
    fetchTeamsOptions();
    // fetchPeopleOptions();
    fetchPrimaryOptions();
  }, []);

  

  const handleCancel = () => {
    setOpenFlag(false);
    setReOpenFlag(false);
    setApproveModal(false);
    setOnHoldFlag(false);
    setInProgressFlag(false);
    setInCancleFlag(true);
    setModal(true);
    setCloseFlag(false);
  };

  const handleRemoveAssignee = async (id) => {
    let peopleData = totalPartCount?.people?.data;

    let newPeopleData = peopleData
      .filter((person) => person.id != id)
      .map((person) => person.id);

    let response = await updateWorkOrderPrimaryAssign(
      data?.id,
      null,
      null,
      newPeopleData,
      data?.attributes?.orderType
    );

    if (response && response.status === 200) {
      getTotalParts();
    }
    // Implement logic to remove the person
  };

  const handleRemoveTeams = async (teams) => {
    let newPeopleData = teams?.map((person) => person?.key);

    let response = await updateWorkOrderPrimaryAssign(
      data?.id,
      null,
      newPeopleData,
      null,
      data?.attributes?.orderType
    );

    if (response && response.status === 200) {
      getTotalParts();
      // setSelectedDates([])
    }
    // Implement logic to remove the person
  };

  const handleRemovePeople = async (teams) => {
    let newPeopleData = teams?.map((person) => person?.key);

    let response = await updateWorkOrderPrimaryAssign(
      data?.id,
      null,
      null,
      newPeopleData,
      data?.attributes?.orderType
    );

    if (response && response.status === 200) {
      getTotalParts();
      setSelectedDates([]);
    }
    // Implement logic to remove the person
  };

  const handleRemovePrimaryAssignee = async (teams) => {
    let people = [...selectedPeople];

    people = people
      ?.filter((person) => person?.key !== teams?.key)
      .map((person) => person?.key);

    let response = await updateWorkOrderPrimaryAssign(
      data?.id,
      teams?.key ? teams.key : null,
      null,
      teams?.key ?  people : [],
      data?.attributes?.orderType,
      true
    );

    if (response && response.status === 200) {
      getTotalParts();
      setSelectedDates([])
    }
    // Implement logic to remove the person
  };

  const handleClose = () => {
    setOpenFlag(false);
    setReOpenFlag(false);
    setApproveModal(false);
    setOnHoldFlag(false);
    setInProgressFlag(false);
    setInCancleFlag(false);
    setCloseFlag(true);
    setModal(true);
  };

  const handleComplete = () => {
    setOpenFlag(false);
    setReOpenFlag(false);
    setApproveModal(true);
    setCloseFlag(false);
    setOnHoldFlag(false);
    setInProgressFlag(false);
    setInCancleFlag(false);
    setModal(true);
  };
  const handleReopen = () => {
    setOpenFlag(false);
    setApproveModal(false);
    setCloseFlag(false);
    setOnHoldFlag(false);
    setInProgressFlag(false);
    setInCancleFlag(false);
    setModal(true);
    setReOpenFlag(true);
  };
  const handleHold = () => {
    setOpenFlag(false);
    setReOpenFlag(false);
    setApproveModal(false);
    setCloseFlag(false);
    setOnHoldFlag(true);
    setInProgressFlag(false);
    setInCancleFlag(false);
    setModal(true);
  };
  const handleOpen = () => {
    setOpenFlag(true);
    setApproveModal(false);
    setReOpenFlag(false);
    setCloseFlag(false);
    setOnHoldFlag(false);
    setInProgressFlag(false);
    setInCancleFlag(false);
    setModal(true);
  };

  const handleArrowClick = () => {
    // Add your API call here
  };

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const [validation, setValidation] = useState({
    people: {
      error: false,
      pattern: (code) => code != "",
      skipped: false,
      message: t.common.required_field,
    },
  });

  const fetchTeamsOptions = async () => {
    const fetchData = await getAllTeams(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      !(roleData === "super_admin")
        ? data?.attributes?.location?.data?.id
        : data?.attributes?.location?.data?.id
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        teams: {
          initOptions: the_options,
          options: the_options,
        },
      }));

      return the_options;
    }
  };

  const onValuesChange = (name, actualValue) => (event, value) => {
    let currentFieldValue = "";
    const dropdown_fields = ["people", "teams"];

    if (dropdown_fields.includes(name)) {
      currentFieldValue = actualValue || value.value;
      let resulteNewData = {
        ...assignData,
        [name]: actualValue || value.value,
      };

      setAssignData(resulteNewData);
    }
    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
  };

  function formatMillisecondsToHHMM(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000); // Convert to seconds
    const hours = Math.floor(totalSeconds / 3600); // Calculate hours
    const minutes = Math.floor((totalSeconds % 3600) / 60); // Calculate remaining minutes
    const seconds = totalSeconds % 60; // Calculate remaining seconds

    // Format with leading zeros
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds} Hr`;
  }

  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "limited_admin", "admin"],
      organisationId,
      !(roleData === "super_admin")
        ? data?.attributes?.location?.data?.id
        : data?.attributes?.location?.data?.id
    );
  
    if (fetchData.data) {
      // Filter out the primary assignee by ID
      const filteredData = fetchData?.data?.filter(
        (el) => el.id !== selectedPrimaryAssignee?.key
      );
  
      const the_options = filteredData?.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        
        let teams_string_array = el?.teams?.map((one) => one.name);
  
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          description: `${teams_string_array.join(" , ")}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
  
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: {
          initOptions: the_options,
          options: the_options,
        },
      }));
  
      return the_options;
    }
  };

  // const fetchPeopleOptions = async () => {
  //   const fetchData = await getActiveUsersByRole(
  //     ["user", "limited_admin", "admin"],
  //     organisationId,
  //     !(roleData === "super_admin")
  //       ? data?.attributes?.location?.data?.id
  //       : data?.attributes?.location?.data?.id
  //   );
  //   // console.log("THE FETCH USERS", fetchData)
  //   if (fetchData?.data) {
  //     const filteredData = fetchData?.data?.filter(
  //       (el) => el.id !== selectedPrimaryAssignee?.key
  //     );

  //     console.log(filteredData,"sdsdss")
  //     let the_options = filteredData?.data?.map((el) => {
  //       const the_image = el.profileImage?.url
  //         ? IMAGES_URL + el.profileImage?.url
  //         : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
  //       let teams_string_array = el?.teams?.map((one) => one.name);
  //       return {
  //         key: el.id,
  //         value: el.id,
  //         text:
  //           el.firstName +
  //           " " +
  //           el.lastName +
  //           " (" +
  //           t.people.roles[el.role.name] +
  //           ")",
  //         // text: el.firstName + ' ' + el.lastName + ' (' + el.role.name + ')',
  //         description: `${teams_string_array.join(" , ")}`,
  //         image: { avatar: true, src: the_image, size: "tiny" },
  //       };
  //     });
  //     setOptions((prevOptions) => ({
  //       ...prevOptions,
  //       people: {
  //         initOptions: the_options,
  //         options: the_options,
  //       },
  //     }));

  //     return the_options;
  //   }
  // };

useEffect(() => {
  if(selectedPrimaryAssignee?.key){

    fetchPeopleOptions()
  }
}, [selectedPrimaryAssignee])


  const fetchPrimaryOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "limited_admin", "admin"],
      organisationId,
      !(roleData === "super_admin")
        ? data?.attributes?.location?.data?.id
        : data?.attributes?.location?.data?.id
    );
    // console.log("THE FETCH USERS", fetchData)
    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // text: el.firstName + ' ' + el.lastName + ' (' + el.role.name + ')',
          description: `${teams_string_array.join(" , ")}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        primaryAssignee: {
          initOptions: the_options,
          options: the_options,
        },
      }));

      return the_options;
    }
  };
  const calculateCurrentCount = (total, percentage = 0) => {
    let datas = typeof percentage;

    const numerator = total * (Number(percentage) / 100); // Calculate current count

    return `(${numerator}/${total}) ${percentage}%`;
  };
  const handleAssign = async () => {
    let currentValidation = {
      ...validation,
      people: {
        ...validation.people,
        error: validation.people.pattern(assignData.people) ? false : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);

    if (!errorsInData) {
      const response = await updateWorkOrderAssign(
        data.id,
        assignData.teams,
        assignData.people,
        data.attributes.orderType
      );
      if (response && response.status == 200) {
        toast.success(t.workOrders.WO_assign_success);
        untoggle();
        setActiveIndex(0);
        setModalWidth(Math.max(window.innerWidth / 2, 514));
        setAssignData({
          teams: [],
          people: [],
        });
        setRefresh(!refresh);

        const currentPathname = window.location.pathname;

        if (currentPathname == "/work-orders") {
          setSearchTitle("");
          setSelectedDates([]);
          setFilterOptions({
            serialNumber: "",

            people: [],
            teams: [],
            customers: [],
            locations: [],
            createdByUser: [],

            status: [],
            priority: [],
            categories: [],
          });
        }
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(response.error.message);
        } else {
          toast.error(t.common.something_wrong);
        }
      } else {
        toast.error(t.common.something_wrong);
        untoggle();
        setActiveIndex(0);
        setModalWidth(Math.max(window.innerWidth / 2, 514));
      }
    } else {
      scrollToError();
    }
  };

  const statusOptions = [
    {
      value: "open",
      color: "blue",
      label: t.workOrders.work_order_status.open,
    },
    {
      value: "reOpen",
      color: "light-purple",
      label: t.workOrders.work_order_status.reOpen,
    },
    {
      value: "cancelled",
      color: "darkgrey",
      label: t.workOrders.work_order_status.cancel,
    },
    {
      value: "inProgress",
      color: "yellow",
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: "onHold",
      color: "red",
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: "inReview",
      color: "green",
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: "completed",
      color: "darkblue",
      label: t.workOrders.work_order_status.completed,
    },
  ];
  const priorityOptions = [
    {
      value: "none",
      label: t.workOrders.priority_status.none,
      color: null,
    },
    {
      value: "low",
      label: t.workOrders.priority_status.low,

      color: "yellow",
    },
    {
      value: "medium",
      label: t.workOrders.priority_status.medium,

      color: "orange",
    },
    {
      value: "high",
      label: t.workOrders.priority_status.high,

      color: "red",
    },
  ];

  const workOrderPriorityColor = priorityOptions.find(
    (pro) => pro.value == data?.attributes.priority
  );

  const workOrderStatus = statusOptions.find(
    (status) => status.value == data?.attributes.status
  );

  const codeOptions = [
    {
      value: "sop",
      label: t.workOrders.work_order_category.Sop,
    },

    {
      value: "project",
      label: t.workOrders.work_order_category.Project,
    },
    {
      value: "preventive",
      label: t.workOrders.work_order_category.Preventive,
    },
    {
      value: "inspection",
      label: t.workOrders.work_order_category.Inspection,
    },
    {
      value: "damage",
      label: t.workOrders.work_order_category.Damage,
    },
  ];

  const categoryOptions = [
    {
      value: "damage",
      label: t.workOrders.form.category_options[0],
    },
    {
      value: "corrective action Downtime",
      label: t.workOrders.form.category_options[1],
    },
    {
      value: "planned Downtime",
      label: t.workOrders.form.category_options[2],
    },
    {
      value: "unplanned Downtime",
      label: t.workOrders.form.category_options[3],
    },
    {
      value: "inspection",
      label: t.workOrders.form.category_options[4],
    },
    {
      value: "electrical",
      label: t.workOrders.form.category_options[5],
    },
    {
      value: "mechanical",
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: "HVAC",
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: "preventive maintenance",
      label: t.workOrders.form.category_options[8],
    },
    {
      value: "project",
      label: t.workOrders.form.category_options[9],
    },
    {
      value: "safety",
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: "sop",
      label: t.workOrders.form.category_options[11],
    },
  ];

  const renderAssigneePeople = (assigneePeople) => {
    if (assigneePeople?.length > 0) {
      return assigneePeople.map((people, index) => {
        if (people?.attributes?.profileImage?.data?.attributes?.url) {
          return (
            <p className="mb-3">
              <Image
                key={index}
                avatar
                title={`${people.attributes.firstName} ${people.attributes.lastName}`}
                src={`${people?.attributes?.profileImage?.data?.attributes?.url}`}
                style={
                  index !== 0
                    ? selectedLanguage == "ar"
                      ? { right: "-6px", width: "34px", height: "34px" }
                      : { left: "-6px", width: "34px", height: "34px" }
                    : selectedLanguage === "ar"
                    ? { width: "34px", height: "34px", left: "5px" }
                    : { width: "34px", height: "34px", right: "5px" }
                }
              />
              {checkForLinkPermission("people") ? (
                <span
                  onClick={() =>
                    history.push(`/people?id=${people.attributes?.countId}`)
                  }
                  className="detail-intro-link"
                >
                  {people.attributes.firstName +
                    " " +
                    people.attributes.lastName}
                </span>
              ) : (
                <span className="">
                  {people.attributes.firstName +
                    " " +
                    people.attributes.lastName}
                </span>
              )}
            </p>
          );
        } else {
          return (
            <p className="mb-3">
              <UserProfilePlaceholder
                key={index}
                name={`${
                  people?.attributes?.firstName
                    ? people?.attributes?.firstName
                    : ""
                } ${
                  people?.attributes?.lastName
                    ? people?.attributes?.lastName
                    : ""
                }`}
                width={"34px"}
                height={"34px"}
                right={selectedLanguage == "en" ? "6px" : "-5px"}
              />
              <div className="hover-container">
                {checkForLinkPermission("people") ? (
                  <span
                    onClick={() =>
                      history.push(`/people?id=${people.attributes?.countId}`)
                    }
                    className="detail-intro-link"
                  >
                    {people.attributes.firstName +
                      " " +
                      people.attributes.lastName}
                  </span>
                ) : (
                  <span className="hover-name">
                    {people.attributes.firstName +
                      " " +
                      people.attributes.lastName}
                  </span>
                )}
                {/* Close Icon */}
                {["open", "reOpen"]?.includes(
                  data?.attributes?.status
                ) ? (
                  <span
                    className="close-icon c-pointer"
                    onClick={() => handleRemoveAssignee(people?.id)}
                  >
                    ✖
                  </span>
                ) : null}
              </div>
            </p>
          );
        }
      });
    } else {
      return (
        <span style={selectedLanguage == "en" ? { marginLeft: "8px" } : {}}>
          {t.common.na}
        </span>
      );
    }
  };

  const categoryStatus = categoryOptions.find(
    (status) => status.value == data?.attributes?.category
  );

  const codeStatus = codeOptions.find(
    (status) => status.value == data?.attributes?.code
  );

  let customerName = t.common.na;

  if (data?.attributes?.customer?.data) {
    customerName = (
      <div
        className="d-flex"
        onClick={() =>
          history.push(
            `/customers?id=${data?.attributes?.customer?.data?.attributes?.countId}`
          )
        }
      >
        <Image
          avatar
          title={data?.attributes.customer?.data?.attributes.name}
          src={LetteredAvatarImageUrl(
            data?.attributes.customer?.data?.attributes?.name
          )}
        />
        <div
          className="work-order-link mt-1"
          basic
          style={{ marginLeft: "4px" }}
        >
          {data?.attributes.customer?.data?.attributes.name}
          {selectedLanguage == "en" ? (
            <Icon name="angle right" />
          ) : (
            <Icon name="angle left" />
          )}
        </div>
      </div>
    );
  } else {
    customerName = t.common.na;
  }

  let createdByName = data?.attributes.createdByUser.data ? (
    <div>
      <Image
        avatar
        title={
          data?.attributes.createdByUser?.data?.attributes?.firstName +
          " " +
          data?.attributes.createdByUser?.data?.attributes?.lastName
        }
        src={
          data?.attributes?.createdByUser?.data?.attributes?.profileImage?.data
            ?.attributes?.url
            ? `${IMAGES_URL}${data?.attributes?.createdByUser?.data?.attributes?.profileImage?.data?.attributes?.url}`
            : LetteredAvatarImageUrl(
                data?.attributes.createdByUser?.data?.attributes?.firstName +
                  " " +
                  data?.attributes.createdByUser?.data?.attributes?.lastName
              )
        }
      />
      <span style={{ marginLeft: "4px" }}>
        {data?.attributes.createdByUser?.data?.attributes?.firstName +
          " " +
          data?.attributes.createdByUser?.data?.attributes?.lastName}
      </span>
    </div>
  ) : (
    t.common.na
  );

  const generateDays = (
    sun = false,
    mon = false,
    tue = false,
    wed = false,
    thu = false,
    fri = false,
    sat = false
  ) => {
    let returnDays = [];
    if (sun)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.sun}
        />
      );
    if (mon)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.mon}
        />
      );
    if (tue)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.tue}
        />
      );
    if (wed)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.wed}
        />
      );
    if (thu)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.thu}
        />
      );
    if (fri)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.fri}
        />
      );
    if (sat)
      returnDays.push(
        <Label
          color="green"
          size="medium"
          style={{
            height: "auto",
            display: "inline-block",
            borderRadius: ".28571429rem",
          }}
          content={t.workOrders.details_view.sat}
        />
      );

    return returnDays;
  };

  const monthDay = () => {
    let monthsDays = [];

    for (let i = 1; i < 32; i++) {
      let day_th = "th";
      if (i == 1) day_th = "st";
      if (i == 2) day_th = "nd";
      if (i == 3) day_th = "rd";

      const obj = {
        key: i,
        value: "d" + i,
        text: i > 28 ? i + day_th + " (or last)" : i + day_th,
      };

      monthsDays.push(obj);
    }

    return monthsDays.filter(
      (month) => month.value == data?.attributes.repeatMonthDay
    )[0].text;
  };

  const plannedValue = () => {
    let returnData = [];
    returnData.push(
      <span>{t.workOrders.details_view[data?.attributes.repeatEvery]}</span>
    );

    switch (data?.attributes.repeatEvery) {
      case "weekly":
        returnData.push(
          <span>
            , {t.workOrders.details_view.every}{" "}
            {data?.attributes.repeatEveryWeek}{" "}
            {` ${
              data?.attributes.repeatEveryWeek > 0
                ? t.workOrders.details_view.weeks
                : t.workOrders.details_view.week
            }`}
          </span>
        );
        returnData.push(
          <div style={{ marginTop: "5px" }}>
            On{" "}
            {generateDays(
              data?.attributes.daysRepeat?.sun,
              data?.attributes.daysRepeat?.mon,
              data?.attributes.daysRepeat?.tue,
              data?.attributes.daysRepeat?.wed,
              data?.attributes.daysRepeat?.thu,
              data?.attributes.daysRepeat?.fri,
              data?.attributes.daysRepeat?.sat
            )}
          </div>
        );

        returnData.push(
          <div style={{ marginTop: "5px" }}>
            <span style={{ marginRight: "2px" }}>
              {t.workOrders.details_view.expire_after}
            </span>
            {data?.attributes.endRepeat}
            <span style={{ marginLeft: "2px" }}>
              {" "}
              {t.workOrders.details_view.orders}
            </span>
          </div>
        );
        break;

      case "monthly":
        returnData.push(
          <span>
            , {t.workOrders.details_view.every}{" "}
            {data?.attributes.repeatEveryMonth}{" "}
            {` ${
              data?.attributes.repeatEveryMonth > 0
                ? t.workOrders.details_view.months
                : t.workOrders.details_view.month
            }`}
          </span>
        );

        returnData.push(
          <div style={{ marginTop: "5px" }}>
            {` ${t.workOrders.details_view.on} `} {monthDay()}
          </div>
        );

        returnData.push(
          <div style={{ marginTop: "5px" }}>
            <span style={{ marginRight: "2px" }}>
              {" "}
              {t.workOrders.details_view.expire_after}
            </span>
            {data?.attributes.endRepeat}
            <span style={{ marginLeft: "2px" }}>
              {" "}
              {t.workOrders.details_view.orders}
            </span>
          </div>
        );
        break;

      case "yearly":
        returnData.push(
          <span>
            , {t.workOrders.details_view.every}{" "}
            {data?.attributes.repeatEveryYear}{" "}
            {` ${
              data?.attributes.repeatEveryYear > 0
                ? t.workOrders.details_view.years
                : t.workOrders.details_view.year
            }`}
          </span>
        );

        returnData.push(
          <div style={{ marginTop: "5px" }}>
            <span style={{ marginRight: "2px" }}>
              {" "}
              {t.workOrders.details_view.expire_after}
            </span>
            {data?.attributes.endRepeat}
            <span style={{ marginLeft: "2px" }}>
              {" "}
              {t.workOrders.details_view.orders}
            </span>
          </div>
        );
        break;

      default:
        return null;
    }

    return returnData;
  };

  const checkValidUser = () => {
    if (roleData === "user") {
      if (data?.attributes?.people?.data?.length > 0) {
        return data?.attributes?.people?.data?.some(
          (item) => item?.id === currentUserId
        );
      }

      if (data?.attributes?.teams?.data?.length > 0) {
        return data.attributes.teams.data.some((team) =>
          team?.attributes?.users?.data?.some(
            (user) => user?.id === currentUserId
          )
        );
      }
    }

    return false;
  };

  const confirmDeclineUser = () => {
    // if (data?.declined && !data?.confirmed) {
    //   return (
    //     <Label size="medium" basic color="red" style={{ height: 'auto' }}>
    //       <Icon name="dont" color="red" /> Declined
    //     </Label>
    //   );
    // }

    if (
      userPermissions["work-order"]?.edit &&
      data.attributes.status === "inReview"
    ) {
      return (
        <>
          <div className="comment d-flex justify-content-between align-items-center p-5 approve-container">
            <div className="">
              <span className="user-img">
                {t.workOrders.table.createdByUser}{" "}
                {data?.attributes?.createdByUser?.data?.attributes?.firstName ||
                data?.attributes?.createdByUser?.data?.attributes?.lastName
                  ? data?.attributes?.createdByUser?.data?.attributes
                      ?.firstName +
                    " " +
                    data?.attributes?.createdByUser?.data?.attributes?.lastName
                  : t.common.na}
                <p className="item-label font-weight-bold">
                  {/* <span >{`${one?.user?.data?.attributes?.firstName ||
                      ''} ${one?.user?.data?.attributes?.lastName ||
                      ''}`}</span> */}
                  <Moment
                    format="DD/MM/YYYY LT"
                    date={data?.attributes?.createdAt}
                  />
                </p>
              </span>
              {/* <div className="d-flex">
                <Label
                  circular
                  empty
                  color={workOrderStatus?.color || 'black'}
                  style={{ marginRight: '5px' }}
                  size="big"
                />
                {workOrderStatus?.label || one.status}
              </div> */}
            </div>
            <div className="approval-button-container ">
              <button
                className="modal-form-action-button decline "
                onClick={() => {
                  setApproveModal(false);
                  setOnHoldFlag(false);
                  setInCancleFlag(false);
                  setInProgressFlag(false);
                  setModal(true);
                }}
              >
                {t.workOrders.details_view.decline}
              </button>

              <button
                onClick={() => {
                  setApproveModal(true);
                  setOnHoldFlag(false);
                  setInCancleFlag(false);
                  setInProgressFlag(false);
                  setModal(true);
                }}
                className="modal-form-action-button blue"
              >
                {t.workOrders.details_view.approve}
              </button>
            </div>

            {/* {one?.image?.data && one?.image?.data.length
                ? one?.image?.data.map((el) => {
                    return (
                      <List divided relaxed>
                        <List.Item
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            window.open(
                              IMAGES_URL + el?.attributes.url,
                              '_blank'
                            );
                          }}
                        >
                          <List.Icon
                            name={
                              el?.attributes.mime.match('image.*')
                                ? 'image'
                                : el?.attributes.mime.match('pdf')
                                ? 'file pdf'
                                : 'file'
                            }
                            size="large"
                            color="black"
                            verticalAlign="middle"
                          />
                          <List.Content>
                            <p className="font-weight-bold ml-2 mr-2">
                              {el?.attributes.name}
                            </p>
                          </List.Content>
                        </List.Item>
                      </List>
                    );
                  })
                : null} */}
          </div>
        </>
        // <div>
        //   <Button
        //     color="green"
        // onClick={() => {
        //   setApproveModal(true);
        //   setOnHoldFlag(false);
        //   setInProgressFlag(false);
        //   setModal(true);
        // }}
        //     // loading={approveButton > 0}
        //   >
        //     {t.workOrders.details_view.approve}
        //   </Button>
        //   <Button
        //     negative
        //     onClick={() => {
        //       setApproveModal(false);
        //       setOnHoldFlag(false);
        //       setInProgressFlag(false);
        //       setModal(true);
        //     }}
        //   >
        //     {t.workOrders.details_view.decline}
        //   </Button>
        // </div>
      );
    } else if (data.attributes.status === "inProgress") {
      return (
        <div className="hold-button-container">
          <button
            // content={t.procedures.form.submit.add}
            // primary
            onClick={() => {
              setApproveModal(false);
              setOnHoldFlag(true);
              setInProgressFlag(false);
              setInCancleFlag(false);
              setModal(true);
            }}
            // loading={buttonStatus == 1}

            className="form-action-button mt-5  blue"
          >
            {t.workOrders.work_order_status.hold}
          </button>
        </div>
        // <div>
        //   <Button
        //     negative
        //     onClick={() => {
        //       setApproveModal(false);
        //       setOnHoldFlag(true);
        //       setInProgressFlag(false);
        //       setModal(true);
        //     }}
        //   >
        //     {t.workOrders.work_order_status.onHold}
        //   </Button>
        // </div>
      );
    } else if (data.attributes.status === "onHold") {
      return (
        <div className="hold-button-container">
          <button
            // content={t.procedures.form.submit.add}
            // primary
            onClick={() => {
              setApproveModal(false);
              setOnHoldFlag(false);
              setInCancleFlag(false);
              setInProgressFlag(true);
              setModal(true);
            }}
            // loading={buttonStatus == 1}
            style={{
              // marginTop: "-10px",
              marginRight: "5px",
            }}
            className="form-action-button blue mt-5"
          >
            {t.workOrders.work_order_status.inProgress}
          </button>
        </div>
        // <div>
        //   <Button
        //     primary
        //     onClick={() => {
        //       setApproveModal(false);
        //       setOnHoldFlag(false);
        //       setInProgressFlag(true);
        //       setModal(true);
        //     }}
        //   >
        //     {t.workOrders.work_order_status.inProgress}
        //   </Button>
        // </div>
      );
    } else if (
      userPermissions["work-order"]?.edit &&
      data.attributes.status === "open"
    ) {
      return (
        <div className="hold-button-container">
          {!data?.attributes?.people?.data.length > 0 ? (
            <button
              // content={t.procedures.form.submit.add}
              // primary
              onClick={() => {
                setAssignModal(true);
              }}
              // loading={buttonStatus == 1}

              className="form-action-button mt-5 blue"
            >
              {t.workOrders.assign}
            </button>
          ) : null}

          {/* <button
            // content={t.procedures.form.submit.add}
            // primary
            onClick={() => {
              setApproveModal(false);
              setOnHoldFlag(false);
              setInProgressFlag(false);
              setInCancleFlag(true);
              setModal(true);
            }}
            // loading={buttonStatus == 1}

            className="form-action-button mt-5 grey"
          >
            {t.common.cancel}
          </button> */}
        </div>
        // <div>
        //   <Button
        //     primary
        //     onClick={() => {
        //       setApproveModal(false);
        //       setOnHoldFlag(false);
        //       setInProgressFlag(true);
        //       setModal(true);
        //     }}
        //   >
        //     {t.workOrders.work_order_status.inProgress}
        //   </Button>
        // </div>
      );
    }
  };

  const handleSave = async () => {
    if ((inCancleFlag || openFlag || reOpenFlag) && !message) {
      toast.error(t.workOrders.submit_error);
      return;
    }
    setLoading(true);
    let res = await updateWorkOrderStatus(
      data.id,
      inCancleFlag
        ? "cancelled"
        : inProgressFlag
        ? "inProgress"
        : reOpenFlag
        ? "reOpen"
        : approveModal
        ? "inReview"
        : closeFlag
        ? "completed"
        : openFlag
        ? "open"
        : "onHold",
      message,
      data?.attributes?.orderType
    );

    setModal(false);
    setMessage("");
    setLoading(false);
    if (res && res.status == 200) {
      window.dispatchEvent(new Event("triggerNotificationRefresh"));
      toast.success(
        inCancleFlag
          ? t.workOrders.success_cancel
          : inProgressFlag
          ? t.workOrders.success_progress
          : reOpenFlag
          ? t.workOrders.success_reOpen
          : approveModal
          ? t.workOrders.success_approve
          : closeFlag
          ? t.workOrders.success_decline
          : openFlag
          ? t.workOrders.success_open
          : t.workOrders.success_hold
      );

      // await getNotifications(currentUserId);

      toggleDeleteSuccessModal("close");
    } else if (res.status == 400) {
      if (res?.error && res?.error?.message) {
        toast.error(res?.error.message);
      } else {
        toast.error(t.common.something_wrong);
      }
    } else {
      toast.error(t.common.something_wrong);
      setModal(false);
      setMessage("");
      setLoading(false);
    }
  };
  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    const currentPathname = window.location.pathname;
    if (data) {
      untoggle();
      setActiveIndex(0);
      setModalWidth(Math.max(window.innerWidth / 2, 514));
      setRefresh(!refresh);

      if (currentPathname == "/work-orders") {
        setSearchTitle("");
        setSelectedDates([]);
        setFilterOptions({
          serialNumber: "",

          people: [],
          teams: [],
          customers: [],
          locations: [],
          createdByUser: [],

          status: [],
          priority: [],
          categories: [],
        });
      }
      setOnHoldFlag(false);
      setInCancleFlag(false);
      setApproveModal(false);
    }
  };

  // checkForLinkPermission("team") ? (
  //   <div className="d-flex mb-5">
  //     <Image
  //       avatar
  //       title={team?.attributes.name}

  //       src={LetteredAvatarImageUrl(team?.attributes.name)}
  //       height="34px"
  //       width="34px"
  //     />

  //     {/* <div
  //       style={{ marginLeft: "4px" }}
  //       onClick={() =>
  //         history.push(`/teams?id=${team?.attributes?.countId}`)
  //       }
  //       className="work-order-link mt-1 "
  //     >
  //       {team?.attributes?.name ? team.attributes.name : t.common.na}
  //     </div> */}
  //   </div>
  // ) :

  let assetData = data.attributes?.assets?.data;
  let assetString = "";

  if (assetData?.length > 0) {
    assetString = assetData.map((each) => each.attributes?.name).join(", ");
  } else {
    assetString = t.common.na;
  }
  let addedPartsData = data?.attributes?.report?.data?.attributes?.parts;
  let addedcostData = "";
  let addedpartData = "";

  if (addedPartsData?.length > 0) {
    addedcostData = addedPartsData.map((each, index) => {
      return (
        <div>
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.form.added_cost + (index + 1)}
            </label>
            <p className="workorder-intro-value set-width">
              {each?.amount ? `${each.amount}` : 0} {organisationCurrency}
            </p>
          </div>
        </div>
      );
    });
  } else {
    addedcostData = (
      <div>
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.added_cost}
          </label>
          <p className="workorder-intro-value set-width">{t.common.na}</p>
        </div>
      </div>
    );
  }

  if (addedPartsData?.length > 0) {
    addedpartData = addedPartsData?.map((each, index) => {
      return (
        <div>
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.form.added_parts + (index + 1)}
            </label>
            <p className="workorder-intro-value set-width">
              {each?.part?.data?.attributes?.name
                ? each?.part?.data?.attributes?.name
                : t.common.na}
            </p>
          </div>
        </div>
      );
    });
  } else {
    addedpartData = (
      <div>
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.added_parts}
          </label>
          <p className="workorder-intro-value set-width">{t.common.na}</p>
        </div>
      </div>
    );
  }
  // {console.log(data?.attributes?.dueDate ,data?.attributes?.dueTime ,"sjsds")}

  const teamProfiles =
    selectedTeams?.length > 0 ? (
      <>
        {selectedTeams.length < 5 ? (
          selectedTeams.map((team, index) => (
            <div
              key={team.key}
              className={`primary-profile-container ${
                [
                  "open",
                  "reOpen",
                ].includes(data?.attributes?.status) ? "hover-div" : ""
              }`}
              style={{
                marginRight: index !== 0 ? "-11px" : "0",
              }}
            >
              <div
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Image
                  avatar
                  data-tip
                  data-for={`tooltip-team-${index}`}
                  src={team?.image?.src}
                  style={{
                    // width: index === 0 ? "34px" : "44px",
                    width: selectedLanguage === "en" && index !== 0 ? "44px" : "34px",
                    height: "34px",
                    right:
                      selectedLanguage === "ar" && index !== 0
                        ? "0px"
                        : index === 0 && selectedLanguage === "en"
                        ? "-8px"
                        : "-9px",
                  }}
                  className={index === 0 ? "img-first" : ""}
                />
                {hoveredIndex === index && (
                  <Tooltip
                    id={`tooltip-team-${index}`}
                    place="top"
                    effect="solid"
                  >
                    {team.text}
                  </Tooltip>
                )}
              </div>
              {/* X Icon */}
              <span
                className="close-icon-wo"
                style={{
                  top: index === 0 ? (selectedLanguage === "ar" ? "-9px" : "-12px") : "-12px",
                  right: index === 0 ? (selectedLanguage === "ar" ? "3px" : "-7px") : "5px",
                  cursor:"pointer"
                 
                }}
                onClick={(e) => {
                  if (
                    [
                      "open",
                      "reOpen",
                     
                    ].includes(data?.attributes?.status)
                  ){

                    e.stopPropagation();
                    handleTeamSelect(team);
                  }
                }}
              >
                <CloseIconWO />
              </span>
            </div>
          ))
        ) : (
          <>
            {selectedTeams.slice(0, 4).map((team, index) => (
              <div
                key={team.key}
                className={`primary-profile-container ${
                  [
                    "open",
                    "reOpen",
                  ].includes(data?.attributes?.status) ? "hover-div" : ""
                }`}
                style={{
                  marginRight: index !== 0 ? "-11px" : "0",
                }}
              >
                <div
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <Image
                    avatar
                    data-tip
                    data-for={`tooltip-team-${index}`}
                    src={team?.image?.src}
                    style={{
                      // width: index === 0 ? "34px" : "44px",
                      width: selectedLanguage === "en" && index !== 0 ? "44px" : "34px",
                      height: "34px",
                      right:
                        selectedLanguage === "ar" && index !== 0
                          ? "0px"
                          : index === 0 && selectedLanguage === "en"
                          ? "-8px"
                          : "-9px",
                    }}
                    className={index === 0 ? "img-first" : ""}
                  />

                  {hoveredIndex === index && (
                    <Tooltip
                      id={`tooltip-team-${index}`}
                      place="top"
                      effect="solid"
                    >
                      {team.text}
                    </Tooltip>
                  )}
                </div>
                {/* X Icon */}
                <span
                  className="close-icon-wo"
                  style={{
                    top: index === 0 ? (selectedLanguage === "ar" ? "-9px" : "-12px") : "-12px",
                    right: index === 0 ? (selectedLanguage === "ar" ? "3px" : "-7px") : "5px",
                    cursor:"pointer"
                   
                  }}
                  onClick={(e) => {
                    if (
                      [
                        "open",
                        "reOpen",
                       
                      ].includes(data?.attributes?.status)
                    ){
                    e.stopPropagation();
                    handleTeamSelect(team);
                    }
                  }}
                >
                  <CloseIconWO />
                </span>
              </div>
            ))}

<div
              onMouseEnter={() => setHoveredIndex("overflowTeam")}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{ position: "relative" }}
            >
              <Image
                key="overflow"
                 className="more-image"
                avatar
                src={`https://ui-avatars.com/api/?name=${selectedTeams?.length -
                  4}%2B&background=8CCA9D&bold=true&color=FFFFFF`}
                style={{
                  width: selectedLanguage === "en"  ? "44px" : "34px",
                  height: "34px",
                  right: "-8px",
                }}
                data-tip
                data-for="overflow-tooltip"
              />
              {hoveredIndex === "overflowTeam" &&
                ReactDOM.createPortal(
                  <Tooltip
                    id="overflow-tooltip"
                    place="top"
                    effect="solid"
                    style={{
                      position: "absolute",
                      top: "-40px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 1050,
                    }}
                  >
                    {selectedTeams
                      .slice(4)
                      .map((team) => team.text)
                      .join(", ")}
                  </Tooltip>,
                  document.body
                )}
              {/* {hoveredIndex === 'overflow' && (
              <Tooltip id="overflow-tooltip" place="top" effect="solid">
                {selectedPeople
                  .slice(3)
                  .map((team) => team.text)
                  .join(", ")}
              </Tooltip>
            )} */}
            </div>
            
            {/* <Image
              key="overflow"
              avatar
              title={`+${selectedTeams?.length - 4}`}
              src={`https://ui-avatars.com/api/?name=${selectedTeams?.length -
                4}%2B&background=8CCA9D&bold=true&color=FFFFFF`}
              style={{
                width: "34px",
                height: "34px",
                right: "-8px",
              }}
            /> */}
          </>
        )}
      </>
    ) : (
      <span className="ml-3 mr-3">{t.common.na}</span>
    );

  const peopleProfiles =
    selectedPeople?.length > 0 ? (
      <>
        {selectedPeople.length < 4 ? (
          selectedPeople.map((team, index) => (
            <div
              key={team.key}
              className={`primary-profile-container ${
               ( [
                  "open",
                  "reOpen",
                ].includes(data?.attributes?.status) && selectedPrimaryAssignee?.key) ? "hover-div" : ""
              }`}
              style={{
                marginRight: index !== 0 ? "-11px" : "0",
              }}
            >
              <div
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Image
                  avatar
                  data-tip
                  data-for={`tooltip-${index}`}
                  src={team?.image?.src}
                  style={{
                    // width: index === 0 ?  "34px" : "44px",
                    width: selectedLanguage === "en" && index !== 0 ? "44px" : "34px",
                    height: "34px",
                    right:
                      selectedLanguage === "ar" && index !== 0
                        ? "0px"
                        : index === 0 && selectedLanguage === "en"
                        ? "-8px"
                        : "-9px",
                  }}
                  className={index === 0 ? "img-first" : ""}
                />
                {hoveredIndex === index && (
                  <Tooltip id={`tooltip-${index}`} place="top" effect="solid">
                    {team.text}
                  </Tooltip>
                )}
              </div>

              <span
                className="close-icon-wo c-pointer"
                style={{
                  top: index === 0 ? (selectedLanguage === "ar" ? "-9px" : "-12px") : "-12px",
                  right: index === 0 ? (selectedLanguage === "ar" ? "3px" : "-7px") : "5px",
                 
                }}
                onClick={(e) => {
                  if (
                    ( [
                      "open",
                      "reOpen",
                    ].includes(data?.attributes?.status) && selectedPrimaryAssignee?.key)
                  ){
                  e.stopPropagation();
                  handlePeopleSelect(team);
                  }
                }}
              >
                <CloseIconWO />
              </span>
            </div>
          ))
        ) : (
          <>
            {selectedPeople?.slice(0, 3).map((team, index) => (
              <div
              className={`primary-profile-container ${
                ( [
                  "open",
                  "reOpen",
                ].includes(data?.attributes?.status) && selectedPrimaryAssignee?.key) ? "hover-div" : ""
              }`}
                key={team.key}
                style={{
                  marginRight: index !== 0 ? "-11px" : "0",
                }}
              >
                <div
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <Image
                    avatar
                    data-tip
                    data-for={`tooltip-${index}`}
                    src={team?.image?.src}
                    style={{
                      // width: index === 0 ? "34px" : "44px",
                      width: selectedLanguage === "en" && index !== 0 ? "44px" : "34px",
                      height: "34px",
                      right:
                        selectedLanguage === "ar" && index !== 0
                          ? "0px"
                          : index === 0 && selectedLanguage === "en"
                          ? "-8px"
                          : "-9px",
                    }}
                    className={index === 0 ? "img-first" : ""}
                  />
                  {hoveredIndex === index && (
                    <Tooltip id={`tooltip-${index}`} place="top" effect="solid">
                      {team.text}
                    </Tooltip>
                  )}
                </div>

                <span
                  className="close-icon-wo"
                  style={{
                    top: index === 0 ? (selectedLanguage === "ar" ? "-9px" : "-12px") : "-12px",
                    right: index === 0 ? (selectedLanguage === "ar" ? "3px" : "-7px") : "5px",
                    cursor:"pointer"
                   
                  }}
                  onClick={(e) => {
                    if (
                      ( [
                        "open",
                        "reOpen",
                      ].includes(data?.attributes?.status) && selectedPrimaryAssignee?.key)
                    ) {
                      e.stopPropagation();
                      handlePeopleSelect(team);
                    }
                  }}
                  // onClick={(e) => {
                    
                  
                  // }}
                >
                  <CloseIconWO />
                </span>
              </div>
            ))}

            <div
              onMouseEnter={() => setHoveredIndex("overflow")}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{ position: "relative" }}
            >
              <Image
                key="overflow"
                className="more-image"
                avatar
                src={`https://ui-avatars.com/api/?name=${selectedPeople?.length -
                  3}%2B&background=8CCA9D&bold=true&color=FFFFFF`}
                style={{
                  // width: "44px",
                  width: selectedLanguage === "en"  ? "44px" : "34px",
                  height: "34px",
                  right: "-8px",
                }}
                data-tip
                data-for="overflow-tooltip"
              />
              {hoveredIndex === "overflow" &&
                ReactDOM.createPortal(
                  <Tooltip
                    id="overflow-tooltip"
                    place="top"
                    effect="solid"
                    style={{
                      position: "absolute",
                      top: "-40px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 1050,
                    }}
                  >
                    {selectedPeople
                      .slice(3)
                      .map((team) => team.text)
                      .join(", ")}
                  </Tooltip>,
                  document.body
                )}
              {/* {hoveredIndex === 'overflow' && (
              <Tooltip id="overflow-tooltip" place="top" effect="solid">
                {selectedPeople
                  .slice(3)
                  .map((team) => team.text)
                  .join(", ")}
              </Tooltip>
            )} */}
            </div>
          </>
        )}
      </>
    ) : (
      <span className="ml-3 mr-3">{t.common.na}</span>
    );
  // const peopleProfiles =
  //   selectedPeople?.length > 0 ? (
  //     <>
  //       {selectedPeople.length < 4 ? (
  //         selectedPeople.map((team, index) => (
  //           <div
  //             key={team.key}
  //                className="primary-profile-container"
  //             style={{

  //               marginRight: index !== 0 ? "-11px" : "0",
  //             }}
  //           >

  //             <Image
  //               avatar
  //               title={team.text}
  //               src={team?.image?.src}
  //               style={{
  //                 width: index === 0 ? "34px" : "44px",
  //                 height: "34px",
  //                 right:
  //                   selectedLanguage === "ar" && index !== 0
  //                     ? "0px"
  //                     : index === 0 && selectedLanguage === "en"
  //                     ? "-8px"
  //                     : "-9px",
  //               }}
  //               className={index === 0 ? "img-first" : ""}
  //             />

  //              <span className="close-icon-wo"
  //               style={{

  //                 top: "-12px",
  //                 right: `${index === 0 ? "-7px" : "5px"}`,

  //                 cursor: "pointer",

  //               }}
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 handlePeopleSelect(team);
  //               }}
  //             >
  //              <CloseIconWO />
  //             </span>
  //           </div>
  //         ))
  //       ) : (
  //         <>
  //           {selectedPeople?.slice(0, 3).map((team, index) => (
  //             <div
  //              className="primary-profile-container"
  //               key={team.key}
  //               style={{

  //                 marginRight: index !== 0 ? "-11px" : "0",
  //               }}
  //             >
  //               <Image
  //                 avatar
  //                 title={team.text}
  //                 src={team?.image?.src}
  //                 style={{
  //                   width: index === 0 ? "34px" : "44px",
  //                   height: "34px",
  //                   right:
  //                     selectedLanguage === "ar" && index !== 0
  //                       ? "0px"
  //                       : index === 0 && selectedLanguage === "en"
  //                       ? "-8px"
  //                       : "-9px",
  //                 }}
  //                 className={index === 0 ? "img-first" : ""}
  //               />
  //               {/* <UserProfilePlaceholder
  //               avatar
  //               name={team.text}
  //               width={index == 0 ? "34px" : "43px"}
  //               height={"34px"}
  //               right={
  //                 selectedLanguage === "ar" && index !== 0
  //                   ? "0px"
  //                   : index === 0 && selectedLanguage === "en"
  //                   ? "-8px"
  //                   : "-9px"
  //               }
  //               className={index === 0 ? "img-first" : ""}
  //             /> */}
  //               {/* X Icon */}
  //               <span className="close-icon-wo"
  //                 style={{
  //                 //   position: "absolute",
  //                   top: "-12px",
  //                   right: `${index === 0 ? "-7px" : "5px"}`,
  //                 //   background: "#0c66e4",
  //                 //   color: "white",
  //                 //   borderRadius: "50%",
  //                 //   zIndex: 1,
  //                 //   width: "16px",
  //                 //   height: "16px",
  //                 //   display: "flex",
  //                 //   alignItems: "center",
  //                 //   justifyContent: "center",
  //                 //   fontSize: "12px",
  //                   cursor: "pointer",
  //                 //   lineHeight: "16px",
  //                 }}
  //                 onClick={(e) => {
  //                   e.stopPropagation();
  //                   handlePeopleSelect(team);
  //                 }}
  //               >
  //               <CloseIconWO />
  //               </span>
  //             </div>
  //           ))}
  //           <Image
  //             key="overflow"
  //             avatar
  //             title={`+${selectedPeople?.length - 3}`}
  //             src={`https://ui-avatars.com/api/?name=${selectedPeople?.length -
  //               3}%2B&background=8CCA9D&bold=true&color=FFFFFF`}
  //             style={{
  //               width: "34px",
  //               height: "34px",
  //               right: "-8px",
  //             }}
  //           />
  //         </>
  //       )}
  //     </>
  //   ) : (
  //     <span className="ml-3 mr-3">{t.common.na}</span>
  //   );

  const primaryProfiles = selectedPrimaryAssignee?.key ? (
    <div
      key={selectedPrimaryAssignee.key}
      className={`primary-profile-container ${
        [
          "open",
          "reOpen",
        ].includes(data?.attributes?.status) ? "hover-div" : ""
      }`}
    >
      <div>
        <Image
          avatar
          data-tip
          data-for={`tooltip-primary`}
        
          src={selectedPrimaryAssignee?.image?.src}
          style={{
            width: "34px",
            height: "34px",
            right: selectedLanguage === "ar" ? "0px" : "-8px",
          }}
          className="img-first wo-detail-img"
        />

        <Tooltip id={"tooltip-primary"} place="top" effect="solid">
          {selectedPrimaryAssignee.text}
        </Tooltip>
      </div>

      <span
        className="close-icon-wo"
        style={{
          top: selectedLanguage === "ar" ? "-9px":"-12px",
          right: selectedLanguage === "ar" ? "3px" :"-7px",
          cursor:"pointer"
         
        }}
        onClick={(e) => {
          if (
            [
              "open",
              "reOpen",
            ].includes(data?.attributes?.status)
          ){
          e.stopPropagation();
          handlePrimarySelect(selectedPrimaryAssignee);
          }
        }}
      >
        <CloseIconWO />
      </span>
    </div>
  ) : (
    <span className="ml-3 mr-3">{t.common.na}</span>
  );

  return (
    <>
      {negative &&
      (data?.attributes?.status == "open" ||
        data?.attributes?.status == "inProgress") ? (
        <>
          <div className="alert ml-0 mr-0 mt-0">
            {t.workOrders.details_view.past_due_statement}{" "}
            <Moment
              date={
                data?.attributes?.dueDate +
                "T" +
                (data?.attributes?.dueTime
                  ? data?.attributes?.dueTime
                  : "08:00:00")
              }
              format="DD/MM/YYYY LT"
            />
          </div>
        </>
      ) : data?.attributes?.status == "cancelled" ? (
        <div className="alert ml-0 mr-0 mt-0 ">
          <div
            className={
              selectedLanguage == "en"
                ? " text-dark text-left"
                : "text-dark text-right"
            }
          >
            <span className="user-img">
              {t.common.reason}:{" "}
              {selectedLanguage === "en"
                ? data?.attributes?.reason?.find(
                    (item) => item?.status === "cancelled"
                  )?.comment
                : data?.attributes?.reason?.find(
                    (item) => item?.status === "cancelled"
                  )?.arcomment}
            </span>
          </div>
        </div>
      ) : null}
      <div className="preview-details-wrapper">
        <div className="intro">
          {/* <div
            className={
              data?.attributes?.status === "completed" ||
              data?.attributes?.status === "inProgress" ||
              data?.attributes?.status === "onHold"
                ? "text-right"
                : ""
            }
            style={{ fontSize: "15px" }}
          >
            {(userPermissions["work-order"]?.edit || checkValidUser()) &&
            data.attributes.status === "open"
              ? confirmDeclineUser()
              : null}
          </div> */}
        </div>
        <div className="data-header">
          <div className="header-title d-flex mb-4 justify-content-between align-items-start">
            <div className="header-pill-container d-flex justify-content-between align-items-baseline">
              <p className="detail-title mt-5" style={{ width: "auto" }}>
                {data?.attributes?.title}
              </p>
            </div>
            <div className="status-container mt-7">
              {/* Button */}
              <div className={`status-button ${data?.attributes?.status}`}>
                <div
                  style={
                    (!["cancelled"].includes(data?.attributes?.status) &&
                      ["super_admin", "admin", "limited_admin"].includes(
                        roleData
                      )) ||
                    (roleData == "user" &&
                      checkValidUser() &&
                      ["inProgress", "onHold"].includes(
                        data?.attributes?.status
                      ))
                      ? selectedLanguage == "en"
                        ? { borderRight: "1px solid white" }
                        : { borderLeft: "1px solid white" }
                      : {}
                  }
                  className="status-text"
                  onClick={toggleDropdown}
                >
                  {statusOptions.find(
                    (status) => status.value === data?.attributes?.status
                  ).label || t.common.na}
                </div>

                {(!["cancelled"].includes(data?.attributes?.status) &&
                  ["super_admin", "admin", "limited_admin"].includes(
                    roleData
                  )) ||
                (roleData === "user" &&
                  checkValidUser() &&
                  ["inProgress", "onHold"].includes(
                    data?.attributes?.status
                  )) ? (
                  <div
                    className={`status-arrow ${
                      selectedLanguage == "en" ? "" : "mt-1"
                    }`}
                    onClick={toggleDropdown}
                  >
                    {isDropdownVisible ? (
                      <ArrowDetailsIcon />
                    ) : (
                      <ArrowDetailsIcon />
                    )}
                  </div>
                ) : null}
              </div>

              {/* Dropdown */}
              {isDropdownVisible && (
                <div className="status-dropdown-wo">
                  {data?.attributes?.status == "inReview" ? (
                    <div onClick={handleOpen} className="status-item open">
                      {t.workOrders.work_order_status.open}
                    </div>
                  ) : null}
                  {data?.attributes?.status == "inProgress" ? (
                    <div onClick={handleHold} className="status-item on-hold">
                      {t.workOrders.work_order_status.onHold}
                    </div>
                  ) : null}
                  {data?.attributes?.status == "onHold" ? (
                    <div
                      onClick={handleComplete}
                      className="status-item completed"
                    >
                      {t.workOrders.work_order_status.inReview}
                    </div>
                  ) : null}

                  {data?.attributes?.status == "completed" ? (
                    <div onClick={handleReopen} className="status-item reOpen">
                      {t.workOrders.work_order_status.reOpen}
                    </div>
                  ) : null}
                  {data?.attributes?.status == "inReview" ? (
                    <div
                      onClick={handleClose}
                      className="status-item closed mt-2"
                    >
                      {t.workOrders.work_order_status.completed}
                    </div>
                  ) : null}
                  {data?.attributes?.status == "open" ||
                  data?.attributes?.status == "reOpen" ? (
                    <div
                      onClick={handleCancel}
                      className="status-item canceled"
                    >
                      {t.workOrders.work_order_status.cancelled}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            {/* <div className="header-id d-flex flex-column">
              <div className="">
                <p className="workorder-intro-value mt-2 d-flex gap-3">
                  <div
                    className={`item-value mt-3 ${
                      selectedLanguage == "en" ? "" : "ml-2"
                    }`}
                  >
                    <Label
                      circular
                      empty
                      color={workOrderStatus?.color}
                      style={{
                        marginRight: "5px",
                        width: "12px",
                        height: "12px",
                      }}
                      size="medium"
                    />
                  </div>
                  <span className="mt-3 ">
                    {data?.attributes?.status
                      ? workOrderStatus?.label
                      : t.common.na}
                  </span>
                </p>
              </div>
            
            </div> */}
          </div>
          {data?.attributes?.image?.data?.attributes?.url ? (
            <div
              style={
                data?.attributes?.image?.data?.attributes?.ext === ".webm" ||
                data?.attributes?.image?.data?.attributes?.ext === ".mp4" ||
                data?.attributes?.image?.data?.attributes?.ext === ".hevc" ||
                data?.attributes?.image?.data?.attributes?.ext === ".mov"
                  ? {}
                  : data?.attributes?.image?.data?.attributes
                  ? { borderRadius: "8px", background: "lightgrey" }
                  : {}
              }
              className="d-flex justify-content-center align-items-center mb-5"
            >
              {data?.attributes?.image?.data?.attributes?.ext === ".webm" ||
              data?.attributes?.image?.data?.attributes?.ext === ".mp4" ||
              data?.attributes?.image?.data?.attributes?.ext === ".hevc" ||
              // data?.attributes?.image?.data?.attributes?.ext === ".heic" ||
              data?.attributes?.image?.data?.attributes?.ext === ".mov" ? (
                <video width="500" height={"156"} controls>
                  <source
                    src={IMAGES_URL + data.attributes.image.data.attributes.url}
                    type={"video/mp4"}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <SemanticImage
                  src={
                    data?.attributes.image?.data?.attributes
                      ? IMAGES_URL + data.attributes.image.data.attributes.url
                      : toAbsoluteUrl("/media/default-img.png")
                  }
                  size="medium"
                  className={
                    data?.attributes?.image?.data?.attributes
                      ? "data-header-image mb-5"
                      : "no-img"
                  }
                />
              )}
            </div>
          ) : null}
        </div>

        <div className="details-wrapper">
          <div className="notes-container">
            <p className="d-flex mb-2">
              {" "}
              <CloseNoteIcon />{" "}
              <span className="ml-1 font-weight-bold">
                {" "}
                {t.workOrders.form.description}
              </span>
            </p>
            <p>
              {data?.attributes?.description
                ? data?.attributes?.description
                : t.common.na}
            </p>
          </div>

          <div className="notes-container mt-5">
            <Grid columns={2}>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-5">
                  <div className="icon-container d-flex align-items-center">
                    {" "}
                    <div>
                      {" "}
                      <CategortDetailsIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.table.category}
                    </label>
                  </div>

                  <div className="workorder-intro-value ">
                    {categoryStatus?.label || t.common.na}
                  </div>
                </div>
              </GridColumn>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-5">
                  <div className="icon-container d-flex align-items-center">
                    {" "}
                    <div>
                      <FlagDetailsIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.table.priority}
                    </label>
                  </div>

                  <div
                    style={
                      selectedLanguage == "en"
                        ? { fontSize: "12px" }
                        : { fontSize: "11px" }
                    }
                    className={`pill gap-0 mt-0 workorder-intro-value ${data.attributes.priority}`}
                  >
                    <FlagWhiteIcon />
                    <span className="ml-2 mr-2">
                      {workOrderPriorityColor.label}{" "}
                    </span>
                  </div>
                </div>
              </GridColumn>
            </Grid>

            <Grid columns={2} className="mt-0">
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-3">
                  <div className="icon-container d-flex align-items-center">
                    {" "}
                    <div>
                      {" "}
                      <LocationDetailsIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.table.location}
                    </label>
                  </div>

                  <div className="workorder-intro-value ">
                    {data?.attributes?.location?.data ? (
                      <React.Fragment>
                        {checkForLinkPermission("location") ? (
                          <span
                            className={`detail-intro-link`}
                            basic
                            onClick={() =>
                              history.push(
                                `/locations?id=${data?.attributes?.location?.data?.attributes?.countId}`
                              )
                            }
                          >
                            {data?.attributes?.location?.data?.attributes?.name}
                          </span>
                        ) : (
                          <span className=" ml-1 mr-1">
                            {data?.attributes?.location?.data?.attributes?.name}
                          </span>
                        )}
                      </React.Fragment>
                    ) : (
                      t.common.na
                    )}
                  </div>
                </div>
              </GridColumn>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-3">
                  <div className="icon-container d-flex align-items-center">
                    {" "}
                    <div>
                      <AreaDetailsIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.parts.filter.area}
                    </label>
                  </div>

                  <div className="workorder-intro-value">
                    {data?.attributes?.area?.data?.attributes.name ||
                      t.common.na}
                  </div>
                </div>
              </GridColumn>
            </Grid>

            <Grid columns={2} className="mt-0">
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-3">
                  <div className="icon-container d-flex align-items-center">
                    {" "}
                    <div>
                      {" "}
                      <SchoolIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.table.customer}
                    </label>
                  </div>

                  <div className="workorder-intro-value">{customerName}</div>
                </div>
              </GridColumn>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-3">
                  <div className="icon-container d-flex align-items-center">
                    {" "}
                    <div>
                      <DetailsAssetIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.form.assets}
                    </label>
                  </div>

                  <div className="workorder-intro-value">
                    {data?.attributes?.assets?.data?.length
                      ? data.attributes.assets.data.map((asset, index) => (
                          <React.Fragment key={index}>
                            {checkForLinkPermission("asset") ? (
                              <p
                                className={`detail-intro-link mb-1 ${
                                  selectedLanguage == "en"
                                    ? "text-left"
                                    : "text-right"
                                }`}
                                basic
                                onClick={() =>
                                  history.push(
                                    `/assets?id=${asset?.attributes?.countId}`
                                  )
                                }
                              >
                                {asset.attributes.name}
                              </p>
                            ) : (
                              <p className={`ml-1 mr-1`} basic>
                                {asset.attributes.name}
                              </p>
                            )}
                          </React.Fragment>
                        ))
                      : t.common.na}
                  </div>
                </div>
              </GridColumn>
            </Grid>
          </div>

          <div className="notes-container mt-5">
            <Grid columns={2}>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-5">
                  <div className="icon-container d-flex align-items-start">
                    {" "}
                    <div>
                      {" "}
                      <PrimaryIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.form.primary}
                    </label>
                  </div>

                  <div
                    className={`workorder-intro-value ${
                      dropdownPrimaryOpen ? "grey-container" : ""
                    }`}
                    style={{ marginLeft: "8px" }}
                  >
                    <div
                      className="team-dropdown-container"
                      style={{ position: "relative" }}
                    >
                      {/* Avatars of selected teams */}
                      {/* <div className="d-flex"> */}
                      <div
                        className={`multi-profile-container no-hover ${
                          [
                            "open",
                            "reOpen",
                          ].includes(data?.attributes?.status) ? "c-pointer" : ""
                        } ${
                          selectedLanguage == "en" ? "" : "rtl-body"
                        }`}
                        onClick={() => {
                          if (
                            [
                              "open",
                              "reOpen",
                            ].includes(data?.attributes?.status)
                          ) {
                            setDropdownPrimaryOpen(true);
                          }
                        }}
                      >
                        {primaryProfiles}
                      </div>
                      {/* {selectedTeams?.length > 0 ? (
                          <>
                            {selectedTeams.length < 3 ? (
                              selectedTeams.map((team, index) => (
                                <UserProfilePlaceholder
                                  key={team.key}
                                  avatar
                                  name={team.text}
                                  onClick={() => setDropdownTeamsOpen(!dropdownTeamsOpen)}
                                  width={"34px"}
                                  height={"34px"}
                                  right={index !== 0 ? "7px" : ""}
                                  className={index === 0 ? "img-first" : ""}
                                />
                              ))
                            ) : (
                              <>
                                {selectedTeams
                                  .slice(0, 2)
                                  .map((team, index) => (
                                    <UserProfilePlaceholder
                                      key={team.key}
                                      avatar
                                      name={team.text}
                                      onClick={() =>
                                        setDropdownTeamsOpen(!dropdownTeamsOpen)
                                      }
                                      width={"34px"}
                                      height={"34px"}
                                      right={
                                        selectedLanguage === "ar" && index !== 0
                                          ? "0px"
                                          : index === 0 &&
                                            selectedLanguage === "en"
                                          ? "-15px"
                                          : "-9px"
                                      }
                                      className={index === 0 ? "img-first" : ""}
                                    />
                                  ))}
                                <Image
                                  key="overflow"
                                  avatar
                                  title={`+${selectedTeams?.length - 2}`}
                                  src={`https://ui-avatars.com/api/?name=${selectedTeams?.length -
                                    2}%2B&background=8CCA9D&bold=true&color=FFFFFF`}
                                  style={{
                                    width: "34px",
                                    height: "34px",
                                    right: "-3px",
                                  }}
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <span>{t.common.na}</span>
                        )} */}
                      {/* </div> */}

                      {/* Dropdown Tooltip */}
                      {dropdownPrimaryOpen && (
                        <div
                          className="dropdown-tooltip people"
                          ref={dropdownRef}
                        >
                          <div className="table-searchbar m-3">
                            <SearchIcon />
                            <Input
                              autoComplete="new-password"
                              className="dropdown-search-input search"
                              value={searchPrimary}
                              onChange={(e) => handleSearchChangePrimary(e)}
                            />
                          </div>
                          <div className="dropdown-options scroll-data">
                            {options?.primaryAssignee?.options.map((team) => (
                              <div
                                key={team.key}
                                className={`dropdown-item teams-options d-flex align-items-center c-pointer ${
                                  selectedPrimaryAssignee?.key === team.key
                                    ? "avatar-selected"
                                    : ""
                                }`}
                                onClick={() => handlePrimarySelect(team)}
                              >
                                <Image
                                  avatar
                                  title={team.text}
                                  src={team?.image?.src}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                                {/* <UserProfilePlaceholder
                                  height="20px"
                                  width="20px"
                                  name={team.text}
                                /> */}
                                <span>
                                  {DisplayFormattedText(team?.text, 26)}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div className="workorder-intro-value ">
                    {data?.attributes?.primaryAssignee?.data ? (
                      <div>
                        <Image
                          style={
                            selectedLanguage === "ar"
                              ? { width: "34px", height: "34px", left: "5px" }
                              : { width: "34px", height: "34px", right: "5px" }
                          }
                          avatar
                          title={
                            data?.attributes?.primaryAssignee?.data?.attributes
                              ?.firstName +
                            " " +
                            data?.attributes?.primaryAssignee?.data?.attributes
                              ?.lastName
                          }
                          src={
                            data?.attributes.primaryAssignee?.data?.attributes
                              .profileImage?.data
                              ? IMAGES_URL +
                                data?.attributes?.primaryAssignee?.data
                                  ?.attributes.profileImage?.data?.attributes
                                  ?.url
                              : LetteredAvatarImageUrl(
                                  data?.attributes.primaryAssignee?.data
                                    ?.attributes?.firstName +
                                    " " +
                                    data?.attributes?.primaryAssignee?.data
                                      ?.attributes?.lastName
                                )
                          }
                          // style={{ width: "34px", height: "34px" }}
                        />
                        <span
                          onClick={() => {
                            history.push(
                              `/people?id=${data?.attributes?.primaryAssignee?.data?.attributes?.countId}`
                            );
                          }}
                          className="detail-intro-link"
                          style={{ marginLeft: "4px" }}
                        >
                          {data?.attributes?.primaryAssignee?.data?.attributes
                            ?.firstName +
                            " " +
                            data?.attributes?.primaryAssignee?.data?.attributes
                              ?.lastName}
                        </span>
                      </div>
                    ) : (
                      t.common.na
                    )}
                  </div> */}
                </div>
              </GridColumn>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-5">
                  <div className="icon-container d-flex align-items-start">
                    {" "}
                    <div>
                      <SecondaryIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.form.secondary}
                    </label>
                  </div>

                  <div
                    className={`workorder-intro-value ${
                      dropdownPeopleOpen ? "grey-container" : ""
                    }`}
                    style={{ marginLeft: "8px" }}
                  >
                    <div
                      className="team-dropdown-container"
                      style={{ position: "relative" }}
                    >
                      {/* Avatars of selected teams */}
                      {/* <div className="d-flex"> */}
                      <div
                        className={`multi-profile-container no-hover ${
                          selectedLanguage == "en" ? "" : "rtl-body"
                        } ${
                          ( [
                            "open",
                            "reOpen",
                          ].includes(data?.attributes?.status) && selectedPrimaryAssignee?.key) ? "c-pointer" : ""
                        }`}
                        onClick={() => {
                          if (
                            ( [
                              "open",
                              "reOpen",
                            ].includes(data?.attributes?.status) && selectedPrimaryAssignee?.key)
                          ) {
                            setDropdownPeopleOpen(true);
                          }
                        }}
                      >
                        {peopleProfiles}
                      </div>
                      {/* {selectedTeams?.length > 0 ? (
                          <>
                            {selectedTeams.length < 3 ? (
                              selectedTeams.map((team, index) => (
                                <UserProfilePlaceholder
                                  key={team.key}
                                  avatar
                                  name={team.text}
                                  onClick={() => setDropdownTeamsOpen(!dropdownTeamsOpen)}
                                  width={"34px"}
                                  height={"34px"}
                                  right={index !== 0 ? "7px" : ""}
                                  className={index === 0 ? "img-first" : ""}
                                />
                              ))
                            ) : (
                              <>
                                {selectedTeams
                                  .slice(0, 2)
                                  .map((team, index) => (
                                    <UserProfilePlaceholder
                                      key={team.key}
                                      avatar
                                      name={team.text}
                                      onClick={() =>
                                        setDropdownTeamsOpen(!dropdownTeamsOpen)
                                      }
                                      width={"34px"}
                                      height={"34px"}
                                      right={
                                        selectedLanguage === "ar" && index !== 0
                                          ? "0px"
                                          : index === 0 &&
                                            selectedLanguage === "en"
                                          ? "-15px"
                                          : "-9px"
                                      }
                                      className={index === 0 ? "img-first" : ""}
                                    />
                                  ))}
                                <Image
                                  key="overflow"
                                  avatar
                                  title={`+${selectedTeams?.length - 2}`}
                                  src={`https://ui-avatars.com/api/?name=${selectedTeams?.length -
                                    2}%2B&background=8CCA9D&bold=true&color=FFFFFF`}
                                  style={{
                                    width: "34px",
                                    height: "34px",
                                    right: "-3px",
                                  }}
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <span>{t.common.na}</span>
                        )} */}
                      {/* </div> */}

                      {/* Dropdown Tooltip */}
                      {dropdownPeopleOpen && (
                        <div
                          className="dropdown-tooltip people"
                          ref={dropdownRef}
                        >
                          <div className="table-searchbar m-3">
                            <SearchIcon />
                            <Input
                              autoComplete="new-password"
                              className="dropdown-search-input search"
                              value={searchPeople}
                              onChange={(e) => handleSearchChangePeople(e)}
                            />
                          </div>
                          <div className="dropdown-options scroll-data">
                            {options?.people?.options.map((team) => (
                              <div
                                key={team.key}
                                className={`dropdown-item teams-options d-flex align-items-center c-pointer ${
                                  selectedPeople?.some(
                                    (selected) => selected.key === team.key
                                  )
                                    ? "avatar-selected"
                                    : ""
                                }`}
                                onClick={() => handlePeopleSelect(team)}
                              >
                                <Image
                                  avatar
                                  title={team.text}
                                  src={team?.image?.src}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                                {/* <UserProfilePlaceholder
                                  height="20px"
                                  width="20px"
                                  name={team.text}
                                /> */}
                                <span>
                                  {DisplayFormattedText(team?.text, 26)}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div
                    className="workorder-intro-value"
                    style={
                      data?.attributes?.people?.data.length > 0
                        ? { marginLeft: "16px" }
                        : { marginLeft: "16px" }
                    }
                  >
                    {renderAssigneePeople(totalPartCount?.people?.data)}
                  </div> */}
                </div>
              </GridColumn>
            </Grid>

            <Grid columns={1} className="mt-0">
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-3">
                  <div className="icon-container d-flex align-items-start">
                    {" "}
                    <div>
                      {" "}
                      <TeamsIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.form.teams}
                    </label>
                  </div>

                  <div
                    className={`workorder-intro-value ${
                      dropdownTeamsOpen ? "grey-container" : ""
                    }`}
                    style={{ marginLeft: "8px" }}
                  >
                    <div
                      className="team-dropdown-container"
                      style={{ position: "relative" }}
                    >
                      {/* Avatars of selected teams */}
                      {/* <div className="d-flex"> */}
                      <div
                        className={`multi-profile-container no-hover ${
                          [
                            "open",
                            "reOpen",
                          ].includes(data?.attributes?.status) ? "c-pointer" : ""
                        } ${
                          selectedLanguage == "en" ? "" : "rtl-body"
                        }`}
                        onClick={() => {
                          if (
                            [
                              "open",
                        "reOpen",
                            ].includes(data?.attributes?.status)
                          ) {
                            setDropdownTeamsOpen(true);
                          }
                        }}
                      >
                        {teamProfiles}
                      </div>
                      {/* {selectedTeams?.length > 0 ? (
                          <>
                            {selectedTeams.length < 3 ? (
                              selectedTeams.map((team, index) => (
                                <UserProfilePlaceholder
                                  key={team.key}
                                  avatar
                                  name={team.text}
                                  onClick={() => setDropdownTeamsOpen(!dropdownTeamsOpen)}
                                  width={"34px"}
                                  height={"34px"}
                                  right={index !== 0 ? "7px" : ""}
                                  className={index === 0 ? "img-first" : ""}
                                />
                              ))
                            ) : (
                              <>
                                {selectedTeams
                                  .slice(0, 2)
                                  .map((team, index) => (
                                    <UserProfilePlaceholder
                                      key={team.key}
                                      avatar
                                      name={team.text}
                                      onClick={() =>
                                        setDropdownTeamsOpen(!dropdownTeamsOpen)
                                      }
                                      width={"34px"}
                                      height={"34px"}
                                      right={
                                        selectedLanguage === "ar" && index !== 0
                                          ? "0px"
                                          : index === 0 &&
                                            selectedLanguage === "en"
                                          ? "-15px"
                                          : "-9px"
                                      }
                                      className={index === 0 ? "img-first" : ""}
                                    />
                                  ))}
                                <Image
                                  key="overflow"
                                  avatar
                                  title={`+${selectedTeams?.length - 2}`}
                                  src={`https://ui-avatars.com/api/?name=${selectedTeams?.length -
                                    2}%2B&background=8CCA9D&bold=true&color=FFFFFF`}
                                  style={{
                                    width: "34px",
                                    height: "34px",
                                    right: "-3px",
                                  }}
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <span>{t.common.na}</span>
                        )} */}
                      {/* </div> */}

                      {/* Dropdown Tooltip */}
                      {dropdownTeamsOpen && (
                        <div className="dropdown-tooltip team" ref={dropdownRef}>
                          <div className="table-searchbar m-3">
                            <SearchIcon />
                            <Input
                              autoComplete="new-password"
                              className="dropdown-search-input search"
                              value={searchTeam}
                              onChange={(e) => handleSearchChange(e)}
                            />
                          </div>
                          <div className="dropdown-options scroll-data">
                            {options?.teams?.options?.map((team) => (
                              <div
                                key={team.key}
                                className={`dropdown-item teams-options d-flex align-items-center c-pointer ${
                                  selectedTeams?.some(
                                    (selected) => selected.key === team.key
                                  )
                                    ? "avatar-selected"
                                    : ""
                                }`}
                                onClick={() => handleTeamSelect(team)}
                              >
                                <UserProfilePlaceholder
                                  height="20px"
                                  width="20px"
                                  name={team.text}
                                />
                                <span>
                                  {DisplayFormattedText(team?.text, 26)}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </GridColumn>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
              </GridColumn>
            </Grid>

            {/* <Grid columns={2} className="mt-0">
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-3">
                  <div className="icon-container d-flex align-items-start">
                    {" "}
                    <div>
                      {" "}
                      <TeamsIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.form.teams}
                    </label>
                  </div>

                  <div
                    className="workorder-intro-value"
                    style={{ marginLeft: "8px" }}
                  >
                    {teamName.length > 0 ? (
                      teamName
                    ) : (
                      <span
                        style={
                          selectedLanguage == "en" ? { marginLeft: "8px" } : {}
                        }
                      >
                        {t.common.na}
                      </span>
                    )}
                  </div>
                </div>
              </GridColumn>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
              </GridColumn>
            </Grid> */}
          </div>
          {data?.attributes?.orderType == "reactive" ? (
            <div className="notes-container mt-5">
              <Grid columns={2}>
                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-5">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                        <OrderTypeIcon />
                      </div>
                      <label className="workorder-intro-label work-order-label">
                        {" "}
                        {t.workOrders.form.order_type}
                      </label>
                    </div>

                    <div
                      className="workorder-intro-value"
                      style={{ minWidth: "182px" }}
                    >
                      {t.workOrders.form.order_type_1}
                    </div>
                  </div>
                </GridColumn>
              </Grid>

              <Grid columns={2} className="mt-0">
                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-3">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                        <DateDetailsIcon />
                      </div>
                      <label
                        className={`workorder-intro-label work-order-label `}
                      >
                        {" "}
                        {t.workOrders.form.start_date_new}
                      </label>
                    </div>

                    <div className={`workorder-intro-value `}>
                      {/* {data?.attributes?.startDate ? (
                        <>
                    
                            {format(
                              new Date(
                                data?.attributes?.startDate +
                                  "T" +
                                  `${data?.attributes?.startTime || "08:00:00"}`
                              ),
                              "dd/MM/yyyy hh:mm a"
                            )}
                      
                      
                        </>
                      ) : (
                        <span>{t.common.na}</span>
                      )} */}
                      {data?.attributes?.startDate ? (
                        format(
                          new Date(
                            data?.attributes?.startDate +
                              "T" +
                              `${data?.attributes?.startTime || "08:00:00"}`
                          ),
                          "dd/MM/yyyy hh:mm a" // Formats date and time
                        )
                      ) : (
                        <span>{t.common.na}</span>
                      )}
                    </div>
                  </div>
                </GridColumn>
                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-3">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                      {negative ?    <DuedateIcon color={"#f64e60"}/> :  <DateDetailsIcon color={"#788CA5"} />}  
                      {/* <DateDetailsIcon color={"#788CA5"} /> */}
                      </div>
                      <label
                        className={`workorder-intro-label work-order-label`}
                      >
                        {" "}
                        {t.workOrders.form.due_date_new}
                      </label>
                    </div>

                    <div
                      className={`workorder-intro-value ${
                        negative ? "text-danger" : ""
                      }`}
                    >
                      {data?.attributes?.dueDate ? (
                        <>
                          {format(
                            new Date(
                              data?.attributes?.dueDate +
                                "T" +
                                `${data?.attributes?.dueTime || "08:00:00"}`
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </>
                      ) : (
                        <span>{t.common.na}</span>
                      )}
                    </div>
                  </div>
                </GridColumn>
              </Grid>
            </div>
          ) : (
            <div className="notes-container mt-5">
              <Grid columns={2}>
                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-5">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                        <OrderTypeIcon />
                      </div>
                      <label className="workorder-intro-label work-order-label">
                        {" "}
                        {t.workOrders.form.order_type}
                      </label>
                    </div>

                    <div
                      className="workorder-intro-value"
                      style={{ minWidth: "146px" }}
                    >
                      {t.workOrders.form.order_type_2}
                    </div>
                  </div>
                </GridColumn>

                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-5">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                        <DateDetailsIcon />
                      </div>
                      <label className="workorder-intro-label work-order-label">
                        {" "}
                        {t.workOrders.form.start_date_new}
                      </label>
                    </div>

                    <div className="workorder-intro-value">
                      {data?.attributes?.dueDate ? (
                        <>
                          {format(
                            new Date(
                              data?.attributes?.dueDate +
                                "T" +
                                `${data?.attributes?.dueTime || "08:00:00"}`
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </>
                      ) : (
                        <span>{t.common.na}</span>
                      )}
                    </div>
                  </div>
                </GridColumn>
              </Grid>

              <Grid columns={2} className="mt-0">
                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-3">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                        <DateDetailsIcon />
                      </div>
                      <label className="workorder-intro-label work-order-label">
                        {" "}
                        {data?.attributes?.endType == "after"
                          ? t.workOrders.details_view.expire_after
                          : t.workOrders.details_view.expire_on}{" "}
                      </label>
                    </div>

                    <div className="workorder-intro-value">
                      {data?.attributes?.endType == "after" ? (
                        `${data?.attributes?.endRepeat} ${t.workOrders.title}`
                      ) : (
                        <Moment
                          format="DD/MM/YYYY"
                          date={data?.attributes?.endDate}
                        />
                      )}
                    </div>
                  </div>
                </GridColumn>

                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-3">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                        <DateDetailsIcon />
                      </div>
                      <label className="workorder-intro-label work-order-label">
                        {" "}
                        {t.workOrders.details_view.repeat}
                      </label>
                    </div>

                    <div className="workorder-intro-value">
                      {data?.attributes?.orderType === "reactive"
                        ? t.common.na
                        : `${
                            t.workOrders.details_view[
                              data?.attributes?.repeatEvery
                            ]
                          }; ${
                            data?.attributes?.daysRepeat
                              ? " " +
                                Object.keys(data.attributes.daysRepeat)
                                  .filter(
                                    (day) =>
                                      day !== "id" &&
                                      data.attributes.daysRepeat[day]
                                  )
                                  .map((day) => {
                                    const formattedDay =
                                      day.charAt(0).toUpperCase() +
                                      day.slice(1);

                                    return t.common.days[formattedDay];
                                  })
                                  .join(", ")
                              : ""
                          }`}
                    </div>
                  </div>
                </GridColumn>
              </Grid>
            </div>
          )}

          <div className="metrics-card-container mt-5">
            <div className="metrics-card">
              <div className="metrics-grid">
                <div
                  onClick={() =>
                    setActiveIndex(data?.attributes?.procedure?.data ? 1 : 0)
                  }
                  className="metrics-item c-pointer"
                >
                  <h2>{t.workOrders.form.procedure}</h2>
                  <div className="value c-pointer">
                    {data?.attributes?.procedure?.data
                      ? calculateCurrentCount(
                          Number(
                            data?.attributes?.procedure?.data?.attributes
                              ?.procedureItems
                          ),
                          Number(
                            data?.attributes?.report?.data?.attributes
                              ?.procedureCompletion || 0
                          )
                        )
                      : t.common.na}
                  </div>
                </div>
                {/* <div style={{borderRight:"1px solid black"}}></div> */}
                <div
                  onClick={() =>
                    setActiveIndex(data?.attributes?.procedure?.data ? 2 : 1)
                  }
                  className="metrics-item c-pointer"
                >
                  <h2>{t.workOrders.form.part_set}</h2>
                  <div className="value c-pointer">
                    {totalPartCount?.partCount ? totalPartCount?.partCount : 0}
                  </div>
                </div>
                {/* <div style={{borderRight:"1px solid black"}}></div> */}
                <div
                  onClick={() =>
                    setActiveIndex(data?.attributes?.procedure?.data ? 3 : 2)
                  }
                  className="metrics-item c-pointer"
                >
                  <h2> {t.workOrders.actual_times}</h2>
                  <div className="value c-pointer">
                    {" "}
                    {totalPartCount?.actualTime
                      ? `${moment
                          .utc(
                            moment
                              .duration(totalPartCount?.actualTime)
                              .asMilliseconds()
                          )
                          .locale("en")
                          .format("HH:mm:ss")}`
                      : t.common.na}
                  </div>
                  <div className="sub-value">
                    {t.workOrders.filter.estimation_time_by}
                    <br />
                    {totalPartCount?.estimationTime
                      ? `${totalPartCount?.estimationTime} Hrs`
                      : t.common.na}
                  </div>
                </div>
                <div
                  onClick={() =>
                    setActiveIndex(data?.attributes?.procedure?.data ? 4 : 3)
                  }
                  className="metrics-item c-pointer"
                >
                  <h2>{t.workOrders?.form?.additional_cost}</h2>
                  <div className="value c-pointer">
                    {totalPartCount?.additionalCost
                      ? `${Number(totalPartCount?.additionalCost).toFixed(
                          2
                        )} ${organisationCurrency}`
                      : t.common.na}
                  </div>
                </div>
                <div
                  onMouseOver={() => setTotalCostTooltip(true)}
                  onMouseLeave={() => setTotalCostTooltip(false)}
                  className="metrics-item c-pointer no-hover"
                >
                  <h2>{t.workOrders.labor.total_cost}</h2>
                  <div className="value black">
                    {" "}
                    {totalPartCount?.totalCost
                      ? `${Number(totalPartCount?.totalCost).toFixed(
                          2
                        )} ${organisationCurrency}`
                      : t.common.na}
                  </div>
                  <div className="sub-value">
                    {t.workOrders.form.labor_cost}
                    <br />
                    {totalPartCount?.laborCost
                      ? `${Number(totalPartCount?.laborCost).toFixed(2)}` +
                        " " +
                        organisationCurrency
                      : t.common.na}
                  </div>
                  {totalCostTooltip ? (
                    <div className="custom-tooltip">
                      <div className="tooltip-content">
                        <h3>{t.workOrders.labor.total_cost}</h3>
                        <div className="cost-breakdown">
                          <div className="cost-row">
                            <span className="cost-label parts-cost">
                              {t.workOrders.cost.partsCosts}:{" "}
                            </span>
                            <span className="cost-value">
                              &nbsp;{" "}
                              {totalPartCount?.subTotal % 1 === 0
                                ? totalPartCount?.subTotal
                                : totalPartCount?.subTotal?.toFixed(2)}{" "}
                              {organisationCurrency}
                            </span>
                          </div>
                          <Divider className="mb-2 mt-2" />
                          <div className="cost-row">
                            <span className="cost-label">
                              {" "}
                              {t.workOrders.cost.laborCosts}:
                            </span>
                            <span className="cost-value">
                              &nbsp;{" "}
                              {totalPartCount?.totalLaborCost % 1 === 0
                                ? Number(totalPartCount?.totalLaborCost)
                                : Number(
                                    totalPartCount?.totalLaborCost?.toFixed(2)
                                  )}{" "}
                              {organisationCurrency}
                            </span>
                          </div>
                          <Divider className="mb-2 mt-2" />
                          <div className="cost-row">
                            <span className="cost-label">
                              {t.workOrders.cost.addCost} : {"  "}
                            </span>
                            <span className="cost-value">
                              &nbsp;{" "}
                              {Number(totalPartCount?.additionalCost % 1) === 0
                                ? Number(totalPartCount?.additionalCost)
                                : totalPartCount?.additionalCost?.toFixed(
                                    2
                                  )}{" "}
                              {organisationCurrency}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* <Divider /> */}

          {/* <div className="d-flex align-items-start">
          <label className="label-item mt-0">
            {""}
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.table.priority}
             
            </label>
          </label>
          <p
            style={
              selectedLanguage == "en"
                ? { fontSize: "12px" }
                : { fontSize: "11px" }
            }
            className={`pill gap-0 mt-0 workorder-intro-value ${data.attributes.priority}`}
          >
            <FlagWhiteIcon />
            <span className="ml-2 mr-2">{workOrderPriorityColor.label} </span>
          </p>
         
        </div>
       
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.procedure}
           
          </label>
          <p
            onClick={() => {
              if (data.attributes?.procedure?.data?.attributes?.countId)
                history.push(
                  `/procedures?id=${data.attributes?.procedure?.data?.attributes?.countId}`
                );
            }}
            className={`workorder-intro-value ${
              data.attributes?.procedure?.data?.attributes?.countId
                ? "detail-intro-link"
                : ""
            }`}
          >
            {data.attributes?.procedure?.data?.attributes?.title || t.common.na}
          </p>
        </div> */}

          {/* {data?.attributes?.orderType == "reactive" ? (
          <>
            <Grid columns={2}>
              <GridColumn className="pr-0">
                {" "}
                <div className="d-flex align-items-start mt-5">
                  <label className="workorder-intro-label work-order-label">
                    {t.workOrders.details_view.start_date}
                   
                  </label>
                  <p className="workorder-intro-value set-width">
                    {data?.attributes?.startDate ? (
                      <Moment
                        format="DD/MM/YYYY"
                        date={data?.attributes?.startDate}
                      />
                    ) : (
                      <span>{t.common.na}</span>
                    )}
                  </p>
                </div>
              </GridColumn>
              <GridColumn>
                {" "}
                <div className="d-flex align-items-start mt-5">
                  <label className="workorder-intro-label">
                    {t.workOrders.details_view.start_time}
                   
                  </label>
                  <p className="workorder-intro-value set-width">
                    {data?.attributes?.startTime
                      ? format(
                          new Date(
                            data?.attributes?.startDate +
                              "T" +
                              `${data?.attributes?.startTime || "08:00:00"}`
                          ),
                          "hh:mm a" // This will display the time in 12-hour format with AM/PM
                        )
                      : t.common.na}
                  </p>
                </div>
              </GridColumn>
            </Grid>

            <Grid columns={2} className="mt-0">
              <GridColumn className="pr-0">
                {" "}
                <div className="d-flex align-items-start">
                  <label className="workorder-intro-label work-order-label">
                    {t.workOrders.table.dueDate}
                   
                  </label>
                  <p className="workorder-intro-value">
                    {data?.attributes?.dueDate ? (
                      <Moment
                        format="DD/MM/YYYY"
                        date={data?.attributes?.dueDate}
                      />
                    ) : (
                      <span>{t.common.na}</span>
                    )}
                  </p>
                </div>
              </GridColumn>
              <GridColumn>
                {" "}
                <div className="d-flex align-items-start">
                  <label
                    style={{ width: "72px" }}
                    className="workorder-intro-label"
                  >
                    {t.workOrders.form.due_time}
                   
                  </label>
                  <p className="workorder-intro-value">
                    {data?.attributes?.dueTime
                      ? format(
                          new Date(
                            data?.attributes?.dueDate +
                              "T" +
                              `${data?.attributes?.dueTime || "08:00:00"}`
                          ),
                          "hh:mm a" // This will display the time in 12-hour format with AM/PM
                        )
                      : t.common.na}
                  </p>
                </div>
              </GridColumn>
            </Grid>
          </>
        ) : null}
        {data?.attributes?.orderType == "reactive" ? (
          <div className="d-flex align-items-start mt-5">
            <label
              // style={{ minWidth: "106px" }}
              className={`workorder-intro-label work-order-label ${
                selectedLanguage == "en" ? "" : "mr-4"
              }`}
            >
              {t.workOrders.form.order_type}
             
            </label>
            <p className="workorder-intro-value" style={{ minWidth: "152px" }}>
              {t.workOrders.form.order_type_1}
            </p>
          </div>
        ) : null} */}

          {/* <Divider /> */}

          {/* <>
          {selectedFinalPartData?.length == 0 ? (
            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label work-order-label">
                {t.workOrders.form.part_set}
               
              </label>
              <p className="workorder-intro-value">{t.common.na}</p>
            </div>
          ) : (
            <>
              <label className="workorder-intro-label work-order-label mt-5">
                {t.workOrders.form.part_set}
               
              </label>
              {selectedFinalPartData?.length > 0 ? (
                <div className="scroll-data" style={{ overflow: "auto" }}>
                  <div className={"mt-6"}>
                    <div
                    
                      className={`kanbar-header`}
                    >
                      <div class="grid-container-element set wo-form wo-form-data">
                        <div
                          style={{
                            minWidth: "86px",
                            backgroundColor: "#e9f2ff",
                          }}
                          class="grid-child-element set"
                        >
                          {t.workOrders.table.ID}
                        </div>
                        <div
                          style={{
                            minWidth: "140px",
                            backgroundColor: "#e9f2ff",
                          }}
                          class="grid-child-element set"
                        >
                          {t.workOrders.details_view.name}
                        </div>
                        <div
                          style={{
                            minWidth: "140px",
                            backgroundColor: "#e9f2ff",
                          }}
                          class="grid-child-element set"
                        >
                          {t.parts.form.inv_loc}
                        </div>
                        <div
                          style={{
                            minWidth: "100px",
                            backgroundColor: "#e9f2ff",
                            textAlign: "end",
                          }}
                          class="grid-child-element set"
                        >
                          {t.parts.table.quantity}
                        </div>{" "}
                        <div
                          style={{
                            minWidth: "124px",
                            backgroundColor: "#e9f2ff",
                            textAlign: "end",
                          }}
                          class="grid-child-element set"
                        >
                          {t.parts.form.unit_cost}
                        </div>
                        <div
                          style={{
                            minWidth: "124px",
                            backgroundColor: "#e9f2ff",
                            textAlign: "end",
                          }}
                          class="grid-child-element set"
                        >
                          {t.parts.form.total_cost}
                        </div>
                      </div>
                    </div>
                  </div>

                  {selectedFinalPartData.map((part, index) => (
                    <div
                      className="grid-container-element set wo-form form"
                      key={index}
                    >
                    
                      <div
                        className="grid-child-element set form"
                        style={{
                          padding: "14px 9px",
                          minWidth: "86px",
                        }}
                     
                      >
                        {part?.countId ? part.countId : null}
                      </div>

                      <div
                        className="grid-child-element set form"
                        style={{
                          padding: "14px 9px",
                          minWidth: "140px",
                        }}
                       
                      >
                        {userPermissions?.part?.view ? (
                          <b
                            style={{ color: "#0c66e4", wordBreak: "break-all" }}
                            className="c-pointer"
                            onClick={() =>
                              history.push(`/parts?id=${part?.countId}`)
                            }
                          >
                            {" "}
                            {DisplayFormattedText(part?.name, 13)}
                          </b>
                        ) : (
                          <b> {DisplayFormattedText(part?.name, 13)}</b>
                        )}
                      </div>
                      <div
                        className="grid-child-element set form c-pointer"
                        style={{
                          padding: "14px 9px",
                          minWidth: "140px",
                          color: "#0c66e4",
                          wordBreak: "break-all",
                        }}
                        onClick={() =>
                          history.push(`/locations?id=${part?.location_id}`)
                        }
                      
                      >
                        {DisplayFormattedText(part?.location_name, 13)}
                      </div>
                      <div
                        className="grid-child-element set form"
                        style={{
                          padding: "14px 9px",
                          minWidth: "100px",
                          display: "flex",
                          gap: "10px",
                          justifyContent: "end",
                        }}
                      >
                        <div style={{ minWidth: "70px", textAlign: "end" }}>
                          {part?.quantity
                            ? DisplayFormattedText(String(part?.quantity), 8)
                            : 0}{" "}
                        </div>
                        {part?.unitType && (
                          <div
                            style={{ minWidth: "20px", textAlign: "center" }}
                          >
                            {part?.unitType
                              ? HandleUnitType(part?.unitType)
                              : ""}
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          selectedLanguage == "en"
                            ? "grid-child-element set form mt-2"
                            : "grid-child-element set form mt-2"
                        }
                        style={{
                          minWidth: "124px",
                          display: "flex",
                          justifyContent: "end",
                        }}
                       
                      >
                        <div
                          style={{ minWidth: "74px", textAlign: "end" }}
                          className="number-cost-set"
                        >
                          {DisplayFormattedText(String(part?.unitCost), 7)}
                        </div>
                        <div
                          style={{ minWidth: "20px", textAlign: "center" }}
                          className={` align-items-center justify-content-center ${
                            selectedLanguage == "en" ? "ml-2" : "mr-2"
                          }`}
                        >
                          {organisationCurrency}s
                        </div>
                      </div>

                      <div
                        className={
                          selectedLanguage == "en"
                            ? "grid-child-element set form mt-2"
                            : "grid-child-element set form mt-2"
                        }
                        style={{
                          minWidth: "124px",
                          display: "flex",
                          justifyContent: "end",
                        }}
                        
                      >
                        <div
                          style={{ minWidth: "74px", textAlign: "end" }}
                          className="number-cost-set"
                        >
                          {DisplayFormattedText(String(part?.totalCost), 7)}
                        </div>
                        <div
                          style={{ minWidth: "20px", textAlign: "center" }}
                          className={` align-items-center justify-content-center ${
                            selectedLanguage == "en" ? "ml-2" : "mr-2"
                          }`}
                        >
                          {organisationCurrency}s
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}

              <div
                className="mt-4"
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                <span>
                  <b>{t.workOrders.form.sub_total}</b>
                </span>
                <span>
                  <b>{data?.attributes?.subTotal}</b>
                </span>
                <span>
                  <b>{`${organisationCurrency}s`}</b>
                </span>
              </div>
            </>
          )}
  
        </> */}
          {/* :null} */}
          {/* <Divider /> */}
          {/* <Dropdown
            placeholder={t.workOrders.details_view.status}
            className='status-dropdown'
            style={{width:"200px"}}
            fluid
            selection
            options={statusOptions}
            value={data?.attributes?.status}
            onChange={(e, value) => {
              console.log(value);
              // onValuesChange('customer')
              // console.log(value, e);
              let findPriority = value.options.find(
                (one) => one.value == value.value
              );
              console.log(findPriority, 'hii');
              if (findPriority.value == 'onHold') {
                setModal(true);
                // let mydata = data.attributes.status;
                // mydata = 'inProgress';
                // setData({...data,mydata: 'inProgress'});
                setApproveModal(false);
                setOnHoldFlag(true);
                setInProgressFlag(false);
                setModal(true);
              } else if (findPriority.value == 'inProgress') {
                setApproveModal(false);
                setOnHoldFlag(false);
                setInProgressFlag(true);
                setModal(true);
              }
              // }else if (findPriority.value == 'inReview') {
              //   setApproveModal(false);
              // setOnHoldFlag(false);
              // setInProgressFlag(true);
              // setModal(true);
              // }
            }}
          /> */}

          {/* <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workRequests.form.people}
           
          </label>
          <p
            className="workorder-intro-value"
            style={
              data?.attributes?.people?.data.length > 0
                ? { marginTop: "-6px", marginLeft: "8px" }
                : { marginLeft: "8px" }
            }
          >
            {renderAssigneePeople(data?.attributes?.people?.data)}
          </p>
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.teams}
           
          </label>
          <p className="workorder-intro-value" style={{ marginLeft: "8px" }}>
            {teamName.length > 0 ? (
              teamName
            ) : (
              <span
                style={selectedLanguage == "en" ? { marginLeft: "8px" } : {}}
              >
                {t.common.na}
              </span>
            )}
          </p>
        </div> */}

          {/* {data?.attributes?.orderType !== "reactive" ? (
          <Grid columns={2}>
            <GridColumn>
              {" "}
              <div className="d-flex align-items-start">
                <label className="workorder-intro-label work-order-label">
                  {t.workOrders.form.start_time_date}
                 
                </label>
                <p className="workorder-intro-value">
                  {data?.attributes?.dueDate
                    ? format(
                        new Date(
                          data?.attributes?.dueDate +
                            "T" +
                            `${data?.attributes?.dueTime || "12:00:00"}`
                        ),
                        "dd/MM/yyyy hh:mm a" // Full date and time format
                      )
                    : t.common.na}
                </p>
              </div>
            </GridColumn>
            <GridColumn>
              {" "}
              <div className="d-flex align-items-start">
                <label
                  style={{ minWidth: "71px" }}
                  className="workorder-intro-label "
                >
                  {data?.attributes?.endType == "after"
                    ? t.workOrders.details_view.expire_after
                    : t.workOrders.details_view.expire_on}
                 
                </label>
                <p className="workorder-intro-value ">
                  {data?.attributes?.orderType == "reactive" ? (
                    t.common.na
                  ) : data?.attributes?.endType == "after" ? (
                    `${data?.attributes?.endRepeat} ${t.workOrders.title}`
                  ) : (
                    <Moment
                      format="DD/MM/YYYY"
                      date={data?.attributes?.endDate}
                    />
                  )}
                </p>
              </div>
            </GridColumn>
          </Grid>
        ) : null} */}

          {/* <Divider />

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.filter.estimation_time_by}
           
          </label>
          <p className="workorder-intro-value set-width">
            {data?.attributes?.estimationTime
              ? `${data?.attributes?.estimationTime} Hrs`
              : t.common.na}
          </p>
        </div> */}

          {/* {["admin", "super_admin", "limited_admin"]?.includes(roleData) ? (
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.actual_times}
             
            </label>
            <p className="workorder-intro-value set-width">
              {data?.attributes["work-order-log"]?.data?.length > 0
                ? (() => {
                    // Sum up all workOrderTime values
                    const totalWorkOrderTime = data?.attributes[
                      "work-order-log"
                    ]?.data?.reduce((total, item) => {
                      return total + (item?.attributes?.workOrderTime || 0);
                    }, 0);

                    // Format the total time
                    return (
                      formatMillisecondsToHHMM(totalWorkOrderTime) ||
                      "00:00:00 Hr"
                    );
                  })()
                : t.common.na}
            </p>
          </div>
        ) : null} */}

          {/* <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.labor_cost}
           
          </label>
          <p className="workorder-intro-value set-width">
            {data?.attributes?.laborCost
              ? `${data?.attributes?.laborCost}` + " " + organisationCurrency
              : t.common.na}
          </p>
        </div> */}

          {/* {["admin", "super_admin", "limited_admin"]?.includes(roleData) ? (
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.actual_cost}
             
            </label>
            <p className="workorder-intro-value set-width">
              {data?.attributes?.actualCost
                ? `${data?.attributes?.actualCost} ${organisationCurrency}`
                : t.common.na}
            </p>
          </div>
        ) : null} */}

          {/* {addedcostData} */}

          {/* <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.labor_cost}
            {':'}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.category || t.common.na}
          </p>
        </div> */}

          {/* <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.parts}
            {':'}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.parts && data?.attributes?.parts?.data.length
              ? data?.attributes?.parts?.data.map((each) => {
                  return (
                    <span
                      className="detail-intro-link ml-4 mr-4"
                      basic
                      onClick={() => history.push(`/parts?id=${each?.id}`)}
                    >
                      {each?.attributes?.name}
                    </span>
                  );
                })
              : t.common.na}
          </p>
        </div> */}

          {/* {selectedFinalReportPartData?.length > 0 ? (
          <>
            <label className="workorder-intro-label work-order-label mt-5">
              {t.workOrders.form.added_parts_set}
             
            </label>
            {selectedFinalReportPartData?.length > 0 ? (
              <div className="scroll-data" style={{ overflow: "auto" }}>
                <div className={"mt-6"}>
                  <div
                 
                    className={`kanbar-header`}
                  >
                    <div class="grid-container-element set wo-form wo-form-data">
                      <div
                        style={{ minWidth: "86px", backgroundColor: "#e9f2ff" }}
                        class="grid-child-element set"
                      >
                        {t.workOrders.table.ID}
                      </div>
                      <div
                        style={{
                          minWidth: "140px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.workOrders.details_view.name}
                      </div>
                      <div
                        style={{
                          minWidth: "140px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.parts.form.inv_loc}
                      </div>
                      <div
                        style={{
                          minWidth: "100px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.parts.table.quantity}
                      </div>{" "}
                      <div
                        style={{
                          minWidth: "100px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.parts.form.unit_cost}
                      </div>
                      <div
                        style={{
                          minWidth: "100px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.parts.form.total_cost}
                      </div>
                    </div>
                  </div>
                </div>

                {selectedFinalReportPartData.map((part, index) => (
                  <div
                    className="grid-container-element set wo-form form"
                    key={index}
                  >
              
                    <div
                      className="grid-child-element set form"
                      style={{
                        padding: "14px 9px",
                        minWidth: "86px",
                      }}
                    
                    >
                      {part?.countId ? part.countId : null}
                    </div>

                    <div
                      className="grid-child-element set form"
                      style={{
                        padding: "14px 9px",
                        minWidth: "140px",
                      }}
                      // className="ml-4"
                      // style={{
                      //   width: '145px',
                      //   minWidth: '145px',
                      //   display: 'inline-grid',
                      //   fontSize: '16px',
                      //   lineHeight: '24px',
                      // }}
                    >
                      <b> {DisplayFormattedText(part?.name, 13)}</b>
                    </div>
                    <div
                      className="grid-child-element set form"
                      style={{
                        padding: "14px 9px",
                        minWidth: "140px",
                      }}
                      // className="ml-4"
                      // style={{
                      //   width: '145px',
                      //   minWidth: '145px',
                      //   display: 'inline-grid',
                      //   fontSize: '16px',
                      //   lineHeight: '24px',
                      // }}
                    >
                      {DisplayFormattedText(part?.location_name, 13)}
                    </div>
                    <div
                      className="grid-child-element set form"
                      style={{
                        padding: "14px 9px",
                        minWidth: "100px",
                      }}
                      // className="ml-4"
                      // style={{
                      //   width: '145px',
                      //   minWidth: '145px',
                      //   display: 'inline-grid',
                      //   fontSize: '16px',
                      //   lineHeight: '24px',
                      // }}
                    >
                      {part?.quantity
                        ? DisplayFormattedText(String(part?.quantity), 13)
                        : 0}{" "}
                      {part?.unitType ? HandleUnitType(part?.unitType) : ""}
                    </div>
                    <div
                      className={
                        selectedLanguage == "en"
                          ? "grid-child-element set form mt-2"
                          : "grid-child-element set form mt-2"
                      }
                      style={{
                        minWidth: "100px",
                      }}
                      // className="ml-16"
                      // style={{
                      //   fontSize: '16px',
                      //   lineHeight: '24px',
                      //   width: '99px',
                      //   minWidth: '99px',
                      //   display: 'inline-block',
                      // }}
                    >
                      <span className="number-cost-set">
                        {DisplayFormattedText(String(part?.unitCost), 7)}
                      </span>
                      <span
                        className={` align-items-center justify-content-center ${
                          selectedLanguage == "en" ? "ml-2" : "mr-2"
                        }`}
                        style={{
                          backgroundColor: "#E9F2FF",
                          color: "#0C66E4",
                          padding: "9px 3px 9px 2px",
                          fontSize: "14px",
                        }}
                      >
                        {organisationCurrency}
                      </span>
                    </div>

                    <div
                      className={
                        selectedLanguage == "en"
                          ? "grid-child-element set form mt-2"
                          : "grid-child-element set form mt-2"
                      }
                      style={{ minWidth: "100px" }}
                      // className="ml-16"
                      // style={{
                      //   fontSize: '16px',
                      //   lineHeight: '24px',
                      //   width: '99px',
                      //   minWidth: '99px',
                      //   display: 'inline-block',
                      // }}
                    >
                      <span className="number-cost-set">
                        {DisplayFormattedText(String(part?.totalCost), 7)}
                      </span>
                      <span
                        className={` align-items-center justify-content-center ${
                          selectedLanguage == "en" ? "ml-2" : "mr-2"
                        }`}
                        style={{
                          backgroundColor: "#E9F2FF",
                          color: "#0C66E4",
                          padding: "9px 3px 9px 2px",
                          fontSize: "14px",
                        }}
                      >
                        {organisationCurrency}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            <div
              className="mt-4"
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <span>
                <b>{t.workOrders.form.sub_total}</b>
              </span>
              <span>
                <b>{data?.attributes?.report?.data?.attributes?.subTotal}</b>
              </span>
              <span>
                <b>{`${organisationCurrency}s`}</b>
              </span>
            </div>
          </>
        ) : null} */}

          {/* {data?.attributes?.work_request?.data?.attributes ? (
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workRequests.work_request}
             
            </label>
            <p
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push(
                  `/work-requests?id=${data?.attributes?.work_request?.data?.attributes?.countId}`
                )
              }
              className={`workorder-intro-value set-width d-flex  flex-column  ${
                selectedLanguage == "en" ? "text-left" : "text-right"
              }`}
            >
              {data?.attributes?.work_request?.data?.attributes ? (
                <div
                  className={`work-order-link  ${
                    selectedLanguage == "en" ? "text-left" : "text-right"
                  }`}
                  basic

                  // onClick={() =>
                  //   history.push(`/procedures?id=${each?.id}`)
                  // }
                >
                  <span className="">
                    {data?.attributes?.work_request?.data?.attributes?.title}
                  </span>
                </div>
              ) : (
                t.common.na
              )}
            </p>
          </div>
        ) : null} */}

          {/* <Divider /> */}
          {data?.attributes?.status !== "open" ? (
            <div className="notes-container mt-5">
              <p className="d-flex mb-2">
                {" "}
                <CloseNoteIcon />{" "}
                <span className="ml-1 font-weight-bold">
                  {" "}
                  {t.workOrders?.form?.close_out}
                </span>
              </p>
              <p>
                {data?.attributes?.closeOutNotes
                  ? data?.attributes?.closeOutNotes
                  : t.common.na}
              </p>
            </div>
          ) : null}

          <div className="notes-container mt-5">
            <p className="d-flex mb-2">
              {" "}
              <Attachment />{" "}
              <span className="ml-2 mr-2 workorder-intro-label">
                {" "}
                {t.workOrders.form.file}
              </span>
            </p>

            <p className="workorder-intro-value">
              {data?.attributes?.attachments?.data?.length
                ? data.attributes.attachments.data.map((attachment, index) => (
                    <React.Fragment key={index}>
                      <p
                        className={`detail-intro-link mb-1 ${
                          selectedLanguage == "en" ? "text-left" : "text-right"
                        }`}
                        basic
                        onClick={() => {
                          window.open(attachment?.attributes?.url, "_blank");
                        }}
                      >
                        {attachment?.attributes?.name}
                      </p>
                    </React.Fragment>
                  ))
                : t.common.na}
            </p>
          </div>

          {/* <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label ">
            {t.workOrders.form.file}
           
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.attachments?.data?.length
              ? data.attributes.attachments.data.map((attachment, index) => (
                  <React.Fragment key={index}>
                    <p
                      className={`detail-intro-link mb-1 ${
                        selectedLanguage == "en" ? "text-left" : "text-right"
                      }`}
                      basic
                      onClick={() => {
                        window.open(attachment?.attributes?.url, "_blank");
                      }}
                    >
                      {attachment?.attributes?.name}
                    </p>
                  </React.Fragment>
                ))
              : t.common.na}
          </p>
        </div> */}

          {/* <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label ">
              {t.assets.filter.qrcode}
             
            </label>
            <p className="workorder-intro-value set-width">
              <div className="d-flex">
                <div>
                  <QRComponentToPrint
                    titleData={t.assets.table.ID}
                    titleName={t.assets.table.name}
                    fromWO={true}
                    ref={qrRef}
                    data={{
                      ...data,
                      attributes: {
                        name: data?.attributes?.title,
                        count: data?.attributes?.countId,
                        serialNumber: data?.id,
                      },
                    }}
                    titleString={"WO-"}
                  />
                </div>
                <div className="m-3">
                  <ReactToPrint
                    trigger={() => (
                      <span
                        className="c-pointer"
                        style={{ position: "relative" }}
                        onMouseOver={() => setPrintTooltip(true)}
                        onMouseLeave={() => setPrintTooltip(false)}
                      >
                        <Printer />
                        <Tooltip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position="bottom center"
                          show={showPrintTooltip}
                          textBoxWidth="35px"
                          arrowAlign="center"
                          fontSize="9px"
                          fontWeight="400"
                          fontFamily="Roboto"
                          padding="5px 5px 5px 8px"
                          borderRadius="6px"
                          static
                          moveDown="10px"
                        >
                          <span className="work-order-preview-tooltip">
                            {t.common.print}
                          </span>
                        </Tooltip>
                      </span>
                    )}
                    content={() => {
                      return qrRef.current;
                    }}
                  />
                  <span
                    className="ml-2 mr-2 c-pointer"
                    style={{ position: "relative" }}
                    onMouseOver={() => setDownloadTooltip(true)}
                    onMouseLeave={() => setDownloadTooltip(false)}
                    onClick={() => {
                      qrRef.current.exposeDownloadFunction();
                    }}
                  >
                    <FileDownloadIcon />
                    <Tooltip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position="bottom center"
                      show={showDownloadTooltip}
                      textBoxWidth="92px"
                      arrowAlign="center"
                      fontSize="9px"
                      fontWeight="400"
                      fontFamily="Roboto"
                      padding="5px 5px 5px 8px"
                      borderRadius="6px"
                      static
                      moveDown="10px"
                    >
                      <span className="work-order-preview-tooltip">
                        {t.common.download_QR}
                      </span>
                    </Tooltip>
                  </span>
                </div>
              </div>
            </p>
          </div> */}
          {/* <label className="qr-code-container label-item mt-5">
          {t.assets.filter.qrcode}
        </label> */}

          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label  grey">
                {t.workOrders.table.createdByUser}
              </label>
              <p
                style={{ marginTop: "-1px", fontSize: "12px" }}
                className="workorder-intro-value set-width grey"
              >
                {data?.attributes?.createdByUser?.data?.attributes?.firstName &&
                data?.attributes?.createdByUser?.data?.attributes?.lastName
                  ? data?.attributes?.createdByUser?.data?.attributes
                      ?.firstName +
                    " " +
                    data?.attributes?.createdByUser?.data?.attributes?.lastName
                  : t.common.na}
                {", "}
                <Moment
                  format="DD/MM/YYYY LT"
                  date={data?.attributes?.createdAt}
                />
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label   grey">
                {t.common.update_by}
              </label>
              <p
                style={{ marginTop: "-1px", fontSize: "12px" }}
                className="workorder-intro-value set-width grey"
              >
                {data?.attributes?.updatedByUser?.data
                  ? data?.attributes?.updatedByUser?.data?.attributes
                      ?.firstName +
                    " " +
                    data?.attributes?.updatedByUser?.data?.attributes
                      ?.lastName +
                    ", " +
                    moment(data?.attributes?.updatedAt).format("DD/MM/YYYY LT")
                  : t.common.na}
              </p>
            </div>
          </div>
        </div>

        <StatusChangeModal
          handleSave={handleSave}
          loading={loading}
          onHoldFlag={onHoldFlag}
          selectedLanguage={selectedLanguage}
          setMessage={setMessage}
          message={message}
          t={t}
          openModal={openModal}
          openFlag={openFlag}
          closeFlag={closeFlag}
          reOpenFlag={reOpenFlag}
          inCancleFlag={inCancleFlag}
          setModal={setModal}
          approveModal={approveModal}
          inProgressFlag={inProgressFlag}
        />
        <Modal
          size="tiny"
          open={DeleteSuccessModal}
          onClose={() => {
            toggleDeleteSuccessModal("close");
          }}
          style={{ minHeight: "195px" }}
          className={`delete-result-modal success`}
        >
          <div className="text-right mt-5 mr-5 ml-5">
            <span
              className="c-pointer"
              onClick={() => {
                toggleDeleteSuccessModal("close");
              }}
            >
              <CloseIcon />
            </span>
          </div>
          <Modal.Content>
            <div style={{ textAlign: "center", marginTop: "-9px" }}>
              <div className="mb-5">
                {inProgressFlag ? (
                  <Success />
                ) : !onHoldFlag ? (
                  approveModal ? (
                    <Success />
                  ) : (
                    <Success />
                  )
                ) : (
                  <Success />
                )}
              </div>
              <p className="status-modal-popup-message">
                {/* {t.workOrders.details_view.work_order_no}{' '} */}
                {inCancleFlag
                  ? t.workOrders.success_cancel
                  : inProgressFlag
                  ? t.workOrders.success_progress
                  : !onHoldFlag
                  ? approveModal
                    ? t.workOrders.success_approve
                    : t.workOrders.success_decline
                  : t.workOrders.success_hold}{" "}
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions></Modal.Actions>
        </Modal>

        <AssigneeModal
          fetchPeopleOptions={fetchPeopleOptions}
          t={t}
          onValuesChange={onValuesChange}
          options={options}
          setAssignData={setAssignData}
          setValidation={setValidation}
          setAssignModal={setAssignModal}
          validation={validation}
          assignModal={assignModal}
          assignData={assignData}
          fetchTeamsOptions={fetchTeamsOptions}
          selectedLanguage={selectedLanguage}
          handleAssign={handleAssign}
        />
      </div>{" "}
    </>
  );
};

export default Details;
