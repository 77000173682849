import React, { useEffect, useState } from "react";
import { CloseIcon, DeleteIcon } from "../../../../../Assets/Icons/svg";
import { Icon, Input } from "semantic-ui-react";
import {
  deletePartFromWO,
  editWoPartData,
  handleKeyDown,
} from "../../../../config/functions";
import { selectTranslations } from "../../../../config/i18n/slice";
import { usePermissionsSimplified } from "../../../../../hooks/usePermissionsSimplified";
import useDisplayFormattedText from "../../../../../hooks/useDisplayFormattedText";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useDeletePermissionModal from "../../../../../hooks/Messages/useDeletePermissionModal";

const PartData = ({
  handleDecimalPoints,
  setSubTotal,
  roleData,
  allIds,
  HandleUnitType,
  activePartIndex,
  setActivePartIndex,
  selectedLanguage,
  organisationCurrency,
  selectedmyData,
  setSelectedmyData,
  getAllParts,
  data,
}) => {
  const t = useSelector(selectTranslations);
  const history = useHistory();
  const { DisplayFormattedText } = useDisplayFormattedText();
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();

  const [deleteData, setDeleteData] = useState({
    temp_id: "",
    id: "",
    type: "",
  });

  const [selectedFinalPartData, setSelectedFinalPartData] = useState(
    JSON.parse(JSON.stringify(selectedmyData))
  );

  useEffect(() => {
    setSelectedFinalPartData(JSON.parse(JSON.stringify(selectedmyData)));
  }, [selectedmyData]);
/**
 * The function `checkPermission` determines whether a user has permission based on their role and
 * location.
 */

  const checkPermission = (labor) => {
    if (roleData == "super_admin") {
      return true;
    } else if (allIds.includes(labor.location)) {
      return true;
    } else {
      return false;
    }
  };


/**
 * The `deleteHandler` function handles the deletion of a part from a work order and displays success
 * or error messages accordingly.
 */
  const deleteHandler = async () => {
    const response = await deletePartFromWO(
      deleteData?.temp_id,
      deleteData?.id,
      deleteData?.type
    );

    if (response.status == 200) {
      toast.success(
        deleteData?.type ? t.parts.delete_success : t.sets.delete_success
      );

      window.dispatchEvent(new Event("triggerNotificationRefresh"));
      setSelectedmyData([]);
      setDeleteData({
        temp_id: "",
        id: "",
        type: "",
      });
      getAllParts();
      setActivePartIndex({});
      setSelectedFinalPartData([]);
    } else if (response?.error && response?.error?.message) {
      setDeleteData({
        temp_id: "",
        id: "",
        type: "",
      });
      toast.error(response?.error?.message);
    } else {
      setDeleteData({
        temp_id: "",
        id: "",
        type: "",
      });
      toast.error(t.common.something_wrong);
    }
  };

/**
 * The `handleDelete` function sets a delete permission modal to true and prepares data for deletion
 * based on the part type.
 */
  const handleDelete = async (part, index) => {
  if(['cancelled','completed'].includes(data?.attributes?.status))  {
    return
  }
    setDeletePermissionModal(true);

    setDeleteData({
      temp_id: part?.temp_id,
      id: data?.id,
      type: part?.type === "Part" ? true : false,
    });
  };


/**
 * The function `handleSubmitClick` handles the submission of updated part data, updating state and
 * displaying toast messages based on the response.
 */
  const handleSubmitClick = async (part, index) => {
    let prevPartsData = [...selectedmyData];

    if (prevPartsData[index]?.quantity == Number(part?.quantity)) {
      return;
    }

    const response = await editWoPartData(
      part?.part,
      part?.location,
      Number(part?.unitCost),
      Number(part?.quantity),
      Number(part?.totalCost),
      data?.id,
      part?.type,
      part?.temp_id
    );

    if (response && response.status == 200) {
      window.dispatchEvent(new Event("triggerNotificationRefresh"));
      
      let allParts = [...selectedFinalPartData];

      let prevPartsData = [...selectedmyData];

      let currentPart = allParts[index];

      prevPartsData[index] = currentPart;
      setSelectedmyData(prevPartsData);

      const total = prevPartsData?.reduce((acc, item) => {
        return acc + Number(item.totalCost); // Ensure totalCost is treated as a number
      }, 0);

      setSubTotal(total);
      setActivePartIndex({
        ...activePartIndex,
        [index]: false,
      });

      toast.success(
        part?.type === "Part"
          ? t.workOrders.form.part_qty_update
          : t.workOrders.form.set_qty_update
      );
    } else if (response?.error && response?.error?.message) {
      let allParts = [...selectedFinalPartData];

      let prevPartsData = [...selectedmyData];

      let currentPart = prevPartsData[index];

      allParts[index] = currentPart;
      setSelectedFinalPartData(allParts);

      setActivePartIndex({
        ...activePartIndex,
        [index]: false,
      });

      toast.error(response?.error?.message);
    } else {
      let allParts = [...selectedFinalPartData];

      let prevPartsData = [...selectedmyData];

      let currentPart = prevPartsData[index];

      allParts[index] = currentPart;
      setSelectedFinalPartData(allParts);

      toast.error(t.common.something_wrong);
    }
  };

/**
 * The function `handleDetailsPartQtyChanges` updates the quantity and total cost of a part based on a
 * checkbox input.
 */
  const handleDetailsPartQtyChanges = (
    row,
    checked,
    selectedFinalPartData = [],
    index = null
    // setFieldTouched
  ) => {
    let allParts = [...selectedFinalPartData];
    let prevdata = selectedmyData;

    let currentPart = { ...row };
    let value = parseFloat(checked).toFixed(4);

    if (checked) {
      currentPart.quantity = Number(value);
      // updatedInitRow.quantity = Number(value);
      currentPart.totalCost = handleDecimalPoints(
        Number(value) * Number(row.unitCost)
      );
    } else {
      currentPart.quantity = null;
      // updatedInitRow.quantity = Number(value);
      currentPart.totalCost = Number(row.unitCost);
    }

    allParts[index] = currentPart;

    //   setSelectedTablePartData(allParts);
    setSelectedFinalPartData(allParts);
    //   setPrevPartsData(allParts);
  };

  return (
    <>
      {selectedFinalPartData?.length ? (
        selectedFinalPartData?.map((part, index) => (
          <>
            <div
              className="grid-container-element set wo-form2 form"
              key={index}
            >
              <div
                className="grid-child-element set form"
                style={{
                  padding: "14px 9px",
                  minWidth: "86px",
                }}
              >
                {part?.countId ? part.countId : null}
              </div>

              <div
                className="grid-child-element set form"
                style={{
                  padding: "14px 9px",
                  minWidth: "140px",
                }}
              >
                {userPermissions?.part?.view ? (
                  <b
                    style={{ color: "#0c66e4", wordBreak: "break-all" }}
                    className="c-pointer"
                    onClick={() => history.push(`/parts?id=${part?.countId}`)}
                  >
                    {" "}
                    {DisplayFormattedText(part?.name, 13)}
                  </b>
                ) : (
                  <b> {DisplayFormattedText(part?.name, 13)}</b>
                )}
              </div>

              <div
                className="grid-child-element set form"
                style={{
                  padding: "14px 9px",
                  minWidth: "140px",
                }}
              >
                {checkPermission(part) ? (
                  <b
                    style={{ color: "#0c66e4", wordBreak: "break-all" }}
                    className="c-pointer"
                    onClick={() =>
                      history.push(`/locations?id=${part?.location_cid}`)
                    }
                  >
                    {" "}
                    {DisplayFormattedText(part?.location_name, 13)}
                  </b>
                ) : (
                  <b> {DisplayFormattedText(part?.location_name, 13)}</b>
                )}
              </div>
              <div
                className="grid-child-element set form"
                style={{
                  padding: "11px 9px",
                  minWidth: "140px",
                }}
              >
                <div>
                  <>
                    <Input
                      style={{ width: "75px" }}
                      autoComplete="new-password"
                      type="number"
                      disabled={['cancelled','completed'].includes(data?.attributes?.status)}
                      onKeyDown={(evt) => handleKeyDown(evt)}
                      onWheel={(e) => e.target.blur()}
                      value={part?.quantity}
                      onChange={(e, value) => {
                        handleDetailsPartQtyChanges(
                          part,
                          e.target.value,
                          selectedFinalPartData,
                          index
                        );
                      }}
                      onBlur={() => {
                        if (part?.quantity) {
                          handleSubmitClick(part, index);
                        } else {
                          console.log(
                            "No quantity available, skipping API call."
                          );
                        }
                      }}
                    >
                      <input />
                    </Input>
                    <span className="ml-2 mr-2">
                      {" "}
                      {part?.type == "Part"
                        ? part?.unitType
                          ? HandleUnitType(part?.unitType)
                          : ""
                        : null}
                    </span>
                  </>
                </div>
                {!part?.quantity ? (
                  <div className="label-error">{t.common.required_field}</div>
                ) : null}
              </div>
              <div
                className={
                  selectedLanguage == "en"
                    ? "grid-child-element set form mt-2"
                    : "grid-child-element set form mt-2"
                }
                style={{
                  minWidth: "130px",
                }}
              >
                <div
                  style={{
                    width: "75px",
                    display: "inline-block",
                    textAlign: "end",
                  }}
                  className="number-cost-set"
                >
                  {DisplayFormattedText(String(part?.unitCost), 7)}
                </div>
                <span
                  className={` align-items-center justify-content-center ${
                    selectedLanguage == "en" ? "ml-2" : "mr-2"
                  }`}
                  style={{
                    backgroundColor: "#E9F2FF",
                    color: "#0C66E4",
                    padding: "9px 3px 9px 2px",
                    fontSize: "14px",
                  }}
                >
                  {organisationCurrency}
                </span>
              </div>

              <div
                className={
                  selectedLanguage == "en"
                    ? "grid-child-element set form mt-2"
                    : "grid-child-element set form mt-2"
                }
                style={{
                  minWidth: "130px",
                }}
              >
                <div
                  style={{
                    width: "75px",
                    display: "inline-block",
                    textAlign: "end",
                  }}
                  className="number-cost-set"
                >
                  {DisplayFormattedText(String(part?.totalCost), 7)}
                </div>
                <span
                  className={` align-items-center justify-content-center ${
                    selectedLanguage == "en" ? "ml-2" : "mr-2"
                  }`}
                  style={{
                    backgroundColor: "#E9F2FF",
                    color: "#0C66E4",
                    padding: "9px 3px 9px 2px",
                    fontSize: "14px",
                    minWidth: "33px",
                    textAlign: "end",
                  }}
                >
                  {organisationCurrency}
                </span>
              </div>
              <div
                onClick={() => handleDelete(part, index)}
                className={`grid-child-element set form mt-2 ${ ['cancelled','completed'].includes(data?.attributes?.status) ? "" : "c-pointer"}`}
              >
                <DeleteIcon />
              </div>
            </div>
          </>
        ))
      ) : (
        <div>{t.common.na}</div>
      )}
      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={deleteData?.type ? t.parts.add : t.sets.add}
      >
        <span>
          {deleteData?.type
            ? t.parts.delete_part_statement
            : t.sets.delete_set_statement}
        </span>
      </DeletePermissionModal>
    </>
  );
};

export default PartData;
