import React from 'react'
import { Modal } from 'semantic-ui-react';
import { CloseIcon } from '../../../../../Assets/Icons/svg';

const ProcedureModal = ({procedureText, setData, data,opemModal,handleAddProcedure,setOpenModal,selectedLanguage,t,handleProcedureAdd}) => {
  return (
    <Modal
    size="tiny"
    open={opemModal}
    className="assets-modal"
    onClose={() => {
        setData({
        ...data,
        procedure:procedureText,
        })
        setOpenModal(false);
    }}
    style={{ minHeight: "191px" }}
  >
    <div className="header-container">
      <span className="assets-header">
        
          { t.workOrders.procedure_modal.title}
         
      </span>
      <div>
        <span
          className="c-pointer text-right"
          onClick={() => {
            setData({
                ...data,
                procedure:procedureText,
                })
            setOpenModal(false);
          }}
        >
          <CloseIcon />
        </span>
      </div>
    </div>

    <Modal.Content>
      <p className="assets-label ml-1 mt-3" style={{ lineHeight: "24px" }}>
      { t.workOrders.procedure_modal.sub_title}
      </p>

<b className="assets-label ml-1 mt-3" style={{ lineHeight: "24px",fontWeight:"500" }}> { t.workOrders.procedure_modal.important}</b>

<p className="assets-label ml-1 mt-3" style={{ lineHeight: "24px" }}>{ t.workOrders.procedure_modal.sub_title1}</p>
<p className="assets-label ml-1 mt-3" style={{ lineHeight: "24px" }}>{ t.workOrders.procedure_modal.sub_title2}</p>
<p className="assets-label ml-1 mt-3" style={{ lineHeight: "24px" }}> { t.workOrders.procedure_modal.proceed}</p>
    </Modal.Content>
    <div
      className={`modal-button-container mt-3 ${
        selectedLanguage == "en" ? "mr-6" : "ml-10"
      }`}
    >
      <button
        onClick={() => {

            setData({
                ...data,
                procedure:procedureText,
                })
            setOpenModal(false);
        //   setToggleStatus(
        //     data?.attributes?.status == "active" ? true : false
        //   );
        }}
        style={{ color: "black" }}
        className="modal-form-action-button"
      >
        {t.common.cancel}
      </button>

      <button
        onClick={() => {
          setOpenModal(false);
        //   handleAddProcedure();
        }}
        className="modal-form-action-button light-blue"
      >
       { t.workOrders.procedure_modal.confirm}
      </button>
    </div>
  </Modal>
  )
}

export default ProcedureModal