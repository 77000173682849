import React from "react";
import { Dropdown, Form, Modal } from "semantic-ui-react";
import Asterisk from "../../../../components/common/Asterisk";
import { CloseIcon } from "../../../../../Assets/Icons/svg";

const AssigneeModal = ({
  onValuesChange,
  handleAssign,
  selectedLanguage,
  fetchTeamsOptions,
  options,
  assignModal,
  setAssignModal,
  validation,
  assignData,
  t,
  setValidation,
  setAssignData,
  fetchPeopleOptions,
}) => {
  return (
    <Modal
      className="assets-modal"
      size="tiny"
      open={assignModal}
      onClose={() => {
        setAssignModal(false);
        setValidation({
          people: {
            error: false,
            pattern: (code) => code != "",
            skipped: false,
            message: t.common.required_field,
          },
        });
        setAssignData({
          people: [],
          teams: [],
        });
      }}
    >
      <div className="header-container">
        <span className="assets-header">{t.workOrders.assign_people}</span>
        <div>
          <span
            className="c-pointer text-right"
            onClick={() => {
              setAssignModal(false);
              setValidation({
                people: {
                  error: false,
                  pattern: (code) => code != "",
                  skipped: false,
                  message: t.common.required_field,
                },
              });
              setAssignData({
                people: [],
                teams: [],
              });
            }}
          >
            <CloseIcon />
          </span>
        </div>
      </div>

      <Modal.Content>
        {/* <label>{t.categories.import_categories}</label> */}

        <div className="assignee-data">
          <Form.Field>
            <label className="label-item mt-5">
              {t.workOrders.form.people}
              <Asterisk color="#172B4D" />
            </label>
            <Dropdown
              placeholder={t.workOrders.form.people_placeholder}
              fluid
              selection
              noResultsMessage={t.common.no_results_found}
              search
              error={validation.people.error && validation.people.skipped}
              // ////allowAdditions
              multiple
              maxHeight={50}
              className="custom-dropdown-menu"
              clearable
              options={options?.people?.initOptions}
              value={assignData.people}
              onChange={onValuesChange("people")}
              onOpen={fetchPeopleOptions}
              onBlur={(e) => {
                if (validation.people.pattern(assignData.people)) {
                  setValidation({
                    ...validation,
                    people: {
                      ...validation.people,
                      error: false,
                      skipped: true,
                    },
                  });
                } else {
                  setValidation({
                    ...validation,
                    people: {
                      ...validation.people,
                      error: true,
                      skipped: true,
                    },
                  });
                }
              }}
            />

            {validation.people.error && validation.people.skipped ? (
              <div className="label-error" style={{ marginTop: "-15px" }}>
                {validation.people.message}
              </div>
            ) : (
              ""
            )}
          </Form.Field>

          <Form.Field>
            <label className="label-item mt-4">{t.workOrders.form.teams}</label>
            <Dropdown
              placeholder={t.workOrders.form.teams_placeholder}
              fluid
              selection
              noResultsMessage={t.common.no_results_found}
              search
              multiple
              clearable
              upward
              className="custom-dropdown-menu"
              // ////allowAdditions
              // onAddItem={handleAddTeam}
              options={options.teams?.initOptions}
              value={assignData.teams}
              onChange={onValuesChange("teams")}
              onOpen={fetchTeamsOptions}
            />
          </Form.Field>
        </div>

        {/* <Input
    id="barcodePopup"
    type="file"
    fluid
    //   value={selectedFile}
    onChange={(e) => {
      if (e.target.files && e.target.files.length) {
        setSelectedFile(e.target.files[0]);
      }
    }}
  />
  <div className="buttun-type-link mt-4" basic onClick={handleDownload}>
    {t.categories.download_sample}
  </div> */}
      </Modal.Content>

      <div
        className={`modal-button-container ${
          selectedLanguage == "en" ? "mr-6" : "ml-10"
        }`}
      >
        <button
          onClick={() => {
            setAssignModal(false);
            setValidation({
              people: {
                error: false,
                pattern: (code) => code != "",
                skipped: false,
                message: t.common.required_field,
              },
            });
            setAssignData({
              people: [],
              teams: [],
            });
          }}
          className="modal-form-action-button"
        >
          {t.assets.form.cancel}
        </button>

        <button
          onClick={() => handleAssign()}
          className="modal-form-action-button light-blue"
        >
          {t.common.add}
        </button>
      </div>
      {/* <Modal.Actions>
  <Button color="black" onClick={() => handleCategoryCreate()}>
    {t.categories.import}
  </Button>
  <Button negative onClick={() => setModalOpenState(false)}>
    {t.common.cancel}
  </Button>
</Modal.Actions> */}
    </Modal>
  );
};

export default AssigneeModal;
