import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../../config/i18n/slice';
import { Formik, Form as FormikForm, ErrorMessage } from 'formik';
import {
  Card,
  CardContent,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  Grid,
  GridColumn,
  Input,
  Modal,
  Field
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  ActionBtnIcon,
  AddIconWithCircle,
  CloseIcon,
  InfoIcon,
  InfoIconBlue,
  POGreyIcon,
  RestockIcon,
  TextSnippet,
} from '../../../../Assets/Icons/svg';
import ToolTip from 'react-power-tooltip';
import { useHistory } from 'react-router';
import {
  DropdownToggle,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
} from 'reactstrap';
import Asterisk from '../../../components/common/Asterisk';
import {
  currencyDecimalPlaces,
  handleKeyDown,
  restockQuantity,
  uploadFile,
} from '../../../config/functions';
import { useDropzone } from 'react-dropzone';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import Add from '../../PurchaseOrders/add';

//File Upload Function for restock Quantity
const FileUpload = ({
  onFileChange,
  onCertificateFileChange,
  name = '',
  attachments = {},
  setAttachments = () => {},
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      onFileChange(acceptedFiles, name);
    },
  });
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === 'image' ? (
        attachments?.image?.name ? (
          // <div className="image-dropzone file d-flex justify-content-between text-center">
          //   <div>
          //     <label style={{ fontSize: '16px' }} className="mb-0">
          //       <span
          //         className={`${
          //           selectedLanguage == 'en' ? 'ml-5 mr-8' : 'ml-8 mr-5'
          //         }`}
          //       >
          //         <AddedFileIcon />
          //       </span>

          //       {attachments?.image?.name}
          //     </label>
          //   </div>

          //   <div
          //     className="mr-5 ml-5 mt-1 c-pointer"
          //     onClick={(e) => {
          //       e.stopPropagation();
          //       setAttachments({
          //         ...attachments,
          //         image: null,
          //       });
          //     }}
          //   >
          //     <CloseIcon />
          //   </div>
          // </div>

          <div
            className={`image-dropzone file d-flex justify-content-between text-center ${'mt-5'}`}
          >
            <div>
              <label style={{ fontSize: '16px' }} className="mb-0">
                <span
                  className={`${
                    selectedLanguage == 'en' ? 'ml-5 mr-8' : 'ml-8 mr-5'
                  }`}
                >
                  <TextSnippet />
                </span>

                {attachments?.image?.name}
              </label>
            </div>

            <div
              className="mr-5 ml-5 mt-1 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  image: null,
                });
              }}
            >
              <CloseIcon />
            </div>
          </div>
        ) : (
          <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <span className="mt-1">
                <TextSnippet />
              </span>
              <div className="pl-8 pr-8">
                <p className="p1 m-0">{t.workOrders.form.file}</p>
                <p className="p2 m-0">{t.parts.form.attach_file}</p>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        )
      ) : null}
    </div>
  );
};
/*
 * The Inventory component in JavaScript manages inventory data, allows restocking, and displays relevant information with tooltips and modals.
 */
const Inventory = ({
  data,
  setToggleAsideModal,
  toggleAsideModal,
  untoggle,
  setFilterOptions,
  setModalWidth,
  modalWidth,
  allIds,
  role,
  setTogglePreviw
}) => {
  const t = useSelector(selectTranslations);
  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const history = useHistory();
  const [showAvlQtyTooltip, setAvlQtyTooltip] = useState({});
  const [showInstockQtyTooltip, setInstockQtyTooltip] = useState({});
  const [showOrderQtyTooltip, setOrderQtyTooltip] = useState({});
  const [showResQtyTooltip, setResQtyTooltip] = useState({});
  const [showMinQtyTooltip, setMinQtyTooltip] = useState({});
  const [showMaxQtyTooltip, setMaxQtyTooltip] = useState({});
  const [openRestockModal, setOpenRestockModal] = useState(false);
  const [POData, setPOData] = useState([]);

  const [attachments, setAttachments] = useState({
    image: null,
  });

  let filteredInventory = [];


  const [restokeData, setRestokeData] = useState({
    unitCost: data?.attributes?.unitCost || null,
    location: '',
    // area: '',
    quantity: null,
    totalCost: null,
  });

  const [options, setOptions] = useState({
    locations: [],
    area: {
      initOption: [],
      option: [],
    },
  });

  if(!(role === "super_admin")){
     filteredInventory = data?.attributes?.inventory?.filter(item => 
      allIds?.includes(item?.location?.data?.id)
    );
  }else{
    filteredInventory = data?.attributes?.inventory
  }



  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const onFileChange = (files, name) => {
    // console.log('show', e.target.files[0]);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return 'JOD';
    }
  });

  // const restokeHandle = async () => {
  //   let the_options_location = [];
  //   let the_options_area = [];

  //   let inventoryData = data?.attributes?.inventory;
  //   if (inventoryData?.length > 0) {
  //     inventoryData.forEach((el) => {
  //       the_options_location.push({
  //         key: `${el?.location?.data?.id}`,
  //         value: `${el?.location?.data?.id}`,
  //         text: `${el?.location?.data?.attributes?.name}`,
  //       });

  //       the_options_area.push({
  //         key: `${el?.area?.data?.id}`,
  //         value: `${el?.area?.data?.id}`,
  //         text: `${el?.area?.data?.attributes?.name}`,
  //         location: `${el?.location?.data?.id}`,
  //       });
  //     });
  //   }

  //   setOptions({
  //     ...options,
  //     locations: the_options_location,
  //     area: {
  //       initOption: the_options_area,
  //       option: the_options_area,
  //     },
  //   });
  //   // data?.attributes?.inventory.map((data, index) => {

  //   // })
  //   setOpenRestockModal(true);
  // };

  const restokeHandle = async () => {
    let the_options_location = [];
    let the_options_area = [];
    let uniqueLocations = new Set(); // To keep track of unique locations

    let inventoryData = data?.attributes?.inventory;

    if (inventoryData?.length > 0) {
      inventoryData.forEach((el) => {
        const locationId = el?.location?.data?.id;
        if ((!(role === "super_admin") ?allIds.includes(locationId) : true) && !uniqueLocations.has(locationId)) {
          uniqueLocations.add(locationId); // Add locationId to the Set
          the_options_location.push({
            key: `${locationId}`,
            value: `${locationId}`,
            text: `${el?.location?.data?.attributes?.name}`,
          });
        }
      });
    }
    setOptions({
      ...options,
      locations: the_options_location,
    });
    setOpenRestockModal(true);
  };

  const HandleUnitType = (data) => {
    let unitType;
    if (data) {
      unitType = allQtyOptions.find((el) => el.value == data);
    }
    return data ? unitType?.text : null;
  };

  const handleSubmit = async (values) => {
    const inventoryImageReponse =
      attachments.image && attachments.image.isChanged
        ? await uploadFile([attachments.image.file])
        : null;

    let file =
      inventoryImageReponse?.status == 200
        ? inventoryImageReponse.data[0].id
        : null;

    const response = await restockQuantity(
      values?.location ? Number(values.location) : null,
      values?.unitCost ? Number(values.unitCost) : null,
      values?.totalCost ? Number(values.totalCost) : null,
      // values?.area ? Number(values.area) : null,
      values?.quantity ? Number(values?.quantity) : null,
      file,
      currentUserId,
      organisationId,
      data?.id
    );

    if (response.status == 200) {
      setOpenRestockModal(false);
      setFilterOptions({
        vendors: [],
        serialNumber: '',
        categories: [],
        teams: [],
        status: [''],
        partType: [''],
        assets: [],
        locations: [],
        area: [],
        serialNumber: '',
      });
      toast.success(t.parts.form.restock_success);
      untoggle();
      setToggleAsideModal(false);
      setRestokeData({
        unitCost: null,
        location: '',
        area: '',
        quantity: null,
        totalCost: null,
      });

      // setSuccessModal(true);
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        toast.error(response.error.message);

        setOpenRestockModal(false);
      } else {
        toast.error(t.common.something_wrong);
        setOpenRestockModal(false);
      }
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  let allQtyOptions = [
    {
      key: 0,
      value: 'pieces',
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: 'items',
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: 'units',
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: 'liters (L)',
      text: selectedLanguage == 'en' ? 'l' : 'لتر',
    },
    {
      key: 4,
      value: 'gallons (gal)',
      text: selectedLanguage == 'en' ? 'gal' : 'جالون',
    },
    {
      key: 5,
      value: 'cubic meters (m³)',
      text: selectedLanguage == 'en' ? 'm³' : 'م³',
    },
    {
      key: 6,
      value: 'cubic feet (ft³)',
      text: selectedLanguage == 'en' ? 'ft³' : 'ق³',
    },
    {
      key: 7,
      value: 'barrels',
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: 'kilograms (kg)',
      text: selectedLanguage == 'en' ? 'kg' : 'كجم',
    },
    {
      key: 9,
      value: 'pounds (lb)',
      text: selectedLanguage == 'en' ? 'lb' : 'رطل',
    },

    {
      key: 10,
      value: 'tons',
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: 'metric tonnes',
      text: t.parts.mass_unit.m_tons,
    },
  ];

  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.trunc(value * factor) / factor;
    const formattedValue = truncatedValue.toFixed(decimalPlaces);
    
    // Remove trailing zeros after the decimal point
    if (formattedValue.indexOf('.') !== -1) {
      return formattedValue.replace(/\.?0+$/, '');
    }
  
    return formattedValue;
  };
  // let allQtyOptions = [
  //   {
  //     key: 0,
  //     value: 'pieces',
  //     text: t.parts.qty_unit.piece,
  //   },
  //   {
  //     key: 1,
  //     value: 'items',
  //     text: t.parts.qty_unit.items,
  //   },
  //   {
  //     key: 2,
  //     value: 'units',
  //     text: t.parts.qty_unit.unit,
  //   },
  //   {
  //     key: 3,
  //     value: 'liters (L)',
  //     text: selectedLanguage == 'en' ? 'L' : 'لتر',
  //   },
  //   {
  //     key: 4,
  //     value: 'gallons (gal)',
  //     text: selectedLanguage == 'en' ? 'GAL' : 'جالون',
  //   },
  //   {
  //     key: 5,
  //     value: 'cubic meters (m³)',
  //     text: selectedLanguage == 'en' ? 'M³' : 'م3',
  //   },
  //   {
  //     key: 6,
  //     value: 'cubic feet (ft³)',
  //     text: selectedLanguage == 'en' ? 'FT³' : 'قدم3',
  //   },
  //   {
  //     key: 7,
  //     value: 'barrels',
  //     text: t.parts.vol_unit.barrels,
  //   },
  //   {
  //     key: 8,
  //     value: 'kilograms (kg)',
  //     text: selectedLanguage == 'en' ? 'KG' : 'كجم ',
  //   },
  //   {
  //     key: 9,
  //     value: 'pounds (lb)',
  //     text: selectedLanguage == 'en' ? 'LB' : 'رطل',
  //   },
  //   {
  //     key: 10,
  //     value: 'tons metric tonnes',
  //     text: t.parts.mass_unit.tons,
  //   },
  // ];
  const PersonValidationSchema = Yup.object().shape({
    location: Yup.string().required(t.common.required_field),
    // area: Yup.string().required(t.common.required_field),
    quantity: Yup.number()
      .nullable()
      .min(0.1, t.common.quantity_must_be_greater_than_zero) // Add condition for quantity
      .test(
        'is-valid-quantity',
        t.common.valid_digits, // Error message for invalid quantity
        (value) => {
          // Check if quantity matches the pattern
          if (!value) return true; // If quantity is empty, return true
          const regex = /^\d{1,8}(\.\d{1,4})?$/;
          return regex.test(value.toString());
        }
      )
      .required(t.common.required_field),
    unitCost: Yup.number()
      .nullable()
      .min(1, t.common.cost_must_be_greater_than_zero) // Add condition for unitCost
      .test(
        'is-valid-quantity',
        t.common.valid_digits, // Error message for invalid quantity
        (value) => {
          // Check if quantity matches the pattern
          if (!value) return true; // If quantity is empty, return true
          const regex = /^\d{1,8}(\.\d{1,4})?$/;
          return regex.test(value.toString());
        }
      )
      .required(t.common.required_field),
  });

  const handlePOModalOpen = () => {
    
    let allPartData = [];
    if (data?.attributes?.inventory?.length > 0) {
       allPartData =  data.attributes.inventory
        .map((inv) => {
          return {
            id: `${data?.id}-${inv?.location?.data?.id}`,
            part_id: data?.id,
            countId: data?.attributes?.countId,
            name: data?.attributes?.name,
            location: inv?.location?.data?.attributes?.name,
            locationId: inv?.location?.data?.id,
            totalCost: inv?.unitCost || 0,
            quantity: 1,
            unitCost: inv?.unitCost || 0,
            unitType: data?.attributes?.measuringUnit,
            selectedPart: false,
            fillMax: false,
            availableQty: inv?.availableQuantity ? inv.availableQuantity : 0,
            orderedQuantity: inv?.orderedQuantity ? inv?.orderedQuantity : 0,
          };
        });
    }
    // let selectedPartData = {
    //   selectedPart: true,
    //   fillMax: false,
    //   id: data?.id,
    //   countId: data?.attributes?.countId,
    //   name: data?.attributes?.name,
    //   totalCost: data?.attributes?.unitCost,
    //   quantity: 1,
    //   unitType: data?.attributes?.measuringUnit,
    //   unitCost: data?.attributes?.unitCost,
    //   availableQty: data?.attributes?.totalAvailableQuantity
    //     ? data?.attributes?.totalAvailableQuantity
    //     : 0,
    //   orderedQuantity: data?.attributes?.totalOrderedQuantity
    //     ? data?.attributes?.totalOrderedQuantity
    //     : 0,
    // };

    setPOData(allPartData);
    setTogglePreviw(false)
    setToggleAsideModal(true);
  };

  const unitType = data?.attributes?.measuringUnit;
  return (
    <>
      <div className="data-header mt-5">
        <div className="header-title d-flex justify-content-between align-items-start">
          <div className="header-pill-container d-flex align-items-baseline">
            <p className="detail-title" style={{ width: '350px' }}>
              {data?.attributes?.name}
            </p>
          </div>
          {!userPermissions?.['purchase-order']?.create && !userPermissions?.part?.restock ? null :
          <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2">
            <UncontrolledDropdown className="mr-2" direction="down">
              <DropdownToggle color="primary" className="option-toggle">
                <ActionBtnIcon />{' '}
              </DropdownToggle>
              <DropdownMenu
                style={{ width: '222px' }}
                className={selectedLanguage == 'ar' ? 'rtl' : ''}
              >

{/* {userPermissions?.['purchase-order']?.create ? (   
   <>
                <DropdownItem className="divider" />
                <DropdownItem onClick={() => handlePOModalOpen()}>
                  <POGreyIcon /> {t.parts.form.create_po}
                </DropdownItem>
                      </>
                    ) : null} */}
                {userPermissions?.part?.restock ? (
                  <>
                    <DropdownItem className="divider" />
                    <DropdownItem onClick={() => restokeHandle()}>
                      <RestockIcon /> {t.parts.form.restock}
                    </DropdownItem>
                  </>
                ) : null}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>}
        </div>

        <div className="header-title mt-5 d-flex new-inventery-header align-items-start">
          <div
            className=" mt-4"
            style={
              selectedLanguage == 'en'
                ? { width: '60%', marginLeft: '20px' }
                : { width: '60%', marginRight: '20px' }
            }
          >
            <label className="workorder-intro-label">
              {t.parts.form.qty_in_stoke}
              {':'}
            </label>
            <p className={`workorder-intro-value parts2 mt-2 mr-0`}>
              {data?.attributes?.qtyInStock ? data?.attributes?.qtyInStock : 0}{' '}
              {HandleUnitType(data?.attributes?.measuringUnit)}
            </p>
          </div>

          <div className=" align-items-start mt-4">
            <label className="workorder-intro-label">
              {t.parts.form.avg_cost}
              {':'}
            </label>
            <p className={`workorder-intro-value parts2 mr-0 mt-2`}>
              {data?.attributes?.avgUnitCost
                ? handleDecimalPoints(data?.attributes?.avgUnitCost) + ' ' + organisationCurrency
                : t.common.na}{' '}
            </p>
          </div>
        </div>

        {/* <div className="header-title mt-4 d-flex new-inventery-header align-items-start">
          <div
            className="align-items-start"
            style={
              selectedLanguage == 'en'
                ? { width: '60%', marginLeft: '20px' }
                : { width: '60%', marginRight: '20px' }
            }
          >
            <label className="workorder-intro-label">
              {t.parts.form.minimum_qty}
              {':'}{' '}
              <span
                className="c-pointer"
                style={{ position: 'relative' }}
                onMouseEnter={() =>
                  
                  setMinQtyTooltip({
                    ...showMinQtyTooltip,
                    [index]: true,
                  })
                }
                  // setMinQtyTooltip(true)}
                onMouseLeave={() =>
                  setMinQtyTooltip({
                    ...showMinQtyTooltip,
                    [index]: true,
                  })
                  }
              >
                {showMinQtyTooltip ? (
                  <>
                    <InfoIconBlue />
                    <ToolTip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position={'bottom top'}
                      show={showMinQtyTooltip}
                      textBoxWidth="135px"
                      arrowAlign="center"
                      fontSize="9px"
                      fontWeight="400"
                      fontFamily={
                        selectedLanguage == 'ar' ? 'Noto Kufi Arabic' : 'Roboto'
                      }
                      padding="4px 14px 4px 7px"
                      lineHeight="16px"
                      borderRadius="6px"
                      static
                      moveDown="0px"
                    >
                      <span
                        className="work-order-preview-tooltip parts"
                        style={{ width: '112px' }}
                      >
                        {t.parts.form.min_qty_tooltip}
                      </span>
                    </ToolTip>
                  </>
                ) : (
                  <InfoIcon />
                )}
              </span>
            </label>
            <p className="workorder-intro-value parts2 mt-2">
              {data?.attributes?.minimumQuantity
                ? data?.attributes?.minimumQuantity +
                  ' ' +
                  HandleUnitType(data?.attributes?.measuringUnit)
                : t.common.na}
            </p>
          </div>

          <div className=" align-items-start">
            <label className="workorder-intro-label">
              {t.parts.form.miximum_qty}
              {':'}{' '}
              <span
                className="c-pointer"
                style={{ position: 'relative' }}
                onMouseEnter={() => setMaxQtyTooltip(true)}
                onMouseLeave={() => setMaxQtyTooltip(false)}
              >
                {showMaxQtyTooltip ? (
                  <>
                    <InfoIconBlue />
                    <ToolTip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position={'bottom top'}
                      show={showMaxQtyTooltip}
                      textBoxWidth="140px"
                      arrowAlign="center"
                      fontSize="9px"
                      fontWeight="400"
                      fontFamily={
                        selectedLanguage == 'ar' ? 'Noto Kufi Arabic' : 'Roboto'
                      }
                      padding="4px 14px 4px 7px"
                      lineHeight="16px"
                      borderRadius="6px"
                      static
                      moveDown="0px"
                    >
                      <span className="work-order-preview-tooltip parts">
                        {t.parts.form.max_qty_tooltip}
                      </span>
                    </ToolTip>
                  </>
                ) : (
                  <InfoIcon />
                )}
              </span>
            </label>
            <p className="workorder-intro-value parts2 mt-2 mr-0">
              {data?.attributes?.maximumQuantity
                ? data?.attributes?.maximumQuantity +
                  ' ' +
                  HandleUnitType(data?.attributes?.measuringUnit)
                : t.common.na}
            </p>
          </div>
        </div> */}
        {filteredInventory?.map((data, index) => (
          <>
            <div
              className={
                index == 0
                  ? 'mt-6 inventory-detail-wrap'
                  : 'mt-6 inventory-detail-wrap'
              }
            >
              <div className={`kanbar-header`}>
                <div class="grid-container-element">
                  <div style={{color:"#0c66e4",textDecoration:"underline"}} onClick={()=>{
                    history.push(`/locations?id=${data?.location?.data?.attributes?.countId}`)
                  }} class="grid-child-element first c-pointer">
                    {data?.location?.data?.attributes?.name}
                  </div>
                  {data?.unitCost !== null  ?   <div class="grid-child-element first">{`${t.parts.form.unit_cost}: ${data?.unitCost} ${organisationCurrency}`}</div> : <div class="grid-child-element first">{`${t.parts.form.unit_cost}: ${t.common.na}`}</div>}
                </div>
              </div>
            </div>

            <div className="inventory-details mt-3">

            <Grid>
                <GridColumn>
                  <div className=" align-items-start">
                    <label
                      className={`workorder-intro-label ${
                        selectedLanguage == 'en' ? 'ml-5' : 'mr-5'
                      }`}
                    >
                     {t.parts.form.minimum_qty}
                      {':'}{' '}
                      <span
                className="c-pointer"
                style={{ position: 'relative' }}
                onMouseEnter={() =>
                  
                  setMinQtyTooltip({
                    ...showMinQtyTooltip,
                    [index]: true,
                  })
                }
                  // setMinQtyTooltip(true)}
                onMouseLeave={() =>
                  setMinQtyTooltip({
                    ...showMinQtyTooltip,
                    [index]: false,
                  })
                  }
              >
                {showMinQtyTooltip[index] ? (
                  <>
                    <InfoIconBlue />
                    <ToolTip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position={'bottom top'}
                      show={showMinQtyTooltip[index]}
                      textBoxWidth="135px"
                      arrowAlign="center"
                      fontSize="9px"
                      fontWeight="400"
                      fontFamily={
                        selectedLanguage == 'ar' ? 'Noto Kufi Arabic' : 'Roboto'
                      }
                      padding="4px 14px 4px 7px"
                      lineHeight="16px"
                      borderRadius="6px"
                      static
                      moveDown="0px"
                    >
                      <span
                        className="work-order-preview-tooltip parts"
                        style={{ width: '112px' }}
                      >
                        {t.parts.form.min_qty_tooltip}
                      </span>
                    </ToolTip>
                  </>
                ) : (
                  <InfoIcon />
                )}
              </span>
                    </label>
                    <p className={`workorder-intro-value parts3  mr-0`}>
                    {data?.minimumQuantity ? data?.minimumQuantity : 0}{' '}
                    {HandleUnitType(unitType)}
              {/* {data?.minimumQuantity
                ? data?.minimumQuantity +
                  ' ' +
                  HandleUnitType(data?.attributes?.measuringUnit)
                : t.common.na} */}
            </p>
                  </div>
                </GridColumn>
                <GridColumn>
                  <div className=" align-items-start">
                  <label
                      className={`workorder-intro-label ${
                        selectedLanguage == 'en' ? 'ml-4' : 'mr-4'
                      }`}
                    >
              {t.parts.form.miximum_qty}
              {':'}{' '}
              <span
                className="c-pointer"
                style={{ position: 'relative' }}
                onMouseEnter={() =>
                   
                  
                   setMaxQtyTooltip({
                    ...showMaxQtyTooltip,
                    [index]: true,
                  })
                
                }
                onMouseLeave={() =>    setMaxQtyTooltip({
                  ...showMaxQtyTooltip,
                  [index]: false,
                })}
              >
                {showMaxQtyTooltip[index] ? (
                  <>
                    <InfoIconBlue />
                    <ToolTip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position={'bottom top'}
                      show={showMaxQtyTooltip[index]}
                      textBoxWidth="140px"
                      arrowAlign="center"
                      fontSize="9px"
                      fontWeight="400"
                      fontFamily={
                        selectedLanguage == 'ar' ? 'Noto Kufi Arabic' : 'Roboto'
                      }
                      padding="4px 14px 4px 7px"
                      lineHeight="16px"
                      borderRadius="6px"
                      static
                      moveDown="0px"
                    >
                      <span className="work-order-preview-tooltip parts">
                        {t.parts.form.max_qty_tooltip}
                      </span>
                    </ToolTip>
                  </>
                ) : (
                  <InfoIcon />
                )}
              </span>
            </label>
            <p className={`workorder-intro-value parts mt-2 mr-0`}>
            {data?.maximumQuantity ? data?.maximumQuantity : 0}{' '}
            {HandleUnitType(unitType)}
              
            </p>
                  </div>
                </GridColumn>
              </Grid>

              <Grid>
                <GridColumn className='pt-0'>
                  <div className=" align-items-start">
                    <label
                      className={`workorder-intro-label ${
                        selectedLanguage == 'en' ? 'ml-5' : 'mr-5'
                      }`}
                    >
                      {t.parts.table.qty_in_stock}
                      {':'}{' '}
                      <span
                        className="c-pointer"
                        style={{ position: 'relative' }}
                        onMouseEnter={() =>
                          setInstockQtyTooltip({
                            ...showInstockQtyTooltip,
                            [index]: true,
                          })
                        }
                        onMouseLeave={() =>
                          setInstockQtyTooltip({
                            ...showInstockQtyTooltip,
                            [index]: false,
                          })
                        }
                      >
                        {showInstockQtyTooltip[index] ? (
                          <>
                            <InfoIconBlue />
                            <ToolTip
                              color="#fff"
                              backgroundColor="#172B4D"
                              position={
                                selectedLanguage == 'ar'
                                  ? 'left top'
                                  : 'right top'
                              }
                              show={showInstockQtyTooltip[index]}
                              textBoxWidth="135px"
                              arrowAlign="center"
                              fontSize="9px"
                              fontWeight="400"
                              fontFamily={
                                selectedLanguage == 'ar'
                                  ? 'Noto Kufi Arabic'
                                  : 'Roboto'
                              }
                              padding="4px 14px 4px 7px"
                              lineHeight="16px"
                              borderRadius="6px"
                              static
                              moveDown="0px"
                            >
                              <span className="work-order-preview-tooltip parts">
                                {t.parts.form.qty_in_stoke_tooltip}
                              </span>
                            </ToolTip>
                          </>
                        ) : (
                          <InfoIcon />
                        )}
                      </span>
                    </label>
                    <p className={`workorder-intro-value parts3  mr-0`}>
                      {data?.quantity ? data?.quantity : 0}{' '}
                      {HandleUnitType(unitType)}
                    </p>
                  </div>
                </GridColumn>
                <GridColumn className='pt-0'>
                  <div className=" align-items-start">
                    <label
                      className={`workorder-intro-label ${
                        selectedLanguage == 'en' ? 'ml-4' : 'mr-4'
                      }`}
                    >
                      {t.parts.form.avl_qty}
                      {':'}{' '}
                      <span
                        className="c-pointer"
                        style={{ position: 'relative' }}
                        onMouseEnter={() =>
                          setAvlQtyTooltip({
                            ...showAvlQtyTooltip,
                            [index]: true,
                          })
                        }
                        onMouseLeave={() =>
                          setAvlQtyTooltip({
                            ...showAvlQtyTooltip,
                            [index]: false,
                          })
                        }
                      >
                        {showAvlQtyTooltip[index] ? (
                          <>
                            <InfoIconBlue />
                            <ToolTip
                              color="#fff"
                              backgroundColor="#172B4D"
                              position={
                                selectedLanguage == 'en'
                                  ? 'left top'
                                  : 'right top'
                              }
                              //  position="bottom center"
                              show={showAvlQtyTooltip[index]}
                              textBoxWidth="165px"
                              arrowAlign="start"
                              fontSize="9px"
                              fontWeight="400"
                              fontFamily={
                                selectedLanguage == 'ar'
                                  ? 'Noto Kufi Arabic'
                                  : 'Roboto'
                              }
                              padding="4px 14px 4px 7px"
                              lineHeight="16px"
                              borderRadius="6px"
                              static
                              moveDown="0px"
                            >
                              <span className="work-order-preview-tooltip parts">
                                {t.parts.form.avl_qty_tooltip}
                              </span>
                            </ToolTip>
                          </>
                        ) : (
                          <InfoIcon />
                        )}
                      </span>
                    </label>
                    <p className={`workorder-intro-value parts mt-2 mr-0`}>
                      {data?.availableQuantity ? data?.availableQuantity : 0}{' '}
                      {HandleUnitType(unitType)}
                    </p>
                  </div>
                </GridColumn>
              </Grid>

              <Grid className=''>
                <GridColumn className="pt-0">
                  <div className="align-items-start mb-2">
                    <label
                      className={`workorder-intro-label ${
                        selectedLanguage == 'en' ? 'ml-5' : 'mr-5'
                      }`}
                    >
                      {t.parts.form.order_qty}
                      {':'}{' '}
                      <span
                        className="c-pointer"
                        style={{ position: 'relative' }}
                        onMouseEnter={() =>
                          setOrderQtyTooltip({
                            ...showOrderQtyTooltip,
                            [index]: true,
                          })
                        }
                        onMouseLeave={() =>
                          setOrderQtyTooltip({
                            ...showOrderQtyTooltip,
                            [index]: false,
                          })
                        }
                      >
                        {showOrderQtyTooltip[index] ? (
                          <>
                            <InfoIconBlue />
                            <ToolTip
                              color="#fff"
                              backgroundColor="#172B4D"
                              position={
                                selectedLanguage == 'ar'
                                  ? 'left top'
                                  : 'right top'
                              }
                              show={showOrderQtyTooltip[index]}
                              textBoxWidth="170px"
                              arrowAlign="center"
                              fontSize="9px"
                              fontWeight="400"
                              fontFamily={
                                selectedLanguage == 'ar'
                                  ? 'Noto Kufi Arabic'
                                  : 'Roboto'
                              }
                              padding="4px 14px 4px 7px"
                              lineHeight="16px"
                              borderRadius="6px"
                              static
                              moveDown="0px"
                            >
                              <span className="work-order-preview-tooltip parts">
                                {t.parts.form.order_qty_tooltip}
                              </span>
                            </ToolTip>
                          </>
                        ) : (
                          <InfoIcon />
                        )}
                      </span>
                    </label>
                    <p className={`workorder-intro-value parts3 mr-0`}>
                      {data?.orderedQuantity ? data?.orderedQuantity : 0}{' '}
                      {HandleUnitType(unitType)}
                    </p>
                  </div>
                </GridColumn>
                <GridColumn className="pt-0">
                  <div
                    className={` align-items-start ${
                      selectedLanguage == 'en' ? 'ml-4' : 'mr-4'
                    } mb-2`}
                  >
                    <label className="workorder-intro-label">
                      {t.parts.form.reserved_qty}
                      {':'}{' '}
                      <span
                        className="c-pointer"
                        style={{ position: 'relative' }}
                        onMouseEnter={() =>
                          setResQtyTooltip({
                            ...showResQtyTooltip,
                            [index]: true,
                          })
                        }
                        onMouseLeave={() =>
                          setResQtyTooltip({
                            ...showResQtyTooltip,
                            [index]: false,
                          })
                        }
                      >
                        {showResQtyTooltip[index] ? (
                          <>
                            <InfoIconBlue />
                            <ToolTip
                              color="#fff"
                              backgroundColor="#172B4D"
                              position={
                                selectedLanguage == 'en'
                                  ? 'left top'
                                  : 'right top'
                              }
                              show={showResQtyTooltip[index]}
                              textBoxWidth="163px"
                              arrowAlign="start"
                              fontSize="9px"
                              fontWeight="400"
                              fontFamily={
                                selectedLanguage == 'ar'
                                  ? 'Noto Kufi Arabic'
                                  : 'Roboto'
                              }
                              padding="4px 14px 4px 7px"
                              lineHeight="16px"
                              borderRadius="6px"
                              static
                              moveDown="0px"
                            >
                              <span className="work-order-preview-tooltip parts">
                                {t.parts.form.reserved_qty_tooltip}
                              </span>
                            </ToolTip>
                          </>
                        ) : (
                          <InfoIcon />
                        )}
                      </span>
                    </label>
                    <p className={`workorder-intro-value parts2 mt-2 mr-0`}>
                      {data?.reservedQuantity ? data?.reservedQuantity : 0}{' '}
                      {HandleUnitType(unitType)}
                    </p>
                  </div>
                </GridColumn>
              </Grid>

              {/* <Grid>
      <GridColumn className='pt-0' width={8}>
      <div className="d-flex align-items-start mb-2">
        <label className="workorder-intro-label ml-5">
          {t.parts.form.min_qty}
          {':'}
        </label>
        <p className={`workorder-intro-value mr-0 parts`}>
          {data?.attributes?.avgUnitCost ? data?.attributes?.avgUnitCost : 0}
        </p>
      </div>
      </GridColumn>
      <GridColumn className='pt-0' width={8}>
      <div className="d-flex align-items-start ml-4 mb-2">
        <label className="workorder-intro-label">
          {t.parts.form.max_qty}
          {':'}
        </label>
        <p className={`workorder-intro-value mr-0 parts`}>
          {data?.attributes?.avgUnitCost ? data?.attributes?.avgUnitCost : 0}
        </p>
      </div>
      </GridColumn>
    </Grid> */}
            </div>
          </>
        ))}
      </div>

      <Modal
        className="assets-modal"
        size="tiny"
        open={openRestockModal}
        onClose={() => {
          setOpenRestockModal(false);
          // setValidation({
          //   people: {
          //     error: false,
          //     pattern: (code) => code != '',
          //     skipped: false,
          //     message: t.common.required_field,
          //   },
          // });
          // setAssignData({
          //   people: [],
          //   teams: [],
          // });
        }}
      >
        <div className="header-container">
          <span className="assets-header">{t.parts.form.restock}</span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setOpenRestockModal(false);
                // setValidation({
                //   people: {
                //     error: false,
                //     pattern: (code) => code != '',
                //     skipped: false,
                //     message: t.common.required_field,
                //   },
                // });
                // setAssignData({
                //   people: [],
                //   teams: [],
                // });
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content className="pb-0 pl-7 pr-7 pt-7">
          {/* <label>{t.categories.import_categories}</label> */}

          <div className="assignee-data assignee-data-rtl-body">
            <Formik
              initialValues={restokeData}
              enableReinitialize
              validationSchema={PersonValidationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({
                errors,
                touched,
                setFieldValue,
                values,
                setFieldTouched,
                handleBlur,
                submitForm,
              }) => (
                <FormikForm>
          
                  <label className="label-item">
                    {t.parts.filter.location}
                    <Asterisk color="#172B4D" />
                  </label>
                  <Dropdown
                    placeholder={t.assets.form.select_location}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    selection
                 
                    options={options.locations}
                    value={values?.location}
                    onChange={(e, value) => {
                      setFieldValue('location', value.value);
                      // setFieldValue('area', '', false);
                      // const filteredAreas = options?.area?.initOption.filter(
                      //   (area) => area.location == value.value
                      // );
                      // setOptions((prevOptions) => ({
                      //   ...prevOptions,
                      //   area: { ...prevOptions.area, option: filteredAreas }, // Update only the option property
                      // }));
                    }}
                    error={errors.location && touched.location}
                    onBlur={(e) => {
                      setFieldTouched('location', true);
                    }}
                  />
                  {errors.location && touched.location ? (
                    <div className="label-error">{errors.location}</div>
                  ) : (
                    ''
                  )}
                  

                  {/* <label className="label-item mt-5">
                    {t.parts.filter.area}
                    <Asterisk color="#172B4D" />
                  </label>
                  <Dropdown
                    placeholder={t.parts.form.add_area}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    selection
                    search
                    disabled={!values?.location}
                    options={options?.area?.option}
                    value={values?.area}
                    onChange={(e, value) => setFieldValue('area', value.value)}
                    error={errors.area && touched.area}
                    onBlur={(e) => {
                      setFieldTouched('area', true);
                    }}
                  />
                  {errors.area && touched.area ? (
                    <div className="label-error">{errors.area}</div>
                  ) : (
                    ''
                  )} */}

                  <label className="label-item mt-5">
                    {t.parts.table.quantity}
                    <Asterisk color="#172B4D" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    type="number"
                    fluid
                    onKeyDown={(evt) => handleKeyDown(evt)}
                    placeholder={t.parts.form.enter_qty}
                    value={values.quantity}
                    onChange={(e) => {
                      let value = parseFloat(e.target.value).toFixed(4);
                      if (e.target.value) {
                        let total_cost = handleDecimalPoints(value * values.unitCost)
                        setFieldValue('quantity', Number(value));
                        setFieldValue(
                          'totalCost',
                          Number(total_cost),
                          false
                        );
                      } else {
                        setFieldValue('quantity', null);
                        let total_cost = handleDecimalPoints(value * values.unitCost)
                        setFieldValue(
                          'totalCost',
                          Number(total_cost),
                          false
                        );
                      }
                    }}
                    error={errors.quantity && touched.quantity}
                    onBlur={(e) => {
                      setFieldTouched('quantity', true);
                    }}
                  >
                    <input />

                    <span
                      className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                        selectedLanguage == 'en' ? 'ml-5' : 'mr-5'
                      }`}
                      // onClick={() => {
                      //   setOpenJobTitleAddModal(true);
                      // }}
                      style={{ backgroundColor: '#E9F2FF', color: '#0C66E4' }}
                    >
                      {HandleUnitType(data?.attributes?.measuringUnit)}
                    </span>
                    {/* <Label style={{ height: '100%' }}>
                      {organisationCurrency}
                    </Label> */}
                  </Input>
                  {errors.quantity && touched.quantity ? (
                    <div className="label-error">{errors.quantity}</div>
                  ) : (
                    ''
                  )}

                  <label className="label-item mt-5">
                    {t.parts.form.unit_cost}
                    <Asterisk color="#172B4D" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    type="number"
                    fluid
                    onKeyDown={(evt) => handleKeyDown(evt)}
                    placeholder={t.parts.form.enter_unit_cost}
                    value={values.unitCost}
                    onChange={(e) => {
                      let value = handleDecimalPoints(e.target.value)
                      if (e.target.value) {

                        let total_cost = handleDecimalPoints(value * values.quantity)
                        setFieldValue('unitCost', Number(value));
                        setFieldValue(
                          'totalCost',
                          Number(total_cost),
                          false
                        );
                      } else {
                        setFieldValue('unitCost', null);

                        let total_cost = handleDecimalPoints(value * values.quantity)
                        setFieldValue(
                          'totalCost',
                          Number(total_cost),
                          false
                        );
                      }
                    }}
                    error={errors.unitCost && touched.unitCost}
                    onBlur={(e) => {
                      setFieldTouched('unitCost', true);
                    }}
                  >
                    <input />

                    <span
                      className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                        selectedLanguage == 'en' ? 'ml-5' : 'mr-5'
                      }`}
                      // onClick={() => {
                      //   setOpenJobTitleAddModal(true);
                      // }}
                      style={{ backgroundColor: '#E9F2FF', color: '#0C66E4' }}
                    >
                      {organisationCurrency}
                    </span>
                    {/* <Label style={{ height: '100%' }}>
                      {organisationCurrency}
                    </Label> */}
                  </Input>
                  {errors.unitCost && touched.unitCost ? (
                    <div className="label-error">{errors.unitCost}</div>
                  ) : (
                    ''
                  )}

                  <div className="mt-6">
                    <span className="new-grey-btn restock ml-0 mr-0">
                      {t.parts.form.total_cost} &nbsp; &nbsp;
                      {values.totalCost ? handleDecimalPoints(values.totalCost) : 0}{' '}
                      {organisationCurrency}s
                    </span>
                  </div>

                  {/* <Input
                  autoComplete="new-password"
                  type="number"
                  disabled
                  fluid
                  onKeyDown={(evt) => handleKeyDown(evt)}
                  value={values.totalCost}
                >
                  <input />




                  <span
                    className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                      selectedLanguage == 'en' ? 'ml-5' : 'mr-5'
                    }`}
                  
                    style={{ backgroundColor: '#E9F2FF', color: '#0C66E4' }}
                  >
                    {organisationCurrency}
                  </span>
                
                </Input> */}

                  <Form.Field className="mt-8">
                    <FileUpload
                      onFileChange={onFileChange}
                      name="image"
                      attachments={attachments}
                      setAttachments={setAttachments}
                    />
                  </Form.Field>

                  <div
                    className={`modal-button-container mt-7 ${
                      selectedLanguage == 'en' ? 'mr-3' : 'ml-7'
                    }`}
                  >
                    <button
                      onClick={() => {
                        setOpenRestockModal(false);
                        // setValidation({
                        //   people: {
                        //     error: false,
                        //     pattern: (code) => code != '',
                        //     skipped: false,
                        //     message: t.common.required_field,
                        //   },
                        // });
                        // setAssignData({
                        //   people: [],
                        //   teams: [],
                        // });
                      }}
                      className="modal-form-action-button black"
                    >
                      {t.assets.form.cancel}
                    </button>

                    <button
                      type="submit"
                      // onClick={() => handleAssign()}
                      className="modal-form-action-button light-blue"
                    >
                      {t.parts.form.restock}
                    </button>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Modal.Content>
      </Modal>

      {toggleAsideModal && (
        <Add
          toggled={toggleAsideModal}
          untoggleModal={() => {
            setToggleAsideModal(false);
            setTogglePreviw(true)
          }}
          allIds={allIds}
          role={role}
          modalWidth={modalWidth}
          setModalWidth={setModalWidth}
          untogglePrevModal={untoggle}
          fromPart={true}
          POData={POData}

          // setListSearchTitle={setSearchTitle}
          // setSortData={setSortData}
          // setFilterOptions={setFilterOptions}
          // filterOptions={filterOptions}
          // setDueDates={setDueDates}
          // setSearchTitle = {setSearchTitle}
          // setSelectedDates = {setSelectedDates}
        />
      )}
    </>
  );
};

export default Inventory;
