import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../../config/i18n/slice";
import {
  Checkbox,
  Dimmer,
  Dropdown,
  Form,
  Input,
  Loader,
  Modal,
} from "semantic-ui-react";
import location_icon from "../../../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../../../Assets/Icons/pin_drop.png";
import {
  ArrowLeft,
  ArrowRight,
  CloseIcon,
  CustomerPlusIcon,
  SearchIcon,
} from "../../../../../Assets/Icons/svg";
import {
  currencyDecimalPlaces,
  fetchInvLocationsOptions,
  getAllWOParts,
  getAllWOPartsData,
  handleKeyDown,
  updatePartWorkOrder,
} from "../../../../config/functions";
import { toast } from "react-toastify";
import useDisplayFormattedText from "../../../../../hooks/useDisplayFormattedText";
import DataTable from "react-data-table-component";
import Pagination from "../../../../components/common/CommonPagination";
import _ from "lodash";
import { usePermissionsSimplified } from "../../../../../hooks/usePermissionsSimplified";
import PartData from "./PartData";
const Part = ({ data, untoggle, setData }) => {
  const t = useSelector(selectTranslations);
  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });
  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });
  const [activePartIndex, setActivePartIndex] = useState({});

  const allIds = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }
    });

  const [options, setOptions] = useState({
    inv_location: [],
  });
  const { DisplayFormattedText } = useDisplayFormattedText();
  const [loading, setLoading] = useState(true);
  const [partsData, setPartsData] = useState([]);
  const [subTotal, setSubTotal] = useState();
  const [selectedmyData, setSelectedmyData] = useState([]);
  const [selectedTablePartData, setSelectedTablePartData] = useState([]);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [partSearchTitle, setPartSearchTitle] = useState("");
  const [allPartsData, setAllPartsData] = useState([]);
  const [prevPartsData, setPrevPartsData] = useState([]);
  const [initPartsData, setInitPartsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [addPartModal, setAddPartModal] = useState(false);
  const [selectedInvLoc, setSelectedInvLoc] = useState("");
  const conditionalRowStyles = [
    {
      when: (row) => row?.disable == true,
      // Condition to check
      style: {
        backgroundColor: "#e4e4e4", // Apply red color to the row
      },
    },
  ];
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  /*
   * The function `mergeAndTransformData` merges and transforms data from two arrays of parts and sets
   */
  const mergeAndTransformData = (parts = [], sets = []) => {
    return [
      ...parts.map((item) => ({
        countId: item?.part?.countId,
        part: item?.part?.id,
        unitType: item?.part?.measuringUnit,
        type: "Part",
        id: `${item?.part?.countId}-${item?.location?.id}`,
        temp_id: item?.id,
        name: item?.part?.name,
        location_name: item?.location?.name,
        location_cid: item?.location?.countId,
        location: item?.location?.id,
        quantity: item?.quantity !== null ? item?.quantity : 0,
        unitCost: item?.unitCost !== null ? Number(item?.unitCost) : 0,
        totalCost: item?.totalCost !== null ? Number(item?.totalCost) : 0,
      })),
      ...sets.map((item) => ({
        type: "Set",
        part: item?.set?.id,
        unitType: null,
        id: `${item?.set?.countId}-${item?.location?.id}`,
        temp_id: item?.id,
        countId: item?.set?.countId,
        name: item?.set?.name,
        location_name: item?.location?.name,
        location_cid: item?.location?.countId,
        location: item?.location?.id,
        quantity: item?.quantity !== null ? item?.quantity : 0,
        unitCost: item?.unitCost !== null ? Number(item?.unitCost) : 0,
        totalCost: item?.totalCost !== null ? Number(item?.totalCost) : 0,
      })),
    ];
  };

  /**
   * The function `getInvLocations` fetches inventory locations data, organizes it into parent and
   * sub-location options, and sets the options state with the data.
   */
  const getInvLocations = async () => {
    const fetchData = await fetchInvLocationsOptions(organisationId);

    let parentOptions = [];

    if (fetchData?.length > 0) {
      fetchData.forEach((el) => {
        parentOptions.push({
          key: el.id,
          value: el.id,
          text: el.name,
          disabled: el?.disable,
          image: { src: location_icon },
          className: "main-location-dropdown-option",
        });

        if (el?.subLocations?.length > 0) {
          el.subLocations.forEach((subEl) => {
            parentOptions.push({
              key: subEl.id,
              value: subEl.id,
              text: subEl.name,
              image: { src: sub_location_icon },
              className: "sub-location-dropdown-option",
              parentId: el.id, // Keep track of the parent
            });
          });
        }
      });
      setOptions({
        ...options,
        inv_location: parentOptions,
      });

      return parentOptions;
    }
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#E9F2FF",
        paddingTop: "8px", // override the cell padding for head cells
      },
    },
    cells: {
      style: {
        paddingTop: "20px",
        paddingBottom: "8px",
      },
    },
  };

  let allQtyOptions = [
    {
      key: 0,
      value: "pieces",
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: "items",
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: "units",
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: "liters (L)",
      text: selectedLanguage == "en" ? "l" : "لتر",
    },
    {
      key: 4,
      value: "gallons (gal)",
      text: selectedLanguage == "en" ? "gal" : "جالون",
    },
    {
      key: 5,
      value: "cubic meters (m³)",
      text: selectedLanguage == "en" ? "m³" : "م³",
    },
    {
      key: 6,
      value: "cubic feet (ft³)",
      text: selectedLanguage == "en" ? "ft³" : "ق³",
    },
    {
      key: 7,
      value: "barrels",
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: "kilograms (kg)",
      text: selectedLanguage == "en" ? "kg" : "كجم",
    },
    {
      key: 9,
      value: "pounds (lb)",
      text: selectedLanguage == "en" ? "lb" : "رطل",
    },

    {
      key: 10,
      value: "tons",
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: "metric tonnes",
      text: t.parts.mass_unit.m_tons,
    },
  ];
  let _newData = initPartsData;

  useEffect(() => {
    // Clone the data to apply filters without mutating the original data
    let filteredData = [..._newData];
    setCurrentPage(1);
    // Apply partSearchTitle filter
    if (partSearchTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(partSearchTitle), "i");
      const isMatch = (result) => re.test(result?.name);
      filteredData = _.filter(filteredData, isMatch);
    }

    // Apply selectedInvLoc filter
    if (selectedInvLoc) {
      filteredData = filteredData.filter(
        (part) => part?.location === selectedInvLoc
      );
    }
    // Check if all parts are selected and set state
    if (filteredData.length > 0) {
      const allSelectedDisable = filteredData.every(
        (item) => item.disable === true
      );

      if (!allSelectedDisable) {
        const allSelected = filteredData
          .filter((item) => item.disable === false) // Only include non-disabled items
          .every((item) => item.selectedPart === true);
        setPartsData(filteredData);

        setAreAllSelected(allSelected);
      } else {
        setPartsData(filteredData);
        setAreAllSelected(false);
      }
      // const allSelected = filteredData.every((item) => item?.selectedPart === true);
    } else {
      setPartsData([]);
    }
  }, [partSearchTitle, selectedInvLoc]);

  /**
   * The function `handleRowSelected` updates selected rows in a table based on user input and manages
   * the state of selected parts data.
   */
  const handleRowSelected = (row, checked, selectedPartsData = []) => {
    let allSelectedRows = [...selectedPartsData];
    let selectedRows = [...prevPartsData];

    if (checked) {
      allSelectedRows.push({ ...row, selectedPart: true, selectedPart: true });
      selectedRows.push({ ...row, selectedPart: true });
    } else {
      allSelectedRows = allSelectedRows.filter((el) => el?.id !== row?.id);
      selectedRows = selectedRows.filter((el) => el?.id !== row?.id);
    }

    const updatedData = [...partsData].map((item) => ({
      ...item,
      selectedPart: allSelectedRows.some((el) => el.id === item.id),
    }));

    const updatedInitData = [...initPartsData].map((item) => ({
      ...item,
      selectedPart: allSelectedRows.some((el) => el.id === item.id),
    }));

    // Check if all rows are selected to update "Select All" checkbox
    const allRowsSelected = updatedData.every((item) => item.selectedPart);

    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
    setSelectedTablePartData(allSelectedRows);
    setAreAllSelected(allRowsSelected);
  };

  /**
   * The function `handleQtyChange` updates quantity and total cost values for selected rows in a parts
   * data table based on user input.
   */
  const handleQtyChange = (
    // values,
    row,
    checked,
    // setFieldValue,
    selectedPartsData = [],
    index = null
    // setFieldTouched
  ) => {
    let value = parseFloat(checked).toFixed(4);
    let allSelectedRows = [...partsData];
    let allSelectedRowsData = Array.isArray(selectedPartsData)
      ? [...selectedPartsData]
      : [];
    let allSelectedInitRows = [...initPartsData];
    const rowIndex = allSelectedRows.findIndex((el) => el?.id === row?.id);
    const rowInitIndex = allSelectedInitRows.findIndex(
      (el) => el?.id === row?.id
    );

    const updatedRow = { ...allSelectedRows[rowIndex] };
    const updatedInitRow = { ...allSelectedInitRows[rowInitIndex] };
    if (checked) {
      updatedRow.quantity = Number(value);
      updatedInitRow.quantity = Number(value);
      updatedRow.totalCost = handleDecimalPoints(
        Number(value) * Number(updatedRow.unitCost)
      );
      updatedInitRow.totalCost = handleDecimalPoints(
        Number(value) * Number(updatedInitRow.unitCost)
      );
      if (updatedRow.availableQty == Number(value)) {
        updatedRow.fillMax = true;
      } else {
        updatedRow.fillMax = false;
      }
      if (updatedInitRow.availableQty == Number(value)) {
        updatedInitRow.fillMax = true;
      } else {
        updatedInitRow.fillMax = false;
      }
    } else {
      updatedRow.quantity = null;
      updatedInitRow.quantity = null;
      updatedRow.fillMax = false;
      updatedInitRow.fillMax = false;
      updatedRow.totalCost = Number(updatedRow.unitCost);
      updatedInitRow.totalCost = Number(updatedInitRow.unitCost);
    }

    allSelectedRows[rowIndex] = updatedRow;
    allSelectedInitRows[rowInitIndex] = updatedInitRow;

    if (allSelectedRowsData?.length > 0) {
      const rowIndexSelected = allSelectedRowsData.findIndex(
        (el) => el?.id === row?.id
      );
      const updatedSelectedRow = { ...allSelectedRowsData[rowIndexSelected] };

      if (checked) {
        updatedSelectedRow.quantity = Number(value);
        updatedSelectedRow.totalCost = Number(
          handleDecimalPoints(
            Number(value) * Number(updatedSelectedRow.unitCost)
          )
        );

        if (Number(updatedSelectedRow.availableQty) == Number(value)) {
          updatedSelectedRow.fillMax = true;
        } else {
          updatedSelectedRow.fillMax = false;
        }
      } else {
        updatedSelectedRow.quantity = null;
        updatedSelectedRow.fillMax = false;
        updatedSelectedRow.totalCost = 0;
      }

      allSelectedRowsData[rowIndexSelected] = updatedSelectedRow;

      let subTotalCost = 0;
      allSelectedRowsData.forEach((row) => {
        subTotalCost += Number(row.totalCost);
      });

      setSelectedTablePartData(allSelectedRowsData);

      handleDecimalPoints(subTotalCost);
    }
    setPartsData(allSelectedRows);
    setInitPartsData(allSelectedInitRows);
  };

  /**
   * The `handleDecimalPoints` function rounds a given value to a specified number of decimal places and
   * removes trailing zeros after the decimal point.
   */
  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const roundedValue = Math.round(value * factor) / factor;
    const formattedValue = roundedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after decimal point
    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return Number(formattedValue);
  };
  /**
   * The function `HandleUnitType` takes in data and returns the corresponding text value from
   * `allQtyOptions` based on the data provided.
   */

  const HandleUnitType = (data) => {
    let unitType;
    if (data) {
      unitType = allQtyOptions.find((el) => el.value == data);
    }
    return data ? unitType?.text : null;
  };

  /*
   * The `addParts` function in JavaScript fetches and processes parts data, updating the state
   * accordingly and handling modals based on conditions.
   */
  const addParts = async (selectedPartsData = [], fetchdata = false) => {
    setAreAllSelected(false);
    // setAddPartModal(true);
    if (partsData?.length <= 0 || fetchdata) {
      const fetchData = await getAllWOPartsData(organisationId);

      if (fetchData) {
        if (fetchData?.length == 0) {
          // setNoPartModal(true);
        } else {
          let allPartData = fetchData?.map((part) => {
            return {
              id: `${part.countId}-${part?.inventoryLocation?.id}`,
              part: part?.id,
              type: part.type,
              countId: part?.countId,
              name: part?.name,
              totalCost:
                part?.inventoryLocation?.unitCost !== null
                  ? Number(
                      handleDecimalPoints(part?.inventoryLocation?.unitCost)
                    )
                  : 0,
              quantity: 1,
              unitCost:
                part?.inventoryLocation?.unitCost !== null
                  ? Number(
                      handleDecimalPoints(part?.inventoryLocation?.unitCost)
                    )
                  : 0,
              unitType: part?.measuringUnit,
              selectedPart: false,
              location: part?.inventoryLocation?.id,
              location_cid: part?.inventoryLocation?.countId,
              location_name: part?.inventoryLocation?.name,
              asset: part?.relatedAsset,
              status: part?.inventoryLocation?.status,
              disable:
                part?.inventoryLocation?.status == "outOfStock" ? true : false,
              availableQty:
                part?.inventoryLocation?.availableQuantity !== null
                  ? part?.inventoryLocation?.availableQuantity
                  : 0,
            };
          });
          const filteredParts = fetchData
            ?.map((part) => {
              const isSelected = selectedPartsData.some(
                (selectedPart) => selectedPart.id === part.id
              );

              if (isSelected) {
                return null; // Skip this part
              } else {
                return {
                  id: `${part.countId}-${part?.inventoryLocation?.id}`,
                  type: part.type,
                  part: part?.id,
                  countId: part?.countId,
                  name: part?.name,

                  totalCost:
                    part?.inventoryLocation?.unitCost !== null
                      ? Number(
                          handleDecimalPoints(part?.inventoryLocation?.unitCost)
                        )
                      : 0,
                  quantity: 1,
                  unitCost:
                    part?.inventoryLocation?.unitCost !== null
                      ? Number(
                          handleDecimalPoints(part?.inventoryLocation?.unitCost)
                        )
                      : 0,

                  unitType: part?.measuringUnit,
                  selectedPart: false,
                  location_cid: part?.inventoryLocation?.countId,
                  location: part?.inventoryLocation?.id,
                  location_name: part?.inventoryLocation?.name,
                  asset: part?.relatedAsset,
                  status: part?.inventoryLocation?.status,
                  disable:
                    part?.inventoryLocation?.status == "outOfStock"
                      ? true
                      : false,
                  availableQty: part?.inventoryLocation?.availableQuantity
                    ? part?.inventoryLocation?.availableQuantity
                    : 0,
                };
              }
            })
            .filter((part) => part !== null);

          setPartsData(filteredParts);
          setInitPartsData(filteredParts);
          setAllPartsData(allPartData);

          if (!fetchdata) {
            setAddPartModal(true);
          }
        }
      } else {
        toast.error(t.common.something_wrong);

        if (!fetchdata) {
          setAddPartModal(true);
        }
      }
    } else {
      setAddPartModal(true);
      const filteredParts = allPartsData
        ?.map((part) => {
          const isSelected = selectedPartsData.some(
            (selectedPart) => selectedPart.id === part.id
          );
          if (isSelected) {
            return null; // Skip this part
          } else {
            return {
              id: part?.id,
              type: part?.type,
              countId: part?.countId,
              part: part?.part,
              name: part?.name,
              totalCost: part.totalCost,
              quantity: part?.quantity ? part?.quantity : null,
              unitCost: part?.unitCost,
              unitType: part?.unitType,
              selectedPart: false,
              location: part?.location,
              location_cid: part?.location_cid,
              location_name: part?.location_name,
              asset: part?.asset,
              status: part?.status,
              disable: part?.disable,
              availableQty: part?.availableQty ? part.availableQty : 0,
            };
          }
        })
        .filter((part) => part !== null);
      setPartsData(filteredParts);
      setInitPartsData(filteredParts);
    }
  };

  /**
   * The function `handleClosePartModal` resets various state variables and updates selected data arrays.
   */
  const handleClosePartModal = () => {
    let newAddedData = [...prevPartsData];
    setSelectedInvLoc("");
    setAddPartModal(false);
    setPartSearchTitle("");
    setCurrentPage(1);
    setSelectedTablePartData(newAddedData);
    setSelectedmyData(newAddedData);
  };

  /**
   * The function `handleSelectAll` updates the selected parts data based on a checkbox input, marking
   * all rows as selected or deselected while avoiding duplicates and considering disabled items.
   */
  const handleSelectAll = (checked) => {
    let updatedData = [...partsData];
    let selectedRows = [...prevPartsData];
    let allSelectedRowsData = [...selectedTablePartData]; // Clone the existing selected data

    if (checked) {
      // If checked, mark all rows as selected, but skip if disabled
      updatedData = updatedData.map((item) =>
        item.disable
          ? item // Skip if disabled
          : { ...item, selectedPart: true }
      );

      // Add the selected parts to allSelectedRowsData, avoiding duplicates
      updatedData.forEach((item) => {
        if (!item.disable) {
          const alreadySelected = allSelectedRowsData.some(
            (selectedItem) => selectedItem.id === item.id
          );

          if (!alreadySelected) {
            allSelectedRowsData.push({ ...item, selectedPart: true });
            selectedRows.push({ ...item, selectedPart: true });
          }
        }
      });
    } else {
      // If unchecked, mark all rows as deselected, but skip if disabled
      updatedData = updatedData.map((item) =>
        item.disable
          ? item // Skip if disabled
          : { ...item, selectedPart: false }
      );

      // Remove the deselected parts from allSelectedRowsData
      allSelectedRowsData = allSelectedRowsData.filter(
        (selectedItem) =>
          !updatedData.some(
            (updatedItem) =>
              updatedItem.id === selectedItem.id && !updatedItem.disable
          )
      );

      selectedRows = allSelectedRowsData.filter(
        (selectedItem) =>
          !updatedData.some(
            (updatedItem) =>
              updatedItem.id === selectedItem.id && !updatedItem.disable
          )
      );
    }

    const updatedInitData = [...initPartsData].map((item) => ({
      ...item,
      selectedPart:
        !item.disable && allSelectedRowsData.some((el) => el.id === item.id),
    }));

    // Update state
    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
    setSelectedTablePartData(allSelectedRowsData); // Update selected rows// Update previous state
    setAreAllSelected(checked); // Update the "Select All" state
  };

  /**
   * The `transformDataSet` function takes an array of data objects and transforms each object by setting
   * specific fields based on certain conditions or defaults.
   */
  const transformDataSet = (dataArray) => {
    return dataArray?.map((data) => ({
      set: data?.part, // Set the `set` field to `part` value
      location: data?.location ? data?.location : null, // Use `asset` if available, otherwise default to 58
      unitCost: data?.unitCost !== null ? Number(data?.unitCost) : null, // Use unitCost if positive, else 100
      quantity: data?.quantity !== null ? data?.quantity : 1, // Use quantity if positive, else 5
      totalCost: data?.totalCost !== null ? Number(data?.totalCost) : null,
      ...(data?.temp_id ? { id: data.temp_id } : {}), // Calculate or default
    }));
  };

  /**
   * The `transformDataPart` function takes an array of data objects and transforms each object by
   * setting specific fields based on certain conditions or defaults.
   */
  const transformDataPart = (dataArray) => {
    return dataArray?.map((data) => ({
      part: data?.part, // Set the `set` field to `part` value
      location: data?.location ? data?.location : null, // Use `asset` if available, otherwise default to 58
      unitCost: data?.unitCost !== null ? Number(data?.unitCost) : null,
      // Use unitCost if positive, else 100
      quantity: data?.quantity !== null ? data?.quantity : 1, // Use quantity if positive, else 5
      totalCost: data?.totalCost !== null ? Number(data?.totalCost) : null,
      ...(data?.temp_id ? { id: data.temp_id } : {}),
      // Calculate or default
    }));
  };

  /**
   * The function `getPaginatedData` takes an array of data and returns a paginated subset based on the
   * current page and page size.
   */
  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

  /**
   * The function `handleAddPartData` processes selected part and set data, updates a work order, and
   * handles different response scenarios accordingly.
   */
  const handleAddPartData = async (setFieldValue) => {

    const filteredData = selectedTablePartData.filter(
      (item) => !prevPartsData.includes(item)
    );
    
    const parts = filteredData?.filter((item) => item.type === "Part");
    const sets = filteredData?.filter((item) => item.type === "Set");

    let setsData = transformDataSet(sets);
    let partData = transformDataPart(parts);

    const response = await updatePartWorkOrder(
      partData,
      setsData,
      data?.id,
      organisationId
    );
    if (response && response.status == 200) {
      setPartSearchTitle("");
      // setSelectedTablePartData([]);
      window.dispatchEvent(new Event("triggerNotificationRefresh"));
      // setSelectedmyData([]);
      setSelectedInvLoc("");
      setAllPartsData([]);
      setPrevPartsData([]);
      setInitPartsData([]);
      setPartsData([]);
      setCurrentPage(1);

      getAllParts();
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        toast.error(response.error.message);
      } else {
        toast.error(t.common.something_wrong);
      }
      setSelectedTablePartData(prevPartsData);
      setSelectedmyData(prevPartsData);
    } else {
      toast.error(t.common.something_wrong);
    }
    setSelectedInvLoc("");
    setAddPartModal(false);
    setCurrentPage(1);
    // setSelectedmyData(selectedTablePartData);
    // setFieldValue('orderItems.items', selectedTablePartData);
  };
  /**
   * The function `getAllParts` fetches data, processes it, and updates various states based on the
   * response.
   */

  const getAllParts = async () => {
    setLoading(true);
    const response = await getAllWOParts(data?.id);

    if (response) {
      const selectedFinalData = mergeAndTransformData(
        response?.part,
        response?.set
      );
      setSubTotal(response?.subTotal);
      setLoading(false);
      addParts(selectedFinalData, true);
      setSelectedTablePartData(selectedFinalData);
      setSelectedmyData(selectedFinalData);
      setPrevPartsData(selectedFinalData);
    } else {
      toast.error(t.common.something_wrong);
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvLocations();
    getAllParts();
  }, []);

  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          {t.common.loading}
        </Loader>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div
                className={`labor-grey-btn ${
                  selectedLanguage == "ar" ? "ml-5" : "mr-5"
                }`}
              >
                {t.workOrders.form.sub_total}

                <span
                  className={`${selectedLanguage == "en" ? "ml-3" : "mr-3"}`}
                >
                  {subTotal ? subTotal : 0} {` ${organisationCurrency}s`}
                </span>
              </div>
            </div>
            <div>
              {!["completed", "cancelled"].includes(data?.attributes?.status) &&
              ["user", "admin", "super_admin", "limited_admin"].includes(
                roleData
              ) ? (
                <button
                  className={`buttun-type-link mt-1`}
                  basic
                  type="button"
                  // disabled={!values?.locations}
                  style={{ background: "transparent" }}
                  onClick={() => addParts(selectedTablePartData)}
                >
                  <span className="customer-add-btn">
                    {" "}
                    <CustomerPlusIcon width="20px" height="20px" />{" "}
                  </span>

                  <p className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                    {t.workOrders.form.add_part_set}
                  </p>
                </button>
              ) : null}
            </div>
          </div>

          {selectedmyData?.length > 0 ? (
            <>
              <div className="scroll-data" style={{ overflow: "auto" }}>
                <div className={"mt-6"}>
                  <div className={`kanbar-header`}>
                    <div class="grid-container-element set wo-form2">
                      <div
                        style={{
                          minWidth: "86px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.workOrders.table.ID}
                      </div>
                      <div
                        style={{
                          minWidth: "140px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.workOrders.details_view.name}
                      </div>
                      <div
                        style={{
                          minWidth: "140px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.parts.form.inv_loc}
                      </div>
                      <div
                        style={{
                          minWidth: "140px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.parts.table.quantity}
                      </div>{" "}
                      <div
                        style={{
                          minWidth: "130px",
                          textAlign: "center",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.parts.form.unit_cost}
                      </div>
                      <div
                        style={{
                          minWidth: "130px",
                          textAlign: "center",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.parts.form.total_cost}
                      </div>
                      <div
                        style={{
                          minWidth: "40px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      ></div>
                    </div>
                  </div>
                </div>

                <PartData
                  selectedmyData={selectedmyData}
                  roleData={roleData}
                  setSubTotal={setSubTotal}
                  data={data}
                  getAllParts={getAllParts}
                  handleDecimalPoints={handleDecimalPoints}
                  setPrevPartsData={setPrevPartsData}
                  setSelectedmyData={setSelectedmyData}
                  allIds={allIds}
                  setSelectedTablePartData={setSelectedTablePartData}
                  organisationCurrency={organisationCurrency}
                  selectedLanguage={selectedLanguage}
                  HandleUnitType={HandleUnitType}
                  activePartIndex={activePartIndex}
                  setActivePartIndex={setActivePartIndex}
                />
              </div>
            </>
          ) : (
            <div className="text-center mt-5">
              <p className="required-text">{t.common.na}</p>
            </div>
          )}

          <Modal
            size="large"
            open={addPartModal}
            className="assets-modal part-list-modal"
            style={{ height: "770px" }}
            onClose={() => {
              handleClosePartModal();
            }}
          >
            <div className="header-container">
              <span className="assets-header">
                {t.workOrders.form.add_part_set}
              </span>
              <div>
                <span
                  className="c-pointer text-right"
                  onClick={() => {
                    handleClosePartModal();
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            </div>

            <Modal.Content className="mt-2">
              <div style={{ gap: "10px" }} className="d-flex">
                <Form.Field style={{ minHeight: "43px" }}>
                  <Dropdown
                    placeholder={t.parts.form.inv_loc}
                    style={{ minWidth: "300px", padding: "6px 2px 1px 12px" }}
                    className={
                      selectedLanguage == "en"
                        ? "loc-dropdown"
                        : "loc-dropdown wos"
                    }
                    selection
                    noResultsMessage={t.common.no_results_found}
                    search
                    clearable
                    // onAddItem={handleAddLocation}
                    options={options?.inv_location}
                    // onOpen={fetchLocationsOptions}
                    value={selectedInvLoc}
                    onChange={(e, value) => {
                      setSelectedInvLoc(value?.value);
                    }}
                  />
                </Form.Field>
                <div
                  style={{ width: "-webkit-fill-available" }}
                  className="set-input"
                >
                  <span className="search-svg-set">
                    <SearchIcon />
                  </span>
                  <Input
                    style={{ width: "-webkit-fill-available" }}
                    placeholder={t.workOrders.form.search_parts}
                    value={partSearchTitle}
                    onChange={(e) => setPartSearchTitle(e.target.value)}
                  />
                </div>
              </div>
              {loading ? (
                <Dimmer active inverted>
                  <Loader inverted content={t.common.loading} />
                </Dimmer>
              ) : (
                <>
                  <div style={{}} className="  mt-5  bulk-upload-table">
                    <DataTable
                      className="wo-table-div"
                      conditionalRowStyles={conditionalRowStyles}
                      columns={[
                        {
                          name: (
                            <div>
                              <Checkbox
                                checked={areAllSelected}
                                disabled={partsData?.every(
                                  (item) => item?.disable == true
                                )}
                                onChange={(e, value) =>
                                  handleSelectAll(value.checked)
                                }
                              />
                            </div>
                          ),
                          width: "50px",
                          cell: (row) => (
                            <div className="d-flex align-items-center">
                              <div>
                                <Checkbox
                                  checked={row?.selectedPart}
                                  disabled={row?.disable}
                                  className="set-checkbox-gery"
                                  // defaultChecked={row?.selectedPart}
                                  onChange={(e, value) => {
                                    if (!row?.disable) {
                                      handleRowSelected(
                                        row,
                                        value.checked,
                                        selectedTablePartData
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          ),
                        },
                        {
                          minWidth: "100px",
                          name: t.workOrders.table.ID,
                          //   name: t.categories.table.name,
                          sortField: "name",
                          sortable: true,
                          cell: (row) => (
                            <div className="d-flex align-items-center">
                              <div>
                                <span className="id-text-style">
                                  {row?.countId ? row?.countId : ""}
                                </span>
                              </div>
                            </div>
                          ),
                        },
                        {
                          minWidth: "240px",
                          name: t.workOrders.details_view.name,

                          cell: (row) => (
                            <div className="d-flex align-items-center">
                              <div>
                                <span className="id-text-style">
                                  {row?.name
                                    ? DisplayFormattedText(row?.name, 23)
                                    : ""}
                                </span>
                              </div>
                            </div>
                          ),
                        },
                        {
                          minWidth: "200px",
                          name: t.parts.form.inv_loc,

                          cell: (row) => (
                            <div className="d-flex align-items-center">
                              <div>
                                <span className="id-text-style">
                                  {row?.location_name
                                    ? DisplayFormattedText(
                                        row?.location_name,
                                        20
                                      )
                                    : ""}
                                </span>
                              </div>
                            </div>
                          ),
                        },
                        {
                          minWidth: "200px",
                          name: t.assets.title,
                          cell: (row) => {
                            const locationData = row?.asset;
                            if (locationData?.length > 0) {
                              const firstLocation = locationData[0]?.name;
                              const remainingCount = locationData.length - 1;
                              const remainingLocations = locationData
                                .slice(1)
                                .map((location) => location?.name)
                                .join(", ");
                              const displayText =
                                remainingCount > 0
                                  ? `${firstLocation} ... +${remainingCount}`
                                  : firstLocation;
                              return (
                                <span
                                  title={locationData
                                    .map((location) => location?.name)
                                    .join(", ")}
                                >
                                  {displayText}
                                </span>
                              );
                            } else {
                              return "";
                            }
                          },
                        },

                        {
                          minWidth: "112px",
                          name: t.po.form.qty_to_order,

                          cell: (row) => (
                            <div className="d-flex align-items-center">
                              <Input
                                style={{ width: "80px" }}
                                disabled={!row?.selectedPart}
                                // defaultValue="1"
                                autoComplete="new-password"
                                type="number"
                                onKeyDown={(evt) => handleKeyDown(evt)}
                                onWheel={(e) => e.target.blur()}
                                value={row?.quantity}
                                onChange={(e, value) => {
                                  handleQtyChange(
                                    //   values,
                                    row,
                                    e.target.value,
                                    //   setFieldValue,
                                    selectedTablePartData
                                  );
                                }}
                              >
                                <input />
                              </Input>

                              {row?.type == "Part" ? (
                                <span
                                  className={`align-items-center justify-content-center ${
                                    selectedLanguage === "en" ? "ml-2" : "mr-2"
                                  }`}
                                  style={{
                                    backgroundColor: "#E9F2FF",
                                    color: "#0C66E4",
                                    minWidth:
                                      selectedLanguage == "en"
                                        ? "40px"
                                        : "56px",
                                    padding: "9px 3px 9px 2px",
                                    fontSize: "14px",
                                    display: "inline-block",
                                    textAlign: "center",
                                  }}
                                >
                                  {row?.unitType
                                    ? HandleUnitType(row?.unitType)
                                    : null}
                                </span>
                              ) : null}
                            </div>
                          ),
                        },
                        {
                          minWidth: "150px",
                          name: t.parts.form.avl_qty,
                          right: true,
                          cell: (row) => (
                            <div className="d-flex align-items-center">
                              <div>
                                <span className="id-text-style">
                                  {row?.type == "Set" ? (
                                    row?.status == "outOfStock" ? (
                                      <div
                                        style={{
                                          color: "#788CA5",
                                          width: "103px",
                                          textAlign: "end",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {t.parts.status.out_of_stock}
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          color: "black",
                                          width: "103px",
                                          textAlign: "end",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {t.parts.status.inStock}
                                      </div>
                                    )
                                  ) : row?.status == "outOfStock" ? (
                                    <div
                                      style={{
                                        color: "#788CA5",
                                        width: "103px",
                                        textAlign: "end",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {t.parts.status.out_of_stock}
                                    </div>
                                  ) : row?.availableQty ? (
                                    <>
                                      <div className="id-text-style d-flex align-items-center">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span
                                            style={{
                                              textAlign: "end",
                                              minWidth: "65px", // Set a fixed width based on the longest expected value
                                              // Align text to the right within the fixed space
                                              display: "inline-block",
                                            }}
                                          >
                                            {row?.availableQty
                                              ? row?.availableQty
                                              : 0}
                                          </span>
                                          <span
                                            className={`align-items-center justify-content-center ${
                                              selectedLanguage === "en"
                                                ? "ml-2"
                                                : "mr-2"
                                            }`}
                                            style={{
                                              backgroundColor: "#E9F2FF",
                                              color: "#0C66E4",
                                              minWidth:
                                                selectedLanguage == "en"
                                                  ? "33px"
                                                  : "48px",
                                              padding: "9px 3px 9px 2px",
                                              fontSize: "14px",
                                              display: "inline-block",
                                              textAlign: "center",
                                            }}
                                          >
                                            {row?.unitType
                                              ? HandleUnitType(row?.unitType)
                                              : null}
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    0
                                  )}
                                </span>
                              </div>
                            </div>
                          ),
                        },
                        {
                          minWidth: "150px",
                          name: t.parts.form.unit_cost,
                          right: true,
                          style: {
                            justifyContent: "end", // Align header text to the right
                          },
                          cell: (row) => (
                            <div className="id-text-style d-flex align-items-center">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    textAlign: "end",
                                    minWidth: "65px", // Set a fixed width based on the longest expected value
                                    // Align text to the right within the fixed space
                                    display: "inline-block",
                                  }}
                                >
                                  {row?.unitCost ? row?.unitCost : 0}
                                </span>
                                <span
                                  className={`align-items-center justify-content-center ${
                                    selectedLanguage === "en" ? "ml-2" : "mr-2"
                                  }`}
                                  style={{
                                    backgroundColor: "#E9F2FF",
                                    color: "#0C66E4",
                                    minWidth: "33px",
                                    padding: "9px 3px 9px 2px",
                                    fontSize: "14px",
                                    display: "inline-block",
                                  }}
                                >
                                  {organisationCurrency}
                                </span>
                              </div>
                            </div>
                          ),
                        },
                      ]}
                      data={getPaginatedData(partsData)}
                      fixedHeader
                      customStyles={customStyles}
                      onRowClicked={(row) => {
                        if (!row.disable) {
                          handleRowSelected(
                            row,
                            row?.selectedPart ? false : true,
                            selectedTablePartData
                          );
                        }
                      }}
                      noDataComponent={<span>{t.sets.no_data_wo}</span>}
                    />
                  </div>

                  {partsData && partsData?.length > 0 && (
                    <div className="d-flex justify-content-end">
                      <Pagination
                        ofString={t.common.of}
                        total={partsData.length}
                        prevItem={
                          selectedLanguage === "en" ? (
                            <ArrowLeft />
                          ) : (
                            <ArrowRight />
                          )
                        }
                        nextItem={
                          selectedLanguage === "en" ? (
                            <ArrowRight />
                          ) : (
                            <ArrowLeft />
                          )
                        }
                        page={currentPage}
                        limit={pageSize}
                        handleNext={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                        handlePrevious={() => {
                          setCurrentPage(currentPage - 1);
                        }}
                        parts={true}
                      />
                    </div>
                  )}
                </>
              )}
            </Modal.Content>
            {!loading && (
              <div
                className={`modal-button-container ${
                  selectedLanguage == "en" ? "mr-8" : "ml-10"
                }`}
              >
                <button
                  className="modal-form-action-button black"
                  onClick={() => {
                    handleClosePartModal();
                  }}
                >
                  {t.assets.form.cancel}
                </button>

                <button
                  onClick={() => handleAddPartData()}
                  className="modal-form-action-button light-blue"
                >
                  {t.common.add}
                </button>
              </div>
            )}
          </Modal>
        </>
      )}
    </>
  );
};

export default Part;
