import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../app/config/i18n/slice";
import {
  AddIcon,
  AddIconWithCircle,
  AmountIcon,
  Attachment,
  CategoryInspection,
  CategoryMechanical,
  CheckboxIcon,
  ChecklistIcon,
  CloseIcon,
  CopyIcon,
  CSVSvg,
  Damage,
  DateIcon,
  DeleteIcon,
  DocSvg,
  DownArrow,
  Electrical,
  EvidanceIcon,
  FileIcon,
  FilePresentIcon,
  GreyFileIcon,
  GreyWOIcon,
  HeadingIcon,
  InspectionIcon,
  LogicIcon,
  MultipleChoiceIcon,
  NumberIcon,
  OperationProcedure,
  OptionIcon,
  OrderIcon,
  PdfGreyIcon,
  PhotoIcon,
  PhotoSvg,
  PictureIcon,
  Preventive,
  Project,
  Refrigeration,
  RequiredIcon,
  Safety,
  ShareIcon,
  SignatureIcon,
  Success,
  TextIcon,
  TextSvg,
  UpArrow,
  VideoGreyIcon,
  WhiteDownChevron,
  ZipSvg,
} from "../../Assets/Icons/svg";
import { cloneDeep } from "lodash";
import {
  Message,
  Header,
  Dropdown,
  Grid,
  Checkbox,
  Radio,
  Modal,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Segment,
  TextArea,
  List,
  DropdownMenu,
  DropdownItem,
} from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Asterisk from "../../app/components/common/Asterisk";
import {
  Accordion,
  AccordionItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import Toggle from "react-toggle";
import { nanoid } from "nanoid";

function useProcedureTFields(
  fields,
  duplicatedIndex,
  setDuplicatedIndex,
  setFinalIndex,
  finalIndex,
  setFinalAddedData,
  finalAddedData
) {
  const t = useSelector(selectTranslations);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const [procedureItemsVisible, setProcedureItemsVisible] = useState({}); // State to manage visibility of procedureItems
  // Function to toggle visibility of procedureItems
  const toggleProcedureItems = (index) => {
    if (procedureItemsVisible[index]) {
      setProcedureItemsVisible({
        ...procedureItemsVisible,
        [index]: false,
      });
    } else {
      setProcedureItemsVisible({
        ...procedureItemsVisible,
        [index]: true,
      });
    }
    // setProcedureItemsVisible(!procedureItemsVisible);
  };

  const fieldRefs = useRef([]);
  const procedureRefs = useRef({});
  fieldRefs.current =
    fields?.length &&
    fields.map((_, i) => fieldRefs.current[i] ?? React.createRef());

  fields?.length &&
    fields.forEach((field, outerIndex) => {
      if (!procedureRefs?.current[outerIndex]) {
        procedureRefs.current[outerIndex] = [];
      }
      field.procedureFields.forEach((_, innerIndex) => {
        procedureRefs.current[outerIndex][innerIndex] =
          procedureRefs.current[outerIndex][innerIndex] ?? React.createRef();
      });
    });

  useEffect(() => {
    // if (duplicatedIndex !== null && fieldRefs.current[duplicatedIndex]) {
    //   // fieldRefs.current[duplicatedIndex].current.scrollIntoView({ behavior: 'smooth' });
    //   fieldRefs.current[duplicatedIndex].current.focus();
    //   setDuplicatedIndex(null);
    // }

    if (duplicatedIndex !== null && duplicatedIndex !== undefined) {
      const [fieldIndex, procedureIndex] = duplicatedIndex.split("-");

      const refKey = `${fieldIndex}-${procedureIndex}`;

      if (procedureIndex == null || !procedureIndex) {
        if (fieldRefs.current[duplicatedIndex]) {
          // Scroll into view or focus on the input field
          fieldRefs.current[duplicatedIndex].current.focus();
        }
      } else {
        if (
          procedureRefs?.current[fieldIndex] &&
          procedureRefs.current[fieldIndex][procedureIndex]
        ) {
          procedureRefs.current[fieldIndex][procedureIndex].current &&
            procedureRefs.current[fieldIndex][procedureIndex].current.focus();
        }
        // if (fieldRefs.current[refKey]) {
        //   // Scroll into view or focus on the input field
        //   fieldRefs.current[refKey].current.focus();
        //   // fieldRefs.current[refKey].focus();
        // }
      }

      setDuplicatedIndex(null); // Reset duplicated index
    }
  }, [duplicatedIndex]);

  const fieldTypes = [
    {
      key: "checkbox",
      value: "checkbox",
      text: (
        <span>
          <span className="ml-2 mr-2">
            <CheckboxIcon />
          </span>
          {t.procedures.form.type_options[0]}
        </span>
      ),
    },
    {
      key: "text",
      value: "text",
      text: (
        <span>
          <span className="ml-2 mr-2">
            <TextIcon />
          </span>
          {t.procedures.form.type_options[1]}
        </span>
      ),
    },
    {
      key: "number",
      value: "number",
      text: (
        <span>
          <span className="ml-2 mr-2">
            <NumberIcon />
          </span>
          {t.procedures.form.type_options[2]}
        </span>
      ),
    },
    {
      key: "amount",
      value: "amount",
      text: (
        <span>
          <span className="ml-2 mr-2">
            <AmountIcon />
          </span>
          {t.procedures.form.type_options[3]}
        </span>
      ),
    },
    {
      key: "multipleChoice",
      value: "multipleChoice",
      text: (
        <span>
          <span className="ml-2 mr-2">
            <MultipleChoiceIcon />
          </span>
          {t.procedures.form.type_options[4]}
        </span>
      ),
    },
    {
      key: "checklist",
      value: "checklist",
      text: (
        <span>
          <span className="ml-2 mr-2">
            <ChecklistIcon />
          </span>
          {t.procedures.form.type_options[5]}
        </span>
      ),
    },
    {
      key: "inspectionCheck",
      value: "inspectionCheck",
      text: (
        <span>
          <span className="ml-2 mr-2">
            <InspectionIcon />
          </span>
          {t.procedures.form.type_options[6]}
        </span>
      ),
    },
    {
      key: "signature",
      value: "signature",
      text: (
        <span>
          <span className="ml-2 mr-2">
            <SignatureIcon />
          </span>
          {t.procedures.form.type_options[7]}
        </span>
      ),
    },
    {
      key: "date",
      value: "date",
      text: (
        <span>
          <span className="ml-2 mr-2">
            <DateIcon />
          </span>
          {t.procedures.form.type_options[8]}
        </span>
      ),
    },
    {
      key: "file",
      value: "file",
      text: (
        <span>
          <span className="ml-2 mr-2">
            <FileIcon />
          </span>
          {t.procedures.form.type_options[9]}
        </span>
      ),
    },
    {
      key: "photo",
      value: "photo",
      text: (
        <span>
          <span className="ml-2 mr-2">
            <PhotoIcon />
          </span>
          {t.procedures.form.type_options[10]}
        </span>
      ),
    },
  ];

  const inspectionTypes = [
    {
      key: "yesNoNA",
      value: "yesNoNA",
      text: t.procedures.form.inspection_types[0].join(" - "),
      logicAnswers: [
        {
          key: 0,
          value: "yes",
          text: t.procedures.form.inspection_types[0][0],
        },
        {
          key: 1,
          value: "no",
          text: t.procedures.form.inspection_types[0][1],
        },
        {
          key: 2,
          value: "na",
          text: t.procedures.form.inspection_types[0][2],
        },
      ],
    },
    {
      key: "goodFairPoorNA",
      value: "goodFairPoorNA",
      text: t.procedures.form.inspection_types[1].join(" - "),
      logicAnswers: [
        {
          key: 0,
          value: "good",
          text: t.procedures.form.inspection_types[1][0],
        },
        {
          key: 1,
          value: "fair",
          text: t.procedures.form.inspection_types[1][1],
        },
        {
          key: 2,
          value: "poor",
          text: t.procedures.form.inspection_types[1][2],
        },
        {
          key: 3,
          value: "na",
          text: t.procedures.form.inspection_types[1][3],
        },
      ],
    },
    {
      key: "passFailNA",
      value: "passFailNA",
      text: t.procedures.form.inspection_types[2].join(" - "),
      logicAnswers: [
        {
          key: 0,
          value: "pass",
          text: t.procedures.form.inspection_types[2][0],
        },
        {
          key: 1,
          value: "fail",
          text: t.procedures.form.inspection_types[2][1],
        },
        {
          key: 2,
          value: "na",
          text: t.procedures.form.inspection_types[2][2],
        },
      ],
    },
    {
      key: "safeRiskNA",
      value: "safeRiskNA",
      text: t.procedures.form.inspection_types[3].join(" - "),
      logicAnswers: [
        {
          key: 0,
          value: "safe",
          text: t.procedures.form.inspection_types[3][0],
        },
        {
          key: 1,
          value: "risk",
          text: t.procedures.form.inspection_types[3][1],
        },
        {
          key: 2,
          value: "na",
          text: t.procedures.form.inspection_types[3][2],
        },
      ],
    },

    {
      key: "compliantNonCompliantNA",
      value: "compliantNonCompliantNA",
      text: t.procedures.form.inspection_types[4].join(" - "),
      logicAnswers: [
        {
          key: 0,
          value: "compliant",
          text: t.procedures.form.inspection_types[4][0],
        },
        {
          key: 1,
          value: "non-compliant",
          text: t.procedures.form.inspection_types[4][1],
        },
        {
          key: 2,
          value: "na",
          text: t.procedures.form.inspection_types[4][2],
        },
      ],
    },
    {
      key: "scaleFive",
      value: "scaleFive",
      text: t.procedures.form.inspection_types[5][0],
      logicAnswers: [
        {
          key: 0,
          value: "0",
          text: "0",
        },
        {
          key: 1,
          value: "1",
          text: "1",
        },
        {
          key: 2,
          value: "2",
          text: "2",
        },
        {
          key: 3,
          value: "3",
          text: "3",
        },
        {
          key: 4,
          value: "4",
          text: "4",
        },
        {
          key: 5,
          value: "5",
          text: "5",
        },
      ],
    },
  ];

  const logicTriggers = [
    {
      key: 0,
      value: "action",
      icon: "bullhorn",
      text: t.procedures.form.then_options[0],
    },
    {
      key: 1,
      value: "evidence",
      icon: "images",
      text: t.procedures.form.then_options[1],
    },
  ];

  const generatedFields = (
    fields,
    setFields,
    validationProcedureItems,
    setValidationProcedureItems,
    attachmentsRef,
    setActiveIndex
  ) => {
    const handleFieldChange = (id, type, index = "", isHeader = true) => (
      event,
      value
    ) => {
      // 1. Make a shallow copy of the items
      let currentFieldValue = "";
      let newfields = cloneDeep(fields);
      // 2. Make a shallow copy of the item you want to mutate
      let item = cloneDeep(fields[id]);
      // 3. Replace the property you're intested in

      if (type == "label") {
        currentFieldValue = event.target.value;
        if (isHeader) {
          item.label = event.target.value;
        } else {
          item.procedureFields[index].label = event.target.value;
        }
      }
      if (type == "fieldType") {
        // setDefaultField(value.value);

        if (!isHeader) {
          setFinalIndex(`${id}-${index}`);
          item.procedureFields[index].fieldType = value.value;
          currentFieldValue = value.value;
          if (
            item?.procedureFields[index]?.fieldType == "inspectionCheck" &&
            !item?.procedureFields[index]?.inspectionType
          ) {
            item.procedureFields[index].inspectionType =
              inspectionTypes[0].value;
          }
          if (item?.procedureFields[index]?.inspectionType) {
            let values = inspectionTypes.find(
              (one) =>
                one.value === item?.procedureFields[index]?.inspectionType
            );
          }
        } else {
          item.fieldType = value.value;
          currentFieldValue = value.value;
          if (item?.fieldType == "inspectionCheck" && !item?.inspectionType) {
            item.inspectionType = inspectionTypes[0].value;
          }
          if (item.inspectionType) {
            let values = inspectionTypes.find(
              (one) => one.value === item.inspectionType
            );
          }

          // if (values) {
          //   item.failedValue = values.logicAnswers[0].value;
          // }
        }
      }
      if (type == "isRequired") {
        if (!isHeader) {
          item.procedureFields[index].isRequired = value.checked;
        } else {
          item.isRequired = value.checked;
        }
      }

      if (type == "inspectionType") {
        if (!isHeader) {
          item.procedureFields[index].inspectionType = value.value;
          item.procedureFields[index].logicTrigger = "";
          item.procedureFields[index].logicAnswer = "";
          item.procedureFields[index].actionDetails = "";
          item.procedureFields[index].evidenceDetails = "";

          if (item.procedureFields[index].inspectionType) {
            let values = inspectionTypes.find(
              (one) => one.value === item.procedureFields[index].inspectionType
            );
          }
        } else {
          item.procedureFields[index].inspectionType = value.value;
          item.procedureFields[index].logicTrigger = "";
          item.procedureFields[index].logicAnswer = "";
          item.procedureFields[index].actionDetails = "";
          item.procedureFields[index].evidenceDetails = "";

          if (item.procedureFields[index].inspectionType) {
            let values = inspectionTypes.find(
              (one) => one.value === item.procedureFields[index].inspectionType
            );
          }
        }
      }

      // if (type == 'failedValue') item.failedValue = String(value.value);

      if (type == "logic") {
        event.stopPropagation();
        if (!isHeader) {
          item.procedureFields[index].logic = item.procedureFields[index].logic
            ? false
            : true;
          if (!item?.procedureFields[index]?.logic) {
            item.procedureFields[index].logicTrigger = null;
            item.procedureFields[index].logicAnswer = "";
            item.procedureFields[index].actionDetails = "";
            item.procedureFields[index].evidenceDetails = "";
          }
        } else {
          item.logic = item.logic ? false : true;
          if (!item.logic) {
            item.logicTrigger = null;
            item.logicAnswer = "";
            item.actionDetails = "";
            item.evidenceDetails = "";
          }
        }
      }
      if (type == "logicAnswer") {
        if (!isHeader) {
          item.procedureFields[index].logicAnswer = String(value.value);
          item.procedureFields[index].failedValue = String(value.value);
        } else {
          item.logicAnswer = String(value.value);
          item.failedValue = String(value.value);
        }
      }
      if (type == "logicTrigger") {
        if (!isHeader) {
          item.procedureFields[index].logicTrigger = value.value;
        } else {
          item.logicTrigger = value.value;
        }
      }
      if (type == "actionDetails") {
        if (!isHeader) {
          item.procedureFields[index].actionDetails = event.target.value;
        } else {
          item.actionDetails = event.target.value;
        }
      }

      if (type == "evidenceDetails") {
        if (!isHeader) {
          item.procedureFields[index].evidenceDetails = event.target.value;
        } else {
          item.evidenceDetails = event.target.value;
        }
      }

      if (type == "active") {
        newfields.forEach((el) => (el.active = false));
        item.active = true;
        // setActiveIndex(id);
      }
      // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
      newfields[id] = item;

      /////     Validate the item data and set validation array for items

      let validationProcedureItemsFields = [...validationProcedureItems];
      let ItemFieldValidation = { ...validationProcedureItemsFields[id] };

      if (!isHeader) {
        if (
          ItemFieldValidation.procedureFields &&
          Array.isArray(ItemFieldValidation.procedureFields)
        ) {
          // Clone the specific procedure field to update
          let procedureFieldValidation = {
            ...ItemFieldValidation.procedureFields[index],
          };

          // Update the specific type within the procedure field if it exists
          if (procedureFieldValidation && procedureFieldValidation[type]) {
            let updatedField = {
              ...procedureFieldValidation,
              [type]: {
                ...procedureFieldValidation[type],
                error: procedureFieldValidation[type].pattern(currentFieldValue)
                  ? false
                  : true,
                skipped: true,
                message:
                  typeof procedureFieldValidation[type]?.messageData ===
                  "function"
                    ? procedureFieldValidation[type].messageData(
                        currentFieldValue
                      )
                    : t.common.required_field,
              },
            };

            // Update the specific procedure field with the modified field
            ItemFieldValidation.procedureFields[index] = updatedField;

            // Update the main item field validation
            validationProcedureItemsFields[id] = ItemFieldValidation;

            // Update the state with the modified validation items
            setValidationProcedureItems(validationProcedureItemsFields);
          }
        }
      } else {
        if (ItemFieldValidation && ItemFieldValidation[type]) {
          let currentValidation = {
            ...ItemFieldValidation,
            [type]: {
              ...ItemFieldValidation[type],
              error: ItemFieldValidation[type].pattern(currentFieldValue)
                ? false
                : true,
              skipped: true,
              message:
                typeof ItemFieldValidation[type]?.messageData === "function"
                  ? ItemFieldValidation[type].messageData(currentFieldValue)
                  : t.common.required_field,
              // message: ItemFieldValidation[type].messageData(currentFieldValue)
              // ? ItemFieldValidation[type].message(currentFieldValue)
              // : ItemFieldValidation[type].message,
              // message: ItemFieldValidation[type].message(currentFieldValue),
            },
          };
          //console.log('current', currentValidation);
          validationProcedureItemsFields[id] = currentValidation;
          setValidationProcedureItems(validationProcedureItemsFields);
        }
        // if (ItemFieldValidation && ItemFieldValidation[type]) {
        //   let currentValidation = {
        //     ...ItemFieldValidation,
        //     [type]: {
        //       ...ItemFieldValidation[type],
        //       error: ItemFieldValidation[type].pattern(currentFieldValue)
        //         ? false
        //         : true,
        //       skipped: true,
        //     },
        //   };
        //   //console.log('current', currentValidation);
        //   validationProcedureItemsFields[id] = currentValidation;
        //   setValidationProcedureItems(validationProcedureItemsFields);
        // }
      }

      ////////////////////////////////////////////////////////////////////////////////////////////
      // //console.log(newfields[id]);
      // 5. Set the state to our new copy
      setFields(newfields);
    };
    const removeThisField = (index, isHeader = false, i = null) => (e) => {
      e.stopPropagation();
      let newFields = [...fields];
      let newItemValidationFields = [...validationProcedureItems];

      if (isHeader) {
        setProcedureItemsVisible({
          ...procedureItemsVisible,
          [index]: false,
        });
        const headerField = newFields[index];

        const procedureFieldsToAdd = headerField.procedureFields || [];

        // Change the label of the header field to 'outer_header'
        headerField.label = "outer_header";

        // Remove the corresponding header validation item
        const headerValidationItem = newItemValidationFields[index];

        // Remove validation fields outside procedureFields
        delete headerValidationItem.id;
        delete headerValidationItem.label;
        delete headerValidationItem.fieldType;
        delete headerValidationItem.options;

        // Update the fields and validation arrays
        newFields[index] = headerField;
        newItemValidationFields[index] = headerValidationItem;
        // Add each procedure field to newFields and its validation item to newItemValidationFields
        // procedureFieldsToAdd.forEach((procedureField, procedureIndex) => {
        //   newFields.splice(index + procedureIndex, 0, procedureField);

        //   const procedureValidationItem =
        //     headerValidationItem.procedureFields[procedureIndex];
        //   newItemValidationFields.splice(
        //     index + procedureIndex,
        //     0,
        //     procedureValidationItem
        //   );
        // });
      } else {
        if (i == null) {
          newFields.splice(index, 1);
          newItemValidationFields.splice(index, 1);
        } else {
          // Remove a specific procedure field inside a header
          const headerField = newFields[index];

          const procedureFieldToRemove = headerField?.procedureFields[i];

          // if (headerField?.procedureFields?.length == 1) {
          //   newFields.splice(index, 1);
          //   newItemValidationFields.splice(index, 1);
          // } else {
          if (procedureFieldToRemove) {
            // Remove the procedure field from the header
            headerField.procedureFields.splice(i, 1);

            // Update the fields array with the modified header field
            newFields[index] = headerField;

            // Remove the corresponding validation for the procedure field
            const headerValidationItem = newItemValidationFields[index];
            headerValidationItem.procedureFields.splice(i, 1);

            // Update the validation array with the modified header validation item
            newItemValidationFields[index] = headerValidationItem;
            // }
          }
        }
        // Remove a non-header field
      }
      // Update state with the new arrays
      // let combined = newFields.map((field, idx) => ({
      //   field,
      //   validation: newItemValidationFields[idx],
      // }));

      // // Sort combined array so that fields with label !== 'outer_header' are at the top
      // combined.sort((a, b) => (a.field.label !== 'outer_header' ? -1 : 1));

      let combined = newFields.map((field, idx) => ({
        field,
        validation: newItemValidationFields[idx],
      }));

      combined.sort((a, b) => {
        if (
          a.field.label === "outer_header" &&
          b.field.label !== "outer_header"
        )
          return 1;
        if (
          a.field.label !== "outer_header" &&
          b.field.label === "outer_header"
        )
          return -1;
        return 0;
      });

      // Separate the combined array back into newFields and newItemValidationFields
      if (isHeader) {
        newFields = combined.map((item) => item.field);
        newItemValidationFields = combined.map((item) => item.validation);
      }

      setFields(newFields);
      setValidationProcedureItems(newItemValidationFields);
    };

    const duplicateField = (index, isHeader = false, i = null) => (e) => {
      e.stopPropagation();
      let newFields = [...fields];
      let newItemValidationFields = [...validationProcedureItems];
      const id = newFields.length + 1;

      const totalProcedureFields = fields.reduce((acc, field) => {
        return acc + field.procedureFields.length;
      }, 0);

      if (isHeader) {
        const headerField = newFields[index];
        const procedureFieldsToAdd = headerField.procedureFields || [];
        const headerCount = fields.filter(
          (field) => field.label !== "outer_header"
        ).length;
        let newField = {
          ...headerField,
          id: id,
          key: nanoid(),
          // label: `${t.procedures.form.header} ${headerCount + 1}`,
          procedureFields: [],
        };

        let newValidationData = {
          ...newItemValidationFields[index],
          id: {
            error: false,
            pattern: (id) => id >= 0,
            skipped: false,
            message: t.common.required_field,
          },
          label: {
            error: true,
            pattern: (label) => label !== "" && label.length <= 500,
            skipped: false,
            message: t.common.required_field,
            messageData: (label) => {
              if (label === "") {
                return t.common.required_field;
              } else if (label.length > 500) {
                return t.common.characters_field;
              }
              return t.common.required_field;
            },
          },
          fieldType: {
            error: false,
            pattern: (fieldType) => fieldType != "",
            skipped: false,
            message: t.common.required_field,
          },
          options: [
            {
              error: false,
              pattern: (value, fieldType) =>
                ["multipleChoice", "checklist", "checkbox"].includes(fieldType)
                  ? value != "" && value.length <= 500
                  : true,
              skipped: false,
              message: t.common.required_field,
              messageData: (label) => {
                if (label === "") {
                  return t.common.required_field;
                } else if (label.length > 500) {
                  return t.common.characters_field;
                }
                return t.common.required_field;
              },
            },
          ],
          procedureFields: [],
        };

        newFields.splice(index + 1, 0, newField);
        newItemValidationFields.splice(index + 1, 0, newValidationData);

        setDuplicatedIndex(`${index + 1}`);
      } else {
        const headerField = newFields[index];
        const procedureFieldToDuplicate = headerField.procedureFields[i];
        let duplicatedProcedureValidation;
        let newField;

        if (
          ["multipleChoice", "checklist", "checkbox"].includes(
            procedureFieldToDuplicate?.fieldType
          )
        ) {
          newField = {
            ...procedureFieldToDuplicate,
            id: headerField.procedureFields.length + 1,
            key: nanoid(),
            attachments: [],
            prevAttachments: [],
            options: [
              {
                item: "",
              },
            ],
            // label: `${t.procedures.form.add_procedure_item} ${headerField
            //   .procedureFields.length + 1}`,
          };

          duplicatedProcedureValidation = {
            ...newItemValidationFields[index].procedureFields[i],
            label: {
              ...newItemValidationFields[index].procedureFields[i].label,
              error: false,
            },
            options: [
              {
                error: false,
                pattern: (value, fieldType) =>
                  ["multipleChoice", "checklist", "checkbox"].includes(
                    fieldType
                  )
                    ? value != "" && value.length <= 500
                    : true,
                skipped: false,
                message: t.common.required_field,
                messageData: (label) => {
                  if (label === "") {
                    return t.common.required_field;
                  } else if (label.length > 500) {
                    return t.common.characters_field;
                  }
                  return t.common.required_field;
                },
              },
            ],
          };
        } else {
          newField = {
            ...procedureFieldToDuplicate,
            id: headerField.procedureFields.length + 1,
            attachments: [],
            prevAttachments: [],
            key: nanoid(),
            // label: `${t.procedures.form.add_procedure_item} ${headerField
            //   .procedureFields.length + 1}`,
          };

          duplicatedProcedureValidation = {
            ...newItemValidationFields[index].procedureFields[i],
            label: {
              ...newItemValidationFields[index].procedureFields[i].label,
              error: false,
            },
          };
        }

        headerField.procedureFields.splice(i + 1, 0, newField);
        newFields[index] = headerField;

        newItemValidationFields[index].procedureFields.splice(
          i + 1,
          0,
          duplicatedProcedureValidation
        );
        newItemValidationFields[index] = {
          ...newItemValidationFields[index],
          procedureFields: [...newItemValidationFields[index].procedureFields],
        };

        setDuplicatedIndex(`${index}-${i + 1}`);

        // }
      }

      // Update state with the new arrays
      setFields(newFields);
      setValidationProcedureItems(newItemValidationFields);
    };

    const handleDeleteAttachment = (attachmentId, isHeader = true) => {
      if (!isHeader) {
        setFields((prevFields) => {
          return prevFields.map((prevField) => {
            const updatedProcedureFields = prevField?.procedureFields?.map(
              (procedureField) => {
                const newPrevAttachments = procedureField.prevAttachments.filter(
                  (prev) => prev.id !== attachmentId
                );
                const newAttachments = procedureField.attachments.filter(
                  (id) => id !== attachmentId
                );
                return {
                  ...procedureField,
                  prevAttachments: newPrevAttachments,
                  attachments: newAttachments,
                };
              }
            );

            return {
              ...prevField,
              procedureFields: updatedProcedureFields,
            };
          });
        });
      } else {
        setFields((prevFields) => {
          return prevFields.map((prevField) => {
            const newPrevAttachments = prevField.prevAttachments.filter(
              (prev) => prev.id !== attachmentId
            );
            const newAttachments = prevField.attachments.filter(
              (id) => id !== attachmentId
            );
            return {
              ...prevField,
              prevAttachments: newPrevAttachments,
              attachments: newAttachments,
            };
          });
        });
      }
    };

    // const updateValidationData = (startIndex, endIndex,type = 'FIELD') => {

    //   if(type === 'FIELD'){

    //     const newValidationData = [...validationProcedureItems];
    //     const movedItem = newValidationData.splice(startIndex, 1)[0];
    //     newValidationData.splice(endIndex, 0, movedItem);

    //     // Update validationProcedureItems state
    //     setValidationProcedureItems(newValidationData);
    //   }else{

    //   }
    // };

    const updateValidationData = (
      startIndex,
      endIndex,
      type = "FIELD",
      sourceFieldIndex = null,
      destinationFieldIndex = null
    ) => {
      if (type === "FIELD") {
        const newValidationData = [...validationProcedureItems];
        const movedItem = newValidationData.splice(startIndex, 1)[0];
        newValidationData.splice(endIndex, 0, movedItem);

        // Update validationProcedureItems state
        setValidationProcedureItems(newValidationData);
      } else if (type === "PROCEDURE") {
        if (sourceFieldIndex !== null && destinationFieldIndex !== null) {
          // Handle moving a procedure field between different fields
          const sourceValidationField =
            validationProcedureItems[sourceFieldIndex];
          const destinationValidationField =
            validationProcedureItems[destinationFieldIndex];

          const sourceProcedureFields = [
            ...sourceValidationField?.procedureFields,
          ];
          const destinationProcedureFields = [
            ...destinationValidationField?.procedureFields,
          ];

          const movedProcedure = sourceProcedureFields.splice(startIndex, 1)[0];

          if (sourceFieldIndex === destinationFieldIndex) {
            // Reorder within the same field
            sourceProcedureFields.splice(endIndex, 0, movedProcedure);
            const newField = {
              ...sourceValidationField,
              procedureFields: sourceProcedureFields,
            };

            const newValidationData = [...validationProcedureItems];
            newValidationData[sourceFieldIndex] = newField;

            setValidationProcedureItems(newValidationData);
          } else {
            // Move to a different field
            destinationProcedureFields.splice(endIndex, 0, movedProcedure);
            const newSourceField = {
              ...sourceValidationField,
              procedureFields: sourceProcedureFields,
            };
            const newDestinationField = {
              ...destinationValidationField,
              procedureFields: destinationProcedureFields,
            };

            const newValidationData = [...validationProcedureItems];
            newValidationData[sourceFieldIndex] = newSourceField;
            newValidationData[destinationFieldIndex] = newDestinationField;

            setValidationProcedureItems(newValidationData);
          }
        }
      }
    };

    const handleDragEnd = (result) => {
      const { source, destination, type } = result;

      // Do nothing if there's no destination
      if (!destination) return;

      if (
        destination.droppableId === "fields" &&
        destination.index === fields?.length - 1 &&
        type == "FIELD"
      ) {
        // Prevent drag and drop operation
        return;
      }

      if (
        type === "FIELD" &&
        (fields[source.index].label === "outer_header" ||
          fields[destination.index]?.label === "outer_header")
      ) {
        return;
      }

      if (type === "FIELD") {
        // Reorder fields
        const reorderedFields = Array.from(fields);
        const [removed] = reorderedFields.splice(source.index, 1);
        reorderedFields.splice(destination.index, 0, removed);
        setFields(reorderedFields);
        updateValidationData(result.source.index, result.destination.index);
      } else {
        // Reorder or move procedure fields
        const sourceFieldIndex = fields.findIndex(
          (field) => field.key == source.droppableId
        );
        const destinationFieldIndex = fields.findIndex(
          (field) => field.key == destination.droppableId
        );

        if (sourceFieldIndex === -1 || destinationFieldIndex === -1) {
          return;
        }

        const sourceField = fields[sourceFieldIndex];
        const destinationField = fields[destinationFieldIndex];

        const sourceProcedureFields = Array.from(sourceField.procedureFields);
        const destinationProcedureFields = Array.from(
          destinationField.procedureFields
        );

        const [movedProcedure] = sourceProcedureFields.splice(source.index, 1);

        if (sourceFieldIndex === destinationFieldIndex) {
          if (destinationProcedureFields?.length == 1) {
          }
          // Reorder within the same field
          sourceProcedureFields.splice(destination.index, 0, movedProcedure);
          const newField = {
            ...sourceField,
            procedureFields: sourceProcedureFields,
          };

          const newFields = [...fields];
          newFields[sourceFieldIndex] = newField;

          setFields(newFields);
          updateValidationData(
            result.source.index,
            result.destination.index,
            "PROCEDURE",
            sourceFieldIndex
          );
        } else {
          // Move to a different field
          destinationProcedureFields.splice(
            destination.index,
            0,
            movedProcedure
          );

          const newSourceField = {
            ...sourceField,
            procedureFields: sourceProcedureFields,
          };
          const newDestinationField = {
            ...destinationField,
            procedureFields: destinationProcedureFields,
          };

          const newFields = [...fields];
          newFields[sourceFieldIndex] = newSourceField;
          newFields[destinationFieldIndex] = newDestinationField;

          // if (newSourceField.procedureFields.length === 0) {
          //   newFields.splice(sourceFieldIndex, 1);
          // }

          const hasOuterHeader = fields.filter(
            (field) => field.label === "outer_header"
          );
          if (hasOuterHeader?.length === 0) {
          }

          setFields(newFields);
          updateValidationData(
            result.source.index,
            result.destination.index,
            "PROCEDURE",
            sourceFieldIndex,
            destinationFieldIndex
          );
        }
      }
    };

    const hasOuterHeader = fields.filter(
      (field) => field.label === "outer_header"
    );
    const applyMarginBottom =
      hasOuterHeader.length > 0 &&
      hasOuterHeader.every((field) => field.procedureFields.length === 0);

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable
          // type={JSON.stringify({
          //   level: 0,
          //   level0Id: '',
          //   level1Id: '',
          //   level0Index: 0,
          //   level1Index: 0,
          // })}
          type="FIELD"
          droppableId="fields"
          isCombineEnabled={true}
          isDropDisabled={false}
          direction="vertical"
          // isDropDisabled= {
          // fields.some(field => field.label !== 'outer_header')}
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Accordion>
                {fields.map((field, i) => (
                  <Draggable
                    key={field?.key}
                    draggableId={field?.key?.toString()}
                    index={i}
                    isDragDisabled={field.label === "outer_header"}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          // Ensure the dragged item remains visible
                          left: "0px",
                          userSelect: "none",
                          position: "static",
                          background: "white",
                        }}
                      >
                        <div className={"mb-5"} tabIndex="1">
                          {field?.fieldType == "heading" ? (
                            field?.label != "outer_header" ? (
                              <>
                                <AccordionItem key={field?.key}>
                                  <div
                                  // style={{ border: '2px solid red' }}
                                  >
                                    <div
                                      className={
                                        validationProcedureItems[i - 1]?.label
                                          ?.error &&
                                        validationProcedureItems[i - 1]?.label
                                          ?.skipped
                                          ? "main-div header m-1 mt-13 custom-textarea-parent"
                                          : "main-div header m-1 mt-5 custom-textarea-parent"
                                      }
                                      // className="main-div header m-1 mt-5"
                                    >
                                      <div
                                        style={{
                                          width: "-webkit-fill-available",
                                        }}
                                      >
                                        <div className="header-title-change-procedure blue">
                                          <div className="title-div d-flex">
                                            <div
                                              {...provided.dragHandleProps}
                                              className="
                                         mt-2 ml-1"
                                            >
                                              <OrderIcon />{" "}
                                            </div>
                                            {!procedureItemsVisible[i] ? (
                                              <div
                                                className="mt-2 c-pointer"
                                                onClick={() =>
                                                  toggleProcedureItems(i)
                                                }
                                              >
                                                {<DownArrow />}
                                              </div>
                                            ) : (
                                              <div
                                                className="c-pointer"
                                                style={
                                                  selectedLanguage == "en"
                                                    ? { marginTop: "5px" }
                                                    : {
                                                        marginTop: "5px",
                                                        rotate: "180deg",
                                                      }
                                                }
                                                onClick={() =>
                                                  toggleProcedureItems(i)
                                                }
                                              >
                                                {<UpArrow />}
                                              </div>
                                            )}
                                            <>
                                              <TextArea
                                                ref={fieldRefs?.current[i]}
                                                autoComplete="new-password"
                                                className="custom-text-area"
                                                rows={2}
                                                fluid
                                                style={{
                                                  width:
                                                    "-webkit-fill-available",
                                                }}
                                                placeholder={
                                                  validationProcedureItems[i]
                                                    ?.label?.error &&
                                                  validationProcedureItems[i]
                                                    ?.label?.skipped
                                                    ? ""
                                                    : field?.placeholder
                                                }
                                                value={field?.label}
                                                onChange={handleFieldChange(
                                                  i,
                                                  "label"
                                                )}
                                              />
                                              <div
                                                className={
                                                  selectedLanguage == "ar"
                                                    ? "mr-2 p-2"
                                                    : "ml-3 p-2"
                                                }
                                                // onClick={removeThisField(i)}
                                                style={{
                                                  background: "#0C66E4",
                                                  color: "white",
                                                  borderRadius: "3px",
                                                  alignSelf: "start",
                                                }}
                                              >
                                                {
                                                  fields[i]?.procedureFields
                                                    ?.length
                                                }
                                              </div>

                                              <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ml-1 mt-2 align-self-start">
                                                <UncontrolledDropdown
                                                  className="mr-2"
                                                  direction="down"
                                                >
                                                  <DropdownToggle
                                                    color="primary"
                                                    className="procedure-toggle"
                                                  >
                                                    <OptionIcon />
                                                  </DropdownToggle>
                                                  <DropdownMenu
                                                    className={
                                                      selectedLanguage == "ar"
                                                        ? "procedure rtl"
                                                        : "procedure"
                                                    }
                                                  >
                                                    <DropdownItem className="divider" />
                                                    <DropdownItem
                                                      onClick={duplicateField(
                                                        i,
                                                        true
                                                      )}
                                                    >
                                                      {t.procedures.duplicate}
                                                    </DropdownItem>
                                                    <DropdownItem className="divider" />
                                                    <DropdownItem
                                                      onClick={removeThisField(
                                                        i,
                                                        true
                                                      )}
                                                    >
                                                      {t.common.delete}
                                                    </DropdownItem>
                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                              </div>
                                            </>
                                          </div>
                                          {validationProcedureItems[i]?.label
                                            ?.error &&
                                          validationProcedureItems[i]?.label
                                            ?.skipped ? (
                                            <div className="label-error ml-17 mr-17">
                                              {
                                                validationProcedureItems[i]
                                                  .label.message
                                              }
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {/* {field?.label ? <div className='ml-17 mr-17 mt-1 text-right'>({field?.label?.length}/500)</div> : null} */}
                                        </div>
                                      </div>
                                    </div>

                                    <Droppable
                                      droppableId={field?.key?.toString()}
                                      className="drps"
                                      // isDropDisabled={false}
                                      type="PROCEDURE"
                                      isDropDisabled={false}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                          style={
                                            field?.procedureFields?.length ==
                                              0 && !procedureItemsVisible[i]
                                              ? { marginBottom: "25px" }
                                              : {}
                                          }
                                          // style={applyMarginBottom ? { marginBottom: "5pc" } : {}}
                                        >
                                          {/* <div style={{height:"200px"}}></div> */}
                                          {!procedureItemsVisible[i] &&
                                            field?.procedureFields?.map(
                                              (field, index) => (
                                                <Draggable
                                                  key={field?.key}
                                                  draggableId={field?.key?.toString()}
                                                  index={index}
                                                  isDragDisabled={
                                                    false
                                                    // fields[i]?.procedureFields
                                                    //   ?.length == 1
                                                  }
                                                >
                                                  {(dragableProvided) => (
                                                    <div
                                                      ref={
                                                        dragableProvided.innerRef
                                                      }
                                                      {...dragableProvided.draggableProps}
                                                      style={{
                                                        ...dragableProvided
                                                          .draggableProps.style,
                                                        // Ensure the dragged item remains visible
                                                        left: "0px",
                                                        userSelect: "none",
                                                        position: "static",
                                                        background: "white",
                                                      }}
                                                      className={
                                                        validationProcedureItems[
                                                          i
                                                        ]?.label?.error &&
                                                        validationProcedureItems[
                                                          i
                                                        ]?.label?.skipped &&
                                                        index == 0
                                                          ? "main-div m-3 mt-13 "
                                                          : "main-div m-3 mt-5"
                                                      }
                                                    >
                                                      <div className="">
                                                        <>
                                                          <div className="header-title-change-procedure">
                                                            <div className="title-div d-flex">
                                                              <div
                                                                {...dragableProvided.dragHandleProps}
                                                                className="mr-1 mt-2 order-icon-wrapper"
                                                              >
                                                                <OrderIcon />{" "}
                                                              </div>
                                                              <>
                                                                <TextArea
                                                                  // ref={fieldRefs.current[i]}
                                                                  // ref={fieldRefs.current[`${i}-${index}`]}
                                                                  className="custom-text-area"
                                                                  rows={2}
                                                                  fluid
                                                                  ref={
                                                                    procedureRefs
                                                                      ?.current[
                                                                      i
                                                                    ][index]
                                                                  }
                                                                  autoComplete="new-password"
                                                                  style={{
                                                                    width:
                                                                      "-webkit-fill-available",
                                                                  }}
                                                                  // placeholder={
                                                                  //   t.procedures
                                                                  //     .form
                                                                  //     .title_placeholder
                                                                  // }
                                                                  placeholder={
                                                                    validationProcedureItems[
                                                                      i
                                                                    ]
                                                                      ?.procedureFields[
                                                                      index
                                                                    ]?.label
                                                                      ?.error &&
                                                                    validationProcedureItems[
                                                                      i
                                                                    ]
                                                                      ?.procedureFields[
                                                                      index
                                                                    ]?.label
                                                                      ?.skipped
                                                                      ? ""
                                                                      : field?.placeholder
                                                                    // t.procedures.form
                                                                    //   .title_placeholder
                                                                  }
                                                                  value={
                                                                    field?.label
                                                                  }
                                                                  onChange={handleFieldChange(
                                                                    i,
                                                                    "label",
                                                                    index,
                                                                    false
                                                                  )}
                                                                />
                                                              </>
                                                              <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ml-1 mt-2 align-self-start">
                                                                <UncontrolledDropdown
                                                                  className="mr-2"
                                                                  direction="down"
                                                                >
                                                                  <DropdownToggle
                                                                    color="primary"
                                                                    className="procedure-toggle"
                                                                  >
                                                                    <OptionIcon />
                                                                  </DropdownToggle>
                                                                  <DropdownMenu
                                                                    className={
                                                                      selectedLanguage ==
                                                                      "ar"
                                                                        ? "procedure rtl"
                                                                        : "procedure"
                                                                    }
                                                                  >
                                                                    {/* <DropdownItem className="divider" />
                                                                    <DropdownItem
                                                                    // onClick={() => downloadXLS()}
                                                                    >
                                                                      {
                                                                        t
                                                                          .procedures
                                                                          .add_type
                                                                      }
                                                                    </DropdownItem> */}

                                                                    <DropdownItem className="divider" />
                                                                    <DropdownItem
                                                                      onClick={duplicateField(
                                                                        i,
                                                                        false,
                                                                        index
                                                                      )}
                                                                    >
                                                                      {
                                                                        t
                                                                          .procedures
                                                                          .duplicate
                                                                      }
                                                                    </DropdownItem>
                                                                    <DropdownItem className="divider" />

                                                                    {
                                                                      <DropdownItem
                                                                        onClick={removeThisField(
                                                                          i,
                                                                          false,
                                                                          index
                                                                        )}
                                                                      >
                                                                        {
                                                                          t
                                                                            .common
                                                                            .delete
                                                                        }
                                                                      </DropdownItem>
                                                                    }
                                                                  </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                              </div>
                                                            </div>
                                                            {validationProcedureItems[
                                                              i
                                                            ]?.procedureFields[
                                                              index
                                                            ]?.label?.error &&
                                                            validationProcedureItems[
                                                              i
                                                            ]?.procedureFields[
                                                              index
                                                            ]?.label
                                                              ?.skipped ? (
                                                              <div className="label-error ml-8 mr-8">
                                                                {
                                                                  validationProcedureItems[
                                                                    i
                                                                  ]
                                                                    .procedureFields[
                                                                    index
                                                                  ]?.label
                                                                    .message
                                                                }
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* {field?.label ? <div className='ml-8 mr-8 mt-1 text-right'>({field?.label?.length}/500)</div> : null} */}
                                                          </div>

                                                          <Form.Field
                                                            style={{
                                                              minHeight: "60px",
                                                            }}
                                                          >
                                                            <label className="label-item mt-9">
                                                              {
                                                                t.procedures
                                                                  .form.type
                                                              }
                                                              {/* <Icon name="asterisk" color="red" size="small" /> */}
                                                              <Asterisk color="#172B4D" />
                                                            </label>
                                                            <Dropdown
                                                              placeholder={
                                                                t.procedures
                                                                  .form.type
                                                              }
                                                              fluid
                                                              noResultsMessage={
                                                                t.common
                                                                  .no_results_found
                                                              }
                                                              selection
                                                              options={
                                                                fieldTypes
                                                              }
                                                              value={
                                                                field.fieldType
                                                              }
                                                              onChange={handleFieldChange(
                                                                i,
                                                                "fieldType",
                                                                index,
                                                                false
                                                              )}
                                                            />
                                                          </Form.Field>
                                                        </>
                                                      </div>

                                                      <div className="hide-on-non-focused mt-8">
                                                        {/* Additional fields go here */}
                                                        {generatedSubFields(
                                                          field.fieldType,
                                                          field,
                                                          index,
                                                          fields,
                                                          setFields,
                                                          validationProcedureItems,
                                                          setValidationProcedureItems,
                                                          handleFieldChange,
                                                          i,
                                                          false,
                                                          validationProcedureItems[
                                                            i
                                                          ]?.procedureFields[
                                                            index
                                                          ]
                                                        )}

                                                        {field?.prevAttachments
                                                          ?.length > 0
                                                          ? field.prevAttachments?.map(
                                                              (prev, index) => {
                                                                console.log('prev: ', prev);
                                                                return (
                                                                  <span
                                                                    onClick={() =>
                                                                      attachmentsRef.current.click()
                                                                    }
                                                                    className="file-name-show-procedure"
                                                                  >
                                                                    {/* <FilePresentIcon /> */}
                                                                    <label style={{wordBreak:"break-word"}} className="m-0">
                                                                      <span className="ml-2 mr-2">
                                                                        {prev?.mime?.match(
                                                                         /(jpg|jpeg|png|svg\+xml)$/i
                                                                        ) ? (
                                                                          <PhotoSvg />
                                                                        ) : prev?.mime?.match(
                                                                            /pdf$/
                                                                          ) ? (
                                                                          <PdfGreyIcon />
                                                                        ) : prev?.mime?.match(
                                                                         /(mp4|mov|quicktime)$/i
                                                                        ) ? (
                                                                        <VideoGreyIcon />
                                                                      ) : prev?.mime?.match(
                                                                            /(csv|spreadsheetml\.sheet|vnd\.ms-excel)$/i
                                                                          ) ? (
                                                                          <CSVSvg />
                                                                        ) : prev?.mime?.match(
                                                                            /zip$/
                                                                          ) ? (
                                                                          <ZipSvg />
                                                                        ) : prev?.mime?.match(
                                                                            /(doc|wordprocessingml\.document|msword)$/i
                                                                          ) ? (
                                                                          <DocSvg />
                                                                        ) : prev?.mime?.match(
                                                                            /txt$/
                                                                          ) ? (
                                                                          <TextSvg />
                                                                        ) : (
                                                                          <Attachment />
                                                                        )}
                                                                      </span>{" "}
                                                                      {
                                                                        prev.name
                                                                      }
                                                                    </label>

                                                                    <span
                                                                      className="ml-3 mr-3 c-pointer"
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                        handleDeleteAttachment(
                                                                          prev.id,
                                                                          false
                                                                        );
                                                                      }}
                                                                    >
                                                                      <CloseIcon />
                                                                    </span>
                                                                  </span>
                                                                );
                                                              }
                                                            )
                                                          : null}

                                                        <div className="d-flex justify-content-between mt-5">
                                                          <div
                                                            style={
                                                              field.fieldType !=
                                                              "heading"
                                                                ? {}
                                                                : {
                                                                    marginTop:
                                                                      "20px",
                                                                    justifyContent:
                                                                      "flex-end",
                                                                  }
                                                            }
                                                            className={`flexbox align-center procedure-actions ${
                                                              field.fieldType ===
                                                                "signature" ||
                                                              field.fieldType ===
                                                                "file" ||
                                                              field.fieldType ===
                                                                "photo"
                                                                ? "justify-start"
                                                                : "justify-end"
                                                            } `}
                                                            basic
                                                          >
                                                            {field.fieldType !=
                                                              "heading" && (
                                                              <div
                                                                className="flexbox align-center change-svg-procedure flex-wrap"
                                                                style={{
                                                                  maxWidth:
                                                                    "178px",
                                                                }}
                                                              >
                                                                <span
                                                                  onClick={() => {
                                                                    setActiveIndex(
                                                                      {
                                                                        isheader: false,
                                                                        headerIndex: i,
                                                                        fieldIndex: index,
                                                                      }
                                                                    );
                                                                    attachmentsRef.current.click();
                                                                  }}
                                                                >
                                                                  <FilePresentIcon />
                                                                  <label className="m-0"></label>
                                                                </span>
                                                                {/* )} */}
                                                              </div>
                                                            )}
                                                          </div>

                                                          <div
                                                            className={`flexbox align-center ${
                                                              selectedLanguage ==
                                                              "en"
                                                                ? ""
                                                                : "rtl-container"
                                                            }`}
                                                          >
                                                            {field.fieldType !=
                                                              "heading" && (
                                                              <>
                                                                <span className="required-text ml-2 mr-2">
                                                                  {
                                                                    t.procedures
                                                                      .form
                                                                      .is_required
                                                                  }
                                                                </span>

                                                                <Toggle
                                                                  // type="checkbox"
                                                                  className="toggle-data"
                                                                  checked={
                                                                    field.isRequired
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleFieldChange(
                                                                      i,
                                                                      "isRequired",
                                                                      index,
                                                                      false
                                                                    )(
                                                                      {},
                                                                      {
                                                                        checked:
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                      }
                                                                    )
                                                                  }
                                                                />
                                                              </>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Draggable>
                                              )
                                            )}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </div>
                                </AccordionItem>
                              </>
                            ) : (
                              <>
                                <div
                                // style={{ border: '2px solid red' }}
                                >
                                  <Droppable
                                    droppableId={field?.key?.toString()}
                                    type="PROCEDURE"
                                    isDropDisabled={false}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={
                                          applyMarginBottom
                                            ? { marginBottom: "4pc" }
                                            : {}
                                        }
                                      >
                                        {!procedureItemsVisible[i] &&
                                          field?.procedureFields?.map(
                                            (field, index) => (
                                              <Draggable
                                                key={field?.key}
                                                draggableId={field?.key?.toString()}
                                                index={index}
                                              >
                                                {(dragableProvided) => (
                                                  <div
                                                    ref={
                                                      dragableProvided.innerRef
                                                    }
                                                    {...dragableProvided.draggableProps}
                                                    style={{
                                                      ...dragableProvided
                                                        .draggableProps.style,
                                                      // Ensure the dragged item remains visible
                                                      left: "0px",
                                                      userSelect: "none",
                                                      position: "static",
                                                      background: "white",
                                                    }}
                                                    className={
                                                      validationProcedureItems[
                                                        i - 1
                                                      ]?.label?.error &&
                                                      validationProcedureItems[
                                                        i - 1
                                                      ]?.label?.skipped &&
                                                      index == 0
                                                        ? "main-div m-1 mt-13 "
                                                        : "main-div m-1 mt-5"
                                                    }
                                                  >
                                                    <div className="">
                                                      <>
                                                        <div className="header-title-change-procedure">
                                                          <div className="title-div d-flex">
                                                            <div
                                                              {...dragableProvided.dragHandleProps}
                                                              className="mr-1 mt-2 order-icon-wrapper"
                                                            >
                                                              <OrderIcon />{" "}
                                                            </div>
                                                            <>
                                                              <Input
                                                                ref={
                                                                  procedureRefs
                                                                    ?.current[
                                                                    i
                                                                  ][index]
                                                                }
                                                                autoComplete="new-password"
                                                                style={{
                                                                  width:
                                                                    "-webkit-fill-available",
                                                                }}
                                                                placeholder={
                                                                  validationProcedureItems[
                                                                    i
                                                                  ]
                                                                    ?.procedureFields[
                                                                    index
                                                                  ]?.label
                                                                    ?.error &&
                                                                  validationProcedureItems[
                                                                    i
                                                                  ]
                                                                    ?.procedureFields[
                                                                    index
                                                                  ]?.label
                                                                    ?.skipped
                                                                    ? ""
                                                                    : field?.placeholder
                                                                  // t.procedures.form
                                                                  //   .title_placeholder
                                                                }
                                                                value={
                                                                  field?.label
                                                                }
                                                                onChange={handleFieldChange(
                                                                  i,
                                                                  "label",
                                                                  index,
                                                                  false
                                                                )}
                                                              />
                                                            </>
                                                            <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ml-1 mt-2">
                                                              <UncontrolledDropdown
                                                                className="mr-2"
                                                                direction="down"
                                                              >
                                                                <DropdownToggle
                                                                  color="primary"
                                                                  className="procedure-toggle"
                                                                >
                                                                  <OptionIcon />
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                  className={
                                                                    selectedLanguage ==
                                                                    "ar"
                                                                      ? "procedure rtl"
                                                                      : "procedure"
                                                                  }
                                                                >
                                                                  {/* <DropdownItem className="divider" />
                                                                  <DropdownItem>
                                                                    {
                                                                      t
                                                                        .procedures
                                                                        .add_type
                                                                    }
                                                                  </DropdownItem> */}

                                                                  <DropdownItem className="divider" />
                                                                  <DropdownItem
                                                                    onClick={duplicateField(
                                                                      i,
                                                                      false,
                                                                      index
                                                                    )}
                                                                  >
                                                                    {
                                                                      t
                                                                        .procedures
                                                                        .duplicate
                                                                    }
                                                                  </DropdownItem>
                                                                  <DropdownItem className="divider" />

                                                                  {/* {fields[i]
                                                                    ?.procedureFields
                                                                    ?.length >
                                                                  1 ? ( */}
                                                                  <DropdownItem
                                                                    onClick={removeThisField(
                                                                      i,
                                                                      false,
                                                                      index
                                                                    )}
                                                                  >
                                                                    {
                                                                      t.common
                                                                        .delete
                                                                    }
                                                                  </DropdownItem>
                                                                  {/* ) : null} */}
                                                                </DropdownMenu>
                                                              </UncontrolledDropdown>
                                                            </div>
                                                          </div>
                                                          {validationProcedureItems[
                                                            i
                                                          ]?.procedureFields[
                                                            index
                                                          ]?.label?.error &&
                                                          validationProcedureItems[
                                                            i
                                                          ]?.procedureFields[
                                                            index
                                                          ]?.label?.skipped ? (
                                                            <div className="label-error ml-8 mr-8">
                                                              {
                                                                validationProcedureItems[
                                                                  i
                                                                ]
                                                                  ?.procedureFields[
                                                                  index
                                                                ]?.label.message
                                                              }
                                                            </div>
                                                          ) : null}
                                                        </div>

                                                        <Form.Field
                                                          style={{
                                                            minHeight: "60px",
                                                          }}
                                                        >
                                                          <label className="label-item mt-9">
                                                            {
                                                              t.procedures.form
                                                                .type
                                                            }

                                                            <Asterisk color="#172B4D" />
                                                          </label>
                                                          <Dropdown
                                                            placeholder={
                                                              t.procedures.form
                                                                .type
                                                            }
                                                            fluid
                                                            noResultsMessage={
                                                              t.common
                                                                .no_results_found
                                                            }
                                                            selection
                                                            options={fieldTypes}
                                                            value={
                                                              field.fieldType
                                                            }
                                                            onChange={handleFieldChange(
                                                              i,
                                                              "fieldType",
                                                              index,
                                                              false
                                                            )}
                                                          />
                                                        </Form.Field>
                                                      </>
                                                    </div>

                                                    <div className="hide-on-non-focused mt-8">
                                                      {/* Additional fields go here */}
                                                      {generatedSubFields(
                                                        field.fieldType,
                                                        field,
                                                        index,
                                                        fields,
                                                        setFields,
                                                        validationProcedureItems,
                                                        setValidationProcedureItems,
                                                        handleFieldChange,
                                                        i,
                                                        false,
                                                        validationProcedureItems[
                                                          i
                                                        ]?.procedureFields[
                                                          index
                                                        ]
                                                      )}

                                                      {field?.prevAttachments
                                                        ?.length > 0
                                                        ? field.prevAttachments?.map(
                                                            (prev, index) => {
                                                              return (
                                                                <span
                                                                  onClick={() =>
                                                                    attachmentsRef.current.click()
                                                                  }
                                                                  className="file-name-show-procedure"
                                                                >
                                                                  {/* <FilePresentIcon /> */}
                                                                  <label className="m-0">
                                                                    <span className="ml-2 mr-2">
                                                                      <PictureIcon />
                                                                    </span>{" "}
                                                                    {prev.name}
                                                                  </label>

                                                                  <span
                                                                    className="ml-3 mr-3 c-pointer"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                      handleDeleteAttachment(
                                                                        prev.id,
                                                                        false
                                                                      );
                                                                    }}
                                                                  >
                                                                    <CloseIcon />
                                                                  </span>
                                                                </span>
                                                              );
                                                            }
                                                          )
                                                        : null}
                                                      {/* <Divider /> */}
                                                      <div className="d-flex justify-content-between mt-5">
                                                        <div
                                                          style={
                                                            field.fieldType !=
                                                            "heading"
                                                              ? {}
                                                              : {
                                                                  marginTop:
                                                                    "20px",
                                                                  justifyContent:
                                                                    "flex-end",
                                                                }
                                                          }
                                                          className={`flexbox align-center procedure-actions ${
                                                            field.fieldType ===
                                                              "signature" ||
                                                            field.fieldType ===
                                                              "file" ||
                                                            field.fieldType ===
                                                              "photo"
                                                              ? "justify-start"
                                                              : "justify-end"
                                                          } `}
                                                          basic
                                                        >
                                                          {field.fieldType !=
                                                            "heading" && (
                                                            <div
                                                              className="flexbox align-center change-svg-procedure flex-wrap"
                                                              style={{
                                                                maxWidth:
                                                                  "178px",
                                                              }}
                                                            >
                                                              <span
                                                                onClick={() => {
                                                                  setActiveIndex(
                                                                    {
                                                                      isheader: false,
                                                                      headerIndex: i,
                                                                      fieldIndex: index,
                                                                    }
                                                                  );
                                                                  attachmentsRef.current.click();
                                                                }}
                                                              >
                                                                <FilePresentIcon />
                                                                <label className="m-0"></label>
                                                              </span>
                                                              {/* )} */}
                                                            </div>
                                                          )}
                                                        </div>

                                                        <div
                                                          className={`flexbox align-center ${
                                                            selectedLanguage ==
                                                            "en"
                                                              ? ""
                                                              : "rtl-container"
                                                          }`}
                                                        >
                                                          {field.fieldType !=
                                                            "heading" && (
                                                            <>
                                                              <span className="required-text ml-2 mr-2">
                                                                {
                                                                  t.procedures
                                                                    .form
                                                                    .is_required
                                                                }
                                                              </span>

                                                              <Toggle
                                                                // type="checkbox"
                                                                className="toggle-data"
                                                                checked={
                                                                  field.isRequired
                                                                }
                                                                onChange={(e) =>
                                                                  handleFieldChange(
                                                                    i,
                                                                    "isRequired",
                                                                    index,
                                                                    false
                                                                  )(
                                                                    {},
                                                                    {
                                                                      checked:
                                                                        e.target
                                                                          .checked,
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                            </>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </Draggable>
                                            )
                                          )}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </div>
                              </>
                            )
                          ) : (
                            <div
                              className={
                                validationProcedureItems[i - 1]?.label?.error &&
                                validationProcedureItems[i - 1]?.label?.skipped
                                  ? "main-div mt-13 m-1 "
                                  : "main-div mt-5 m-1"
                              }
                            >
                              <div className="">
                                <>
                                  <div className="header-title-change-procedure">
                                    <div className="title-div d-flex">
                                      <div
                                        {...provided.dragHandleProps}
                                        className="mr-1 mt-2 order-icon-wrapper"
                                      >
                                        <OrderIcon />{" "}
                                      </div>
                                      <>
                                        <Input
                                          autoComplete="new-password"
                                          style={{
                                            width: "-webkit-fill-available",
                                          }}
                                          // placeholder={
                                          //   t.procedures.form.title_placeholder
                                          // }
                                          placeholder={
                                            validationProcedureItems[i]?.label
                                              ?.error &&
                                            validationProcedureItems[i]?.label
                                              ?.skipped
                                              ? ""
                                              : field?.placeholder
                                            // t.procedures.form
                                            //   .title_placeholder
                                          }
                                          value={field?.label}
                                          onChange={handleFieldChange(
                                            i,
                                            "label"
                                          )}
                                        />
                                      </>

                                      {/* {field.fieldType == 'checkbox' ? (  <div>
                                    <div className="flexbox align-center">
                                      <Checkbox disabled />
                                      <Input
                                        autoComplete="new-password"
                                        fluid
                                        placeholder={
                                          t.procedures.form.checkbox_label
                                        }
                                        style={{
                                          width: '100%',
                                          marginLeft: '5px',
                                        }}
                                        value={field.label}
                                        onChange={handleFieldChange(
                                          index,
                                          'label'
                                        )}
                                      />
                                    </div>

                                    {validationProcedureItems[index]?.label
                                      ?.error &&
                                    validationProcedureItems[index]?.label
                                      ?.skipped ? (
                                      <div className="label-error">
                                        {
                                          validationProcedureItems[index].label
                                            .message
                                        }
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>) : (
                                    <Input
                                      autoComplete="new-password"
                                      defaultValue={"njdjss"}
                                      style={{width:"379px"}}
                                      placeholder={
                                        t.procedures.form.title_placeholder
                                      }
                                      value={field?.label}
                                      onChange={handleFieldChange(
                                        index,
                                        'label'
                                      )}
                                    />
                                    {validationProcedureItems[index]?.label
                                      ?.error &&
                                    validationProcedureItems[index]?.label
                                      ?.skipped ? (
                                      <div className="label-error">
                                        {
                                          validationProcedureItems[index].label
                                            .message
                                        }
                                      </div>
                                    ) : (
                                      ''
                                    )} )} */}
                                      <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ml-1 mt-2">
                                        <UncontrolledDropdown
                                          className="mr-2"
                                          direction="down"
                                        >
                                          <DropdownToggle
                                            color="primary"
                                            className="procedure-toggle"
                                          >
                                            <OptionIcon />
                                          </DropdownToggle>
                                          <DropdownMenu
                                            className={
                                              selectedLanguage == "ar"
                                                ? "procedure rtl"
                                                : "procedure"
                                            }
                                          >
                                            {/* {role !== 'view_only' ? (
                <>
                  <DropdownItem>
                    <ShareIcon /> {t.common.share}
                  </DropdownItem>
                  <DropdownItem className="divider" />
                </>
              ) : null} */}
                                            {/* <DropdownItem className="divider" />
                                            <DropdownItem
                                            // onClick={() => downloadXLS()}
                                            >
                                              {t.procedures.add_type}
                                            </DropdownItem> */}

                                            <DropdownItem className="divider" />
                                            <DropdownItem
                                              onClick={duplicateField(i)}
                                            >
                                              {t.procedures.duplicate}
                                            </DropdownItem>
                                            <DropdownItem className="divider" />
                                            {fields && fields?.length > 1 ? (
                                              <DropdownItem
                                                onClick={removeThisField(i)}
                                              >
                                                {t.common.delete}
                                              </DropdownItem>
                                            ) : null}
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </div>
                                    </div>
                                    {validationProcedureItems[i]?.label
                                      ?.error &&
                                    validationProcedureItems[i]?.label
                                      ?.skipped ? (
                                      <div className="label-error ml-8 mr-8">
                                        {
                                          validationProcedureItems[i].label
                                            .message
                                        }
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  {/* <label className="label-item">
                                  {field.fieldType == 'checkbox'
                                    ? t.procedures.form.checkbox_label
                                    : t.procedures.form.title}
                                
                                  <Asterisk color="#172B4D" />
                                </label> */}

                                  {/* {field.fieldType == 'checkbox' ? (
                                  <div>
                                    <div className="flexbox align-center">
                                      <Checkbox disabled />
                                      <Input
                                        autoComplete="new-password"
                                        fluid
                                        placeholder={
                                          t.procedures.form.checkbox_label
                                        }
                                        style={{
                                          width: '100%',
                                          marginLeft: '5px',
                                        }}
                                        value={field.label}
                                        onChange={handleFieldChange(
                                          index,
                                          'label'
                                        )}
                                      />
                                    </div>

                                    {validationProcedureItems[index]?.label
                                      ?.error &&
                                    validationProcedureItems[index]?.label
                                      ?.skipped ? (
                                      <div className="label-error">
                                        {
                                          validationProcedureItems[index].label
                                            .message
                                        }
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    <Input
                                      autoComplete="new-password"
                                      fluid
                                      placeholder={
                                        t.procedures.form.title_placeholder
                                      }
                                      value={field.label}
                                      onChange={handleFieldChange(
                                        index,
                                        'label'
                                      )}
                                    />
                                    {validationProcedureItems[index]?.label
                                      ?.error &&
                                    validationProcedureItems[index]?.label
                                      ?.skipped ? (
                                      <div className="label-error">
                                        {
                                          validationProcedureItems[index].label
                                            .message
                                        }
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                )} */}

                                  <Form.Field style={{ minHeight: "60px" }}>
                                    <label className="label-item mt-9">
                                      {t.procedures.form.type}
                                      {/* <Icon name="asterisk" color="red" size="small" /> */}
                                      <Asterisk color="#172B4D" />
                                    </label>
                                    <Dropdown
                                      placeholder={t.procedures.form.type}
                                      fluid
                                      noResultsMessage={
                                        t.common.no_results_found
                                      }
                                      selection
                                      options={fieldTypes}
                                      value={field.fieldType}
                                      onChange={handleFieldChange(
                                        i,
                                        "fieldType"
                                      )}
                                    />
                                  </Form.Field>
                                </>
                              </div>

                              <div className="hide-on-non-focused mt-8">
                                {/* Additional fields go here */}
                                {generatedSubFields(
                                  field.fieldType,
                                  field,
                                  i,
                                  fields,
                                  setFields,
                                  validationProcedureItems,
                                  setValidationProcedureItems,
                                  handleFieldChange
                                )}
                                {field?.prevAttachments?.length > 0
                                  ? field.prevAttachments?.map(
                                      (prev, index) => {
                                        return (
                                          <span
                                            onClick={() =>
                                              attachmentsRef.current.click()
                                            }
                                            className="file-name-show-procedure"
                                          >
                                            {/* <FilePresentIcon /> */}
                                            <label className="m-0">
                                              <span className="ml-2 mr-2">
                                                <PictureIcon />
                                              </span>{" "}
                                              {prev.name}
                                            </label>

                                            <span
                                              className="ml-3 mr-3 c-pointer"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteAttachment(prev.id);
                                              }}
                                            >
                                              <CloseIcon />
                                            </span>
                                          </span>
                                        );
                                      }
                                    )
                                  : null}
                                {/* <Divider /> */}
                                <div className="d-flex justify-content-between mt-5">
                                  <div
                                    style={
                                      field.fieldType != "heading"
                                        ? {}
                                        : {
                                            marginTop: "20px",
                                            justifyContent: "flex-end",
                                          }
                                    }
                                    className={`flexbox align-center procedure-actions ${
                                      field.fieldType === "signature" ||
                                      field.fieldType === "file" ||
                                      // field.fieldType === 'checklist' ||
                                      // field.fieldType === 'text' ||
                                      // field.fieldType === 'amount' ||
                                      // field.fieldType === 'number' ||
                                      field.fieldType === "photo"
                                        ? "justify-start"
                                        : "justify-end"
                                    } `}
                                    basic
                                  >
                                    {field.fieldType != "heading" && (
                                      <div
                                        className="flexbox align-center change-svg-procedure flex-wrap"
                                        style={{ maxWidth: "178px" }}
                                      >
                                        {/* {field.prevAttachments?.length > 0 ? (
                                      field.prevAttachments?.map(
                                        (prev, index) => {
                                          return (
                                            <span
                                              onClick={() =>
                                                attachmentsRef.current.click()
                                              }
                                            >
                                              <FilePresentIcon />
                                              <label className="m-0">
                                                {prev.name}
                                              </label>

                                              <span
                                                className="ml-3 mr-3 c-pointer"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleDeleteAttachment(
                                                    prev.id
                                                  );
                                                }}
                                              >
                                                <DeleteIcon />
                                              </span>
                                            </span>
                                          );
                                        }
                                      )
                                    ) : ( */}
                                        <span
                                          onClick={() => {
                                            setActiveIndex({
                                              isheader: true,
                                              headerIndex: i,
                                              fieldIndex: null,
                                            });
                                            attachmentsRef.current.click();
                                          }}
                                          // onClick={() => {
                                          //   handleFieldChange(index, 'active')(
                                          //     {},
                                          //     {}
                                          //   );
                                          //   attachmentsRef.current.click();
                                          // }}
                                        >
                                          <FilePresentIcon />
                                          <label className="m-0"></label>
                                        </span>
                                        {/* )} */}
                                      </div>
                                    )}
                                    {/* {fields && fields.length > 1 ? (
                                      <div
                                        className="buttun-type-link remove"
                                        basic
                                        onClick={removeThisField(i)}
                                      >
                                        <DeleteIcon
                                          width="20"
                                          height="20"
                                          fill="red"
                                        />
                                        {t.procedures.form.remove_button}
                                      </div>
                                    ) : null} */}
                                  </div>

                                  <div
                                    className={`flexbox align-center ${
                                      selectedLanguage == "en"
                                        ? ""
                                        : "rtl-container"
                                    }`}
                                  >
                                    {field.fieldType != "heading" && (
                                      <>
                                        <span className="required-text ml-2 mr-2">
                                          {t.procedures.form.is_required}
                                        </span>

                                        <Toggle
                                          // type="checkbox"
                                          className="toggle-data"
                                          checked={field.isRequired}
                                          onChange={(e) =>
                                            handleFieldChange(i, "isRequired")(
                                              {},
                                              { checked: e.target.checked }
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* <p>
                            {t.procedures.form.add_procedure_item}{' '}
                            {` ${index + 1} `}
                          </p> */}
                          {/* </div> */}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              </Accordion>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );

    // return fields.map((field, index) => (
    //   <>
    //     <div
    //       tabindex="1"
    //       className="mb-5"
    //       //   onClick={handleFieldChange(index, 'active')}
    //       // className={field.active ? 'active-field' : ''}
    //     >

    //       <div className="procedure-form-item-header">
    //         <p>
    //           {t.procedures.form.add_procedure_item}
    //           {` ${index + 1} `}
    //         </p>
    //       </div>
    //       <div className="mt-5">
    //         {field.fieldType == 'heading' ? (
    //           <Form>
    //             <Form.Field>
    //               <label className="label-item">
    //                 {t.procedures.form.add_heading}
    //                 {/* <Icon name="asterisk" color="red" size="small" /> */}
    //                 <Asterisk color="#172B4D" />
    //               </label>
    //               <Input
    //                 autoComplete="new-password"
    //                 fluid
    //                 placeholder={t.procedures.form.add_heading}
    //                 value={field.label}
    //                 onChange={handleFieldChange(index, 'label')}
    //               />
    //               {validationProcedureItems[index]?.label?.error &&
    //               validationProcedureItems[index]?.label?.skipped ? (
    //                 <div className="label-error">
    //                   {validationProcedureItems[index].label.message}
    //                 </div>
    //               ) : (
    //                 ''
    //               )}
    //             </Form.Field>
    //           </Form>
    //         ) : (
    //           <Form>
    //             <Form.Field className="mb-0">
    //               <label className="label-item">
    //                 {field.fieldType == 'checkbox'
    //                   ? t.procedures.form.checkbox_label
    //                   : t.procedures.form.title}
    //                 {/* <Icon name="asterisk" color="red" size="small" /> */}
    //                 <Asterisk color="#172B4D" />
    //               </label>

    //               {field.fieldType == 'checkbox' ? (
    //                 <div>
    //                   <div className="flexbox align-center">
    //                     <Checkbox disabled />
    //                     <Input
    //                       autoComplete="new-password"
    //                       fluid
    //                       placeholder={t.procedures.form.checkbox_label}
    //                       style={{ width: '100%', marginLeft: '5px' }}
    //                       value={field.label}
    //                       onChange={handleFieldChange(index, 'label')}
    //                     />
    //                   </div>

    //                   {validationProcedureItems[index]?.label?.error &&
    //                   validationProcedureItems[index]?.label?.skipped ? (
    //                     <div className="label-error">
    //                       {validationProcedureItems[index].label.message}
    //                     </div>
    //                   ) : (
    //                     ''
    //                   )}
    //                 </div>
    //               ) : (
    //                 <div>
    //                   <Input
    //                     autoComplete="new-password"
    //                     fluid
    //                     placeholder={t.procedures.form.title_placeholder}
    //                     value={field.label}
    //                     onChange={handleFieldChange(index, 'label')}
    //                   />
    //                   {validationProcedureItems[index]?.label?.error &&
    //                   validationProcedureItems[index]?.label?.skipped ? (
    //                     <div className="label-error">
    //                       {validationProcedureItems[index].label.message}
    //                     </div>
    //                   ) : (
    //                     ''
    //                   )}
    //                 </div>
    //               )}
    //             </Form.Field>

    //             <Form.Field>
    //               <label className="label-item">
    //                 {t.procedures.form.type}
    //                 {/* <Icon name="asterisk" color="red" size="small" /> */}
    //                 <Asterisk color="#172B4D" />
    //               </label>
    //               <Dropdown
    //                 placeholder={t.procedures.form.type}
    //                 fluid
    //                 selection
    //                 options={fieldTypes}
    //                 value={field.fieldType}
    //                 onChange={handleFieldChange(index, 'fieldType')}
    //               />
    //             </Form.Field>
    //           </Form>
    //         )}
    //       </div>
    //       <div className="hide-on-non-focused">
    //         {generatedSubFields(
    //           field.fieldType,
    //           field,
    //           index,
    //           fields,
    //           setFields,
    //           validationProcedureItems,
    //           setValidationProcedureItems,
    //           handleFieldChange
    //         )}

    //         <Divider />
    //         <div
    //           style={
    //             field.fieldType != 'heading'
    //               ? { marginTop: '10px' }
    //               : { marginTop: '20px', justifyContent: 'flex-start' }
    //           }
    //           className={`flexbox align-center procedure-actions ${
    //             field.fieldType === 'signature' ||
    //             field.fieldType === 'file' ||
    //             field.fieldType === 'photo'
    //               ? 'justify-start'
    //               : 'justify-end'
    //           } `}
    //           basic
    //         >
    //           <div className={`flexbox align-center ${selectedLanguage == 'en' ? '' : 'rtl-container'}`}>
    //             {field.fieldType != 'heading' && (
    //               <>
    //                 <span className="required-text ml-2 mr-2">
    //                   {t.procedures.form.is_required}
    //                 </span>

    //                 <Toggle
    //                   // type="checkbox"
    //                   className="toggle-data"
    //                   checked={field.isRequired}
    //                   onChange={(e) =>
    //                     handleFieldChange(index, 'isRequired')(
    //                       {},
    //                       { checked: e.target.checked }
    //                     )
    //                   }
    //                 />
    //               </>
    //             )}
    //           </div>
    //           {field.fieldType != 'heading' && (
    //             <div
    //               className="flexbox align-center flex-wrap"
    //               style={{ maxWidth: '178px' }}
    //             >
    //               {field.prevAttachments?.length > 0 ? (
    //                 field.prevAttachments?.map((prev, index) => {
    //                   return (
    //                     <span onClick={() => attachmentsRef.current.click()}>
    //                       <FilePresentIcon />
    //                       <label className="m-0">{prev.name}</label>

    //                       <span
    //                         className="ml-3 mr-3 c-pointer"
    //                         onClick={(e) => {
    //                           e.stopPropagation();
    //                           handleDeleteAttachment(prev.id);
    //                         }}
    //                       >
    //                         <DeleteIcon />
    //                       </span>
    //                     </span>
    //                   );
    //                 })
    //               ) : (
    //                 <span
    //                   onClick={() => {
    //                     handleFieldChange(index, 'active')({}, {});
    //                     attachmentsRef.current.click();
    //                   }}
    //                 >
    //                   <FilePresentIcon />
    //                   <label className="m-0"></label>
    //                 </span>
    //               )}
    //             </div>
    //           )}
    //           {fields && fields.length > 1 ? (
    //             <div
    //               className="buttun-type-link remove ml-4 mr-4"
    //               basic
    //               onClick={removeThisField(index)}
    //             >
    //               <DeleteIcon width="20" height="20" fill="red" />
    //               {t.procedures.form.remove_button}
    //             </div>
    //           ) : null}
    //         </div>
    //       </div>
    //     </div>
    //   </>
    // ));
  };

  const generatedSubFields = (
    type,
    field,
    index,
    fields,
    setFields,
    validationProcedureItems,
    setValidationProcedureItems,
    handleFieldChange,
    i,
    isHeader = true,
    validationObj
  ) => {
    let logicAnswersOptions = inspectionTypes.filter(
      (el) => el.value == field.inspectionType
    );

    if (logicAnswersOptions.length > 0)
      logicAnswersOptions = logicAnswersOptions[0].logicAnswers;

    const addOption = (parentIndex, i, isHeader = true) => {
      let newFields = [...fields];

      if (!isHeader) {
        let modifiedField = { ...newFields[i].procedureFields[parentIndex] };
        // 1. Make a shallow copy of the items
        let newOptions = [...field.options];
        // 2. Make a shallow copy of the item you want to mutate
        newOptions.push({ item: "" });

        modifiedField.options = newOptions;
        // 5. Set the state to our new copy
        newFields[i].procedureFields[parentIndex] = modifiedField;

        setFields(newFields);

        let validations = cloneDeep(validationProcedureItems);
        validations[i].procedureFields[parentIndex].options.push({
          error: false,
          pattern: (value, fieldType) =>
            ["multipleChoice", "checklist", "checkbox"].includes(fieldType)
              ? value != "" && value.length <= 500
              : true,
          skipped: false,
          message: t.common.required_field,
          messageData: (label) => {
            if (label === "") {
              return t.common.required_field;
            } else if (label.length > 500) {
              return t.common.characters_field;
            }
            return t.common.required_field;
          },
        });
        setValidationProcedureItems(validations);
      } else {
        let newFields = [...fields];

        let modifiedField = { ...newFields[parentIndex] };
        // 1. Make a shallow copy of the items
        let newOptions = [...field.options];
        // 2. Make a shallow copy of the item you want to mutate
        newOptions.push({ item: "" });

        modifiedField.options = newOptions;
        // 5. Set the state to our new copy
        newFields[parentIndex] = modifiedField;

        setFields(newFields);

        let validations = cloneDeep(validationProcedureItems);
        validations[parentIndex].options.push({
          error: false,
          pattern: (value, fieldType) =>
            ["multipleChoice", "checklist", "checkbox"].includes(fieldType)
              ? value != "" && value.length <= 500
              : true,
          skipped: false,
          message: t.common.required_field,
          messageData: (label) => {
            if (label === "") {
              return t.common.required_field;
            } else if (label.length > 500) {
              return t.common.characters_field;
            }
            return t.common.required_field;
          },
        });
        setValidationProcedureItems(validations);
      }
    };

    const removeOption = (parentIndex, id, i, isHeader = true) => (e) => {
      e.stopPropagation();
      let newFields = [...fields];
      if (!isHeader) {
        e.stopPropagation();

        let modifiedField = { ...newFields[i].procedureFields[parentIndex] };
        // 1. Make a shallow copy of the items
        let newOptions = [...field.options];
        // 2. Make a shallow copy of the item you want to mutate
        newOptions.splice(id, 1);

        modifiedField.options = newOptions;
        // 5. Set the state to our new copy
        newFields[i].procedureFields[parentIndex] = modifiedField;

        setFields(newFields);
        let validations = cloneDeep(validationProcedureItems);
        validations[i].procedureFields[parentIndex].options.splice(id, 1);
        setValidationProcedureItems(validations);
      } else {
        let modifiedField = { ...newFields[parentIndex] };
        // 1. Make a shallow copy of the items
        let newOptions = [...field.options];
        // 2. Make a shallow copy of the item you want to mutate
        newOptions.splice(id, 1);

        modifiedField.options = newOptions;
        // 5. Set the state to our new copy
        newFields[parentIndex] = modifiedField;

        setFields(newFields);
        let validations = cloneDeep(validationProcedureItems);
        validations[parentIndex].options.splice(id, 1);
        setValidationProcedureItems(validations);
      }
    };

    const handleOptionChange = (parentIndex, id, i, isHeader = true) => (
      event
    ) => {
      let newFields = [...fields];
      if (!isHeader) {
        let modifiedField = { ...newFields[i].procedureFields[parentIndex] };

        // 1. Make a shallow copy of the items
        let newOptions = [...field.options];

        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...field.options[id] };

        // 3. Replace the property you're intested in

        item = event.target.value;
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        newOptions[id].item = item;

        modifiedField.options = newOptions;
        // 5. Set the state to our new copy
        newFields[i].procedureFields[parentIndex] = modifiedField;

        setFields(newFields);
        let validations = cloneDeep(validationProcedureItems);
        validations[i].procedureFields[parentIndex].options[id] = {
          ...validations[i].procedureFields[parentIndex].options[id],
          error: validations[i].procedureFields[parentIndex].options[
            id
          ].pattern(
            newFields[i].procedureFields[parentIndex].options[id].item,
            newFields[i].procedureFields[parentIndex].fieldType
          )
            ? false
            : true,
          skipped: true,
          message: validations[i].procedureFields[parentIndex].options[
            id
          ].messageData(
            newFields[i].procedureFields[parentIndex].options[id].item
          ),
        };
        setValidationProcedureItems(validations);
      } else {
        let modifiedField = { ...newFields[parentIndex] };
        // 1. Make a shallow copy of the items
        let newOptions = cloneDeep(field.options);
        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...field.options[id] };
        // 3. Replace the property you're intested in

        item = event.target.value;
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        newOptions[id].item = item;

        modifiedField.options = newOptions;
        // 5. Set the state to our new copy
        newFields[parentIndex] = modifiedField;

        setFields(newFields);
        let validations = cloneDeep(validationProcedureItems);
        validations[parentIndex].options[id] = {
          ...validations[parentIndex].options[id],
          error: validations[parentIndex].options[id].pattern(
            newFields[parentIndex].options[id].item,
            newFields[parentIndex].fieldType
          )
            ? false
            : true,
          skipped: true,
          message: validations[parentIndex].options[id].messageData(
            newFields[parentIndex].options[id].item
          ),
        };
        setValidationProcedureItems(validations);
      }
    };

    const generatedOptions = (parentIndex, i, isHeader = true) => {
      let checkStyle = <Checkbox disabled />;
      if (field.fieldType == "multipleChoice") {
        checkStyle = <Radio disabled />;
      }
      if (field.fieldType == "checkbox") {
        return (
          // <div className="flexbox align-center">
          //   <Form.Field
          //     style={{
          //       display: 'flex',
          //       alignItems: 'center',
          //       marginBottom: 0,
          //       width: '100%',
          //     }}
          //   >
          //     {checkStyle}
          <>
            <div style={{ marginRight: "10px" }}>
              <TextArea
                autoComplete="new-password shiv"
                className="custom-text-area"
                rows={2}
                fluid
                placeholder={t.procedures.form.option}
                value={field?.options[0].item}
                style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}
                onChange={handleOptionChange(parentIndex, 0, i, isHeader)}
              ></TextArea>
            </div>
            {false
              ? validationProcedureItems[parentIndex]?.options[index]
                  ?.skipped &&
                validationProcedureItems[parentIndex]?.options[index]
                  ?.error && (
                  <div className="label-error ml-4 mr-4">
                    {
                      validationProcedureItems[parentIndex]?.options[index]
                        ?.message
                    }
                  </div>
                )
              : validationObj?.options[0]?.skipped &&
                validationObj?.options[0]?.error && (
                  <div className="label-error ml-4 mr-4">
                    {validationObj?.options[0]?.message}
                  </div>
                )}

            {field?.options[0]?.item ? (
              <div className="ml-4 mr-4 mt-1 text-right">
                ({field?.options[0]?.item.length}/500)
              </div>
            ) : null}
          </>

          // </Form.Field>
          // </div>
        );
      } else {
        const getItemStyle = (isDragging, draggableStyle) => ({
          ...draggableStyle,
          userSelect: "none",
          position: "static",
          padding: "0 0",
          margin: `15px 0 0 0`,
          borderRadius: "3px",
          background: isDragging ? "#e2f5fc" : "",
          height: "28px",
        });

        return field?.options.map((item, index) => (
          <Draggable
            key={index}
            draggableId={"draggable-" + index}
            index={index}
          >
            {(provided, snapshot) => {
              return (
                <div className="procedure-form-item-container">
                  <div
                    className="align-center h-auto"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <Form.Field
                      style={{ minHeight: "65px" }}
                      className="d-flex align-items-center w-100 "
                    >
                      <span
                        style={{ minWidth: "25px", alignSelf: "start" }}
                        {...provided.dragHandleProps}
                      >
                        <OrderIcon />
                      </span>
                      {/* {checkStyle} */}

                      <div className="item-option-container">
                        <TextArea
                          id={"option-" + index}
                          autoComplete="new-password"
                          className="custom-text-area"
                          rows={2}
                          fluid
                          placeholder={t.procedures.form.option}
                          value={item.item}
                          onChange={handleOptionChange(
                            parentIndex,
                            index,
                            i,
                            isHeader
                          )}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              if (item.item.length > 0) {
                                addOption(index, i, isHeader);
                              }
                            }
                          }}
                        />
                        {/* <input type="checkbox" /> */}
                      </div>

                      <span
                        className="ml-1 c-pointer align-self-start"
                        onClick={removeOption(parentIndex, index, i, isHeader)}
                      >
                        <DeleteIcon />
                      </span>
                    </Form.Field>
                    <div>
                      {isHeader
                        ? validationProcedureItems[parentIndex]?.options[index]
                            ?.skipped &&
                          validationProcedureItems[parentIndex]?.options[index]
                            ?.error && (
                            <div
                              className="label-error ml-7 mr-7"
                              style={{ marginTop: "4px" }}
                            >
                              {
                                validationProcedureItems[parentIndex]?.options[
                                  index
                                ]?.message
                              }
                            </div>
                          )
                        : validationObj?.options[index]?.skipped &&
                          validationObj?.options[index]?.error && (
                            <div
                              className="label-error ml-7 mr-7"
                              style={{ marginTop: "4px" }}
                            >
                              {validationObj?.options[index]?.message}
                            </div>
                          )}

                      {item?.item ? (
                        <div className="ml-7 mr-7 mt-1 text-right">
                          ({item?.item.length}/500)
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            }}
          </Draggable>
        ));
      }
    };

    let returnFields = null;
    switch (type) {
      case "text":
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
              {/* {fieldTypes[1]?.icon} */}
              <TextIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.text_placeholder}
          </Segment>
        );
        break;
      case "number":
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
              {/* {fieldTypes[2]?.icon} */}
              <NumberIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.number_placeholder}
          </Segment>
        );

        break;
      case "amount":
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
              {/* {fieldTypes[3]?.icon} */}
              <AmountIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.amount_placeholder}
          </Segment>
        );
        break;

      case "signature":
        returnFields = (
          <>
            <Segment secondary>
              <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
                {/* {fieldTypes[7]?.icon} */}
                <SignatureIcon width="24px" height="24px" fill="#788CA5" />
              </span>
              {t.procedures.form.signature_placeholder}
            </Segment>{" "}
          </>
        );
        break;
      case "checkbox":
        returnFields = (
          <>
            {generatedOptions(index, i, isHeader)}
            {/* <Segment secondary className="m-5">
              <span className={selectedLanguage == 'ar' ? 'ml-2' : 'mr-2'}>
               
              </span>
              {t.procedures.form.option}
            </Segment>{' '} */}
          </>
        );
        break;
      case "date":
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
              {/* {fieldTypes[8]?.icon} */}
              <DateIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.date_placeholder}{" "}
          </Segment>
        );
        break;
      case "file":
        // text,number,amount, signature, date, file fields
        //isrequired
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
              {/* {fieldTypes[9]?.icon} */}
              <FileIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.file_placeholder}
          </Segment>
        );
        break;

      case "photo":
        // text,number,amount, signature, date, file fields
        //isrequired
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
              {/* {fieldTypes[10]?.icon} */}
              <PhotoIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.photo_placeholder}
          </Segment>
        );
        break;

      case "multipleChoice":
      case "checklist":
        // multipleChoice fields
        //isRequired & options
        returnFields = (
          <>
            {/* <label className="label-item">{t.procedures.form.options}</label> */}
            <DragDropContext
              onDragEnd={(result) => {
                if (!result.destination) {
                  return;
                }
                const [removed] = field.options.splice(result.source.index, 1);
                field.options.splice(result.destination.index, 0, removed);

                const [removedIndex] = validationObj.options.splice(
                  result.source.index,
                  1
                );
                validationObj.options.splice(
                  result.destination.index,
                  0,
                  removedIndex
                );

                // Update state or context with the reordered validation items
              }}
            >
              <Droppable droppableId="droppable-1">
                {(provided, _) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {generatedOptions(index, i, isHeader)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div style={{ marginTop: "10px" }} className="flexbox">
              <div
                className="item-button-group"
                onClick={() => addOption(index, i, isHeader)}
              >
                <span className="item-button-icon-container">
                  <AddIconWithCircle />
                </span>
                <div className="item-add-option-text">
                  {t.procedures.form.add_option}
                </div>
              </div>
              {/* <div
                  className={'buttun-type-link'}
                  basic
                  onClick={() => addOption(index)}
                >
                  <Icon name="add" />
                  {t.procedures.form.add_option}
                </div> */}
            </div>
          </>
        );
        break;
      case "inspectionCheck":
        // inspectionCheck fields
        //isRequired & inspectionTypes & logic

        const handleDelete = (field, i, index, value) => {
          let filteredTrigger = [];
          filteredTrigger = field.logicTrigger
            ? field.logicTrigger.filter((tr) => tr !== value)
            : [];
          handleFieldChange(
            i,
            "logicTrigger",
            index,
            false
          )({}, { value: [...filteredTrigger] });
        };

        returnFields = isHeader ? (
          <>
            <Form>
              <Form.Field className="mb-0">
                <label className="label-item">
                  {t.procedures.form.inspection_type}
                  <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.procedures.form.inspection_type}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  options={inspectionTypes}
                  value={
                    isHeader ? field.inspectionType : field?.inspectionType
                  }
                  onChange={handleFieldChange(
                    index,
                    "inspectionType",
                    i,
                    isHeader
                  )}
                />
              </Form.Field>

              {/* <Form.Field>
                <label className="label-item">
                  {t.procedures.form.failed_value}
                </label>
                <Dropdown
                  placeholder={t.procedures.form.failed_value_placeholder}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  disabled={logicAnswersOptions.length < 1}
                  options={logicAnswersOptions}
                  value={field.failedValue}
                  onChange={handleFieldChange(index, 'failedValue')}
                />
              </Form.Field> */}
            </Form>

            {field.logic && isHeader ? (
              <div className="d-flex align-items-center add-procedure-dropdown-n justify-content-between">
                <div className="">
                  <div className="flexbox align-center justify-between">
                    <div className="flexbox align-center">
                      {/* <Icon name="code branch" style={{ marginRight: '5px' }} /> */}
                      <p className="m-1">{t.procedures.form.if_answer_is}</p>
                      <div className="custom-menu-dropdown">
                        <UncontrolledDropdown className="me-2" direction="down">
                          <DropdownToggle className="option-toggle inspection-option-dropdown">
                            {field.logicAnswer
                              ? logicAnswersOptions.find(
                                  (one) => one.value === field.logicAnswer
                                )?.text
                              : t.procedures.form.if_answer_is_placeholder}{" "}
                            <WhiteDownChevron fill="#0C66E4" />{" "}
                          </DropdownToggle>
                          <DropdownMenu>
                            {logicAnswersOptions.map((one) => {
                              return (
                                <div
                                  onClick={() => {
                                    handleFieldChange(index, "logicAnswer")(
                                      {},
                                      { value: one.value }
                                    );
                                  }}
                                >
                                  <DropdownItem>{one.text}</DropdownItem>{" "}
                                  <DropdownItem className="divider" />
                                </div>
                              );
                            })}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      {/* <Dropdown
                        style={{ margin: '0 5px' }}
                        inline
                        disabled={logicAnswersOptions.length < 1}
                        options={logicAnswersOptions}
                        value={field.logicAnswer}
                        placeholder={t.procedures.form.if_answer_is_placeholder}
                        onChange={handleFieldChange(index, 'logicAnswer')}
                      /> */}
                      <p className="m-1">{t.procedures.form.then}</p>

                      {/* <Dropdown
                          style={{ margin: '0 5px' }}
                          inline
                          multiple
                          options={logicTriggers}
                          disabled={logicAnswersOptions.length < 1}
                          value={field.logicTrigger}
                          placeholder={t.procedures.form.then_placeholder}
                          onChange={handleFieldChange(index, 'logicTrigger')}
                        /> */}
                      <div className="custom-menu-dropdown">
                        <UncontrolledDropdown className="me-2" direction="down">
                          <DropdownToggle className="option-toggle inspection-option-dropdown d-flex justify-content-between align-items-center">
                            <div>
                              {field.logicTrigger && field.logicTrigger.length
                                ? field.logicTrigger.map((tr) => {
                                    let triggerLabel = logicTriggers.find(
                                      (each) => each.value == tr
                                    );
                                    return (
                                      <p className="m-0">
                                        {triggerLabel?.text}
                                      </p>
                                    );
                                  })
                                : t.procedures.form.then_placeholder}{" "}
                            </div>
                            <WhiteDownChevron fill="#0C66E4" />{" "}
                          </DropdownToggle>
                          <DropdownMenu>
                            {logicTriggers.map((one) => {
                              return (
                                <div>
                                  <div className="d-flex ">
                                    <DropdownItem>
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={
                                            field.logicTrigger &&
                                            field.logicTrigger.includes(
                                              one.value
                                            )
                                          }
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              let filteredTrigger = field.logicTrigger
                                                ? field.logicTrigger
                                                : [];
                                              handleFieldChange(
                                                index,
                                                "logicTrigger"
                                              )(
                                                {},
                                                {
                                                  value: [
                                                    ...filteredTrigger,
                                                    one.value,
                                                  ],
                                                }
                                              );
                                            } else {
                                              let filteredTrigger = [];
                                              filteredTrigger = field.logicTrigger
                                                ? field.logicTrigger.filter(
                                                    (tr) => tr !== one.value
                                                  )
                                                : [];
                                              handleFieldChange(
                                                index,
                                                "logicTrigger"
                                              )(
                                                {},
                                                { value: [...filteredTrigger] }
                                              );
                                            }
                                          }}
                                        />
                                        <span className="ml-2 mr-2">
                                          {one.text}
                                        </span>
                                      </label>
                                    </DropdownItem>{" "}
                                  </div>
                                  <DropdownItem className="divider" />
                                </div>
                              );
                            })}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                    {/* <div
                        className={'buttun-type-link remove'}
                        basic
                        onClick={handleFieldChange(index, 'logic')}
                      >
                        {t.procedures.form.cancel_button}
                      </div> */}
                  </div>
                  {/* {field?.logicTrigger?.includes('action') ? (
                    <span className="new-added-add-logic-txt">
                      {logicTriggers[0]?.text}
                    </span>
                  ) : null} */}
                  {/* {field?.logicTrigger?.includes('evidence') ? (
                    <span className="new-added-add-logic-txt">
                      {' '}
                      {logicTriggers[1]?.text}
                    </span>
                  ) : null} */}
                </div>
                <div onClick={handleFieldChange(index, "logic")}>
                  <DeleteIcon />
                </div>
              </div>
            ) : null}

            {field.logicTrigger && field.logicTrigger.length ? (
              <div>
                <Form className="mt-5 mb-5">
                  {field.logicTrigger.includes("action") ? (
                    <Form.Field>
                      <label className="label-item">
                        {t.procedures.form.required_action}
                      </label>
                      <Segment secondary>
                        <span
                          className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}
                        >
                          <GreyWOIcon />
                        </span>
                        {t.procedures.form.wo_placeholder}
                      </Segment>
                      {/* <TextArea
                        autoComplete="new-password"
                        value={field.actionDetails}
                        onChange={handleFieldChange(index, 'actionDetails')}
                        rows={2}
                        fluid
                        placeholder={t.procedures.form.required_action}
                      /> */}
                    </Form.Field>
                  ) : null}
                  {field.logicTrigger.includes("evidence") ? (
                    <Form.Field>
                      <label className="label-item">
                        {t.procedures.form.required_evidence}
                      </label>
                      <Segment secondary>
                        <span
                          className={selectedLanguage == "ar" ? "ml-3" : "mr-3"}
                        >
                          <GreyFileIcon />
                        </span>
                        {t.procedures.form.file_placeholder}
                      </Segment>
                      {/* <TextArea
                        autoComplete="new-password"
                        value={field.evidenceDetails}
                        onChange={handleFieldChange(index, 'evidenceDetails')}
                        rows={2}
                        fluid
                        placeholder={t.procedures.form.required_evidence}
                      /> */}
                    </Form.Field>
                  ) : null}
                </Form>
              </div>
            ) : null}

            <div style={{ marginTop: "0px" }}>
              {!field.logic && (
                <div
                  className="item-button-group"
                  onClick={handleFieldChange(index, "logic")}
                >
                  <span className="item-button-icon-container">
                    <LogicIcon />
                  </span>
                  <div className="item-add-option-text">
                    {t.procedures.form.add_logic}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <Form>
              <Form.Field className="mb-0">
                <label className="label-item">
                  {t.procedures.form.inspection_type}
                  <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.procedures.form.inspection_type}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  options={inspectionTypes}
                  value={field?.inspectionType}
                  onChange={handleFieldChange(
                    i,
                    "inspectionType",
                    index,
                    false
                  )}
                />
              </Form.Field>

              {/* <Form.Field>
                <label className="label-item">
                  {t.procedures.form.failed_value}
                </label>
                <Dropdown
                  placeholder={t.procedures.form.failed_value_placeholder}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  disabled={logicAnswersOptions.length < 1}
                  options={logicAnswersOptions}
                  value={field.failedValue}
                  onChange={handleFieldChange(index, 'failedValue')}
                />
              </Form.Field> */}
            </Form>

            {field?.logic && (
              <label
                // style={
                //   selectedLanguage == 'en'
                //     ? { marginLeft: '-5px' }
                //     : { marginRight: '-5px' }
                // }
                className="title-text-isc ml-1 mr-1"
              >
                {t.procedures.logic}
              </label>
            )}

            <div style={{ marginTop: "0px" }}>
              {!field?.logic ? (
                <div
                  className="item-button-group"
                  onClick={handleFieldChange(i, "logic", index, false)}
                >
                  <span className="item-button-icon-container">
                    <LogicIcon />
                  </span>
                  <div className="item-add-option-text">
                    {t.procedures.form.add_logic}
                  </div>
                </div>
              ) : (
                <div className="logic-box-for-isc mt-1">
                  {field.logic ? (
                    <div className="d-flex align-items-center add-procedure-dropdown-n justify-content-between">
                      <div className="">
                        <div className="flexbox align-center justify-between">
                          <div className="flexbox align-center">
                            {/* <Icon name="code branch" style={{ marginRight: '5px' }} /> */}
                            <p className="m-1">
                              {t.procedures.form.if_answer_is}
                            </p>
                            <div className="custom-menu-dropdown">
                              <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                              >
                                <DropdownToggle className="option-toggle inspection-option-dropdown">
                                  {field.logicAnswer
                                    ? logicAnswersOptions.find(
                                        (one) => one.value === field.logicAnswer
                                      )?.text
                                    : t.procedures.form
                                        .if_answer_is_placeholder}{" "}
                                  <WhiteDownChevron fill="#0C66E4" />{" "}
                                </DropdownToggle>
                                <DropdownMenu>
                                  {logicAnswersOptions.map((one) => {
                                    return (
                                      <div
                                        onClick={() => {
                                          handleFieldChange(
                                            i,
                                            "logicAnswer",
                                            index,
                                            false
                                          )({}, { value: one.value });
                                        }}
                                      >
                                        <DropdownItem>{one.text}</DropdownItem>{" "}
                                        <DropdownItem className="divider" />
                                      </div>
                                    );
                                  })}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            {/* <Dropdown
                          style={{ margin: '0 5px' }}
                          inline
                          disabled={logicAnswersOptions.length < 1}
                          options={logicAnswersOptions}
                          value={field.logicAnswer}
                          placeholder={t.procedures.form.if_answer_is_placeholder}
                          onChange={handleFieldChange(index, 'logicAnswer')}
                        /> */}
                            <p className="m-1">{t.procedures.form.then}</p>

                            {/* <Dropdown
                            style={{ margin: '0 5px' }}
                            inline
                            multiple
                            options={logicTriggers}
                            disabled={logicAnswersOptions.length < 1}
                            value={field.logicTrigger}
                            placeholder={t.procedures.form.then_placeholder}
                            onChange={handleFieldChange(index, 'logicTrigger')}
                          /> */}
                            <div className="custom-menu-dropdown">
                              <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                              >
                                <DropdownToggle className="option-toggle inspection-option-dropdown d-flex justify-content-between align-items-center">
                                  <div>
                                    {field.logicTrigger &&
                                    field.logicTrigger.length
                                      ? field.logicTrigger.map((tr) => {
                                          let triggerLabel = logicTriggers.find(
                                            (each) => each.value == tr
                                          );
                                          return (
                                            <p className="m-0">
                                              {triggerLabel?.text}
                                            </p>
                                          );
                                        })
                                      : t.procedures.form.then_placeholder}{" "}
                                  </div>
                                  <WhiteDownChevron fill="#0C66E4" />{" "}
                                </DropdownToggle>
                                <DropdownMenu>
                                  {logicTriggers.map((one) => {
                                    return (
                                      <div>
                                        <div className="d-flex ">
                                          <DropdownItem>
                                            <label>
                                              <input
                                                type="checkbox"
                                                checked={
                                                  field.logicTrigger &&
                                                  field.logicTrigger.includes(
                                                    one.value
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    let filteredTrigger = field.logicTrigger
                                                      ? field.logicTrigger
                                                      : [];
                                                    handleFieldChange(
                                                      i,
                                                      "logicTrigger",
                                                      index,
                                                      false
                                                    )(
                                                      {},
                                                      {
                                                        value: [
                                                          ...filteredTrigger,
                                                          one.value,
                                                        ],
                                                      }
                                                    );
                                                  } else {
                                                    let filteredTrigger = [];
                                                    filteredTrigger = field.logicTrigger
                                                      ? field.logicTrigger.filter(
                                                          (tr) =>
                                                            tr !== one.value
                                                        )
                                                      : [];
                                                    handleFieldChange(
                                                      i,
                                                      "logicTrigger",
                                                      index,
                                                      false
                                                    )(
                                                      {},
                                                      {
                                                        value: [
                                                          ...filteredTrigger,
                                                        ],
                                                      }
                                                    );
                                                  }
                                                }}
                                              />
                                              <span className="ml-2 mr-2">
                                                {one.text}
                                              </span>
                                            </label>
                                          </DropdownItem>{" "}
                                        </div>
                                        <DropdownItem className="divider" />
                                      </div>
                                    );
                                  })}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                          {/* <div
                          className={'buttun-type-link remove'}
                          basic
                          onClick={handleFieldChange(index, 'logic')}
                        >
                          {t.procedures.form.cancel_button}
                        </div> */}
                        </div>
                        {/* {field?.logicTrigger?.includes('action') ? (
                      <span className="new-added-add-logic-txt">
                        {logicTriggers[0]?.text}
                      </span>
                    ) : null}
                    {field?.logicTrigger?.includes('evidence') ? (
                      <span className="new-added-add-logic-txt">
                        {' '}
                        {logicTriggers[1]?.text}
                      </span>
                    ) : null} */}
                      </div>
                      <div
                        onClick={handleFieldChange(i, "logic", index, false)}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  ) : null}

                  {field.logicTrigger && field.logicTrigger.length ? (
                    <div>
                      <Form className="mt-5 mb-5">
                        {field.logicTrigger.includes("action") ? (
                          <Form.Field>
                            <div className="d-flex justify-content-between ">
                              <label
                                style={
                                  selectedLanguage == "en"
                                    ? { marginLeft: "-5px" }
                                    : { marginRight: "-5px" }
                                }
                                className="sub-text"
                              >
                                <span>
                                  <RequiredIcon />{" "}
                                </span>{" "}
                                {t.procedures.form.required_action}
                              </label>
                              <div
                                className="c-pointer"
                                onClick={() =>
                                  handleDelete(field, i, index, "action")
                                }
                              >
                                <CloseIcon />
                              </div>
                            </div>

                            {/* <label className="label-item">
                          {t.procedures.form.required_action}
                        </label> */}

                            <Segment secondary>
                              <span
                                className={
                                  selectedLanguage == "ar" ? "ml-2" : "mr-2"
                                }
                              >
                                <GreyWOIcon />
                              </span>
                              {t.procedures.form.wo_placeholder}
                            </Segment>
                            {/* <TextArea
                          autoComplete="new-password"
                          value={field.actionDetails}
                          onChange={handleFieldChange(
                            i,
                            'actionDetails',
                            index,
                            false
                          )}
                          rows={2}
                          fluid
                          placeholder={t.procedures.form.required_action}
                        /> */}
                          </Form.Field>
                        ) : null}
                        {field.logicTrigger.includes("evidence") ? (
                          <Form.Field>
                            <div className="d-flex justify-content-between sub-text">
                              <label
                                style={
                                  selectedLanguage == "en"
                                    ? { marginLeft: "-5px" }
                                    : { marginRight: "-5px" }
                                }
                                className="sub-text"
                              >
                                <span className="">
                                  <EvidanceIcon />{" "}
                                </span>{" "}
                                {t.procedures.form.then_options[1]}
                              </label>

                              <div
                                className="c-pointer"
                                onClick={() =>
                                  handleDelete(field, i, index, "evidence")
                                }
                              >
                                <CloseIcon />
                              </div>
                            </div>

                            {/* <label className="label-item">
                          {t.procedures.form.required_evidence}
                        </label> */}
                            <Segment secondary>
                              <span
                                className={
                                  selectedLanguage == "ar" ? "ml-3" : "mr-3"
                                }
                              >
                                <GreyFileIcon />
                              </span>
                              {t.procedures.form.file_placeholder}
                            </Segment>
                            {/* <TextArea
                          autoComplete="new-password"
                          value={field.evidenceDetails}
                          onChange={handleFieldChange(
                            i,
                            'evidenceDetails',
                            index,
                            false
                          )}
                          rows={2}
                          fluid
                          placeholder={t.procedures.form.required_evidence}
                        /> */}
                          </Form.Field>
                        ) : null}
                      </Form>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </>
        );
        break;
      default:
        returnFields = null;
    }

    return returnFields;
  };
  return { generatedProcedureFields: generatedFields };
}

export default useProcedureTFields;
