import React from "react";
import { Image } from "semantic-ui-react";
import md5 from "md5";
const alphabeticColor = {
  a: { bg: "E2F2E7", text: "4FAB68" },
  b: { bg: "B4DCBF", text: "4FAB68" },
  c: { bg: "8CCA9D", text: "4FAB68" },
  d: { bg: "4FAB68", text: "FFFFFF" },
  e: { bg: "428E56", text: "FFFFFF" },
  f: { bg: "C0D9FC", text: "0C66E4" },
  g: { bg: "9CC2F7", text: "0C66E4" },
  h: { bg: "78ABF2", text: "0C66E4" },
  i: { bg: "5494EE", text: "FFFFFF" },
  j: { bg: "307DE9", text: "FFFFFF" },
  k: { bg: "FBE8A1", text: "E2B203" },
  l: { bg: "F3D66D", text: "E2B203" },
  m: { bg: "EDCA49", text: "E2B203" },
  n: { bg: "E2B203", text: "FFFFFF" },
  o: { bg: "BA9202", text: "FFFFFF" },
  p: { bg: "E6EAEF", text: "90A1B5" },
  q: { bg: "CDD5DE", text: "90A1B5" },
  r: { bg: "C1CAD6", text: "90A1B5" },
  s: { bg: "A9B6C6", text: "FFFFFF" },
  t: { bg: "90A1B5", text: "FFFFFF" },
  u: { bg: "EADAFF", text: "AC6CFF" },
  v: { bg: "E0C8FF", text: "AC6CFF" },
  w: { bg: "D5B5FF", text: "AC6CFF" },
  x: { bg: "C091FF", text: "FFFFFF" },
  y: { bg: "AC6CFF", text: "FFFFFF" },
  z: { bg: "9747FF", text: "FFFFFF" },
};
const getInitials = (name) => {
  return `${name.split(" ")[0][0]} ${
    name.split(" ")[1] ? name.split(" ")[1][0] : ""
  }`;
};
const getInitialsOfFirstTwo = (name) => {
  return name.substring(0, 2);
};
const getInitialsOfFirstOne = (name) => {
  return name.substring(0, 1);
};
// const generateBackground = (nameFormat) => {
//   const seed = 123456;
//   let name = nameFormat + md5(nameFormat);
//   let hash = seed;
//   let i;
//   for (i = 0; i < name.length; i += 1) {
//     hash = name.charCodeAt(i) + ((hash << 5) - hash);
//   }
//   let color = '';
//   for (i = 0; i < 3; i += 1) {
//     const value = (hash >> (i * 8)) & 0xff;
//     color += `00${value.toString(16)}`.slice(-2);
//   }
//   return color;
// };
const generateBackground = (name, color) => {
  let firstLetter = name.charAt(0).toLowerCase();
  return color ? color : alphabeticColor[firstLetter]?.bg || "4FAB68";
};
const generateTextColor = (name, color) => {
  let firstLetter = name.charAt(0).toLowerCase();
  return color ? color : alphabeticColor[firstLetter]?.text || "FFFFFF";
};
const UserProfilePlaceholder = ({
  name = "",
  width = "20px",
  height = "20px",
  right = "0px",
  className = "",
  
}) => {
  return (
    // <span className=" text-success user-image-placeholder-container">
    //   {title}
    // </span>
    <Image
      avatar
      title={name}
      src={`https://ui-avatars.com/api/?name=${getInitials(
        name
      )}&background=${generateBackground(
        name
      )}&bold=true&color=${generateTextColor(name)}`}
      style={{ width: width, height: height, right: right }}
      className={className}
    />
  );
};
export const AssetProfilePlaceholder = ({
  name = "",
  width = "20px",
  height = "20px",
  right = "0px",
  className = "",
}) => {
  return (
    // <span className=" text-success user-image-placeholder-container">
    //   {title}
    // </span>
    <Image
      avatar
      title={name}
      src={`https://ui-avatars.com/api/?name=${getInitialsOfFirstTwo(
        name
      )}&background=${generateBackground(
        name
      )}&bold=true&color=${generateTextColor(name)}`}
      style={{ width: width, height: height, right: right }}
      className={className}
    />
  );
};

export const AssetProfilePlaceholder2 = ({
  name = "",
  width = "20px",
  height = "20px",
  right = "0px",
  className = "",
}) => {
  return (
    // <span className=" text-success user-image-placeholder-container">
    //   {title}
    // </span>
    <Image
      bordered
      title={name}
      src={`https://ui-avatars.com/api/?name=${getInitialsOfFirstOne(
        name
      )}&background=${generateBackground(
        name
      )}&bold=true&color=${generateTextColor(name)}`}
      style={{
        width: width,
        height: height,
        right: right,
        borderRadius: "7px",
      }}
      className={className}
    />
  );
};
export default UserProfilePlaceholder;
export const LetteredAvatarImageUrl = (name, color = "", textColor = "") => {
  return `https://ui-avatars.com/api/?name=${getInitials(
    name
  )}&background=${generateBackground(
    name,
    color
  )}&bold=true&color=${generateTextColor(name, textColor)}`;
};
