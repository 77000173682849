import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAllCustomers,
  getAllVendros,
  getAllWorkOrders,
} from '../../app/config/functions';
import { useSelector } from 'react-redux';
const initialState = {
  WOData: [], 
  allWOData: [], 
  isLoading: false,
  initLoading: false,
  error: null, 
  WOInitData: [], 
  page: 1, 
  pageSize: 25, 
  total: 25,
};


/*
 * Fetch WO initial data
 */
export const fetchWOInitData = createAsyncThunk(
  'wo/initData',
  async ({organisationId,role,allIds,currentUserId}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllWorkOrders(organisationId, '', 1, 25,[],[],[],[],[],[],[],'',[],[],role,allIds,currentUserId);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all WO data
 */
export const fetchAllWOData = createAsyncThunk(
  'wo/allData',
  async ({organisationId,role,allIds,currentUserId}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllWorkOrders(organisationId, '', 1, 99999,[],[],[],[],[],[],[],'',[],[],role,allIds,currentUserId);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
        // dispatch(setData(data.response));
        // dispatch(setInitData(data.response));
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter WO data
 */
export const fetchWOWithFilter = createAsyncThunk(
  'wo/filter',
  async (
    {
      organisationId,
      searchName,
      page,
      pageSize,
      assignee = [],
      status = [],
      priority = [],
      category = [],
      customers = [],
      locations = [],
      dueDate = [],
      serialNumber = '',
      teams = [],
      sort = [],
      role,
      allIds = [],
      currentUserId= '',
      orderType = [],
    },
    { dispatch, rejectWithValue }
  ) => {

    // const currentUserId = useSelector((state) => {
    //   if (state?.auth?.user?.id) {
    //     return state.auth.user.id;
    //   } else {
    //     return null;
    //   }
    // });
    // console.log(currentUserId)
    try {
      const data = await getAllWorkOrders(
        organisationId,
        searchName,
        page,
        pageSize,
        assignee,
        status,
        priority,
        category,
        customers,
        locations,
        dueDate,
        serialNumber,
        teams,
        sort,
        role,
        allIds,
        currentUserId,
        orderType
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
       
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const WOSlice = createSlice({
  name: 'workOrder',
  initialState,

  extraReducers: (builder) => {
    builder
  
      .addCase(fetchWOInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchWOInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.WOInitData = action.payload.response;
        state.error = null; 
      })
      .addCase(fetchWOInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchWOWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchWOWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.WOData = action.payload.response;
        state.allWOData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null; 
      })
      .addCase(fetchWOWithFilter.rejected, (state, action) => {
        state.isLoading = false;
       
      })
      
      .addCase(fetchAllWOData.pending, (state) => {
       
      })
      .addCase(fetchAllWOData.fulfilled, (state, action) => {
        state.allWOData = action.payload.response;
      })
      .addCase(fetchAllWOData.rejected, (state, action) => {
      
      });
  },
});

export default WOSlice.reducer;
