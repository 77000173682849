import { filter } from "lodash";
import React, { useEffect, useState, createRef } from "react";
import _ from "lodash";
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  Menu,
  DropdownMenu,
  DropdownItem,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import location_icon from "../../../Assets/Icons/loc_option_parent.png";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../Assets/Icons/grandChild.png";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import {
  getAllLocations,
  getUsersByRole,
  getAllTeams,
  humanize,
  toggle,
  getAllAssets,
  getActiveUsersByRole,
  getAllParentLocations,
  getAllAssetsOption,
} from "../../config/functions";
import calendar_icon from "../../../Assets/Icons/calender-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import {
  ActionBtnIcon,
  DownloadIcon,
  SearchIcon,
  WhiteDownChevron,
} from "../../../Assets/Icons/svg";
import LocationSearchDropdown from "../../components/WorkOrder/LocationSearchDropdown";
import CategoryDropdown from "../../components/common/CategoryDropdown";
import WorkOrderSearchDropdown from "../../components/WorkOrder/WorkOrderSearchDropdown";
import PriorityDropdown from "../../components/common/PriorityDropdown";
import {
  fetchAllWRData,
  fetchWRWithFilter,
} from "../../../redux/reducers/workRequestReducer";
import DatePicker from "react-multi-date-picker";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ToolTip from "react-power-tooltip";
import moment from "moment";
import DownloadExcelFileWithData from "../../../services/DownloadExcelFileWithData";

const Filters = ({
  data,
  setDataByFilter,
  hasStatus,
  setFilterOptions,
  filterOptions,
  setSearchTitle,
  searchTitle,
  debounceValue,
  setSelectedDates,
  selectedDates,
  setloader,
  loader,
  sortData,
  setSortData,
  fileData,
  currentUserId,
  userId
}) => {
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const t = useSelector(selectTranslations);
  const dispatch = useDispatch();

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const [filtersTrigger, setFiltersTrigger] = useState({
    requestedByType: "supervisors",
    requestedBy: {
      supervisors: {
        initOptions: [],
        options: [],
      },
      customers: {
        initOptions: [],
        options: [],
      },
    },
    locations: {
      initOptions: [],
      options: [],
    },
    requestedBy: {
      initOptions: [],
      options: [],
    },
    assets: {
      initOptions: [],
      options: [],
    },
    priority: {
      options: [
        {
          key: 0,
          text: t.common.all,
          value: "",
          color: null,
        },
        {
          key: 1,
          text: t.workOrders.priority_status.low,
          value: "low",
          color: "blue",
        },
        {
          key: 2,
          text: t.workOrders.priority_status.medium,
          value: "medium",
          color: "orange",
        },
        {
          key: 3,
          text: t.workOrders.priority_status.high,
          value: "high",
          color: "red",
        },
        {
          key: 4,
          text: t.workOrders.priority_status.none,
          value: "none",
          color: "blue",
        },
      ],
    },

    status: {
      options: [
        {
          key: 1,
          text: t.common.all,
          value: "",
          color: "",
        },
        {
          key: 1,
          text: t.workRequests.status.approved,
          value: "approved",
          color: "green",
        },
        {
          key: 3,
          text: t.workRequests.status.pending,
          value: "pending",
          color: "orange",
        },
        {
          key: 2,
          text: t.workRequests.status.declined,
          value: "declined",
          color: "red",
        },
      ],
    },
    dueDate: {
      options: [
        { key: 0, text: "Any Date", value: "any_day" },
        { key: 1, text: "Today", value: "today" },
        { key: 2, text: "Tomorrow", value: "tomorrow" },
        { key: 3, text: "Next 7 Days", value: "next_7_days" },
        { key: 4, text: "Next 30 Days", value: "next_30_days" },
        { key: 5, text: "This Month", value: "this_month" },
        { key: 6, text: "This Quarter", value: "this_quarter" },
        { key: 7, text: "This Year", value: "this_year" },
        { key: 8, text: "Yesterday", value: "yesterday" },
        { key: 9, text: "Last Week", value: "last_week" },
        { key: 10, text: "Last Month", value: "last_month" },
        { key: 11, text: "Last Quarter", value: "last_quarter" },
        { key: 12, text: "Last Year", value: "last_year" },
        { key: 13, text: "Past Due", value: "past_due" },
        { key: 14, text: "Unscheduled", value: "unscheduled" },
      ],
    },
  });

  const [filterSearch, setFilterSearch] = useState({
    location: "",
    assets: "",
    requestedBy: "",
  });

  const [newData, setNewData] = useState(data);

  const [openAssigneeType, setOpenAssigneeType] = useState(false);
  const [openAssignees, setOpenAssignees] = useState(false);
  const [openLocations, setOpenLocations] = useState(false);
  const [openDueDate, setOpenDueDate] = useState(false);
  const [openPriority, setOpenPriority] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);

  const WRstatusOptions = [
    {
      value: "approved",
      color: "green",
      label: t.workRequests.status.approved,
    },
    {
      value: "pending",
      color: "orange",
      label: t.workRequests.status.pending,
    },
    {
      value: "declined",
      color: "red",
      label: t.workRequests.status.declined,
    },
  ];

  const priorityOptions = [
    {
      value: "none",
      label: t.workOrders.priority_status.none,
      color: null,
    },
    {
      value: "low",
      label: t.workOrders.priority_status.low,

      color: "yellow",
    },
    {
      value: "medium",
      label: t.workOrders.priority_status.medium,

      color: "orange",
    },
    {
      value: "high",
      label: t.workOrders.priority_status.high,
      color: "red",
    },
  ];

  const statusOptions = [
    {
      value: "open",
      color: "blue",
      label: t.workOrders.work_order_status.open,
    },
    {
      value: "reOpen",
      color: "light-purple",
      label: t.workOrders.work_order_status.reOpen,
    },
    {
      value: "cancelled",
      color: "darkgrey",
      label: t.workOrders.work_order_status.cancel,
    },
    {
      value: "inProgress",
      color: "yellow",
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: "onHold",
      color: "red",
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: "inReview",
      color: "green",
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: "completed",
      color: "darkblue",
      label: t.workOrders.work_order_status.completed,
    },
  ];

  const closeAllFilters = () => {
    setOpenAssigneeType(false);
    setOpenAssignees(false);
    setOpenLocations(false);
    setOpenDueDate(false);
    setOpenPriority(false);
    setOpenStatus(false);
  };

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user) {
      return state?.auth?.user?.locations;
    } else {
      return null;
    }
  });

  const handleChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ""
      );
      setSelectedDates(isoDates);
    }
  };

  /* headers for excel and pdf file */
  const headers = [
    { label: t.workOrders.table.ID, key: "id" },
    { label: t.workOrders.table.title, key: "title" },
    // { label: t.assets.form.description, key: 'description' },
    { label: t.workRequests.table.wr_status, key: "status" },
    { label: t.workRequests.table.wo_status, key: "wo_status" },
    { label: t.workOrders.table.priority, key: "priority" },
    { label: t.workOrders.table.location, key: "location" },
    { label: t.workRequests.table.asset, key: "asset" },
    { label: t.workRequests.table.wo_id, key: "wo_id" },
    { label: t.workRequests.table.sub_date, key: "sub_date" },
    { label: t.workRequests.table.sub_by, key: "sub_by" },
  ];

  /* The function `downloadXLS` fetches WO data, formats it, and downloads it as an Excel file. */
  const downloadXLS = async () => {
    const arraysNotEmpty = Object.values(filterOptions).some(
      (option) => Array.isArray(option) && option.length > 0
    );

    if (!arraysNotEmpty && searchTitle == "" && selectedDates.length == 0) {
      let mydata = await dispatch(
        fetchAllWRData({ organisationId, role, currentUserId,userId })
      );

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }

    let csvData = [];
    for (let i = 0; i < fileData.length; i++) {
      let wr_data = [];

      wr_data.id = fileData[i].id;
      wr_data.title = fileData[i].attributes.title || t.common.na;

      const workRequestStatus = WRstatusOptions.find(
        (status) => status?.value === fileData[i]?.attributes?.status
      );

      wr_data.status = workRequestStatus?.label || t.common.na;

      const workOrderStatus = statusOptions.find(
        (status) =>
          status.value ===
          fileData[i]?.attributes?.workOrder?.data?.attributes?.status
      );
      wr_data.wo_status = workOrderStatus?.label || "";

      const PriorityStatus = priorityOptions.find(
        (status) => status.value === fileData[i].attributes.priority
      );

      wr_data.priority = PriorityStatus?.label || t.common.na;

      // wr_data.description = fileData[i]?.attributes?.description
      //   ? fileData[i].attributes.description
      //   : t.common.na;

      wr_data.location = fileData[i].attributes?.location?.data?.attributes
        ?.name
        ? fileData[i].attributes.location.data.attributes.name
        : t.common.na;

      let assets = fileData[i].attributes?.assets?.data;
      let assetsString = "";

      if (assets?.length > 0) {
        assetsString = assets
          .map((people) => people?.attributes?.name)
          .join(", ");
      } else {
        assetsString = t.common.na;
      }
      wr_data.asset = assetsString;

      wr_data.wo_id = fileData[i].attributes?.workOrder?.data?.id
        ? fileData[i].attributes?.workOrder?.data?.id
        : "";

      wr_data.sub_date = fileData[i]?.attributes?.createdAt
        ? moment(fileData[i].attributes?.createdAt).format("DD/MM/YYYY")
        : t.common.na;

      wr_data.sub_by =
        fileData[i].attributes?.submittedBy?.data?.attributes?.firstName ||
        fileData[i]?.attributes?.submittedBy?.data?.attributes?.lastName
          ? fileData[i].attributes?.submittedBy?.data?.attributes?.firstName +
            " " +
            fileData[i].attributes.submittedBy.data.attributes.lastName
          : t.common.na;

      csvData.push([
        wr_data.id,
        wr_data.title,
        wr_data.status,
        wr_data.wo_status,
        wr_data.priority,
        wr_data.location,
        wr_data.asset,
        wr_data.wo_id,
        wr_data.sub_date,
        wr_data.sub_by,
      ]);
    }
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 25 }, // Title column
      { wch: 20 }, // Description column
      { wch: 25 }, // Category column
      { wch: 20 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 35 }, // Created At column
      { wch: 10 }, // Updated At column
      { wch: 25 }, // Updated At column
      { wch: 30 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      t.workRequests.title
    );
  };

  const fetchLocationOptions = async () => {
    closeAllFilters();

    setOpenLocations(true);
    let the_options = [];

    if (filtersTrigger.locations.initOptions.length < 1) {
      const fetchData = await getAllLocations(organisationId);
      if (fetchData) {
        the_options = fetchData.map((el) => {
          return {
            key: el.id,
            value: el.id,
            text: el.name,
          };
        });

        setFiltersTrigger({
          ...filtersTrigger,
          locations: {
            ...filtersTrigger.locations,
            initOptions: the_options,
            options: the_options,
          },
        });
      } else {
        setFiltersTrigger({
          ...filtersTrigger,
          locations: {
            ...filtersTrigger.locations,
          },
        });
      }
    }
  };

  const fetchUsersOptions = async () => {
    const fetchData = await getUsersByRole(["supervisor"], organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.firstName,
        };
      });
      setFiltersTrigger({
        ...filtersTrigger,
        requestedByType: "supervisors",
        requestedBy: {
          ...filtersTrigger.requestedBy,
          supervisors: {
            ...filtersTrigger.requestedBy.supervisors,
            initOptions: the_options,
            options: the_options,
          },
        },
      });
    }
  };

  useEffect(() => {
    (async function() {
      // const fetchData = await getAllAssets(organisationId,'all_asset',1,9999,!(role === 'super_admin' || role === 'admin') ? currentUserId : []);
      let the_options_asset = [];
      let the_options = [];
      let the_options_user = [];
      let the_options_loc = [];

      const fetchData = await getAllAssetsOption(
        organisationId,
        "all_asset",
        1,
        9999,
        data?.locations
          ? [data?.locations]
          : !(role === "super_admin")
          ? data?.locations
            ? [data?.locations]
            : []
          : [],
        [],
        "",
        "",
        [],
        ["name:asc"]
      );

      let assetOptions = [];
      let parentToChildMap = new Map();
      let childToGrandChildMap = new Map();
      let hierarchicalOptions = [];

      if (fetchData?.response?.length > 0) {
        fetchData.response.forEach((el) => {
          const isDisposed = el?.attributes?.status === "dispose";
      
          // Access rule: Non-admin users can't access certain assets
          const isActive = !isDisposed && (!(role === "super_admin")
            ? (data?.locations
              ? data?.locations == el?.attributes?.location?.data?.id
              : currentUserId?.includes(el?.attributes?.location?.data?.id))
            : true);
      
          // Filter out disposed or inactive assets with no descendants
          if (isDisposed || (!isActive && !el?.attributes?.storedAssets?.length)) {
            return;
          }
      
          // Create parent asset option
          const parentAsset = {
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: parent_icon }, // Parent asset icon
            className: "main-location-dropdown-option",
            disabled: !isActive, // Disable only for non-admin roles
          };
      
          let hasAccessibleChildren = false;
          let hasAccessibleGrandchildren = false;
      
          parentToChildMap.set(el.id, []);
          assetOptions.push(parentAsset);
      
          // Collect child assets
          if (el?.attributes?.storedAssets?.length > 0) {
            el.attributes.storedAssets.forEach((childEl) => {
              if (childEl?.childAsset?.data) {
                const childAsset = childEl.childAsset.data;
                const childIsDisposed = childAsset?.attributes?.status === "dispose";
      
                const childIsActive = !childIsDisposed && (!(role === "super_admin")
                  ? (data?.locations
                    ? data?.locations == childAsset?.attributes?.location?.data?.id
                    : currentUserId?.includes(childAsset?.attributes?.location?.data?.id))
                  : true);
      
                // Filter out disposed or inactive child assets with no grandchildren
                if (childIsDisposed || (!childIsActive && !childEl?.grandChildAsset?.data?.length)) {
                  return;
                }
      
                // Track whether there are any accessible children
                if (childIsActive) hasAccessibleChildren = true;
      
                const childOption = {
                  key: childAsset.id,
                  value: childAsset.id,
                  text: `${childAsset.attributes.name}`,
                  image: { src: child_icon }, // Child asset icon
                  className: "sub-location-dropdown-option",
                  parentId: el.id,
                  disabled: !childIsActive, // Disable child based on role
                };
      
                parentToChildMap.get(el.id).push(childOption);
                childToGrandChildMap.set(childAsset.id, []);
      
                // Collect grandchild assets
                if (childEl?.grandChildAsset?.data?.length > 0) {
                  childEl.grandChildAsset.data.forEach((grandChildEl) => {
                    const grandChildIsDisposed = grandChildEl?.attributes?.status === "dispose";
      
                    const grandChildIsActive = !grandChildIsDisposed && (!(role === "super_admin")
                      ? (data?.locations
                        ? data?.locations == grandChildEl?.attributes?.location?.data?.id
                        : currentUserId?.includes(grandChildEl?.attributes?.location?.data?.id))
                      : true);
      
                    // Filter out disposed or inactive grandchild assets
                    if (grandChildIsDisposed || !grandChildIsActive) {
                      return;
                    }
      
                    // Track whether there are any accessible grandchildren
                    if (grandChildIsActive) hasAccessibleGrandchildren = true;
      
                    const grandChildOption = {
                      key: grandChildEl.id,
                      value: grandChildEl.id,
                      text: `${grandChildEl.attributes.name}`,
                      image: { src: gchild_icon }, // Grandchild asset icon
                      className: "grandchild-asset-dropdown-option",
                      parentId: el.id,
                      childParentId: childAsset.id,
                      disabled: !grandChildIsActive, // Disable based on role
                    };
      
                    childToGrandChildMap.get(childAsset.id).push(grandChildOption);
                  });
                }
              }
            });
          }
      
          // Ensure the parent asset is displayed if it has no accessible children or grandchildren
          if (!hasAccessibleChildren && !hasAccessibleGrandchildren && isActive) {
            // Clear the child options and only show the parent
            parentToChildMap.set(el.id, []);
          }
        });
      
        // Sort child and grandchild options alphabetically within each parent and child context
        parentToChildMap.forEach((children) => {
          children.sort((a, b) => a.text.localeCompare(b.text));
        });
      
        childToGrandChildMap.forEach((grandChildren) => {
          grandChildren.sort((a, b) => a.text.localeCompare(b.text));
        });
      
        // Combine all the options into a hierarchical structure
        assetOptions.forEach((parentOption) => {
          hierarchicalOptions.push(parentOption);
      
          const children = parentToChildMap.get(parentOption.key) || [];
          children.forEach((childOption) => {
            hierarchicalOptions.push(childOption);
      
            const grandChildren = childToGrandChildMap.get(childOption.key) || [];
            grandChildren.forEach((grandChildOption) => {
              hierarchicalOptions.push(grandChildOption);
            });
          });
        });
      }  
      // if (fetchData?.response?.length > 0) {
      //   fetchData.response.forEach((el) => {
      //     // Default to active if the user is admin or super_admin
      //     const isDisposed = el?.attributes?.status === "dispose";

      //     const isActive = isDisposed
      //       ? !isDisposed
      //       : !(role === "super_admin" || role === "admin")
      //       ? data?.locations
      //         ? data?.locations == el?.attributes?.location?.data?.id
      //         : currentUserId?.includes(el?.attributes?.location?.data?.id)
      //       : data?.locations
      //       ? data?.locations == el?.attributes?.location?.data?.id
      //       : !isDisposed; // In
      //     // (roleData === "super_admin" || roleData === "admin" || allIds?.includes(el?.attributes?.location?.data?.id)) &&
      //     // !isDisposed; // Include status check for active
      //     // const isActive = roleData === "super_admin" || roleData === "admin" ||
      //     //                  allIds?.includes(el?.attributes?.location?.data?.id);

      //     // Create the parent asset option
      //     const parentAsset = {
      //       key: el.id,
      //       value: el.id,
      //       text: el.attributes.name,
      //       image: { src: parent_icon }, // Use a location icon for the main asset
      //       className: "main-location-dropdown-option",
      //       disabled: !isActive, // Disable only for non-admin roles
      //     };

      //     parentToChildMap.set(el.id, []);
      //     assetOptions.push(parentAsset);

      //     // Collect child assets
      //     if (el?.attributes?.storedAssets?.length > 0) {
      //       el.attributes.storedAssets.forEach((childEl) => {
      //         if (childEl?.childAsset?.data) {
      //           const childAsset = childEl.childAsset.data;
      //           const childIsDisposed =
      //             childAsset?.attributes?.status === "dispose";

      //           const childIsActive = childIsDisposed
      //             ? !childIsDisposed
      //             : !(role === "super_admin" || role === "admin")
      //             ? data?.locations
      //               ? data?.locations ==
      //                 childAsset?.attributes?.location?.data?.id
      //               : currentUserId?.includes(
      //                   childAsset?.attributes?.location?.data?.id
      //                 )
      //             : data?.locations
      //             ? data?.locations ==
      //               childAsset?.attributes?.location?.data?.id
      //             : !childIsDisposed; // In

      //           const childOption = {
      //             key: childAsset.id,
      //             value: childAsset.id,
      //             text: `${childAsset.attributes.name}`,
      //             image: { src: child_icon }, // Use a sub-location icon for child assets
      //             className: "sub-location-dropdown-option",
      //             parentId: el.id,
      //             disabled: !childIsActive, // Disable child based on role
      //           };

      //           parentToChildMap.get(el.id).push(childOption);

      //           // Initialize a child-to-grandchild mapping for this child
      //           childToGrandChildMap.set(childAsset.id, []);

      //           // Collect grandchild assets under this child
      //           if (childEl?.grandChildAsset?.data?.length > 0) {
      //             childEl.grandChildAsset.data.forEach((grandChildEl) => {
      //               const grandChildIsDisposed =
      //                 grandChildEl?.attributes?.status === "dispose";

      //               const grandChildIsActive = grandChildIsDisposed
      //                 ? !grandChildIsDisposed
      //                 : !(role === "super_admin" || role === "admin")
      //                 ? data?.locations
      //                   ? data?.locations ==
      //                     grandChildEl?.attributes?.location?.data?.id
      //                   : currentUserId?.includes(
      //                       grandChildEl?.attributes?.location?.data?.id
      //                     )
      //                 : data?.locations
      //                 ? data?.locations ==
      //                   grandChildEl?.attributes?.location?.data?.id
      //                 : !grandChildIsDisposed;

      //               const grandChildOption = {
      //                 key: grandChildEl.id,
      //                 value: grandChildEl.id,
      //                 text: `${grandChildEl.attributes.name}`,
      //                 image: { src: gchild_icon }, // Optionally, use a different icon for grandchild
      //                 className: "grandchild-asset-dropdown-option",
      //                 parentId: el.id, // Track the top-level parent asset
      //                 childParentId: childAsset.id, // Track the immediate child parent ID
      //                 disabled: !grandChildIsActive, // Disable grandchild based on role
      //               };

      //               childToGrandChildMap
      //                 .get(childAsset.id)
      //                 .push(grandChildOption);
      //             });
      //           }
      //         }
      //       });
      //     }
      //   });

      //   // Sort child and grandchild options alphabetically within each parent and child context
      //   parentToChildMap.forEach((children) => {
      //     children.sort((a, b) => a.text.localeCompare(b.text));
      //   });

      //   childToGrandChildMap.forEach((grandChildren) => {
      //     grandChildren.sort((a, b) => a.text.localeCompare(b.text));
      //   });

      //   // Combine all the options into a hierarchical structure

      //   // Traverse each parent option and append its children and grandchildren
      //   assetOptions.forEach((parentOption) => {
      //     hierarchicalOptions.push(parentOption);

      //     // Add children under this parent
      //     const children = parentToChildMap.get(parentOption.key) || [];
      //     children.forEach((childOption) => {
      //       hierarchicalOptions.push(childOption);

      //       // Add grandchildren under this child
      //       const grandChildren =
      //         childToGrandChildMap.get(childOption.key) || [];
      //       grandChildren.forEach((grandChildOption) => {
      //         hierarchicalOptions.push(grandChildOption);
      //       });
      //     });
      //   });

      //   // setOptions((prev) => {
      //   //   return {
      //   //     ...prev,
      //   //     assets: hierarchicalOptions,
      //   //   };
      //   // });

      //   // return hierarchicalOptions;
      // }
      // if (fetchData.response) {
      //   fetchData.response.forEach((el) => {
      //     the_options_asset.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: el.attributes.image.data
      //           ? el.attributes.image.data.attributes.url
      //           : LetteredAvatarImageUrl(el.attributes.name + ' '),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }

      const fetchDataLocation = await getAllParentLocations(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        ["name:asc"]
      );

      let parentOptions = [];
      let subOptions = [];

      if (fetchDataLocation?.response?.length > 0) {
        fetchDataLocation.response.forEach((el) => {
          if (!(role === "super_admin")) {
            // Check if the element's ID is included in allIds
            if (currentUserId?.includes(el.id)) {
              // Add parent option normally
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: false, // Parent option enabled
              });

              // Collect sub-options
              if (el?.attributes?.subLocations?.data?.length > 0) {
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (currentUserId?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
              }
            } else {
              // If the element's ID is not in allIds, check sub-locations
              const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
                (subEl) => currentUserId?.includes(subEl.id)
              );

              if (hasMatchingSubLocation) {
                // Add parent option as disabled
                parentOptions.push({
                  key: el.id,
                  value: el.id,
                  text: el.attributes.name,
                  image: { src: location_icon },
                  className: "main-location-dropdown-option",
                  disabled: true, // Disable the parent option
                });

                // Collect matching sub-options
                el?.attributes?.subLocations?.data?.length > 0 &&
                  el.attributes.subLocations.data.forEach((subEl) => {
                    if (currentUserId?.includes(subEl.id)) {
                      subOptions.push({
                        key: subEl.id,
                        value: subEl.id,
                        text: subEl.attributes.name,
                        image: { src: sub_location_icon },
                        className: "sub-location-dropdown-option",
                        parentId: el.id, // Keep track of the parent
                      });
                    }
                  });
              }
            }
          } else {
            // Add parent option
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                subOptions.push({
                  key: subEl.id,
                  value: subEl.id,
                  text: subEl.attributes.name,
                  image: { src: sub_location_icon },
                  className: "sub-location-dropdown-option",
                  parentId: el.id, // Keep track of the parent
                });
              });
            }
          }
        });

        // Sort sub-options alphabetically
        subOptions.sort((a, b) => a.text.localeCompare(b.text));

        // Merge parent options with sorted sub-options
        parentOptions.forEach((parentOption) => {
          the_options_loc.push(parentOption);
          // Add sorted sub-options belonging to this parent
          subOptions.forEach((subOption) => {
            if (subOption.parentId === parentOption.key) {
              the_options_loc.push(subOption);
            }
          });
        });

        // Update the options state
      }
      const fetchDataPeople = await getUsersByRole(
        [
          "user",
          "supervisor",
          "view_only",
          "admin",
          "requester",
          "super_admin",
          "limited_admin",
        ],
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        [],
        [],
        {},
        [],
        !(role === "super_admin") ? currentUserId : []
      );

      if (fetchDataPeople.response) {
        fetchDataPeople.response.map((el) => {
          the_options_user.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.firstName + " " + el.lastName,
            image: {
              avatar: true,
              src: el?.profileImage?.url
                ? el?.profileImage?.url
                : LetteredAvatarImageUrl(el.firstName + " " + el.lastName),
              style: { width: "20px", height: "20px" },
            },
          });
        });
      }

      setFiltersTrigger({
        ...filtersTrigger,
        assets: {
          ...filtersTrigger.assets,
          initOptions: hierarchicalOptions,
          options: hierarchicalOptions,
        },
        locations: {
          ...filtersTrigger.locations,
          initOptions: the_options_loc,
          options: the_options_loc,
        },
        requestedBy: {
          ...filtersTrigger.requestedBy,
          initOptions: the_options_user,
          options: the_options_user,
        },
      });
    })();
  }, []);

  const fetchCustomersOptions = async () => {
    const fetchData = await getUsersByRole(["customer"], organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.customer.name,
        };
      });
      setFiltersTrigger({
        ...filtersTrigger,
        requestedByType: "customers",
        requestedBy: {
          ...filtersTrigger.requestedBy,
          customers: {
            ...filtersTrigger.requestedBy.customers,
            initOptions: the_options,
            options: the_options,
          },
        },
      });
    }
  };

  const handleRequestedByTypeChange = (value) => {
    if (value == "supervisors") {
      fetchUsersOptions();
    }
    if (value == "customers") {
      fetchCustomersOptions();
    }
  };

  const handleSearchLocationChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      location: e.target.value,
    });
  };

  const handleSearchRequestedByChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      requestedBy: e.target.value,
    });
  };

  const handleSearchAssetsChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      assets: e.target.value,
    });
  };

  const handleSearchPeopleChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      requestedBy: e.target.value,
    });
  };

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.location), "i");
    const isMatch = (result) => re.test(result.text);
    setFiltersTrigger({
      ...filtersTrigger,
      locations: {
        ...filtersTrigger.locations,
        options:
          filterSearch.location.length > 0
            ? _.filter(filtersTrigger.locations.initOptions, isMatch)
            : filtersTrigger.locations.initOptions,
      },
    });
  }, [filterSearch.location]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.assets), "i");
    const isMatch = (result) => re.test(result.text);
    setFiltersTrigger({
      ...filtersTrigger,
      assets: {
        ...filtersTrigger.assets,
        options:
          filterSearch.assets.length > 0
            ? _.filter(filtersTrigger.assets.initOptions, isMatch)
            : filtersTrigger.assets.initOptions,
      },
    });
  }, [filterSearch.assets]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.requestedBy), "i");
    const isMatch = (result) => re.test(result.text);
    setFiltersTrigger({
      ...filtersTrigger,
      requestedBy: {
        ...filtersTrigger.requestedBy,
        options:
          filterSearch.requestedBy.length > 0
            ? _.filter(filtersTrigger.requestedBy.initOptions, isMatch)
            : filtersTrigger.requestedBy.initOptions,
      },
    });
  }, [filterSearch.requestedBy]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.requestedBy), "i");
    const isMatch = (result) => re.test(result.text);

    // if (filtersTrigger.requestedByType == 'supervisors') {
    //   setFiltersTrigger({
    //     ...filtersTrigger,
    //     requestedBy: {
    //       ...filtersTrigger.requestedBy,
    //       supervisors: {
    //         ...filtersTrigger.requestedBy.supervisors,
    //         options:
    //           filterSearch.requestedBy.length > 0
    //             ? _.filter(
    //                 filtersTrigger.requestedBy.supervisors.initOptions,
    //                 isMatch
    //               )
    //             : filtersTrigger.requestedBy.supervisors.initOptions,
    //       },
    //     },
    //   });
    // } else {
    //   setFiltersTrigger({
    //     ...filtersTrigger,
    //     requestedBy: {
    //       ...filtersTrigger.requestedBy,
    //       customers: {
    //         ...filtersTrigger.requestedBy.customers,
    //         options:
    //           filterSearch.requestedBy.length > 0
    //             ? _.filter(
    //                 filtersTrigger.requestedBy.customers.initOptions,
    //                 isMatch
    //               )
    //             : filtersTrigger.requestedBy.customers.initOptions,
    //       },
    //     },
    //   });
    // }
  }, [filterSearch.requestedBy]);

  let _newData = newData;
  useEffect(() => {
    (async function() {
      setloader(true);
      await dispatch(
        fetchWRWithFilter({
          organisationId,
          searchName: debounceValue,
          page: "1",
          pageSize: "25",
          role,
          currentUserId,
          userId,
          people: filterOptions.requestedBy,
          status: filterOptions.status,
          priority: filterOptions.priority,
          location: filterOptions.locations,
          assets: filterOptions.assets,
          submissionDate: selectedDates,
          sort: sortData,
        })
      );

      setloader(false);
    })();
    // if (filterOptions.requestedBy.supervisors.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return filterOptions.requestedBy.supervisors.includes(
    //       el.requestedBy.id
    //     );
    //   });

    // if (filterOptions.requestedBy.customers.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return filterOptions.requestedBy.customers.includes(el.requestedBy.id);
    //   });

    // if (filterOptions.locations.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return filterOptions.locations.includes(el.location.id);
    //   });

    // if (filterOptions.priority.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return filterOptions.priority.includes(el.priority);
    //   });

    // if (filterOptions.status.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return filterOptions.status.includes(el.status);
    //   });

    // if (searchTitle.length > 0) {
    //   const re = new RegExp(_.escapeRegExp(searchTitle), 'i');
    //   const isMatch = (result) => re.test(result.title);

    //   _newData =
    //     searchTitle.length > 0 ? _.filter(_newData, isMatch) : _newData;
    // }

    // if (_newData.length > 0) {
    //   setDataByFilter(_newData);
    // } else {
    //   setDataByFilter([]);
    // }
  }, [filterOptions, debounceValue, selectedDates]);

  return (
    <>
      {/* <div>
        <div className="text-right mb-5 pb-5">
          <div
            style={{ gap: "23px" }}
            className="table-searchbar d-flex justify-content-end"
          >
            <div className="work-order-upload">
              <span className="search-svg">
                <SearchIcon />
              </span>
              <Input
                icon={{ name: "search" }}
                placeholder={t.workOrders.filter.search}
                value={searchTitle}
                onChange={(e) => {
                  setSearchTitle(e.target.value);
                }}
              />
            </div>

         
          </div>
        </div>
      </div> */}
      <div className="filters-wrapper justify-content-between mb-0">
        <div className="flexbox table-right-dropdown d-flex ">
          <LocationSearchDropdown
            title={t.workOrders.table.location}
            options={filtersTrigger.locations.options}
            selectedValues={filterOptions.locations}
            searchValue={filterSearch.location}
            handleSearchValue={handleSearchLocationChange}
            onClick={(value) => {
              let finalValue = [value.value];
              if (!value?.parentId) {
                let subData = filtersTrigger.locations.initOptions.filter(
                  (el) => el?.parentId === value.value
                );

                let subDataValues = subData?.map((item) => item?.value);
                let subValues = subData?.map((item) => item?.value);

                subDataValues.push(value.value);
                let filterOptionsValues = filterOptions.locations;

                let filteredSubDataValues = subDataValues.filter((value) =>
                  filterOptionsValues.includes(value)
                );

                let valuesNotInFilterOptions = subValues.filter(
                  (value) => !filterOptionsValues.includes(value)
                );
                let valuesInFilterOptions = subValues.filter((value) =>
                  filterOptionsValues.includes(value)
                );

                if (filteredSubDataValues?.length > 0) {
                  if (
                    subValues.every((value) =>
                      filterOptionsValues.includes(value)
                    ) &&
                    !filterOptionsValues.includes(value.value)
                  ) {
                    finalValue = [value.value];
                  } else if (
                    valuesNotInFilterOptions?.length > 0 &&
                    filterOptionsValues?.includes(value.value)
                  ) {
                    finalValue = [...valuesInFilterOptions, value.value];
                  } else if (valuesNotInFilterOptions?.length > 0) {
                    finalValue = [...valuesNotInFilterOptions, value.value];
                  } else {
                    finalValue = [...filteredSubDataValues];
                  }
                } else {
                  finalValue = [...subDataValues];
                }
              }

              let newArray = toggle(filterOptions.locations, finalValue);
              setFilterOptions({
                ...filterOptions,
                locations: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                location: "",
              });
            }}
          />
          {/* <CategoryDropdown
            issearch={true}
            title={t.workOrders.table.location}
            options={filtersTrigger.locations.options}
            selectedValues={filterOptions.locations}
            searchValue={filterSearch.location}
            handleSearchValue={handleSearchLocationChange}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  locations: [...filterOptions.locations, e.target.value],
                });
              } else {
                let filteredLocations = filterOptions.locations.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  locations: filteredLocations,
                });
              }
            }}
            icon={false}
            image={false}
          /> */}

          <LocationSearchDropdown
            title={t.workRequests.table.asset}
            options={filtersTrigger.assets.options}
            selectedValues={filterOptions.assets}
            searchValue={filterSearch.assets}
            handleSearchValue={handleSearchAssetsChange}
            onClick={(value) => {
              let newArray = toggle(filterOptions.assets, value.value);

              setFilterOptions({
                ...filterOptions,
                assets: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                assets: "",
              });
            }}
          />

          {/* <WorkOrderSearchDropdown
            title={t.workRequests.table.asset}
            options={filtersTrigger.assets.options}
            selectedValues={filterOptions.assets}
            searchValue={filterSearch.assets}
            handleSearchValue={handleSearchAssetsChange}
            onClick={(value) => {
              let newArray = toggle(filterOptions.assets, value.value);

              setFilterOptions({
                ...filterOptions,
                assets: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                assets: '',
              });
            }}
          /> */}
          <PriorityDropdown
            title={t.workOrders.table.priority}
            options={filtersTrigger.priority.options}
            pill={true}
            selectedValues={filterOptions.priority}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  priority: e.target.value ? [e.target.value] : [],
                });
              } else {
                setFilterOptions({
                  ...filterOptions,
                  priority: [],
                });
              }
            }}
            icon={false}
            image={false}
          />

          <PriorityDropdown
            title={t.workOrders.table.status}
            options={filtersTrigger.status.options}
            selectedValues={filterOptions.status}
            pill={false}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  status: e.target.value ? [e.target.value] : [],
                });
              } else {
                setFilterOptions({
                  ...filterOptions,
                  status: [],
                });
              }
            }}
            icon={false}
            image={false}
            label={true}
          />

          <WorkOrderSearchDropdown
            title={t.workRequests.table.sub_by}
            options={filtersTrigger.requestedBy.options}
            selectedValues={filterOptions.requestedBy}
            searchValue={filterSearch.requestedBy}
            handleSearchValue={handleSearchPeopleChange}
            onClick={(value) => {
              let newArray = toggle(filterOptions.requestedBy, value.value);

              setFilterOptions({
                ...filterOptions,
                requestedBy: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                requestedBy: "",
              });
            }}
          />

          <div className="wo-date-picker" key={resetKey}>
            <DatePicker
              style={
                selectedDates.length <= 1
                  ? { width: "170px" }
                  : { width: "188px" }
              }
              value={selectedDates}
              onChange={handleChange}
              showOtherDays={true}
              range
              showMonthDropdown
              showYearDropdown
              placeholder={t.workRequests.table.sub_date}
              className="active"
              weekDays={[
                t.workOrders.details_view.sun,
                t.workOrders.details_view.mon,
                t.workOrders.details_view.tue,
                t.workOrders.details_view.wed,
                t.workOrders.details_view.thu,
                t.workOrders.details_view.fri,
                t.workOrders.details_view.sat,
              ]}
              months={[
                t.common.january,
                t.common.february,
                t.common.march,
                t.common.april,
                t.common.may,
                t.common.june,
                t.common.july,
                t.common.august,
                t.common.september,
                t.common.october,
                t.common.november,
                t.common.december,
              ]}
              format="DD/MM/YY"
            >
              <button
                onClick={() => {
                  setSelectedDates([]);
                  setResetKey((pre) => !pre);
                }}
                className="btn-datepicker blue"
              >
                {t.common.reset}
              </button>
            </DatePicker>
          </div>
          {selectedDates?.length > 0 ||
                  Object.values(filterOptions).some(option => option?.length > 0
          ) ? (
          <div className="form-button-container mt-3">
            <button
              // content={t.procedures.form.submit.add}
              // primary
              onClick={() => {
                setSelectedDates([]);
                setFilterOptions({
                  requestedBy: [],
                  locations: [],
                  assets: [],
                  priority: [],
                  status: [],
                  
                  priority: [],
                  status: [],
                });
              }}
              // loading={buttonStatus == 1}
              style={{ marginTop: "-10px" }}
              className="form-action-button dashboard"
            >
              {t.dashboard.cards.clear}
            </button>
          </div> ) : null}
        </div>
        <div className="d-flex" style={{ gap: "10px" }}>
        <div className="table-searchbar work-order-upload">
            <span className="search-svg">
              <SearchIcon />
            </span>
            <Input
              icon={{ name: "search" }}
              placeholder={t.workOrders.filter.search}
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
            />
          </div>
        <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ">
              <UncontrolledDropdown className="mr-2" direction="down">
                <DropdownToggle
                  style={{ position: "relative", cursor: "pointer" }}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  color="primary"
                  className="option-toggle"
                >
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "ar" ? "top right" : "top left"
                    }
                    show={showTooltip}
                    textBoxWidth={selectedLanguage == "ar" ? "53px" : "87px"}
                    arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
                    fontSize="12px"
                    fontWeight="400"
                    fontFamily={
                      selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                    }
                    padding="4px 4px 4px 8px"
                    borderRadius="5px"
                    static
                    moveRight="10px"
                    moveUp={selectedLanguage == "ar" ? "-1px" : "4px"}
                  >
                    <span className="work-order-tooltip">
                      {t.common.page_action}
                    </span>
                  </ToolTip>
                  <ActionBtnIcon />{" "}
                </DropdownToggle>
                <DropdownMenu className={selectedLanguage == "ar" ? "rtl" : ""}>
                  {/* {role !== 'view_only' ? (
                <>
                  <DropdownItem>
                    <ShareIcon /> {t.common.share}
                  </DropdownItem>
                  <DropdownItem className="divider" />
                </>
              ) : null} */}
                  <DropdownItem className="divider" />
                  <DropdownItem onClick={() => downloadXLS()}>
                    <DownloadIcon /> {t.common.download_sheet}
                  </DropdownItem>
                  {/* <DropdownItem className="divider" />
              <DropdownItem onClick={() => generatePDF()}>
                <PdfIcon /> {t.common.download_pdf}
              </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div> </div>
      </div>
    </>
  );
};

export default Filters;
